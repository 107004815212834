import React from "react";
import { injectIntl } from "react-intl";
import { ACCOUNT_ORIGIN, PROTECTED_SCREEN_ORIGIN } from "enums/twoFactorAuth";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { TwoFactorAccountVerify } from "./TwoFactorAccountVerify";
import { TwoFactorLoginVerify } from "./TwoFactorLoginVerify";

const TwoFactorVerifyModalContent = ({
  intl: { formatMessage },
  mfaPhone,
  error,
  codeResent,
  setup,
  origin,
  loading,
  enrolled,
  actionName,
}) => {
  const formattedNumber = () => {
    const phoneNumber = parsePhoneNumberFromString(mfaPhone);
    const formattedNumberArray = phoneNumber.formatInternational().split(" ");
    return (
      formattedNumberArray.slice(0, 1) +
      " " +
      formattedNumberArray.slice(1, formattedNumberArray.length).join("-")
    );
  };

  return (origin === ACCOUNT_ORIGIN || origin === PROTECTED_SCREEN_ORIGIN) ? (
    <TwoFactorAccountVerify
      formattedMfaPhone={formattedNumber()}
      origin={origin}
      error={error}
      loading={loading}
      codeResent={codeResent}
      formatMessage={formatMessage}
      enrolled={enrolled}
      actionName={actionName}
    />
  ) : (
    <TwoFactorLoginVerify
      setup={setup}
      formattedMfaPhone={formattedNumber()}
      error={error}
      loading={loading}
      codeResent={codeResent}
      formatMessage={formatMessage}
      enrolled={enrolled}
    />
  );
};

export const TwoFactorVerifyModal = injectIntl(TwoFactorVerifyModalContent);
