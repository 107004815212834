import React from "react";
import styled from "styled-components";
import { defineMessages } from "react-intl";
import { FormattedMessage } from "../../../../../FormattedMessage";
import { internalPath } from "../../../../../../util/internalPath";

const messages = defineMessages({
  popularTasks: {
    id:
      "StyledHeaderNavContainer.HeaderNav.HeaderLinks.ServicesMenu.DesktopList.popularTasks",
    defaultMessage: "Popular tasks",
  },
});

export function DesktopList({ isMenuOpen, links }) {
  if (!isMenuOpen) {
    return null;
  }

  return (
    <DesktopStyledList>
      <ServicesListHeader>
        <FormattedMessage message={messages.popularTasks} />
      </ServicesListHeader>

      {links.map((link) => (
        <StyledListItem key={link.path + link.title}>
          <a href={internalPath(link.path)}>{link.title}</a>
        </StyledListItem>
      ))}
    </DesktopStyledList>
  );
}

export const servicesMenuZIndex = 1;

const DesktopStyledList = styled.ul`
  position: absolute;
  top: 100%;
  right: 0;
  width: 315px;
  background: ${(props) => props.theme.color.white};
  padding-top: ${(props) => props.theme.spacing.sm};
  padding-bottom: ${(props) => props.theme.spacing.sm};
  padding-left: ${(props) => props.theme.spacing.xxxxl};
  padding-right: ${(props) => props.theme.spacing.xxxxl};

  border: 1px solid ${(props) => props.theme.color.buttonBorder};
  box-shadow: 2px 4px 3px 0 ${(props) => props.theme.color.dropShadow};
  z-index: ${servicesMenuZIndex};
`;

const ServicesListHeader = styled.div`
  margin: ${(props) => props.theme.spacing.sm};
  text-transform: uppercase;
  font-weight: ${(props) => props.theme.typography.weight.regular};
`;

const StyledListItem = styled.li`
  padding: ${(props) => props.theme.spacing.sm};
`;
