import { defineMessages } from "react-intl";
import { getOneLink } from "services/AppsflyerService";

const messages = defineMessages({
  clientIosLinkAlt: {
    id: "components.Footer.StyledFooter.NativeAppBadges.StoreBadge.clientIosLinkAlt",
    defaultMessage: "Download on the App Store",
  },
  clientAndroidLinkAlt: {
    id: "components.Footer.StyledFooter.NativeAppBadges.StoreBadge.clientAndroidLinkAlt",
    defaultMessage: "Get it on Google Play",
  },
});

export function useBadgeLinkData({ formatMessage, storeType }) {
  if (storeType === "appStore") {
    return {
      imgAlt: formatMessage(messages.clientIosLinkAlt),
      storeHref: getOneLink("client"),
    };
  }

  if (storeType === "playStore") {
    return {
      imgAlt: formatMessage(messages.clientAndroidLinkAlt),
      storeHref: getOneLink("client"),
    };
  }

  throw Error(`Unexpected storeType: "${storeType}"`);
}
