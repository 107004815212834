import React, { Component } from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { findDOMNode } from "react-dom";
import { Portal } from "react-portal";
import * as searchActions from "containers/SearchBar/redux/searchBar";
import find from "lodash/find";

export default function enhanceSearchBar({
  name,
  resultsPromise,
  processResults = i => i,
  ResultsClass,
}) {
  return WrappedComponent => {
    const mapStateToProps = state => {
      const data = state.searchBar[name];
      if (!data) return { isLoading: true };
      const { query, isLoading, results, selected } = data;
      return {
        query,
        isLoading,
        selected,
        results: processResults(results, query),
      };
    };

    const actions = Object.keys(searchActions).reduce((memo, fnName) => {
      memo[fnName] = searchActions[fnName].bind(null, name);
      return memo;
    }, {});

    const connectFunction = connect(mapStateToProps, {
      ...actions,
      performSearch: actions.performSearch(resultsPromise),
    });

    class SearchBarWrapper extends Component {
      constructor() {
        super();
        this.onSelectItem = this.onSelectItem.bind(this);
        this.setIsOpened = this.setIsOpened.bind(this);
        this.determineInputDimensions = this.determineInputDimensions.bind(
          this
        );
        this.state = { bottom: 0, left: 0, width: 0, isOpened: false };
      }

      componentDidMount() {
        window.addEventListener("resize", this.determineInputDimensions);
      }

      componentWillUnmount() {
        window.removeEventListener("resize", this.determineInputDimensions);
      }

      componentWillMount() {
        this.SearchBarResultsWrapper = enhanceSearchBarResults(
          name,
          ResultsClass,
          this.onSelectItem
        );
        this.props.initializeSearchBar();
      }

      getInputDimensionValue() {
        const el = findDOMNode(this);
        if (!el) return;
        const dims = el.getBoundingClientRect();
        const { bottom, left, width } = dims;
        const scrollTop = window.pageYOffset;

        return {
          bottom: bottom + scrollTop,
          left,
          width,
        };
      }

      determineInputDimensions() {
        this.setState(this.getInputDimensionValue());
      }

      setIsOpened(bool) {
        this.setState({
          isOpened: bool,
          ...this.getInputDimensionValue(),
        });
      }

      onSelectItem(selected) {
        const item = selected || find(this.props.results.items, "selected");
        this.props.setSelected(item);
      }

      render() {
        const { bottom, left, width, isOpened } = this.state;
        return (
          <WrappedComponent
            {...this.props}
            bottom={bottom}
            left={left}
            width={width}
            isOpened={isOpened}
            setIsOpened={this.setIsOpened}
            SearchBarResults={this.SearchBarResultsWrapper}
          />
        );
      }
    }

    return connectFunction(injectIntl(SearchBarWrapper));
  };
}

function enhanceSearchBarResults(name, WrappedComponent, onSelectItem) {
  return class SearchBarResultsWrapper extends Component {
    constructor() {
      super();
      this.handleKeyPress = this.handleKeyPress.bind(this);
      this.handleTab = this.handleTab.bind(this);
      this.onSelectItem = this.onSelectItem.bind(this);
    }

    componentDidMount() {
      document.addEventListener("keyup", this.handleKeyPress, false);
      document.addEventListener("keydown", this.handleTab, false);
    }

    componentWillUnmount() {
      document.removeEventListener("keyup", this.handleKeyPress, false);
      document.removeEventListener("keydown", this.handleTab, false);
    }

    // keydown needed for TAB. so we can block the default action (move to next field)
    handleTab(e) {
      if (e.keyCode === 9) this.handleKeyPress(e);
    }

    handleKeyPress(e) {
      const { prev, next } = this.props;
      switch (e.keyCode) {
        // Return/Enter/Tab
        case 9:
        case 13:
          this.onSelectItem();
          break;

        // Escape
        case 27:
          this.props.setIsOpened(false);
          break;

        // Left & Up arrow
        case 37:
        case 38:
          prev();
          break;

        // Right & Down arrow
        case 39:
        case 40:
          next();
          break;
        default:
          break;
      }
    }

    componentWillReceiveProps(nextProps) {
      const { query } = nextProps;
      if (query && query !== this.props.query)
        return this.props.setIsOpened(true);
    }

    onSelectItem(item) {
      onSelectItem(item);
      this.props.setIsOpened(false);
    }

    render() {
      const BORDER_WIDTH = 1;
      const { isOpened, bottom, left, width } = this.props;
      const position = {
        position: "absolute",
        top: bottom,
        left: left + BORDER_WIDTH,
        width: width - BORDER_WIDTH * 2,
      };
      return isOpened ? (
        <Portal>
          <WrappedComponent
            {...this.props}
            position={position}
            onSelectItem={this.onSelectItem}
          />
        </Portal>
      ) : null;
    }
  };
}
