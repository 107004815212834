import isNumber from "lodash/isNumber";
import findIndex from "lodash/findIndex";
import storage from "util/localStorage";
import { ALERTS_STORAGE_KEY, FLASH_STORAGE_KEY } from "util/constants";

export const CREATE_ALERT = "alerts/CREATE_ALERT";
export const EXPIRE_ALERT = "alerts/EXPIRE_ALERT";

let alertIndex = 0;

const DEFAULT_EXPIRATION_TIME = 7000;

function calcTimeout(autoRemove) {
  if (autoRemove === false) {
    return 0;
  }
  if (isNumber(autoRemove)) {
    return autoRemove;
  }
  return DEFAULT_EXPIRATION_TIME;
}

export function expireAlert(id) {
  return { type: EXPIRE_ALERT, id };
}

function addAlert(alert) {
  return { type: CREATE_ALERT, alert };
}

export function createAlert(alertData) {
  let messages = [];
  // convert alert arg to the proper format
  if (typeof alertData === "string" || alertData instanceof Array) {
    messages = [].concat(alertData);
  }

  const alert = {
    ...alertData,
    messages: messages.concat(alertData.messages || []), // concat allows string & array to be passed
    values: alertData.values,
    id: (alertIndex += 1),
    type: alertData.type || "message", // default message type
  };

  return (dispatch) => {
    const timeout = calcTimeout(alertData.autoRemove);
    if (timeout > 0) {
      setTimeout(() => {
        dispatch(expireAlert(alert.id));
      }, timeout);
    }
    return dispatch(addAlert(alert));
  };
}

export const init = () => (dispatch) => {
  let messages = storage.get(ALERTS_STORAGE_KEY) || [];
  messages = messages.concat(storage.get(FLASH_STORAGE_KEY) || []);

  storage.set(ALERTS_STORAGE_KEY, []);
  storage.set(FLASH_STORAGE_KEY, []);
  messages.forEach((message) => dispatch(createAlert(message)));
};

// create notifier for next single-page app
export const notifyNextApp = (message) => {
  const messages = storage.get(ALERTS_STORAGE_KEY) || [];
  messages.push(message);
  storage.set(ALERTS_STORAGE_KEY, messages);
};

const initialState = {
  alerts: [],
};

export default function account(state = initialState, action) {
  switch (action.type) {
    case CREATE_ALERT:
      return { alerts: [action.alert, ...state.alerts] };
    case EXPIRE_ALERT: {
      const index = findIndex(state.alerts, { id: action.id });
      return {
        alerts: [
          ...state.alerts.slice(0, index),
          ...state.alerts.slice(index + 1),
        ],
      };
    }

    default:
      return state;
  }
}
