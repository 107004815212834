import { TrMetricsService } from "services/TrMetricsService";
import { snake_caseKeys } from "util/casing";

/**
 * Middleware to fire off event to pie.js
 * Will snake_case keys, unless told to preserve case
 * This allows
 */
const metricMiddleware = store => next => action => {
  if (!action.meta || !action.meta.metrics) {
    return next(action);
  }

  const { data, eventKey } = action.meta.metrics;

  const metricData = action.preserveCase ? data : snake_caseKeys(data);
  if (metricData) metricData.client_publish_key = eventKey;

  if (eventKey === "page_referred") {
    TrMetricsService.fire(eventKey, metricData);
  } else {
    TrMetricsService.fire(eventKey, metricData);
  }
  return next(action);
};

export const fireMetric = (eventKey, data, preserveCase) => ({
  type: "METRIC_CAPTURE_EVENT",
  preserveCase,
  meta: {
    metrics: {
      eventKey,
      data,
    },
  },
});

/** A few metrics are fired with camelCased keys, we can't change them now
 *  as it would mess up reporting. Use this method to preserve casing
 */
export const fireMetricPreserveCase = (event, data) =>
  fireMetric(event, data, true);

export default metricMiddleware;
