import countryIsoCodeFromLocale from "util/countryIsoCodeFromLocale";

const countryConfigObj = {
  US: {
    "account.transactions.showServiceFee": false,
    "account.transactions.showFixedServiceFee": false,
    "ikea.baseAssemblyRate": 36,
    "legal.gdpr.enabled": false,
    "terms.includePrivacyInConsent": true,
    // Duplicate of TaskRabbit.country_config gift_card.enabled.
    "giftCard.enabled": true,
    "become_a_tasker.testimonial_sender_name": "Karsheem W., New York, NY",
    "become_a_tasker.background_check_index": "7_us",
    "become_a_tasker.pay_registration_fee": true,
    "signUp.requiresMarketingConsent": false,
    "signUp.requiresExplicitTermsConsent": false,
    "signUp.requiresImplicitTermsConsent": true,
    "signUp.rabbit.requiresSoleProprietor": true,
    "signUp.rabbit.requiresSoleProprietor.traderVariation": false,
    "trg.grant_application.url": "https://taskrabbit1.typeform.com/to/WFSSRHi9",
    "trg.cash_grant_amount": "$8,000",
    "trg.credit_grant_amount": "$2,000",
    "browseTaskers.showBackgroundCheckMessage": true,
    "account.menu.verification_required_link": "/account/business-info"
  },
  CA: {
    "account.transactions.showServiceFee": true,
    "account.transactions.showFixedServiceFee": false,
    "ikea.baseAssemblyRate": 38,
    "legal.gdpr.enabled": false,
    "terms.includePrivacyInConsent": true,
    // Duplicate of TaskRabbit.country_config gift_card.enabled.
    "giftCard.enabled": true,
    "become_a_tasker.testimonial_sender_name": "Jacob M., Vancouver",
    "become_a_tasker.background_check_index": "7_us",
    "become_a_tasker.pay_registration_fee": true,
    "signUp.requiresMarketingConsent": false,
    "signUp.requiresExplicitTermsConsent": true,
    "signUp.requiresImplicitTermsConsent": false,
    "signUp.rabbit.requiresSoleProprietor": true,
    "signUp.rabbit.requiresSoleProprietor.traderVariation": true,
    "trg.grant_application.url": "https://taskrabbit1.typeform.com/to/JpqFRpXY",
    "trg.cash_grant_amount": "$8,000",
    "trg.credit_grant_amount": "$2,000",
    "browseTaskers.showBackgroundCheckMessage": true,
    "account.menu.verification_required_link": "/account/tax-id"
  },
  FR: {
    "account.transactions.showServiceFee": true,
    "account.transactions.showFixedServiceFee": true,
    "ikea.baseAssemblyRate": 28,
    "legal.gdpr.enabled": true,
    "terms.includePrivacyInConsent": false,
    // Duplicate of TaskRabbit.country_config gift_card.enabled.
    "giftCard.enabled": false,
    "become_a_tasker.testimonial_sender_name": "Venkata R., Paris",
    "become_a_tasker.background_check_index": "7_uk",
    "become_a_tasker.pay_registration_fee": false,
    "signUp.requiresMarketingConsent": true,
    "signUp.requiresExplicitTermsConsent": true,
    "signUp.requiresImplicitTermsConsent": false,
    "signUp.rabbit.requiresSoleProprietor": true,
    "signUp.rabbit.requiresSoleProprietor.traderVariation": true,
    "trg.grant_application.url": "https://taskrabbit1.typeform.com/to/VxwmVHMT",
    "trg.cash_grant_amount": "$8,000",
    "trg.credit_grant_amount": "$2,000",
    "browseTaskers.showBackgroundCheckMessage": true,
    "account.menu.verification_required_link": "/account/business-info"
  },
  GB: {
    "account.transactions.showServiceFee": true,
    "account.transactions.showFixedServiceFee": true,
    "ikea.baseAssemblyRate": 25,
    "legal.gdpr.enabled": true,
    "terms.includePrivacyInConsent": false,
    // Duplicate of TaskRabbit.country_config gift_card.enabled.
    "giftCard.enabled": false,
    "become_a_tasker.testimonial_sender_name": "Michael M., London",
    "become_a_tasker.background_check_index": "7_uk",
    "become_a_tasker.pay_registration_fee": false,
    "signUp.requiresMarketingConsent": true,
    "signUp.requiresExplicitTermsConsent": true,
    "signUp.requiresImplicitTermsConsent": false,
    "signUp.rabbit.requiresSoleProprietor": true,
    "signUp.rabbit.requiresSoleProprietor.traderVariation": true,
    "trg.grant_application.url": "https://taskrabbit1.typeform.com/to/mQgaXNcC",
    "trg.cash_grant_amount": "$8,000",
    "trg.credit_grant_amount": "$2,000",
    "browseTaskers.showBackgroundCheckMessage": false,
    "account.menu.verification_required_link": "/account/tax-id"
  },
  DE: {
    "account.transactions.showServiceFee": true,
    "account.transactions.showFixedServiceFee": true,
    "ikea.baseAssemblyRate": 28,
    "legal.gdpr.enabled": true,
    "terms.includePrivacyInConsent": false,
    // Duplicate of TaskRabbit.country_config gift_card.enabled.
    "giftCard.enabled": false,
    "become_a_tasker.testimonial_sender_name": "Daniel H., Berlin",
    "become_a_tasker.background_check_index": "",
    "become_a_tasker.pay_registration_fee": false,
    "signUp.requiresMarketingConsent": true,
    "signUp.requiresExplicitTermsConsent": true,
    "signUp.requiresImplicitTermsConsent": false,
    "signUp.rabbit.requiresSoleProprietor": true,
    "signUp.rabbit.requiresSoleProprietor.traderVariation": true,
    "trg.grant_application.url": "https://taskrabbit1.typeform.com/to/WFSSRHi9",
    "trg.cash_grant_amount": "$8,000",
    "trg.credit_grant_amount": "$2,000",
    "browseTaskers.showBackgroundCheckMessage": true,
    "account.menu.verification_required_link": "/account/business-info"
  },
  ES: {
    "account.transactions.showServiceFee": true,
    "account.transactions.showFixedServiceFee": true,
    "ikea.baseAssemblyRate": 19,
    "legal.gdpr.enabled": true,
    "terms.includePrivacyInConsent": true,
    // Duplicate of TaskRabbit.country_config gift_card.enabled.
    "giftCard.enabled": false,
    "become_a_tasker.testimonial_sender_name": "Diego M., Barcelona",
    "become_a_tasker.background_check_index": "",
    "become_a_tasker.pay_registration_fee": false,
    "signUp.requiresMarketingConsent": true,
    "signUp.requiresExplicitTermsConsent": true,
    "signUp.requiresImplicitTermsConsent": false,
    "signUp.rabbit.requiresSoleProprietor": true,
    "signUp.rabbit.requiresSoleProprietor.traderVariation": true,
    "trg.grant_application.url": "https://taskrabbit1.typeform.com/to/WFSSRHi9",
    "trg.cash_grant_amount": "$8,000",
    "trg.credit_grant_amount": "$2,000",
    "browseTaskers.showBackgroundCheckMessage": true,
    "account.menu.verification_required_link": "/account/business-info"
  },
  PT: {
    "account.transactions.showServiceFee": true,
    "account.transactions.showFixedServiceFee": true,
    "ikea.baseAssemblyRate": 13,
    "legal.gdpr.enabled": true,
    "terms.includePrivacyInConsent": false,
    // Duplicate of TaskRabbit.country_config gift_card.enabled.
    "giftCard.enabled": false,
    "become_a_tasker.testimonial_sender_name": "Jaime M., Lisboa",
    "become_a_tasker.background_check_index": "",
    "become_a_tasker.pay_registration_fee": false,
    "signUp.requiresMarketingConsent": true,
    "signUp.requiresExplicitTermsConsent": true,
    "signUp.requiresImplicitTermsConsent": false,
    "signUp.rabbit.requiresSoleProprietor": true,
    "signUp.rabbit.requiresSoleProprietor.traderVariation": true,
    "trg.grant_application.url": "https://taskrabbit1.typeform.com/to/WFSSRHi9",
    "trg.cash_grant_amount": "$8,000",
    "trg.credit_grant_amount": "$2,000",
    "browseTaskers.showBackgroundCheckMessage": true,
    "account.menu.verification_required_link": "/account/business-info"
  },
  IT: {
    "account.transactions.showServiceFee": true,
    "account.transactions.showFixedServiceFee": true,
    "ikea.baseAssemblyRate": 15,
    "legal.gdpr.enabled": true,
    "terms.includePrivacyInConsent": false,
    // Duplicate of TaskRabbit.country_config gift_card.enabled.
    "giftCard.enabled": false,
    "become_a_tasker.testimonial_sender_name": "Lorenzo M., Roma",
    "become_a_tasker.background_check_index": "",
    "become_a_tasker.pay_registration_fee": false,
    "signUp.requiresMarketingConsent": true,
    "signUp.requiresExplicitTermsConsent": true,
    "signUp.requiresImplicitTermsConsent": false,
    "signUp.rabbit.requiresSoleProprietor": true,
    "signUp.rabbit.requiresSoleProprietor.traderVariation": true,
    "trg.grant_application.url": "https://taskrabbit1.typeform.com/to/WFSSRHi9",
    "trg.cash_grant_amount": "$8,000",
    "trg.credit_grant_amount": "$2,000",
    "browseTaskers.showBackgroundCheckMessage": true,
    "account.menu.verification_required_link": "/account/business-info"
  },
};

export { countryConfigObj };

export const countryConfig = (countryIsoCode, key, config = null) => {
  config = config || countryConfigObj; // eslint-disable-line

  if (key) {
    return config[countryIsoCode][key];
  }

  return config[countryIsoCode];
};

export const countryConfigFromLocale = (primaryLocale, key, config = null) => {
  const countryIsoCode = countryIsoCodeFromLocale(primaryLocale);

  return countryConfig(countryIsoCode, key, config);
};
