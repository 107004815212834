import { NotifiableError } from "@bugsnag/js";
import { createSlice } from "@reduxjs/toolkit";
import xhr, {
  createApiErrorAlert,
  errorFromStripe,
  instanceOfStripeError,
} from "util/xhr";
import { generateFunnelId } from "util/generateFunnelId";
import { BROADCAST_HIRE } from "build/routes/apiRoutes";

import { selectIkeaJobDraftGuid } from "ikea/redux/modules/selector";
import {
  FLASH_STORAGE_KEY,
  IKEA_BADGES,
  IKEA_CATEGORIES,
  IKEA_DOUBLE_POST_MESSAGE,
  IKEA_TASK_TEMPLATE,
  IKEA_TASK_TITLE,
  CREATE_STRIPE_PAYMENT_METHOD_URL,
} from "util/constants";

import storage from "util/localStorage";
import { TrMetricsService } from "services/TrMetricsService";
import { internalPath } from "util/internalPath";
import { HireConfirmFormData } from "ikea/types";
import { PaymentMethodCallback } from "shared/types";
import { V3Thunk } from "util/reduxTypes";
import qs from "qs";
import { notifyBugsnag } from "util/bugsnag";
import Axios, { AxiosError } from "axios";
import {
  setUserAddress,
  fetchIkeaSchedule,
  updateStateFromStorage,
  loadItemsFromDraft,
  setIkeaStorage,
  getIkeaStorage,
  setFunnelId,
  getJobDraft,
  parseIkeaProducts,
  getIkeaLegacyMetricData,
  firePageViewedMetric,
  IkeaManagerState,
} from "./manager";
import { StripeError } from "@stripe/stripe-js";

const initialState = {
  ready: false,
};

const { actions, reducer } = createSlice({
  name: "ikeaHireConfirm",
  initialState,
  reducers: {
    confirmationReady: (state) => {
      state.ready = true;
    },
  },
});

const { confirmationReady } = actions;

export default reducer;

const createFunnelId =
  (data?: { attributes?: Partial<IkeaManagerState> }): V3Thunk =>
  (dispatch, getState) => {
    const state = getState();

    let { funnel_id: funnelId } = state.ikea.manager;
    if (funnelId) {
      return;
    }

    const query = qs.parse(state.router.location.search, {
      ignoreQueryPrefix: true,
    });

    const existingFunnelId = data?.attributes?.funnel_id;
    const existingJobDraftGuid = data?.attributes?.job_draft_guid;
    const categoryId = data?.attributes?.job_draft?.category_id.toString();

    if (query.job_draft_guid && query.job_draft_guid !== existingJobDraftGuid) {
      funnelId = (query.funnel_id as string) || generateFunnelId(undefined, undefined, categoryId);
    } else {
      funnelId =
        (query.funnel_id as string) || existingFunnelId || generateFunnelId(undefined, undefined, categoryId);
    }

    dispatch(setFunnelId(funnelId));
  };

const loadDraftItems =
  (jobDraftGuid: string): V3Thunk<Promise<void>> =>
  async (dispatch) => {
    const jobDraft = await getJobDraft(jobDraftGuid);
    if (jobDraft.job_id) {
      window.location.href = internalPath("/dashboard/active?filters=1");
    }
    dispatch(
      loadItemsFromDraft(
        parseIkeaProducts(jobDraft.ikea_products.items),
        jobDraftGuid
      )
    );

    await dispatch(setUserAddress(jobDraft.location));
  };

export const onPageLoad =
  (): V3Thunk<Promise<void>> => async (dispatch, getState) => {
    const data = getIkeaStorage();
    dispatch(createFunnelId(data));

    const state = getState();

    await dispatch(fetchIkeaSchedule());

    const { items, location } = state.ikea.manager;

    if (!(items.length && location)) {
      const { job_draft_guid: jobDraftGuid, guid } = qs.parse(
        state.router.location.search,
        { ignoreQueryPrefix: true }
      );
      const queryGuid = jobDraftGuid ?? guid;

      if (queryGuid && typeof queryGuid === "string") {
        await dispatch(loadDraftItems(queryGuid));
      } else if (data) {
        dispatch(updateStateFromStorage(data));
      }
    }

    dispatch(
      firePageViewedMetric("ikea_select_items_completed", "hire_confirm")
    );

    dispatch(
      firePageViewedMetric(
        "confirmation_interstitial_presented",
        "hire_confirm"
      )
    );

    dispatch(confirmationReady());
  };

export const ikeaCreateStripePaymentMethod = (
  paymentMethodCallback: PaymentMethodCallback,
  onSuccessCallback: () => void,
): V3Thunk<Promise<void>> =>
async (dispatch) => {
  try {
    await createAndSetStripePaymentMethod(paymentMethodCallback);
    onSuccessCallback();
  } catch (error) {
    if (Axios.isAxiosError(error) && !handleDoublePostError(error)) {
      dispatch(createApiErrorAlert(error));
    } else if (instanceOfStripeError(error)) {
      dispatch(createApiErrorAlert(errorFromStripe(error)));
    }

    notifyBugsnag(error as NotifiableError);

    throw error;
  }
}

export const ikeaStripePerformHire =
  (
    paymentMethodCallback: PaymentMethodCallback,
    taskDetailFlagEnabled: boolean,
    formData: HireConfirmFormData
  ): V3Thunk<Promise<void>> =>
  async (dispatch, getState) => {
    try {
      const { cardEditing, cardNeeded } = formData;

      // if card was edited or needed
      // create and set the new payment method
      if (cardNeeded || cardEditing) {
        await createAndSetStripePaymentMethod(paymentMethodCallback);
      }

      const state = getState();
      const { form_referrer: formReferrer, funnel_id: funnelId } =
        state.ikea.manager;

      const { promotion, valid } = state.promoCode;
      const promoCode = promotion?.code && valid ? promotion?.code : undefined;
      const jobDraftGuid = selectIkeaJobDraftGuid(state);
      const hireData = prepareHireData({
        ...formData,
        formReferrer,
        funnelId,
        promoCode,
        jobDraftGuid,
      });

      // perform the hire
      const jobData = await xhr.post(BROADCAST_HIRE, hireData);

      await TrMetricsService.fire(
        "simple_hire_job_posted",
        getIkeaLegacyMetricData(state, {
          funnel_id: funnelId,
          form_referrer: formReferrer,
          job_draft_guid: jobDraftGuid,
        })
      );

      setIkeaStorage();
      if (taskDetailFlagEnabled) {
        const { data: { job: { guid: jobGuid } } } = jobData;
        window.location = internalPath(`/dashboard/details/${jobGuid}`);
      } else {
        window.location = internalPath("/dashboard/active?filters=1");
      }
    } catch (error) {
      if (Axios.isAxiosError(error) && !handleDoublePostError(error)) {
        dispatch(createApiErrorAlert(error));
      } else if (instanceOfStripeError(error)) {
        dispatch(createApiErrorAlert(errorFromStripe(error)));
      }

      notifyBugsnag(error as NotifiableError);

      throw error;
    }
  };

interface HireData extends HireConfirmFormData {
  formReferrer?: string;
  funnelId?: string;
  jobDraftGuid?: string;
  promoCode?: string;
}

const prepareHireData = ({
  checkout_agreement: checkoutAgreement,
  phone_number,
  phone_country_code,
  countryCode,
  items,
  location,
  schedule,
  formReferrer,
  funnelId,
  promoCode,
  jobDraftGuid,
}: HireData) => {
  const ikeaProducts = items.reduce(
    (memo: string[], { ikea_id: ikeaId, quantity }) =>
      memo.concat(Array(quantity).fill(ikeaId)),
    []
  );

  return {
    checkout_agreement: checkoutAgreement,
    promotion_code: promoCode,
    phone_number,
    country_code: countryCode,
    phone_country_code,
    ikea_products: ikeaProducts,
    address: location,
    schedule,
    funnel_id: funnelId,
    job_draft_guid: jobDraftGuid,
    category_id: IKEA_CATEGORIES[0],
    task_template_id: IKEA_TASK_TEMPLATE,
    title: IKEA_TASK_TITLE,
    badges: IKEA_BADGES,
    form_referrer: formReferrer,
  };
};

const createAndSetStripePaymentMethod = async (
  paymentMethodCallback: PaymentMethodCallback
) => {
  const paymentMethodResult = await paymentMethodCallback();

  if (paymentMethodResult.error) {
    // eslint-disable-next-line @typescript-eslint/no-throw-literal
    throw paymentMethodResult.error as StripeError;
  }
  await xhr.post(CREATE_STRIPE_PAYMENT_METHOD_URL, {
    payment_method_id: paymentMethodResult.setupIntent.payment_method,
    payment_method_type:
      paymentMethodResult.setupIntent.payment_method_types[0],
  });
};

const handleDoublePostError = (error: AxiosError) => {
  const { errors } = error.response?.data;
  const errorCodes = errors.map((e: { code: string }) => e.code);
  if (!errorCodes.includes("base_double_post_other")) {
    return false;
  }

  const newFlashMessage = {
    messages: [IKEA_DOUBLE_POST_MESSAGE],
    isFlash: true,
    autoRemove: true,
    type: "message",
  };
  const messages = (storage.get(FLASH_STORAGE_KEY) || []).concat(
    newFlashMessage
  );
  storage.set(FLASH_STORAGE_KEY, messages);

  window.location = internalPath("/dashboard/active?filters=1");
  return true;
};
