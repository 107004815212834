import React, { useState, useEffect, useRef, useContext } from "react";
import { injectIntl } from "react-intl";

import { HeaderLinks } from "./HeaderNav/HeaderLinks";
import { MobileLinksContainer } from "./HeaderNav/MobileLinksContainer";
import { Header } from "./HeaderNav/Header";
import { HeaderNavContext } from "./HeaderNavProvider";
import { useIsDesktop } from "../../shared/hooks/use-breakpoints";
import { keyCodes } from "../../enums/keyCodes";

function HeaderNavBase({
  dontShowLinks = false,
  headerlessPages = [],
  isIkeaJob = false,
  isInsideReactRouter = false,
  page = "",
  showMyTaskersLink = false,
  servicesLinks,
  serviceIndexPath,

  // mobileExpandInitialState is used in test to force the hamburger menu open.
  mobileExpandInitialState = false,

  // from injectIntl
  intl: { formatMessage, locale },
}) {
  const {
    hasActiveTasks,
    hasCompletedTasks,
    isRabbit,
    loggedIn,
    referralData,
    routing,
  } = useContext(HeaderNavContext);

  const pathname = routing?.location?.pathname;
  const [mobileExpand, setMobileExpand] = useState(mobileExpandInitialState);
  const [prevPathname, setPrevPathname] = useState(pathname);
  const firstMobileLinkRef = useRef(null);

  const isDesktop = useIsDesktop();

  useEffect(() => {
    if (isDesktop) {
      setMobileExpand(false);
    }
  }, [isDesktop]);

  useEffect(() => {
    if (pathname !== prevPathname && mobileExpand) {
      setMobileExpand(false);
    }
    setPrevPathname(pathname);
  }, [pathname, prevPathname, mobileExpand]);

  const focusFirstHamburgerMenuLink = () => {
    if (firstMobileLinkRef.current) {
      firstMobileLinkRef.current.focus();
    }
  };

  useEffect(() => {
    if (mobileExpand) {
      focusFirstHamburgerMenuLink();
    }
  }, [mobileExpand]);

  const toggleMobile = () => {
    setMobileExpand((x) => !x);
  };

  const onHamburgerKeyDown = (event) => {
    // Make focus loop around in the hamburger menu while the hamburger menu is
    // open.
    if (event.key === keyCodes.tab && mobileExpand) {
      event.preventDefault();
      focusFirstHamburgerMenuLink();
    }

    // Hitting space or enter on the hamburger icon should open/close the
    // hamburger menu.
    if (event.key === keyCodes.enter || event.key === keyCodes.space) {
      toggleMobile();
    }
  };

  if ((headerlessPages && headerlessPages.includes(page)) || isIkeaJob) {
    return null;
  }

  return (
    <div>
      <MobileLinksContainer mobileExpand={mobileExpand}>
        <HeaderLinks
          testid="mobile-header-links"
          deviceType="mobile"
          dontShowLinks={dontShowLinks}
          firstMobileLinkRef={firstMobileLinkRef}
          hasActiveTasks={hasActiveTasks}
          hasCompletedTasks={hasCompletedTasks}
          isInsideReactRouter={isInsideReactRouter}
          isRabbit={isRabbit}
          locale={locale}
          loggedIn={loggedIn}
          referralData={referralData}
          routing={routing}
          showMyTaskersLink={showMyTaskersLink}
          servicesLinks={servicesLinks}
          serviceIndexPath={serviceIndexPath}
          mobileExpandInitialState={mobileExpandInitialState}
        />
      </MobileLinksContainer>
      <Header
        dontShowLinks={dontShowLinks}
        formatMessage={formatMessage}
        isInsideReactRouter={isInsideReactRouter}
        toggleMobile={toggleMobile}
        onHamburgerKeyDown={onHamburgerKeyDown}
        routing={routing}
      >
        <HeaderLinks
          testid="desktop-header-links"
          deviceType="desktop"
          dontShowLinks={dontShowLinks}
          hasActiveTasks={hasActiveTasks}
          hasCompletedTasks={hasCompletedTasks}
          isInsideReactRouter={isInsideReactRouter}
          isRabbit={isRabbit}
          locale={locale}
          loggedIn={loggedIn}
          referralData={referralData}
          routing={routing}
          showMyTaskersLink={showMyTaskersLink}
          servicesLinks={servicesLinks}
          serviceIndexPath={serviceIndexPath}
          mobileExpandInitialState={mobileExpandInitialState}
        />
      </Header>
    </div>
  );
}

export const HeaderNav = injectIntl(HeaderNavBase);
