import { defineMessages } from "react-intl";

/**
 * Shared strings that need to be globally defined in the app
 */

const messages = defineMessages({
  day_label: {
    id: "build.form.datetime_windows.day_label",
    defaultMessage: `{count, plural,
      =0 {<span class='type__label'>Today</span>, <span class='type__label--light'>{date}</span>}
      one {<span class='type__label'>Tomorrow</span>, <span class='type__label--light'>{date}</span>}
      other {<span class='type__label'>{weekday}</span>, <span class='type__label--light'>{date}</span>}
      }`,
  },
  short_day_with_weekday: {
    id: "date_formats.short_day_with_weekday",
    defaultMessage: `{count, plural,
      =0 {Today, {date}}
      one {Tomorrow, {date}}
      other {{weekday}, {date}}
    }`,
  },
  postal_code_label: {
    id: "build.confirm.labels.payment_info.postal_code",
    defaultMessage: "Zip Code",
  },
  payment_method_label: {
    id: "build.confirm.labels.payment_method",
    defaultMessage: "Payment method",
  },
  expiration_label: {
    id: "build.confirm.labels.expiration",
    defaultMessage: "Expiration",
  },
  payment_info_expired: {
    id: "build.confirm.payment_info.expired",
    defaultMessage: "Your card has expired. Please update your payment method.",
  },
  payment_info_failed: {
    id: "build.confirm.payment_info.failed",
    defaultMessage: "Your card has failed. Please update your payment method.",
  },
  payment_info_toggle_to_show: {
    id: "build.confirm.payment_info.toggle_to_show",
    defaultMessage: "Cancel",
  },
  payment_info_toggle_to_edit: {
    id: "build.confirm.payment_info.toggle_to_edit",
    defaultMessage: "Change payment",
  },
  promotion_code_label: {
    id: "build.confirm.placeholders.promotion_code_label",
    defaultMessage: "Promo Code",
  },
  promotion_code_placeholder: {
    id: "build.confirm.placeholders.promotion_code_placeholder",
    defaultMessage: "Enter Promo Code",
  },
  promotion_code: {
    id: "build.confirm.placeholders.promotion_code",
    defaultMessage: "Have a Promo Code?",
  },
  zendesk_issue_form: {
    id: "support.zendesk_issue_form",
    defaultMessage:
      "https://taskrabbit.zendesk.com/login?return_to=https://taskrabbit.zendesk.com/anonymous_requests/new",
  },
  edit_vat_id_url: {
    id: "support.url.edit_vat_id",
    defaultMessage: "/account/vat-id",
  },
  chosen: {
    id: "form.validation.chosen",
    defaultMessage: "Must be chosen.",
  },
  required: {
    id: "form.validation.required",
    defaultMessage: "Can't be blank.",
  },
  email: {
    id: "form.validation.email",
    defaultMessage: "Must be a valid email address.",
  },
  invalid_top_level_domain: {
    id: "form.validation.invalid_top_level_domain",
    defaultMessage: "Oops! Invalid email. Please try again.",
  },
  password: {
    id: "form.validation.password",
    defaultMessage: "Must enter a valid password.",
  },
  phone: {
    id: "form.validation.phone",
    defaultMessage: "Phone number is not valid.",
  },
  postal_code: {
    id: "form.validation.postal_code",
    defaultMessage: "Please enter a valid postal code",
  },
  geocode_failed: {
    id: "form.validation.geocode_failed",
    defaultMessage: "Could not be located",
  },
  incomplete_address: {
    id: "form.validation.incomplete_address",
    defaultMessage: "Enter a complete address for accurate results.",
  },
  inactive_metro: {
    id: "form.validation.inactive_metro",
    defaultMessage: "Sorry, this task is outside of our coverage area",
  },
  category_not_supported: {
    id: "form.validation.category_not_supported",
    defaultMessage: "Sorry, {category_name} isn't offered in your area yet",
  },
  tasker_not_available: {
    id: "form.validation.tasker_not_available",
    defaultMessage:
      "Sorry this tasker is not available in your area. Please pick another tasker.",
  },
  location_required: {
    id: "form.validation.location_required",
    defaultMessage: "Location is required",
  },
  // there is a counterpart in `apps/web/lang/config.en-GB.js` to manage UK wrong_locale
  wrong_locale: {
    id: "form.validation.wrong_locale",
    defaultMessage:
      "Looks like this address is in another country. Please use taskrabbit.com for tasks in the US, taskrabbit.ca for Canada, and taskrabbit.co.uk for UK.",
  },
  cc_num: {
    id: "form.validation.cc_number",
    defaultMessage: "Does not look like a credit card number.",
  },
  cvv: {
    id: "form.validation.cvv",
    defaultMessage: "Is not a valid security code.",
  },
  cc_month: {
    id: "form.validation.expiration_month",
    defaultMessage: "Is not a valid expiration month.",
  },
  cc_year: {
    id: "form.validation.expiration_year",
    defaultMessage: "Is not a valid expiration year.",
  },
  tasker_hired: {
    id: "build.confirm.alert.tasker_hired",
    defaultMessage: "Your Tasker has been hired!",
  },
  broadcast_hired: {
    id: "build.confirm.alert.broadcast_hired",
    defaultMessage: "A Tasker will be in touch soon!",
  },
  // Names for vehicle options
  bicycle: {
    id: "system.vehicles.bicycle.name",
    defaultMessage: "Bike / Motorcycle",
  },
  car: {
    id: "system.vehicles.car.name",
    defaultMessage: "Car",
  },
  car_or_truck: {
    id: "system.vehicles.car_or_truck.name",
    defaultMessage: "Car or Truck",
  },
  large_truck: {
    id: "system.vehicles.large_truck.name",
    defaultMessage: "Large Truck",
  },
  moving_truck: {
    id: "system.vehicles.moving_truck.name",
    defaultMessage: "Cargo Truck",
  },
  none: {
    id: "system.vehicles.none.name",
    defaultMessage: "Not for this task",
  },
  suv: {
    id: "system.vehicles.suv.name",
    defaultMessage: "SUV",
  },
  truck: {
    id: "system.vehicles.truck.name",
    defaultMessage: "Truck",
  },
  van: {
    id: "system.vehicles.van.name",
    defaultMessage: "Van",
  },
  // Header text
  dashboard_page_title: {
    id: "header.explore.page.title",
    defaultMessage: "My Tasks - TaskRabbit",
  },
  dashboard: {
    id: "header.dashboard",
    defaultMessage: "My Tasks",
  },
  team: {
    id: "header.team",
    defaultMessage: "My Taskers",
  },
  explore: {
    id: "header.explore",
    defaultMessage: "Book a Task",
  },
  contactlessTasks: {
    id: "header.covid19.contactlessTasks",
    defaultMessage: "Contactless Tasks & Delivery",
  },
  profile: {
    id: "header.profile",
    defaultMessage: "Tasker Profile",
  },
  account: {
    id: "header.account",
    defaultMessage: "Account",
  },
  how_it_works: {
    id: "header.how_it_works",
    defaultMessage: "How It Works",
  },
  log_in: {
    id: "header.log_in",
    defaultMessage: "Log in",
  },
  become_a_tasker: {
    id: "header.become_a_tasker",
    defaultMessage: "Become A Tasker",
  },
  ad_choices: {
    id: "header.ad_choices",
    defaultMessage: "About Our Ads",
  },
  // IKEA messages
  ikea_category_label: {
    id: "ikea.category.display_name",
    defaultMessage: "IKEA Furniture Assembly",
  },
  donation_type: {
    id: "form.validation.donation_type",
    defaultMessage: "Please choose a category of item.",
  },
  facebook: {
    id: "verifications.facebook",
    defaultMessage: "Facebook",
  },
  idology: {
    id: "verifications.idology",
    defaultMessage: "ID",
  },
  linkedin: {
    id: "verifications.linkedin",
    defaultMessage: "LinkedIn",
  },
  // IKEA Landing Page
  landing_page_what_is_taskrabbit: {
    id: "ikea.landing_page.what_is_taskrabbit",
    defaultMessage: "What is Taskrabbit",
  },
  landing_page_how_it_works: {
    id: "ikea.landing_page.how_it_works",
    defaultMessage: "How it works",
  },
  landing_page_faq: {
    id: "ikea.landing_page.faq",
    defaultMessage: "FAQ",
  },
  landing_page_locations: {
    id: "ikea.landing_page.locations",
    defaultMessage: "Locations",
  },
  landing_page_cta: {
    id: "ikea.landing_page.cta",
    defaultMessage: "Book now",
  },
  marketingConsent: {
    id: "signup.marketing_consent",
    defaultMessage:
      "I do not want to receive promotional emails and notifications from TaskRabbit",
  },
  termsConsentImplicit: {
    id: "signup.terms_consent.implicit",
    defaultMessage:
      'By clicking below and creating an account, I agree to TaskRabbit’s <a target="_blank" href="{termsUrl}">Terms of Service</a> and <a target="_blank" href="{privacyUrl}">Privacy Policy</a>.',
  },
  termsConsentExplicit: {
    id: "signup.terms_consent.explicit",
    defaultMessage:
      'I agree to the <a target="_blank" href="{termsUrl}">Terms and Conditions</a> and have reviewed the <a target="_blank" href="{privacyUrl}">Privacy Policy</a>.',
  },
  classificationAcknowledged: {
    id: "signup.classification_acknowledged",
    defaultMessage: "I acknowledge I am a sole proprietor.",
  },
  classificationAcknowledgedTraderVariation: {
    id: "signup.classification_acknowledged.trader_variation",
    defaultMessage: "I acknowledge I am a sole trader.",
  },
  paymentMethodModalCancel: {
    id: "dashboard.add_payment_method_modal.cancel",
    defaultMessage: "Cancel",
  },
  paymentMethodModalTooltip: {
    id: "dashboard.add_payment_method_modal.payment_method_tooltip",
    defaultMessage:
      "For your protection, we didn't store payment information for your prepaid task. Any tip you send will require a new authorization.",
  },
  paymentMethodModalSave: {
    id: "dashboard.add_payment_method_modal.save",
    defaultMessage: "Save",
  },
  paymentMethodModalSelected: {
    id: "dashboard.add_payment_method_modal.select_payment_method",
    defaultMessage: "Select your payment method",
  },
  paymentMethodModalTotalDue: {
    id: "dashboard.add_payment_method_modal.total_due",
    defaultMessage: "Total due: {amount}",
  },
});

export default messages;
