import React from "react";
import { connect } from "react-redux";

const defaultStyledFooterContext = {
  loggedIn: false,
  referralData: {},
};

export const StyledFooterContext = React.createContext(defaultStyledFooterContext);

export function StyledFooterProvider_({
  children,

  // from mapStateToProps
  loggedIn,
  referralData,
}) {
  const value = {
    loggedIn,
    referralData,
  };

  return (
    <StyledFooterContext.Provider value={value}>
      {children}
    </StyledFooterContext.Provider>
  );
}

function mapStateToProps(state) {
  return {
    loggedIn: state.user.loggedIn,
    referralData: state.dashboard.bootstrap.referral,
  };
}
export const StyledFooterProvider = connect(mapStateToProps)(StyledFooterProvider_);
