import moment from "util/momentWithTZ";
import find from "lodash/find";
import { defineMessages } from "react-intl";
import xhr, { createApiErrorAlert } from "util/xhr";
import { createAlert } from "alerts/redux/modules/alerts";
import {
  fetchSingleTask,
  fetchJobDataResponse,
} from "dashboard/redux/modules/taskLists";
import {
  JOB_RESCHEDULE_DATES,
  JOB_RESCHEDULE_APPT,
  JOB_SKIP_RECURRING_APPT,
} from "dashboard/routes/apiRoutes";

const messages = defineMessages({
  success_reschedule: {
    id: "dashboard.reschedule.success_reschedule",
    defaultMessage: "Your appointment has been rescheduled",
  },
  success_skip: {
    id: "dashboard.reschedule.success_skip",
    defaultMessage: "Your appointment has been skipped",
  },
});

const INITIALIZE_REDUCER = "dashboard/reschedule/INITIALIZE_REDUCER";
const UPDATE_AVAILABLE_DATES = "dashboard/reschedule/UPDATE_AVAILABLE_DATES";
const UPDATE_AVAILABLE_TIMES = "dashboard/reschedule/UPDATE_AVAILABLE_TIMES";
const RESET_DATA = "dashboard/reschedule/RESET_DATA";
const SET_VIEW_STATE = "dashboard/reschedule/SET_VIEW_STATE";
const FETCH_DATES_RESPONSE = "dashboard/reschedule/FETCH_DATES_RESPONSE";
const RESCHEDULE_SELECT_DATE = "dashboard/reschedule/RESCHEDULE_SELECT_DATE";
const RESCHEDULE_SELECT_TIME = "dashboard/reschedule/RESCHEDULE_SELECT_TIME";

const initialState = {
  dates: undefined,
  times: undefined,
  windows: undefined,
  disabled: undefined,
  selected: {
    date: undefined,
    offset_seconds: undefined,
  },
  job_id: undefined,
  appointment_id: undefined,
  rabbit_id: undefined,
};

const _selectDay = date => ({
  type: RESCHEDULE_SELECT_DATE,
  date,
});

export const selectTime = offset_seconds => ({
  type: RESCHEDULE_SELECT_TIME,
  offset_seconds,
});

const updateAvailableDays = dates => ({
  type: UPDATE_AVAILABLE_DATES,
  dates,
});

const updateAvailableTimes = times => ({
  type: UPDATE_AVAILABLE_TIMES,
  times,
});

export const setViewState = viewState => ({
  type: SET_VIEW_STATE,
  viewState,
});

export const performReschedule = () => {
  return (dispatch, getState) => {
    const state = getState();
    const {
      job_id,
      appointment_id,
      rabbit_id,
      selected: { date, offset_seconds },
    } = state.dashboard.reschedule;
    const data = { date, offset_seconds };
    const url = JOB_RESCHEDULE_APPT.replace(
      ":appointment_id",
      appointment_id
    ).replace(":job_id", job_id);

    return xhr.post(url, JSON.stringify(data)).then(
      response => {
        const { original_start_at, start_at } = response.data;
        return fetchSingleTask(job_id, rabbit_id).then(item => {
          dispatch(fetchJobDataResponse(item));
          dispatch(setViewState("dismiss"));
          dispatch(
            createAlert({
              messages: messages.success_reschedule.id,
              values: { start_at, original_start_at },
            })
          );
        });
      },
      e => {
        dispatch(createApiErrorAlert(e));
      }
    );
  };
};

export const performSkip = () => {
  return (dispatch, getState) => {
    const state = getState();
    const { job_id, appointment_id, rabbit_id } = state.dashboard.reschedule;
    const url = JOB_SKIP_RECURRING_APPT.replace(
      ":appointment_id",
      appointment_id
    ).replace(":job_id", job_id);

    return xhr.post(url).then(
      response => {
        return fetchSingleTask(job_id, rabbit_id).then(item => {
          dispatch(fetchJobDataResponse(item));
          dispatch(setViewState("dismiss"));
          dispatch(
            createAlert({
              messages: messages.success_skip.id,
            })
          );
        });
      },
      e => {
        dispatch(createApiErrorAlert(e));
      }
    );
  };
};

const generateDayList = (start_date, end_date) => {
  const days = [];
  const end = moment(end_date).startOf("day");
  let date = moment(start_date).startOf("day");

  do {
    days.push({
      label: date.format("ddd D"),
      value: date.format("YYYY-MM-DD"),
    });
    date = moment(date).add(1, "days");
  } while (date <= end);

  return days;
};

const generateTimeList = (windows, disabled, selectedDate) => {
  // no disabled means return all the windows
  if (!disabled || !disabled.length) return windows;
  // offset: 0 / duration: 86400 means entire day is taken, so return nothing
  if (
    find(disabled, {
      date: selectedDate,
      offset_seconds: 0,
      duration_seconds: 86400,
    })
  )
    return [];
  // filter any windows found with the same selected date / offset_seconds
  return windows.map(win => {
    const { offset_seconds } = win;
    const winDisabled = find(disabled, { date: selectedDate, offset_seconds });
    if (winDisabled) win.disabled = true;
    return win;
  });
};

const initializeReducer = ({
  job_id,
  appointment_id,
  rabbit_id,
  can_skip,
  can_reschedule,
}) => ({
  type: INITIALIZE_REDUCER,
  appointment_id,
  job_id,
  rabbit_id,
  can_reschedule,
  can_skip,
});

const fetchDatesResponse = ({
  disabled,
  windows,
  start_at,
  any_availability,
}) => ({
  type: FETCH_DATES_RESPONSE,
  disabled,
  windows,
  start_at,
  any_availability,
});

export default function rescheduleAppt(state = initialState, action) {
  switch (action.type) {
    case INITIALIZE_REDUCER:
      return {
        ...state,
        job_id: action.job_id,
        appointment_id: action.appointment_id,
        rabbit_id: action.rabbit_id,
        can_reschedule: action.can_reschedule,
        can_skip: action.can_skip,
      };
    case RESET_DATA:
      return initialState;
    case SET_VIEW_STATE:
      return { ...state, viewState: action.viewState };
    case FETCH_DATES_RESPONSE:
      const selected_offset_seconds = moment
        .duration(
          moment(action.start_at).diff(moment(action.start_at).startOf("day"))
        )
        .asSeconds();
      const { any_availability, windows, disabled } = action;
      // TODO:  make sure selected becomes defined a better way than this
      //        or make it as root level property so it doesn't matter?
      const selected = any_availability
        ? find(
            action.windows,
            win => win.offset_seconds >= selected_offset_seconds
          ) || {}
        : {};

      return {
        ...state,
        disabled,
        windows,
        any_availability,
        selected: {
          offset_seconds: selected.offset_seconds,
          date: moment(action.start_at).format("YYYY-MM-DD"),
        },
      };
    case UPDATE_AVAILABLE_TIMES:
      return { ...state, times: action.times };
    case UPDATE_AVAILABLE_DATES:
      return { ...state, dates: action.dates };
    case RESCHEDULE_SELECT_DATE:
      return {
        ...state,
        selected: {
          date: action.date,
          offset_seconds: state.selected.offset_seconds,
        },
      };
    case RESCHEDULE_SELECT_TIME:
      return {
        ...state,
        selected: {
          ...state.selected,
          offset_seconds: action.offset_seconds,
        },
      };
    default:
      return state;
  }
}

export const initialize = ({
  job_id,
  appointment_id,
  rabbit_id,
  can_skip,
  can_reschedule,
}) => {
  return (dispatch, getState) => {
    dispatch(
      initializeReducer({
        job_id,
        appointment_id,
        rabbit_id,
        can_skip,
        can_reschedule,
      })
    );
    const url = JOB_RESCHEDULE_DATES.replace(
      ":appointment_id",
      appointment_id
    ).replace(":job_id", job_id);

    return xhr.get(url).then(response => {
      const {
        start_at,
        start_date,
        end_date,
        disabled,
        windows: { items },
        any_availability,
      } = response.data;
      dispatch(
        fetchDatesResponse({
          disabled,
          windows: items,
          start_at,
          any_availability,
        })
      );
      dispatch(updateAvailableDays(generateDayList(start_date, end_date)));
      dispatch(
        updateAvailableTimes(
          generateTimeList(
            items,
            disabled,
            moment(start_at).format("YYYY-MM-DD")
          )
        )
      );
    });
  };
};

export const resetData = () => ({
  type: RESET_DATA,
});

export const selectDay = date => {
  return (dispatch, getState) => {
    const state = getState();
    const { windows, disabled } = state.dashboard.reschedule;
    dispatch(_selectDay(date));

    const times = generateTimeList(windows, disabled, date);
    dispatch(updateAvailableTimes(times));
  };
};
