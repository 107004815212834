import React from "react";
import BugsnagErrorBoundary from "components/BugsnagErrorBoundary";
import AlertContainer from "alerts/containers/AlertContainer";
import { Provider } from "react-redux";
import createStoreAll from "store/createStoreAll";
import history from "hist";
import GDPRConsentContainer from "components/GDPRConsentContainer";
import IntlRoutes from "components/IntlRoutes";
import { Route } from "react-router-dom";
import { renderRoutes } from "react-router-config";
import { ConnectedRouter } from "connected-react-router";
import { IntlProvider } from "react-intl";
import { ThemeProvider } from "styled-components";
import { prepareLocaleMessages } from "reactHelper/PrepareLocaleMessages";
import { theme } from "components/theme";
import getThemeSierra from "@taskrabbit/meadow-web/lib/Theme/themes/Sierra";
import MeadowThemeProvider from "@taskrabbit/meadow-web/lib/Theme";
// To be PCI compliant stripe must be loaded in this manner.
// To leverage stripe fraud protection this script must
// be present on all pages as per
// https://github.com/stripe/stripe-js#loadstripe
// and
// https://github.com/stripe/stripe-js#ensuring-stripejs-is-available-everywhere
import "@stripe/stripe-js";
import "messages";
import { FeatureManagerProvider } from "services/FeatureFlagService";
import { ExperimentManagerProvider } from "services/ExperimentService";
import RetrieveCurrentUser from "../user/components/RetrieveCurrentUser";
import LuxonAdapter from '@taskrabbit/meadow-web/lib/date-io/luxon';
import MeadowDateIo from "@taskrabbit/meadow-web/lib/date-io/MeadowDateIo";

const { locale, messages } = prepareLocaleMessages();

const store = createStoreAll(history);

export default function TrApp({ component, routes, useRouter, intlRoutes }) {
  let appRoutes;
  if (intlRoutes) {
    appRoutes = <IntlRoutes routes={routes} />;
  } else if (useRouter) {
    appRoutes = renderRoutes(routes);
  } else {
    appRoutes = <Route name="root" path="/" component={component} />;
  }

  return (
    <BugsnagErrorBoundary>
      <IntlProvider locale={locale} messages={messages} textComponent="span">
        <Provider store={store}>
          <ExperimentManagerProvider>
            <FeatureManagerProvider>
              <MeadowThemeProvider theme={getThemeSierra()}>
                <MeadowDateIo.Provider
                  value={new LuxonAdapter({ locale: locale?.split("-")[0] })}
                >
                  <ThemeProvider theme={theme}>
                    <ConnectedRouter history={history}>
                      <>
                        <RetrieveCurrentUser />
                        {appRoutes}
                        <AlertContainer />
                        <GDPRConsentContainer />
                      </>
                    </ConnectedRouter>
                  </ThemeProvider>
                </MeadowDateIo.Provider>
              </MeadowThemeProvider>
            </FeatureManagerProvider>
          </ExperimentManagerProvider>
        </Provider>
      </IntlProvider>
    </BugsnagErrorBoundary>
  );
}

TrApp.defaultProps = {
  component: null,
  intlRoutes: false,
  routes: null,
  useRouter: true,
};
