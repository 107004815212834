import { DASHBOARD_TASK_COUNTS } from "dashboard/routes/apiRoutes";
import { getAxios } from "util/xhr";
import get from "lodash/get";

const LOAD_TASK_COUNTS = "dashboard/task_list/LOAD_TASK_COUNTS";
const LOAD_TASK_COUNTS_REQUEST = "dashboard/task_list/LOAD_TASK_COUNTS_REQUEST";
const LOAD_TASK_COUNTS_RESPONSE =
  "dashboard/task_list/LOAD_TASK_COUNTS_RESPONSE";
const LOAD_TASK_COUNTS_ERROR = "dashboard/task_list/LOAD_TASK_COUNTS_ERROR";
const CANCELLED_TASK_BANNER_DISMISSED =
  "dashboard/task_list/CANCELLED_TASK_BANNER_DISMISSED";

function startLoadTaskCounts() {
  return { type: LOAD_TASK_COUNTS };
}

function loadTaskCountsRequest() {
  return { type: LOAD_TASK_COUNTS_REQUEST };
}

function loadTaskCountsResponse(response) {
  return {
    type: LOAD_TASK_COUNTS_RESPONSE,
    response,
  };
}

function fetchTaskCounts() {
  return (dispatch) => {
    dispatch(loadTaskCountsRequest());

    return getAxios()
      .get(DASHBOARD_TASK_COUNTS)
      .then((response) =>
        dispatch(loadTaskCountsResponse(response.camelCasedData))
      );
  };
}

export function loadTaskCounts() {
  return (dispatch, getState) => {
    const pageNeedsFetch = () => {
      return !get(getState(), "dashboard.taskCounts.counts");
    };

    if (pageNeedsFetch()) {
      dispatch(startLoadTaskCounts());
      return dispatch(fetchTaskCounts());
    }
  };
}

export const cancelledTaskBannerDismissed = () => {
  return { type: CANCELLED_TASK_BANNER_DISMISSED };
};

export default function taskCounts(state = {}, action) {
  switch (action.type) {
    case LOAD_TASK_COUNTS_REQUEST:
      return { ...state, isLoading: true };
    case LOAD_TASK_COUNTS_RESPONSE:
      return { ...action.response, isLoading: false };
    case LOAD_TASK_COUNTS_ERROR:
      return { ...state, isLoading: false };
    case CANCELLED_TASK_BANNER_DISMISSED: {
      return { ...state, cancelledTaksBannerDismissed: true };
    }
    default:
      return state;
  }
}
