import React from "react";
import styled from "styled-components";
import { defineMessages } from "react-intl";
import facebookIcon from "./SocialLinks/facebook-white.svg";
import instagramIcon from "./SocialLinks/instagram-white.svg";
import twitterIcon from "./SocialLinks/twitter-white.svg";

const messages = defineMessages({
  facebook: {
    id: "Footer.StyledFooter.SocialLinksContainer.SocialLinks.facebook",
    defaultMessage: "Facebook",
  },
  instagram: {
    id: "Footer.StyledFooter.SocialLinksContainer.SocialLinks.instagram",
    defaultMessage: "Instagram",
  },
  twitter: {
    id: "Footer.StyledFooter.SocialLinksContainer.SocialLinks.twitter",
    defaultMessage: "Twitter",
  },
});

export function SocialLinks({ formatMessage }) {
  const facebookAlt = formatMessage(messages.facebook);
  const instagramAlt = formatMessage(messages.instagram);
  const twitterAlt = formatMessage(messages.twitter);
  return (
    <LinkGroup>
      <StyledA
        href="https://www.facebook.com/TaskRabbit-106755671141027"
        target="_blank"
        rel="noopener"
      >
        <StyledImg
          alt={facebookAlt}
          src={facebookIcon}
          height="24"
          width="24"
        />
      </StyledA>
      |
      <StyledA
        href="https://www.twitter.com/taskrabbit"
        target="_blank"
        rel="noopener"
      >
        <StyledImg alt={twitterAlt} src={twitterIcon} height="24" width="30" />
      </StyledA>
      |
      <StyledA
        href="https://www.instagram.com/taskrabbit"
        target="_blank"
        rel="noopener"
      >
        <StyledImg
          alt={instagramAlt}
          src={instagramIcon}
          height="24"
          width="24"
        />
      </StyledA>
    </LinkGroup>
  );
}

const LinkGroup = styled.div`
  display: inline-block;
`;

const StyledImg = styled.img`
  vertical-align: middle;
`;

const StyledA = styled.a`
  padding-left: ${(props) => props.theme.spacing.sm};
  padding-right: ${(props) => props.theme.spacing.sm};
`;
