import * as API from "account/routes/apiRoutes";
import { push } from "connected-react-router";
import xhr, { createApiErrorAlert } from "util/xhr";
import { createAlert } from "alerts/redux/modules/alerts";
import { setActiveStep } from "build/redux/modules/confirmation";
import { defineMessages } from "react-intl";

const messages = defineMessages({
  profile_updated: {
    id: "alerts.profile_changed",
    defaultMessage: "Account successfully updated.",
  },
  vat_id_changed: {
    id: "alerts.vat_id_changed",
    defaultMessage: "Your Vat ID information has been updated.",
  },
});

const SAVE_PROFILE = "SAVE_PROFILE";
const UPDATE_PROFILE_REQUEST = "UPDATE_PROFILE_REQUEST";
const UPDATE_PROFILE_RESPONSE = "UPDATE_PROFILE_RESPONSE";
const UPDATE_PROFILE_ERROR = "UPDATE_PROFILE_ERROR";
const LOAD_PROFILE_REQUEST = "LOAD_PROFILE_REQUEST";
const LOAD_PROFILE_ERROR = "LOAD_PROFILE_ERROR";
const INVALIDATE_PROFILE_DATA = "INVALIDATE_PROFILE_DATA"; // also dispatched by code redemption
const UPDATE_INTENDED_USE = "UPDATE_INTENDED_USE";
export const LOAD_PROFILE_RESPONSE = "LOAD_PROFILE_RESPONSE"; // also dispatched by avatar upload

function requestSaveProfile() {
  return { type: UPDATE_PROFILE_REQUEST };
}

function responseSaveProfile(profile) {
  return { type: UPDATE_PROFILE_RESPONSE, profile };
}

function errorSaveProfile() {
  return { type: UPDATE_PROFILE_ERROR };
}

function requestLoadProfile() {
  return { type: LOAD_PROFILE_REQUEST };
}

function responseLoadProfile(profile) {
  return { type: LOAD_PROFILE_RESPONSE, profile };
}

function errorLoadProfile(profile) {
  return { type: LOAD_PROFILE_ERROR, profile };
}

export function invalidateProfile() {
  return { type: INVALIDATE_PROFILE_DATA };
}

function saveProfileForm({ formName, message, redirect } = {}) {
  return (dispatch, getState) => {
    dispatch(requestSaveProfile());
    const profile = combineFormData(getState(), formName);
    return xhr
      .put(API.ACCOUNT_PROFILE, JSON.stringify(profile))
      .then((response) => {
        dispatch(responseSaveProfile(response.data));
        dispatch(createAlert(message.id));
        dispatch(push(redirect || "/"));
      })
      .catch((e) => {
        dispatch(createApiErrorAlert(e));
        dispatch(errorSaveProfile());
      });
  };
}

export function saveVatId() {
  return saveProfileForm({
    formName: "vat_id",
    message: messages.vat_id_changed,
    redirect: "/vat-id",
  });
}

export function saveProfile() {
  return saveProfileForm({
    formName: "profile",
    message: messages.profile_updated,
  });
}

function combineFormData(state, formName) {
  return Object.assign(state.account.profile.data, state.form[formName].values);
}

export function loadProfile(fromConfirmSignupLogin = false) {
  return (dispatch) => {
    dispatch(requestLoadProfile());
    return xhr.get(API.ACCOUNT_PROFILE).then(
      (response) => {
        dispatch(responseLoadProfile(response.data));
        if (fromConfirmSignupLogin) {
          dispatch(setActiveStep("card"));
        }
        return response.data;
      },
      (e) => {
        dispatch(createApiErrorAlert(e));
        dispatch(errorLoadProfile());
      }
    );
  };
}

function shouldFetchProfile(state) {
  if (!state.account.profile.data) return true;
  if (state.account.profile.invalidated) return true;
  return false;
}

export function fetchProfileIfNeeded() {
  return (dispatch, getState) => {
    if (shouldFetchProfile(getState())) {
      return dispatch(loadProfile());
    }
    return null;
  };
}

export function updateIntendedUse(value) {
  return {
    type: UPDATE_INTENDED_USE,
    intended_use: value,
  };
}

const initialState = {
  data: undefined,
  error: undefined,
  isLoading: false,
  invalidated: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SAVE_PROFILE:
      return state;
    case LOAD_PROFILE_REQUEST:
      return { ...state, isLoading: true };
    case LOAD_PROFILE_RESPONSE:
      return {
        ...state,
        isLoading: false,
        data: action.profile,
        invalidated: false,
      };

    case UPDATE_INTENDED_USE:
      return {
        ...state,
        isLoading: false,
        data: {
          ...state.data,
          intended_use: action.intended_use,
        },
      };

    case LOAD_PROFILE_ERROR:
      return {
        ...state,
        isLoading: false,
        invalidated: false,
      };

    case INVALIDATE_PROFILE_DATA:
      return { ...state, invalidated: true };

    case UPDATE_PROFILE_REQUEST:
      return { ...state, isLoading: true };
    case UPDATE_PROFILE_RESPONSE:
      return {
        ...state,
        isLoading: false,
        data: action.profile,
      };
    case UPDATE_PROFILE_ERROR:
      return { ...state, isLoading: false };
    default:
      return state;
  }
}
