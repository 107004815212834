import React, { Component } from "react";
import { Row, Col } from "components/Grid";

export default class DefaultModalBody extends Component {
  render() {
    return (
      <div className="confirm-lightbox">
        <Row className="confirm-lightbox--content-row">
          <Col size={12}>
            <span>{this.props.message}</span>
          </Col>
        </Row>
        <Row guttered={true} thin={true} className="confirm-lightbox--button-row">
          <Col size={12}>
            {/* [MEADOW_TODO] - Button */}
            <button className="btn btn-primary" onClick={this.props.affirmFn}>
              {this.props.affirm}
            </button>
            {/* [MEADOW_TODO] - Button */}
            <button className="btn" onClick={this.props.declineFn}>
              {this.props.decline}
            </button>
          </Col>
        </Row>
      </div>
    );
  }
}
