import get from "lodash";

// designed to be used with the "%{expression}" placeholders
export default function stringExpand(str, data, raiseOnMiss) {
  let throwOnMiss = typeof raiseOnMiss === "undefined" ? true : raiseOnMiss

  return (str || '').replace(/%\{(.+?)\}/g, function(match, key) {
    if (throwOnMiss && !get(data || {}, key))
      throw new Error(
        "Missing interpolation argument `" + key + "` for '" + str + "'"
      );
    return data[key];
  });
}
