/* eslint-disable consistent-return */
/* eslint-disable no-shadow */
import countryIsoCodeFromLocale from "util/countryIsoCodeFromLocale";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import emails from "./email_validation";

const MAX_FILE_SIZE = 50000000;

const isEmpty = (value) =>
  value === undefined || value === null || value === "" || value.length === 0;
const join = (rules) => (value, data) =>
  rules
    .map((rule) => rule(value, data))
    .filter((error) => !!error)[0 /* first error */];

export function email(value) {
  const basicCheck = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  if (isEmpty(value) || !basicCheck.test(value)) {
    return "form.validation.email";
  }
  if (value) {
    if (value.indexOf(".") > -1) {
      const splitEmail = value.split(".");
      const domain = splitEmail[splitEmail.length - 1].toUpperCase();
      if (!emails.topLevelDomains[domain]) {
        return "form.validation.invalid_top_level_domain";
      }
    }
  }
}

export function password(value) {
  if (isEmpty(value)) {
    return "form.validation.password";
  }
}

export function phone(value = "", code = null) {
  const countryCode = code?.phone_country_code ?? code;
  const phoneNumber = countryCode ? countryCode + value : value;

  if (isEmpty(value)) {
    return;
  }

  // Check whether the number is valid based on the default country or is in
  // valid e164 format. If neither is true, return error message.
  const parsedPhoneNumberWithDefaultCountry = parsePhoneNumberFromString(
    phoneNumber,
    typeof countryCode === "string" // A test is doing some weird stuff with this validator so I'm enforcing a check for string type
      ? countryCode
      : countryIsoCodeFromLocale(window.LOCALE)
  );

  if (
    parsedPhoneNumberWithDefaultCountry &&
    parsedPhoneNumberWithDefaultCountry.isValid()
  ) {
    return;
  }

  const parsedPhoneNumber = parsePhoneNumberFromString(phoneNumber);
  if (parsedPhoneNumber && parsedPhoneNumber.isValid()) {
    return;
  }
  return "form.validation.phone";
}

export function required(value) {
  if (isEmpty(value)) {
    return "form.validation.required";
  }
}

export function notEmpty(array) {
  if (!array?.length) {
    return { _error: "form.validation.required" };
  }
}

export function truthy(value) {
  if (!value) {
    return "form.validation.required";
  }
}

export function location(value) {
  if (value) {
    if (value.error) return value.error; // geocoding/in svc area errors get added to value
    if (value.virtual) return false;
    if ((value.lat && value.lng) || value.freeform) {
      return undefined;
    }
  }
  return "form.validation.incomplete_address";
}

export function chosen(value) {
  if (isEmpty(value)) {
    return "form.validation.chosen";
  }
}

export function length(len) {
  return (value) => {
    if (!isEmpty(value) && value.length !== len) {
      return `Length must be ${len}.`;
    }
  };
}

export function minLength(minimum) {
  return (value) => {
    if (!isEmpty(value) && value.length < min) {
      return `Must be at least ${minimum} characters`;
    }
  };
}

export function maxLength(maximum) {
  return (value) => {
    if (!isEmpty(value) && value.length > max) {
      return `Must be no more than ${maximum} characters`;
    }
  };
}

export function min(minimum) {
  return (value) => {
    if (!value || !integer(value) || value < minimum) {
      return `Must be greater than ${value}`;
    }
  };
}

export function max(maximum) {
  return (value) => {
    if (!value || !integer(value) || value > maximum) {
      return `Must be less than ${value}`;
    }
  };
}

export function integer(value) {
  if (!Number.isInteger(Number(value))) {
    return "Must be an integer";
  }
}

export function oneOf(enumeration) {
  return (value) => {
    if (!~enumeration.indexOf(value)) {
      return `Must be one of: ${enumeration.join(", ")}`;
    }
  };
}

export function match(field) {
  return (value, data) => {
    if (data) {
      if (value !== data[field]) {
        return "Do not match";
      }
    }
  };
}

export function ccNumber(value) {
  const luhnCheck = (a) => {
    let b;
    let c;
    let d;
    let e;
    for (d = +a[(b = a.length - 1)], e = 0; b--; ) {
      (c = +a[b]), (d += ++e % 2 ? ((2 * c) % 10) + (c > 4) : c);
    }
    return !(d % 10);
  };
  if (value) {
    const sanitized = String(value).replace(/[^a-zA-Z0-9]/g, "");
    const valid =
      !integer(sanitized) &&
      !minLength(10)(sanitized) &&
      !maxLength(16)(sanitized) &&
      luhnCheck(sanitized);
    if (!valid) return "form.validation.cc_number";
  }
}

export function ccMonth(value) {
  const valid = value <= 12 && value >= 1;
  if (!valid) return "form.validation.expiration_month";
}

export function ccYear(value) {
  const currYear = new Date().getFullYear();
  const valid = value >= currYear && value <= currYear + 20;
  if (!valid) return "form.validation.expiration_year";
}

export function cvv(value) {
  const valid = !minLength(3)(value) && !maxLength(4)(value);
  if (!valid) return "form.validation.cvv";
}

export function postalCode(value) {
  // HOSER: duplicated in a bunch of other places, search for the regex
  const UK_POSTAL_CODE_VALIDATOR = new RegExp(
    /^([^qvx0-9\W*])+([^ijz\W*])+((\s|-)+)?(([a-z0-9])+)?/i
  );
  const CA_POSTAL_CODE_VALIDATOR = new RegExp(
    /^([A-Za-z]\d[A-Za-z]|[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d)$/
  );
  const FR_POSTAL_CODE_VALIDATOR = new RegExp(/^(?:[0-8]\d|9[0-8])\d{3}$/);
  const DE_POSTAL_CODE_VALIDATOR = new RegExp(
    /^(?!01000|99999)(0[1-9]\d{3}|[1-9]\d{4})$/
  );
  const ES_POSTAL_CODE_VALIDATOR = new RegExp(
    /^(?:[0-9][1-9]|[1-9][0-9])\d{3}$/
  );
  const PT_POSTAL_CODE_VALIDATOR = new RegExp(/^\d{4}[-]\d{3}$/);
  const IT_POSTAL_CODE_VALIDATOR = new RegExp(/^\d{5}$/);

  let valid;

  switch (countryIsoCodeFromLocale(window.LOCALE)) {
    case "US":
      valid = value !== "00000" && /^(\d{5})$/.test(value);
      if (!valid) return "form.validation.postal_code";
      break;
    case "GB":
      valid = UK_POSTAL_CODE_VALIDATOR.test(value);
      if (!valid) return "form.validation.postal_code";
      break;
    case "CA":
      // HOSER: DRY up with other ones
      // Allows Canada & US credit card currently. Need to explore support for all credit cards we support
      valid =
        CA_POSTAL_CODE_VALIDATOR.test(value) ||
        (value !== "00000" && /^(\d{5})$/.test(value));
      if (!valid) return "form.validation.postal_code";
      break;
    case "FR":
      valid = FR_POSTAL_CODE_VALIDATOR.test(value);
      if (!valid) return "form.validation.postal_code";
      break;
    case "DE":
      valid = DE_POSTAL_CODE_VALIDATOR.test(value);
      if (!valid) return "form.validation.postal_code";
      break;
    case "ES":
      valid = ES_POSTAL_CODE_VALIDATOR.test(value);
      if (!valid) return "form.validation.postal_code";
      break;
    case "PT":
      valid = PT_POSTAL_CODE_VALIDATOR.test(value);
      if (!valid) return "form.validation.postal_code";
      break;
    case "IT":
      valid = IT_POSTAL_CODE_VALIDATOR.test(value);
      if (!valid) return "form.validation.postal_code";
      break;
    default:
      return false;
  }
}

/* eslint-disable consistent-return */
export function vatId(vat) {
  if (!vat) return;
  const val = vat.trim();
  if (!!val && !/^(gb|gd|ha)?\d{5,12}$/i.test(val)) {
    return "account.vat_id.vat_id_value_error";
  }
}
/* eslint-enable consistent-return */

export const passwordValidator = (values) => {
  let errors = {};

  if (!values.email) {
    errors.email = "form.validation.required";
  }

  if (!values.password) {
    errors.password = "form.validation.required";
  } else if (values.password.length < 8) {
    errors.password = "ChangePasswordV2.form.labels.passwordLength";
  }

  if (!values.confirmPassword) {
    errors.confirmPassword = "form.validation.required";
  }

  if (values.password !== values.confirmPassword) {
    errors.confirmPassword = "ChangePasswordV2.form.labels.passwordMatch";
  }

  return errors;
};

export function createValidator(rules) {
  return (data = {}) => {
    const errors = {};
    Object.keys(rules).forEach((key) => {
      const rule = join([].concat(rules[key])); // concat enables both functions and arrays of functions
      const error = rule(data[key], data);
      if (error) {
        errors[key] = error;
      }
    });

    return errors;
  };
}

export const validateImageFile = function (file) {
  const extension = file.name.split(".").pop().toLowerCase();
  const validExtensions = ["gif", "png", "bmp", "jpeg", "jpg"];
  if (validExtensions.includes(extension) && file.size < MAX_FILE_SIZE) {
    return true;
  }
  return false;
};

export const isValidJSON = function (value) {
  if (!value) return false;
  try {
      return !!JSON.parse(value);
  } catch (e) {
      return false;
  }
}
