import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import classnames from "classnames";
import { FieldProps } from "../types";
import { useIntl } from "react-intl";
import { FormattedMessage } from "components/FormattedMessage";
import CountryDropdown from "build/components/common/CountryDropdown";
import { Row } from "components/Grid";
import { change, Field, getFormValues } from "redux-form";
import { messages } from "../ProfileEdit";

export default function PhoneInput(field: FieldProps): JSX.Element {
  const formName = field.meta.form;
  const dispatch = useDispatch();
  const formData: Record<string, unknown> = useSelector(state => getFormValues(formName)(state));
  const intl = useIntl();

  const { formatMessage } = intl;
  const disabled = field.disabled || false;
  const renderErrorMessage = () => {
    if (field.hideNameOnError) {
      return formatMessage({ id: field.meta.error });
    }
    return (
      <>
        <FormattedMessage message={messages.phone_number} />{" "}
        {formatMessage({ id: field.meta.error }).toLowerCase()}
      </>
    );
  };

  const renderCountryCodeDropdown = useCallback((countryCodeField: FieldProps) => {
    return (
      <CountryDropdown
        onChange={(value: string, countryCode: string) => {
          countryCodeField.input.onChange(value);
          dispatch(change(formName, 'country_code', countryCode));
        }}
        value={countryCodeField.input.value}
        selectedCountryCode={formData?.country_code as string} // country_code will be a virtual field
        type={countryCodeField.type}
      />
    );
  }, [dispatch, formData?.country_code, formName]);

  return (
    <Row
      className={`${
        field.meta.touched && field.meta.error ? "error-container" : ""
      }`}
    >
      <label htmlFor={field.input.name}>
        <div>
          { !field.placeholder && <FormattedMessage message={messages.phone_number} /> }
        </div>
        <div className={classnames({
          "flex-column": true,
          "flex-container": field.isFlex,
          })}>
          <Field
            name="phone_country_code"
            component={renderCountryCodeDropdown}
            type={field.type}
          />
          <input
            id={field.input.name}
            className={field.inputClassName}
            type="text"
            aria-describedby={`${field.input.name}-label`}
            disabled={disabled}
            placeholder={field.placeholder}
            // required for form fields
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...field.input}
          />
        </div>
      </label>
      {field.meta.touched && field.meta.error && (
        <div id={`${field.input.name}-label`} className="error">
          <i
            className="ss-icon ss-lnr-warning red"
            aria-label={formatMessage(messages.error_label)}
          />
          <span className="visually-hidden">
            <FormattedMessage message={messages.error_label} />
          </span>{" "}
          {renderErrorMessage()}
        </div>
      )}
    </Row>
  );
}
