import { useWindowInnerWidth } from "./use-window-inner-width";
import { MaxWidthBreakpoint, MinWidthBreakpoint } from "../../components/theme";

/**
 * The functions in this file are used to determine the current device
 * based on the windowInnerWidth.
 *
 * Currently we are only considering two cases:
 * 1. It's a Mobile device
 * 2. It's NOT a Mobile device, and therefore it's Desktop!
 *
 * If we want to support more than the Mobile/Desktop dichotomy, we
 * will need to refactor these functions to be more specific about the
 * range of the windowInnerWidth values that they represent.
 *
 * TODO (Evan Schrager): Based on the `MinWidthBreakpoint` object in theme.js,
 * it seems we are currently not covering the 501-800 range (tablet?).
 * Since no components are rendering a special view for tablets this is currently
 * a non-issue.
 */
export const useIsMobile = () => {
  const windowInnerWidth = useWindowInnerWidth();
  return windowInnerWidth <= MaxWidthBreakpoint.mobile;
};

export const useIsDesktop = () => !useIsMobile();

export const useWideViewport = () => {
  const windowInnerWidth = useWindowInnerWidth();
  // The initial viewport width local state value is set to 0 in our hook. 
  // Handle the 0 width case explicitly to prevent undesirable "jump" between narrow/wide render behavior.
  if (windowInnerWidth == 0) {
    return null;
  }
  return windowInnerWidth >= MinWidthBreakpoint.lgDesktop;
};

/**
 * ForDesktop and ForMobile are meant to be rendered as wrapper components
 * if you want to conditionally render content for Mobile or Desktop.
 *
 * EXAMPLE:
 *
 * <ForDesktop>
 *   <DesktopComponent>
 *     <DesktopChildComponent>
 *       ...otherChildren
 *     </DesktopChildComponent>
 *   </DesktopComponent>
 * </ForDesktop>
 * <ForMobile>
 *   <MobileComponent>
 *     <MobileChildComponent>
 *       ...otherChildren
 *     </MobileChildComponent>
 *   </MobileComponent>
 * </ForMobile>
 */

export const ForDesktop = ({ children }: any) => useIsDesktop() && children;
export const ForMobile = ({ children }: any) => useIsMobile() && children;
