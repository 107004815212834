export const ACCOUNT_SET_ADDRESSES = "/api/v3/web-client/set_user_location.json"
export const ACCOUNT_GET_ADDRESSES = "/api/v3/web-client/get_user_location.json"
export const ACCOUNT_AVATAR_UPLOAD = "/api/v3/account/avatar.json";
export const ACCOUNT_CHANGE_PASSWORD = "/api/v3/password.json";
export const ACCOUNT_DEACTIVATION = "/api/v3/self_deactivation.json";
export const ACCOUNT_DEACTIVATE = "/api/v3/deactivate.json";
export const ACCOUNT_GIFT_CARDS = "/api/v3/gift_cards.json";
export const ACCOUNT_NOTIFICATIONS = "/api/v3/account/notifications.json";
export const ACCOUNT_PROFILE = "/api/v3/account.json";
export const RABBIT_PROFILE = "/api/v3/profile/rabbit_info.json";
export const RABBIT_PROFILE_UPDATE = "/api/v3/profile/update.json";
export const ACCOUNT_REDEMPTIONS = "/api/v3/redeem.json";
export const ACCOUNT_PAYMENTS_BANK_ACCOUNT =
  "/api/v3/settings/profile/payment_merchants/:id.json";
export const ACCOUNT_PAYMENTS_BANK_ACCT_UPDATE =
  "/api/v3/settings/profile/payment_merchants/bank_account.json";
export const ACCOUNT_PAYMENTS_TAXID =
  "/api/v3/settings/profile/payment_merchants/:id/tax_id.json";
export const ACCOUNT_PAYMENTS_ACCOUNT_LINK =
  "/api/v3/settings/profile/account_links/:id.json";
export const ACCOUNT_TRANSACTIONS = "/api/v3/payments/history.json";
export const ACCOUNT_TRANSACTIONS_EXPORT = "/api/v3/payments/export.json";
export const ACCOUNT_TRANSACTIONS_EXPORT_STATUS =
  "/api/v3/payments/check_export.json";
export const PERSONAL_INFORMATION = "/api/v3/profile/personal_information.json";
export const ACCOUNT_VERIFY_EMAIL = "/api/v3/email_change/send_email";

export const GET_1099K_FORM = "/api/v3/account/1099k_form";
export const GET_1099K_FORMS = "/api/v3/account/1099k_forms";

export const TWO_FACTOR_AUTH_CODE = "/api/v3/sms_auth/phone";
export const TWO_FACTOR_AUTH = "/api/v3/sms_auth";
export const TWO_FACTOR_AUTHORIZE = "/api/v3/mfa/authorize ";

export const TWO_FACTOR_AUTH_SUPPORT = "http://help.tr.co/";
