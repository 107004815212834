import React, { useMemo } from "react";

export function BodyScrollLocker() {
  const reactModalBodyOpenStyle = useReactModalBodyOpenStyle();

  return (
    <style>{reactModalBodyOpenStyle}</style>
  );
}

/**
 * useReactModalBodyOpenStyle sets the body's overflow to hidden to prevent the
 * user from scrolling the page while a modal is open. It also adds some
 * padding to the right side of the bosy so that the page's content does not
 * reflow when the vertical scroll bar is removed.
 */
function useReactModalBodyOpenStyle() {
  // scrollBarWidth should be calculated only once when the modal is opened. It
  // must not be recalculated on future renders.
  const scrollBarWidth = useMemo(getScrollBarWidth, []);

  return `.ReactModal__Body--open { overflow: hidden; padding-right: ${scrollBarWidth}px; }`;
}

function getScrollBarWidth() {
  if (typeof window === "undefined") {
    return 0;
  }
  const inner = document.createElement("p");
  inner.style.width = "100%";
  inner.style.height = "200px";

  const outer = document.createElement("div");
  outer.style.position = "absolute";
  outer.style.top = "0";
  outer.style.left = "0";
  outer.style.visibility = "hidden";
  outer.style.width = "200px";
  outer.style.height = "150px";
  outer.style.overflow = "hidden";
  outer.appendChild(inner);

  document.body.appendChild(outer);
  const innerOffsetWidth = inner.offsetWidth;
  outer.style.overflow = "scroll";
  const innerOffsetWidthWithScroll = inner.offsetWidth;

  const scrollBarWidth =
    innerOffsetWidth === innerOffsetWidthWithScroll
      ? innerOffsetWidth - outer.clientWidth
      : innerOffsetWidth - innerOffsetWidthWithScroll;

  document.body.removeChild(outer);
  return scrollBarWidth;
}
