import xhr from "util/xhr";
import { Observable } from "rxjs";
import { combineEpics } from "redux-observable";

import { updateDashboardMessageNotification } from "dashboard/redux/modules/taskLists";

export const NOTIFICATION_POLLI_STUBBED =
  "dashboard/polling/NOTIFICATION_POLLI_STUBBED";
export const NOTIFICATION_POLLING_INITIALIZE =
  "dashboard/polling/NOTIFICATION_POLLING_INITIALIZE";
export const NOTIFICATION_POLLING_CHECKED =
  "dashboard/polling/NOTIFICATION_POLLING_CHECKED";
export const NOTIFICATION_POLLING_IGNORED =
  "dashboard/polling/NOTIFICATION_POLLING_IGNORED";
export const NOTIFICATION_POLLING_CLOSED =
  "dashboard/polling/NOTIFICATION_POLLING_CLOSED";

const POLLTIME_ENDPOINT = "/api/v3/polltime.json";
const POLL_FREQUENCY_20 = 20000;

const initialState = {
  isPolling: false,
};

export default function eventPoll(state = initialState, action) {
  switch (action.type) {
    case NOTIFICATION_POLLING_INITIALIZE:
      return { ...state, isPolling: true };
    case NOTIFICATION_POLLING_CLOSED:
      return { ...state, isPolling: false };
    case NOTIFICATION_POLLING_CHECKED:
      return state;
    default:
      return state;
  }
}

export const notificationPollingInitialize = () => ({
  type: NOTIFICATION_POLLING_INITIALIZE,
});

const notificationChecked = () => ({
  type: NOTIFICATION_POLLING_CHECKED,
});

const notificationIgnored = () => ({
  type: NOTIFICATION_POLLING_IGNORED,
});

const notificationPollingClosed = () => ({
  type: NOTIFICATION_POLLING_CLOSED,
});

export function handlePollEvents({ events }) {
  if (events.length === 0) {
    return notificationChecked();
  }

  return (dispatch, getState) => {
    // Handles update dashboard messsage counter
    dispatch(updateDashboardMessageNotification(events));

    // Notification alert popup
    dispatch(handlePopupNotifications(events));
  };
}

const notificationPollingEpic = (action$, { getState }) =>
  action$.ofType(NOTIFICATION_POLLING_INITIALIZE).switchMap(() => {
    // Start PollTime polling
    return Observable.interval(POLL_FREQUENCY_20)
      .switchMap(() => {
        const { loggedIn } = getState().user.loggedIn;

        if (!loggedIn) {
          return Observable.of({ type: NOTIFICATION_POLLI_STUBBED });
        }

        return xhr
          .get(POLLTIME_ENDPOINT)
          .then((res) => {
            if (!res.data) {
              return notificationIgnored;
            }
            return handlePollEvents(res.data);
          })
          .catch(() => notificationIgnored);
      })
      .catch(notificationPollingClosed);
  });

export const eventPollEpic = combineEpics(notificationPollingEpic);
