import React, { useCallback, useEffect, useState } from "react";
import { defineMessages, injectIntl } from "react-intl";
import styled from "styled-components";
import {
  HeaderNavigationButton,
  HeaderNavigationIcon,
} from "../../header-constants";
import { useIsDesktop } from "../../../../../shared/hooks/use-breakpoints";
import { MobileList } from "./MobileServicesMenu/MobileList";
import { FormattedMessage } from "../../../../FormattedMessage";

const messages = defineMessages({
  services: {
    id: "MobileServicesMenu.services",
    defaultMessage: "Services",
  },
});

function MobileServicesMenuBase({ links, mobileExpandInitialState }) {
  const [isMenuOpen, setIsMenuOpen] = useState(mobileExpandInitialState);
  const toggleIsMenuOpen = useCallback(() => {
    setIsMenuOpen((x) => !x);
  }, []);
  const closeMenu = useCallback(() => {
    setIsMenuOpen(false);
  }, []);
  const isDesktop = useIsDesktop();
  useEffect(() => {
    if (isDesktop) {
      closeMenu();
    }
  }, [closeMenu, isDesktop]);

  if (!isMenuRenderable({ links })) {
    return null;
  }

  const servicesNavigationIconClassName = isMenuOpen
    ? "ss-lnr-circle-minus"
    : "ss-lnr-plus-circle";

  return (
    <ServicesMenuContainer>
      <HeaderNavigationButton onClick={toggleIsMenuOpen}>
        <HeaderNavigationIcon className={servicesNavigationIconClassName} />
        <FormattedMessage message={messages.services} />
      </HeaderNavigationButton>
      <MobileList isMenuOpen={isMenuOpen} links={links} />
    </ServicesMenuContainer>
  );
}

function isMenuRenderable({ links }) {
  return links != null;
}

const ServicesMenuContainer = styled.div`
  display: block;
  ${(props) => props.theme.breakpoints.desktopUp} {
    display: none;
  }
`;

export const MobileServicesMenu = injectIntl(MobileServicesMenuBase);
