import { ACCOUNT_NOTIFICATIONS } from "account/routes/apiRoutes";
import xhr, { createApiErrorAlert } from "util/xhr";
import { createAlert } from "alerts/redux/modules/alerts";
import { defineMessages } from "react-intl";
import { push } from "connected-react-router";
import { internalPath } from "util/internalPath";

const messages = defineMessages({
  notifications_updated: {
    id: "alerts.notifications_updated",
    defaultMessage: "Notification preferences updated.",
  },
});

const SAVE_NOTIFICATIONS = "account/notifications/SAVE_NOTIFICATIONS";
const LOAD_NOTIFICATIONS_REQUEST =
  "account/notifications/LOAD_NOTIFICATIONS_REQUEST";
const LOAD_NOTIFICATIONS_RESPONSE =
  "account/notifications/LOAD_NOTIFICATIONS_RESPONSE";
const LOAD_NOTIFICATIONS_ERROR =
  "account/notifications/LOAD_NOTIFICATIONS_ERROR";
const UPDATE_NOTIFICATIONS_REQUEST =
  "account/notifications/UPDATE_NOTIFICATIONS_REQUEST";
const UPDATE_NOTIFICATIONS_RESPONSE =
  "account/notifications/UPDATE_NOTIFICATIONS_RESPONSE";
const UPDATE_NOTIFICATIONS_ERROR =
  "account/notifications/UPDATE_NOTIFICATIONS_ERROR";

export function receiveSettings(response) {
  return { type: LOAD_NOTIFICATIONS_RESPONSE, response };
}

export function requestSettings() {
  return { type: LOAD_NOTIFICATIONS_REQUEST };
}

export function errorSettings() {
  return { type: LOAD_NOTIFICATIONS_ERROR };
}

export function requestSaveNotificationSettings() {
  return { type: UPDATE_NOTIFICATIONS_REQUEST };
}

export function receiveSaveNotificationSettings(response) {
  return { type: UPDATE_NOTIFICATIONS_RESPONSE, response };
}

export function errorSaveNotificationSettings(response) {
  return { type: UPDATE_NOTIFICATIONS_ERROR, response };
}

function fetchSettings() {
  return dispatch => {
    dispatch(requestSettings());
    return xhr
      .get(ACCOUNT_NOTIFICATIONS)
      .then(response => dispatch(receiveSettings(response.data)))
      .catch(e => {
        dispatch(createApiErrorAlert(e));
        dispatch(errorSettings());
      });
  };
}

function shouldFetchSettings(state) {
  if (!state.account.notifications.data) return true;
  if (state.account.notifications.isLoading) return false;
}

export function fetchSettingsIfNeeded() {
  return (dispatch, getState) => {
    if (shouldFetchSettings(getState())) {
      return dispatch(fetchSettings());
    }
  };
}

export function saveNotificationSettings() {
  return (dispatch, getState) => {
    dispatch(requestSaveNotificationSettings());
    const state = getState();
    const settings = Object.assign(
      state.account.notifications.data,
      state.form.notifications.values
    );
    return xhr
      .post(ACCOUNT_NOTIFICATIONS, JSON.stringify(settings))
      .then(response => {
        dispatch(receiveSaveNotificationSettings(response.data));
        dispatch(createAlert(messages.notifications_updated.id));
        dispatch(push(internalPath("/")));
      })
      .catch(e => {
        dispatch(createApiErrorAlert(e));
        dispatch(errorSaveNotificationSettings());
      });
  };
}

const initialState = {
  data: undefined,
  error: undefined,
  isLoading: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SAVE_NOTIFICATIONS:
      return state;
    case LOAD_NOTIFICATIONS_REQUEST:
      return { ...state, isLoading: true };
    case LOAD_NOTIFICATIONS_RESPONSE:
      return {

        ...state, isLoading: false, data: action.response
      };
    case LOAD_NOTIFICATIONS_ERROR:
      return { ...state, isLoading: false };
    case UPDATE_NOTIFICATIONS_REQUEST:
      return { ...state, isLoading: true };
    case UPDATE_NOTIFICATIONS_RESPONSE:
      return {

        ...state, isLoading: false, data: action.response
      };
    case UPDATE_NOTIFICATIONS_ERROR:
      return { ...state, isLoading: false };
    default:
      return state;
  }
}
