import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { createSetupIntent } from "payments/redux/modules/stripePaymentMethod";
import { defineMessages } from "react-intl";
import { PaymentRequest, SetupIntentResult, Stripe } from "@stripe/stripe-js";
import { FormattedMessage } from "../../components/FormattedMessage";

const messages = defineMessages({
  applePayCaption: {
    id: "editPayment.applePayCaption",
    defaultMessage:
      "Redirecting you to verify your payment method through Apple Pay...",
  },
  applePaySuccess: {
    id: "editPayment.applePaySuccess",
    defaultMessage:
      "Success!",
  },
  googlePayCaption: {
    id: "editPayment.googlePayCaption",
    defaultMessage:
      "Redirecting you to verify your payment method through Google Pay...",
  },
  googlePayCanceled: {
    id: "editPayment.googlePayCanceled",
    defaultMessage:
      "Your Google Pay setup has been cancelled and changes will not be saved.",
  },
  applePayCanceled: {
    id: "editPayment.applePayCanceled",
    defaultMessage:
      "Your Apple Pay setup has been cancelled and changes will not be saved.",
  }
});

interface NativePayFormProps {
  handlePaymentMethodChange: (isChanged: boolean) => void;
  stripe: Stripe;
  setNativePaySetupIntentResponse: (applePaySetupIntentResponse: SetupIntentResult) => void;
  paymentRequest: PaymentRequest;
  hasApplePay: boolean;
}

function NativePayForm({
  handlePaymentMethodChange,
  stripe,
  setNativePaySetupIntentResponse,
  paymentRequest,
  hasApplePay,
}: NativePayFormProps) {

  const dispatch = useDispatch();

  const [nativePaySuccess, setNativePaySuccess] = useState(null as boolean | null);
  const [nativePayError, setNativePayError] = useState(null as string | null | undefined);

  const [paymentMethodCanceled, setPaymentMethodCanceled] = useState(false);

  useEffect(() => {
    paymentRequest.on("paymentmethod", async (ev) => {
      const setupIntentCall = await dispatch(createSetupIntent({
        win: window,
        paymentMethodType: "card",
      }));
      if (!setupIntentCall){
        return;
      }
      const setupIntent = setupIntentCall.data;
      const clientSecret = setupIntent.client_secret;
      // Confirm the PaymentIntent without handling potential next actions (yet).
      try {
        const response = await stripe.confirmCardSetup(
          clientSecret,
          { payment_method: ev.paymentMethod.id },
          { handleActions: false }
        );
        const { setupIntent: cardSetupIntent, error: setupError } = response;
        if (setupError) {
          // Report to the browser that the payment failed, prompting it to
          // re-show the payment interface, or show an error message and close
          // the payment interface.
          ev.complete("fail");
          setNativePaySuccess(false);
          setNativePayError(setupError.message);
        } else {
          // Report to the browser that the confirmation was successful, prompting
          // it to close the browser payment method collection interface.
          ev.complete("success");
          // Check if the PaymentIntent requires any actions and if so let Stripe.js
          // handle the flow. If using an API version older than "2019-02-11"
          // instead check for: `paymentIntent.status === "requires_source_action"`.
          if (cardSetupIntent?.status === "requires_action") {
            // Let Stripe.js handle the rest of the payment flow.
            const { error } = await stripe.confirmCardSetup(clientSecret);
            if (error) {
              // The payment failed -- ask your customer for a new payment method.
              setNativePaySuccess(false);
              setNativePayError(error.message);
            } else {
              setNativePaySuccess(true);
              // The payment has succeeded.
              setNativePaySetupIntentResponse(response);
              handlePaymentMethodChange(true);
            }
          } else {
            // The payment has succeeded.
            setNativePaySuccess(true);
            setNativePaySetupIntentResponse(response);
            handlePaymentMethodChange(true);
          }
        }
      } catch (error) {
        console.log(error);
        ev.complete("fail");
      }
    });
    paymentRequest.on('cancel', () => {
      setPaymentMethodCanceled(true);
      setNativePaySuccess(false);
    })
  }, [dispatch, handlePaymentMethodChange, paymentRequest, setNativePaySetupIntentResponse, stripe]);

  if (paymentMethodCanceled) {
    return (
      <p>
        <FormattedMessage message={hasApplePay ? messages.applePayCanceled : messages.googlePayCanceled} />
      </p>
    );
  }

  if (nativePaySuccess === true) {
    return (
      <p>
        <FormattedMessage message={messages.applePaySuccess} />
      </p>
    );
  }
  if (nativePaySuccess === false) {
    return (
      <p>
        {nativePayError}
      </p>
    );
  }
  return (
    <p>
      <FormattedMessage message={hasApplePay ? messages.applePayCaption : messages.googlePayCaption} />
    </p>
  );
}


export default NativePayForm;
