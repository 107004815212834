import React, {
  ReactNode,
  useContext,
  useMemo,
  useState,
  useEffect,
} from "react";
import xhr from "../util/xhr";
import { useIsMountedRef } from "../util/useIsMountedRef";
import { featureFlags } from "../enums/featureFlags";

const ENABLED_FEATURES_PATH = "/api/v3/feature_manager/enabled_features.json";

interface FeatureManagerData {
  enabledFeatures: string[];
  ready: boolean;
}

const FeatureManagerContext = React.createContext<FeatureManagerData | null>(
  null
);

interface FeatureManagerProviderProps {
  children: ReactNode;
  overrideFeatures?: string[];
}

export function FeatureManagerProvider({
  children,
  overrideFeatures,
}: FeatureManagerProviderProps) {
  const featureManager = useContext(FeatureManagerContext);
  if (featureManager) {
    throw Error(
      "Developer error: Do not nest FeatureManagerProvider components."
    );
  }

  const [enabledFeatures, setEnabledFeatures] = useState(
    overrideFeatures ?? window.AUDITION?.ENABLED_FEATURES ?? []
  );
  const [ready, setReady] = useState(
    !!overrideFeatures || !!enabledFeatures.length
  );
  const isMountedRef = useIsMountedRef();

  useEffect(() => {
    if (!ready) {
      xhr.get(ENABLED_FEATURES_PATH).then(({ data }) => {
        if (isMountedRef.current) {
          setEnabledFeatures(data.enabled_features);
          setReady(true);
        }
      });
    }
  }, [isMountedRef, ready]);

  const value = useMemo(
    () => ({
      enabledFeatures,
      ready,
    }),
    [enabledFeatures, ready]
  );

  return (
    <FeatureManagerContext.Provider value={value}>
      {children}
    </FeatureManagerContext.Provider>
  );
}

export function useFeatureFlag(featureFlag: featureFlags) {
  if (!Object.values(featureFlags).includes(featureFlag)) {
    throw new Error(`Please use a featureFlag. Received this: ${featureFlag}`);
  }

  const featureManager = useContext(FeatureManagerContext);
  if (!featureManager) {
    throw Error(
      "No 'FeatureManagerContext' provider was found. Add a <FeatureManagerProvider> to your component tree."
    );
  }

  const { enabledFeatures, ready } = featureManager;
  const enabled = enabledFeatures.includes(featureFlag);

  return {
    enabled,
    ready,
  };
}
