import { CURRENCY_CODE } from "util/constants";

export const applyRoundingToAmount = (amount, rounded) => {
  const firstResult = Number(Number(amount).toFixed(2));

  return rounded ? Math.ceil(firstResult) : firstResult;
};

export const format = (
  amount,
  {
    noCentsIfWhole = false,
    currencyCode = CURRENCY_CODE || "USD",
    rounded = false,
  } = {}
) => {
  const roundedAmount = applyRoundingToAmount(amount, rounded);
  const isFractional = roundedAmount % 1;
  const displayCents = isFractional || !noCentsIfWhole;
  const formatOptions = {
    currency: currencyCode,
    style: "currency",
    useGrouping: false,
  };

  if (!displayCents) {
    formatOptions.minimumFractionDigits = 0;
    formatOptions.maximumFractionDigits = 0;
  }

  const formatter = Intl.NumberFormat(window.LOCALE, formatOptions);

  return formatter.format(roundedAmount);
};
