import {
  DIMENSION_ENDPOINT,
  CREATE_REVIEW_ENDPOINT,
} from "feedback/routes/apiRoutes";
import xhr from "util/xhr";
import qs from "qs";
import find from "lodash/find";
import findIndex from "lodash/findIndex";
import { createAlert } from "alerts/redux/modules/alerts";
import { defineMessages } from "react-intl";
import { internalPath } from "util/internalPath";
import { POSITIVE_STAR_RATING } from "../../../util/constants";

const messages = defineMessages({
  success: {
    id: "feedback.api.review.success",
    defaultMessage: "Rating has been submitted",
  },
});

const FETCH_DIMENSIONS = "FETCH_DIMENSIONS";
const FETCH_DIMENSIONS_RESPONSE = "FETCH_DIMENSIONS_RESPONSE";
const FETCH_DIMENSIONS_ERROR = "FETCH_DIMENSIONS_ERROR";
const SET_DIMENSIONS = "SET_DIMENSIONS";
const SET_DIMENSIONS_RESPONSE = "SET_DIMENSIONS_RESPONSE";
const SET_DIMENSIONS_ERROR = "SET_DIMENSIONS_ERROR";
const POST_REVIEW = "POST_REVIEW";
const POST_REVIEW_RESPONSE = "POST_REVIEW_RESPONSE";
const FEEDBACK_SET_REVIEW_STARS = "FEEDBACK_SET_REVIEW_STARS";
const FEEDBACK_SET_REVIEW_MESSAGE = "FEEDBACK_SET_REVIEW_MESSAGE";
const FEEDBACK_SET_FAVORITE_TASKER = "FEEDBACK_SET_FAVORITE_TASKER";
const OPTIMISTICALLY_UPDATE_DIMENSION = "OPTIMISTICALLY_UPDATE_DIMENSION";

const initialState = {
  pendingRequests: [],
};

const processShowDimensionsResponse = (state, action) => {
  return {
    ...state,
    dimensions: action.result.questions.items,
    rabbit: action.result.rabbit,
    already_reviewed: action.result.already_reviewed,
    followup_code: action.result.followup_code,
    positive_review: action.result.positive_review,
    next_action: action.result.next_action,
    invoice_id: action.result.invoice_id,
  };
};

const recalculatePendingRequests = (pendingRequests, requestData, remove) => {
  if (remove) {
    const index = findIndex(pendingRequests, requestData);
    return [
      ...pendingRequests.slice(0, index),
      ...pendingRequests.slice(index + 1),
    ];
  } 
    return [...pendingRequests, requestData];
  
};

export default function feedback(state = initialState, action) {
  switch (action.type) {
    case OPTIMISTICALLY_UPDATE_DIMENSION:
      const updatedIndex = findIndex(
        state.dimensions,
        (dim) => dim.dimension_key == action.dimension_key
      );
      return {
        ...state,
        dimensions: state.dimensions
          .slice(0, updatedIndex)
          .concat([
            { ...state.dimensions[updatedIndex], value: action.value_key },
          ])
          .concat(state.dimensions.slice(updatedIndex + 1)),
      };
    case FETCH_DIMENSIONS_RESPONSE:
      return processShowDimensionsResponse(state, action);
    case SET_DIMENSIONS:
      return {
        ...state,
        pendingRequests: recalculatePendingRequests(
          state.pendingRequests,
          action.data,
          false
        ),
      };
    case SET_DIMENSIONS_ERROR:
      return {
        ...state,
        pendingRequests: recalculatePendingRequests(
          state.pendingRequests,
          action.data,
          true
        ),
      };
    case SET_DIMENSIONS_RESPONSE:
      return {
        ...processShowDimensionsResponse(state, action),
        pendingRequests: recalculatePendingRequests(
          state.pendingRequests,
          action.data,
          true
        ),
      };
    case FEEDBACK_SET_REVIEW_MESSAGE:
      return { ...state, message: action.message };
    case FEEDBACK_SET_FAVORITE_TASKER:
      return { ...state, isFavorite: action.isFavorite };
    case FEEDBACK_SET_REVIEW_STARS:
      return { ...state, numStars: action.stars };
    case POST_REVIEW_RESPONSE:
      return processShowDimensionsResponse(state, action);
    default:
      return state;
  }
}

export const redirectToNextPage = () => {
  return (dispatch, getState) => {
    const state = getState();

    const { loggedIn } = state.user;
    const {
      followup_code,
      numStars,
      positive_review,
      next_action,
      invoice_id,
      isFavorite,
    } = state.feedback.posterFeedback;
    const positiveReview =
      numStars >= POSITIVE_STAR_RATING ||
      (typeof numStars === "undefined" && positive_review);

    let url = "/";

    if (loggedIn) {
      if (positiveReview) {
        if (next_action === "tip" && invoice_id) {
          url = `/feedback/tip/${invoice_id}?referrer=feedback_form`;
        } else if (next_action === "followup" && followup_code) {
          url = `/dashboard?followup=${followup_code}`;
        }
        if (isFavorite && url !== "/") {
          url += `&add_favorite=true`;
        }
      }

      if (url === "/") {
        url = "/dashboard/active";
        if (isFavorite) {
          url += `?add_favorite=true`;
        }
      }
    }
    window.location.href = internalPath(url);
  };
};

export const setNumStars = (stars) => ({
  type: FEEDBACK_SET_REVIEW_STARS,
  stars,
});

export const setMessage = (message) => ({
  type: FEEDBACK_SET_REVIEW_MESSAGE,
  message,
});

export const setFavorite = (isFavorite) => ({
  type: FEEDBACK_SET_FAVORITE_TASKER,
  isFavorite,
});

const postReview = () => ({
  type: POST_REVIEW,
});

const postReviewResponse = (result) => ({
  type: POST_REVIEW_RESPONSE,
  result,
});

const optimisticallyUpdateDimension = ({ dimension_key, value_key }) => ({
  type: OPTIMISTICALLY_UPDATE_DIMENSION,
  dimension_key,
  value_key,
});

export const fetchDimensions = (code) => {
  return (dispatch, getState) => {
    const query = qs.stringify({ code });
    return dispatch({
      types: [
        FETCH_DIMENSIONS,
        FETCH_DIMENSIONS_RESPONSE,
        FETCH_DIMENSIONS_ERROR,
      ],
      promise: (xhr) => {
        return xhr
          .get(DIMENSION_ENDPOINT + "?" + query)
          .then((response) => response.data);
      },
    });
  };
};

// TODO remove on cleanup. Verify dimensions are no longer unused.
export const setDimension = ({
  dimension_key,
  value_key,
  code,
  question,
  answer,
}) => {
  return (dispatch, getState) => {
    // abort any requests if request with same dimension/value is in progress
    const state = getState();
    const actionData = { dimension_key, value_key };
    const requestAlreadyInProgress = find(
      state.feedback.posterFeedback.pendingRequests,
      actionData
    );
    if (requestAlreadyInProgress) {
      return;
    }

    dispatch(optimisticallyUpdateDimension({ dimension_key, value_key }));
    const query = qs.stringify({
      dimension_key,
      value_key,
      code,
      question,
      answer,
    });
    return dispatch({
      types: [SET_DIMENSIONS, SET_DIMENSIONS_RESPONSE, SET_DIMENSIONS_ERROR],
      data: actionData,
      promise: (xhr) => {
        return xhr
          .post(DIMENSION_ENDPOINT + "?" + query)
          .then((response) => response.data);
      },
    });
  };
};

export const submitReview = ({ code }) => {
  return (dispatch, getState) => {
    const {
      message,
      numStars,
      already_reviewed,
    } = getState().feedback.posterFeedback;
    const is_positive = numStars >= POSITIVE_STAR_RATING;
    const query = qs.stringify({
      code,
      message,
      is_positive,
      rating: numStars,
    });

    dispatch(postReview());

    return xhr
      .post(CREATE_REVIEW_ENDPOINT + "?" + query)
      .then((response) => {
        if (!already_reviewed) {
          dispatch(createAlert(messages.success.id));
          dispatch(postReviewResponse(response.data));
        }
      })
      .then(() => {
        dispatch(redirectToNextPage());
      });
  };
};
