import xhr, { snakeCaseKeysAndQueryStringify } from "util/xhr";

import { DEFAULT_RECOMMENDATIONS_SHOW } from "build/routes/apiRoutes";

const REBOOK_FETCH_COMPLETED =
  "dashboard/recommendations/REBOOK_FETCH_COMPLETED";
const REBOOK_FETCH_FAILED = "dashboard/recommendations/REBOOK_FETCH_FAILED";

export const rebookRecosFetched = (recoResponse) => ({
  type: REBOOK_FETCH_COMPLETED,
  recoResponse,
});

export const rebookRecosFailed = () => ({
  type: REBOOK_FETCH_FAILED,
});

export const fetchRebookRecos = (initialData) => (dispatch) => {
  const updateURL =
    DEFAULT_RECOMMENDATIONS_SHOW +
    "?" +
    snakeCaseKeysAndQueryStringify(initialData);

  return xhr
    .get(updateURL)
    .then(({ camelCasedData: data }) => {
      // Ensure only 3 recomenations display
      data.recommendations.items = data.recommendations.items.slice(0, 3);

      return dispatch(
        rebookRecosFetched({ ...data, jobId: initialData.jobId })
      );
    })
    .catch(() => {
      return dispatch(rebookRecosFailed());
    });
};

const initialState = {};

export default function rebookRecommendations(state = initialState, action) {
  switch (action.type) {
    case REBOOK_FETCH_COMPLETED: {
      const { recoResponse } = action;

      const forfeitJobOptions = {};

      forfeitJobOptions[recoResponse.jobId] = recoResponse;

      return { ...state, ...forfeitJobOptions };
    }
    case REBOOK_FETCH_FAILED:
      return state;
    default:
      return state;
  }
}
