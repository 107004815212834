import PropTypes from "prop-types";
import React, { Component, createElement } from "react";
import { defineMessages, injectIntl } from "react-intl";
import DefaultModalBody from "components/Modal/DefaultModalBody";
import enhanceWithModal from "components/Modal/enhanceWithModal";

const messages = defineMessages({
  close_dialog: {
    id: "modalContainer.close_dialog",
    defaultMessage: "Close dialog",
  },
});

class DefaultModalContainer extends Component {
  componentDidMount() {
    this.dismissRef.focus();
  }

  render() {
    const {
      modalClass,
      declineFn,
      title,
      intl: { formatMessage },
    } = this.props;
    const wrappedElement = createElement(
      modalClass || DefaultModalBody,
      this.props
    );

    return (
      <div>
        <div className="lightbox--content-wrapper">
          {!!title && <div className="lightbox--title">{title}</div>}
          <div className="lightbox--content">{wrappedElement}</div>
        </div>

        {/* [MEADOW_TODO] - Button */}
        <button
          className="lightbox--dismiss btn-icon"
          onClick={declineFn}
          ref={(ref) => {
            this.dismissRef = ref;
          }}
          type="button"
          aria-label={formatMessage(messages.close_dialog)}
          tabIndex="0"
        >
          <i className="ss-lnr-cross2" />
        </button>
      </div>
    );
  }
}

export default function ModalContainer(props) {
  return createElement(
    props.renderModal || enhanceWithModal(DefaultModalContainer),
    props
  );
}

DefaultModalContainer = injectIntl(DefaultModalContainer);
