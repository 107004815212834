import { createSelector } from "reselect";

import { getBuildJob, isIkeaJobFlow } from "build/redux/modules/selector";
import { Location } from "shared/types";
import { V3ReduxState } from "util/reduxTypes";

const getIkeaManager = (state: V3ReduxState) => state.ikea.manager;

export const selectJobDraftGuid = createSelector(
  isIkeaJobFlow,
  getBuildJob,
  getIkeaManager,
  (isConvergence, buildJob, ikeaManager): string | undefined =>
    isConvergence ? buildJob.job_draft_guid : ikeaManager.job_draft?.guid
);

export const selectJobLocation = createSelector(
  isIkeaJobFlow,
  getBuildJob,
  getIkeaManager,
  (inConvergence, buildJob, ikeaManager): Location | undefined => {
    if (inConvergence) {
      if (buildJob?.location?.assembly_poster_avg_hourly_rate_cents) {
          return buildJob.location;
      } else if (ikeaManager?.job_draft?.location?.assembly_poster_avg_hourly_rate_cents) {
        return ikeaManager?.job_draft?.location
      }
    }

    return ikeaManager.location;
  }
);

export const selectIkeaJobDraftLocation = createSelector(
  getIkeaManager,
  (ikeaManager): Location | undefined => ikeaManager.job_draft?.location);

export const selectIkeaJobDraftFormattedAddress = createSelector(
  getIkeaManager,
  (ikeaManager): string | undefined => ikeaManager.job_draft?.address?.formatted_address);

export const selectVerifyingLocation = createSelector(
  getIkeaManager,
  (ikeaManager): boolean | undefined => ikeaManager.verifyingLocation)

export const selectIkeaJobDraftPhaseMismatchModal = createSelector(
  getIkeaManager,
  (ikeaManager): boolean | undefined => ikeaManager.showPhaseMismatchModal
);
