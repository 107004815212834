import React, {
  ReactNode,
  useContext,
  useMemo,
  useState,
  useEffect,
} from "react";
import xhr from "util/xhr";
import { useIsMountedRef } from "util/useIsMountedRef";

const EXPERIMENTS_PATH = "/api/v3/experiment_manager/experiments.json";

export interface Experiment {
  experiment_key: string;
  variation_key: string;
}

interface ExperimentData {
  experiments: Experiment[];
  ready: boolean;
}

const ExperimentManagerContext = React.createContext<ExperimentData | null>(
  null
);

interface ExperimentManagerProviderProps {
  children: ReactNode;
  overrideExperiments?: Experiment[];
}

export function ExperimentManagerProvider({
  children,
  overrideExperiments,
}: ExperimentManagerProviderProps) {
  const experimentManager = useContext(ExperimentManagerContext);
  if (experimentManager) {
    throw Error(
      "Developer error: Do not nest ExperimentManagerProvider components."
    );
  }

  const [experiments, setExperiments] = useState(
    overrideExperiments ?? window.AUDITION?.EXPERIMENTS ?? []
  );
  const [ready, setReady] = useState(
    !!overrideExperiments || !!experiments.length
  );
  const isMountedRef = useIsMountedRef();

  useEffect(() => {
    if (!ready) {
      xhr.get(EXPERIMENTS_PATH).then(({ data }) => {
        if (isMountedRef.current) {
          setExperiments(data.experiments);
          setReady(true);
        }
      });
    }
  }, [isMountedRef, ready]);

  const value = useMemo(
    () => ({
      experiments,
      ready,
    }),
    [experiments, ready]
  );

  return (
    <ExperimentManagerContext.Provider value={value}>
      {children}
    </ExperimentManagerContext.Provider>
  );
}

export function useExperiment(experimentKey: string) {
  const experimentManager = useContext(ExperimentManagerContext);
  if (!experimentManager) {
    throw Error(
      "No 'ExperimentManagerContext' provider was found. Add an <ExperimentManagerProvider> to your component tree."
    );
  }

  const { experiments, ready } = experimentManager;
  const variationKey = experiments.find(
    (e) => e.experiment_key === experimentKey
  )?.variation_key;

  return {
    experimentKey,
    variationKey,
    ready,
  };
}
