import xhr, { createApiErrorAlert } from "util/xhr";
import { createActionTypesFor } from "util/reducerHelpers";
import { twoFactorModalSteps } from "enums/twoFactorAuth";
import {
  TWO_FACTOR_AUTH_CODE,
  TWO_FACTOR_AUTH,
} from "account/routes/apiRoutes";
import { push } from "connected-react-router";

const ACCOUNT_SEND_TWO_FACTOR_AUTH_CODE = createActionTypesFor(
  "accountSecurity",
  "sendTwoFactorAuthCode"
);

const ACCOUNT_RESEND_TWO_FACTOR_AUTH_CODE = createActionTypesFor(
  "accountSecurity",
  "resendTwoFactorAuthCode"
);

const ACCOUNT_VERIFY_TWO_FACTOR_AUTH_CODE = createActionTypesFor(
  "accountSecurity",
  "verifyTwoFactorAuthCode"
);

const GET_USER_TWO_FACTOR_AUTH = createActionTypesFor(
  "accountSecurity",
  "getUserTwoFactorAuth"
);

const DELETE_USER_TWO_FACTOR_AUTH = createActionTypesFor(
  "accountSecurity",
  "deleteUserTwoFactorAuth"
);

const ACCOUNT_SECURITY_VERIFY_MFA_CODE =  "ACCOUNT_SECURITY_VERIFY_MFA_CODE";
const ACCOUNT_SECURITY_SUCCESSFUL_SETUP = "accountSecuritySuccessfulSetup";
const ACCOUNT_SECURITY_UPDATE_TWO_FACTOR_MODAL_STEP =
  "accountUpdateTwoFactorModalStep";

const initialState = {
  fetched: false,
  loading: false,
  enrolled: false,
  enrolledPhone: "",
  verifed: false,
  error: null,
  mfaCompletedInPastHour: false,
  modal: {
    mfaPhone: "",
    codeResent: false,
    next: null,
    loading: false,
    error: null,
  },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ACCOUNT_SEND_TWO_FACTOR_AUTH_CODE.SUCCESS: {
      return {
        ...state,
        modal: {
          ...state.modal,
          next: twoFactorModalSteps.verify,
          loading: false,
          error: null,
          mfaPhone: action.data,
        },
      };
    }
    case GET_USER_TWO_FACTOR_AUTH.REQUEST: {
      return {
        ...state,
        loading: true,
        verified: false,
        fetched: false
      };
    }
    case GET_USER_TWO_FACTOR_AUTH.SUCCESS: {
      return {
        ...state,
        loading: false,
        enrolled: action.data.enrolled,
        enrolledPhone: action.data.phone,
        mfaCompletedInPastHour: action.data.mfaCompletedInPastHour,
        verified: action.data.mfaCompletedInPastHour,
        fetched: true,
      };
    }
    case ACCOUNT_SECURITY_SUCCESSFUL_SETUP: {
      return {
        ...state,
        enrolled: action.data.enrolled,
        enrolledPhone: action.data.phone,
        modal: {
          next: null,
          loading: false,
          error: null,
        },
        fetched: false,
      };
    }
    case ACCOUNT_SECURITY_VERIFY_MFA_CODE: {
      return {
        ...state,
        modal: {
          ...state.modal,
          error: null,
          next: action.data,
          mfaPhone: state.enrolled ? state.enrolledPhone : "",
        },
      };
    }
    case ACCOUNT_SEND_TWO_FACTOR_AUTH_CODE.REQUEST:
    case ACCOUNT_RESEND_TWO_FACTOR_AUTH_CODE.REQUEST:
    case ACCOUNT_VERIFY_TWO_FACTOR_AUTH_CODE.REQUEST: {
      return {
        ...state,
        modal: {
          ...state.modal,
          loading: true,
        },
      };
    }
    case ACCOUNT_VERIFY_TWO_FACTOR_AUTH_CODE.SUCCESS: {
      return {
        ...state,
        verified: true,
        modal: {
          next: twoFactorModalSteps.success,
          error: null,
          loading: false,
        },
      };
    }
    case DELETE_USER_TWO_FACTOR_AUTH.SUCCESS: {
      return {
        ...state,
        enrolled: false,
        enrolledPhone: "",
        modal: {
          next: null,
          loading: false,
          error: null,
        },
      };
    }
    case ACCOUNT_RESEND_TWO_FACTOR_AUTH_CODE.SUCCESS: {
      return {
        ...state,
        modal: {
          ...state.modal,
          codeResent: action.data,
          error: false,
          loading: false,
        },
      };
    }
    case DELETE_USER_TWO_FACTOR_AUTH.FAILURE:
    case ACCOUNT_VERIFY_TWO_FACTOR_AUTH_CODE.FAILURE:
    case ACCOUNT_RESEND_TWO_FACTOR_AUTH_CODE:
    case ACCOUNT_SEND_TWO_FACTOR_AUTH_CODE.FAILURE: {
      return {
        ...state,
        modal: {
          ...state.modal,
          error: action.data,
          loading: false,
        },
      };
    }

    case ACCOUNT_SECURITY_UPDATE_TWO_FACTOR_MODAL_STEP: {
      return {
        ...state,
        loading: false,
        fetched: false,
        modal: {
          ...state.modal,
          error: null,
          next: action.data,
        },
      };
    }
    default:
      return state;
  }
}

export const accountOpenVerifyTwoFactorAuth = () => ({
  type: ACCOUNT_SECURITY_VERIFY_MFA_CODE,
  data: twoFactorModalSteps.verify,
});

export const accountOpenActivateTwoFactorAuth = () => ({
  type: ACCOUNT_SECURITY_UPDATE_TWO_FACTOR_MODAL_STEP,
  data: twoFactorModalSteps.setup,
});

export const accountOpenDeactivateTwoFactorAuth = () => ({
  type: ACCOUNT_SECURITY_UPDATE_TWO_FACTOR_MODAL_STEP,
  data: twoFactorModalSteps.delete,
});

export const accountSendTwoFactorAuthCode = (phone, action_name) => async (dispatch) => {
  dispatch({
    type: ACCOUNT_SEND_TWO_FACTOR_AUTH_CODE.REQUEST,
  });

  try {
    await xhr.post(TWO_FACTOR_AUTH_CODE, { phone, action_name: action_name });

    dispatch({
      type: ACCOUNT_SEND_TWO_FACTOR_AUTH_CODE.SUCCESS,
      data: phone,
    });
  } catch (err) {
    dispatch({
      type: ACCOUNT_SEND_TWO_FACTOR_AUTH_CODE.FAILURE,
      data: err.response.data.message,
    });
  }
};

export const accountVerifyTwoFactorAuthCode = (code, enrolled, action_name) => async (dispatch) => {
  dispatch({
    type: ACCOUNT_VERIFY_TWO_FACTOR_AUTH_CODE.REQUEST,
  });

  try {
    await xhr.post(TWO_FACTOR_AUTH, { code, action_name: action_name });

    dispatch({
      type: ACCOUNT_VERIFY_TWO_FACTOR_AUTH_CODE.SUCCESS,
    });

    if (enrolled) {
      dispatch(accountCloseModal());
    }
    
  } catch (err) {
    dispatch({
      type: ACCOUNT_VERIFY_TWO_FACTOR_AUTH_CODE.FAILURE,
      data: err.response.data.message,
    });
  }
};

export const accountTwoFactorSuccessCta = (action_name) => async (dispatch) => {
  try {
    const response = await xhr.get(TWO_FACTOR_AUTH, { action_name: action_name });

    dispatch({
      type: ACCOUNT_SECURITY_SUCCESSFUL_SETUP,
      data: {
        enrolled: response.camelCasedData.enrolled,
        phone: response.camelCasedData.phone,
      },
    });
  } catch (err) {
    dispatch(createApiErrorAlert(err));
  }
};

export const retrieveTwoFactorAuth = (action_name) => async (dispatch) => {
  dispatch({
    type: GET_USER_TWO_FACTOR_AUTH.REQUEST,
  });

  try {
    const response = await xhr.get(TWO_FACTOR_AUTH, { action_name: action_name });

    dispatch({
      type: GET_USER_TWO_FACTOR_AUTH.SUCCESS,
      data: response.camelCasedData,
    });
  } catch (err) {
    dispatch(createApiErrorAlert(err));
  }
};

export const accountDeactivateTwoFactorAuth = (action_name) => async (dispatch) => {
  dispatch({
    type: DELETE_USER_TWO_FACTOR_AUTH.REQUEST,
  });

  try {
    await xhr.delete(TWO_FACTOR_AUTH, { action_name: action_name });

    dispatch({
      type: DELETE_USER_TWO_FACTOR_AUTH.SUCCESS,
    });
  } catch (err) {
    dispatch({
      type: DELETE_USER_TWO_FACTOR_AUTH.FAILURE,
      data: err.response.data.message,
    });
  }
};
export const accountHandleBack = (actionName) => (dispatch) => {
  // Needed to do this back function uniquely or the 
  // modal will reopen randomly on AccountSecurity Page
  const protectedScreenPaths = {
    PAYMENT: '/billing-info',
    PROFILE: '/profile',
    PASSWORD: '/password'
  }
  dispatch(accountCloseModal());
  dispatch(push(protectedScreenPaths[actionName]));
};


export const accountCloseModal = () => ({
  type: ACCOUNT_SECURITY_UPDATE_TWO_FACTOR_MODAL_STEP,
  data: null,
});

export const accountResendTwoFactorCode = (action_name) => async (dispatch) => {
  dispatch({
    type: ACCOUNT_RESEND_TWO_FACTOR_AUTH_CODE.REQUEST,
  });

  try {
    await xhr.put(TWO_FACTOR_AUTH_CODE, { action_name: action_name });

    dispatch({
      type: ACCOUNT_RESEND_TWO_FACTOR_AUTH_CODE.SUCCESS,
      data: true,
    });

    setTimeout(() => {
      dispatch({
        type: ACCOUNT_RESEND_TWO_FACTOR_AUTH_CODE.SUCCESS,
        data: false,
      });
    }, 60000);
  } catch (err) {
    dispatch({
      type: ACCOUNT_RESEND_TWO_FACTOR_AUTH_CODE.FAILURE,
      data: err.response.data.message,
    });
  }
};

export const accountUpdateTwoFactorAuthModalStep = (data) => ({
  type: ACCOUNT_SECURITY_UPDATE_TWO_FACTOR_MODAL_STEP,
  data,
});