import xhr, { createApiErrorAlert, errorFromStripe } from "util/xhr";
import { notifyBugsnag } from "util/bugsnag";
import { notifyNextApp } from "alerts/redux/modules/alerts";
import { RABBIT_HIRE } from "build/routes/apiRoutes";
import { updateJobDraftStatus } from "dashboard/redux/modules/jobDraftList";
import { CREATE_STRIPE_PAYMENT_METHOD_URL } from "util/constants";
import fbm from "build/redux/modules/metrics";
import storage from "util/localStorage";
import { internalPath } from "util/internalPath";
import { isFirstTimeUser } from "../../../shared/utils/userService";
import segmentAnalyticsService from "services/SegmentAnalyticsService";
import { JOB_BOOKED } from "enums/segmentEventNames";
import { DateTime } from "luxon";
import { featureFlags } from "enums/featureFlags";
import { fetchEnabledFeatures } from "../../util/getEnabledFeatures";

export const fireBuildMetric = fbm;

const initialState = { activeStep: "" };

const STORAGE_KEY_ATTRIBUTE = "tr-post-data";

export const PERFORM_HIRE = "PERFORM_HIRE";
export const PERFORM_HIRE_ERROR = "PERFORM_HIRE_ERROR";
const TOGGLE_FORM_STATE = "TOGGLE_FORM_STATE";
const SET_ACTIVE_STEP = "SET_ACTIVE_STEP";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/default-param-last
export default function confirmation(state = initialState, action) {
  switch (action.type) {
    case PERFORM_HIRE:
      return { ...state, hireInProgress: true };
    case PERFORM_HIRE_ERROR:
      return { ...state, hireInProgress: false };
    case TOGGLE_FORM_STATE:
      return {
        ...state,
        hireInProgress: !state.hireInProgress,
      };
    case SET_ACTIVE_STEP:
      return {
        ...state,
        activeStep: action.step,
      };
    default:
      return state;
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const toggleFormState = () => ({
  type: TOGGLE_FORM_STATE,
});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const setActiveStep = (step) => ({
  type: SET_ACTIVE_STEP,
  step,
});

const segmentTrackJobBooked = async ({ state, jobId, isClientsFirstJob }) => {
  const {
    build: {
      manager: {
        job,
        job: { invitee, location },
      },
      recommendations,
    },
    promoCode,
    tasker: {
      taskerProfile: { profiles },
    },
  } = state;

  const [tasker] = profiles;

  // recommendations.recommendations is populated only if
  // /recommendations page was visited in the current session
  const recommendationIndex = recommendations.recommendations.findIndex(
    (recommendation) => recommendation.userId === invitee.id
  );
  const recommendation = recommendations.recommendations[recommendationIndex];

  const reviewCount =
    tasker.categories.find(
      (category) => category.categoryId === job.category_id
    )?.reviewCount || 0;

  const segmentPayload = {
    // job_length: null,
    // tasker_suggested_hourly_rate: null,
    // tasker_minimum_hours_required: null,
    is_clients_first_job: isClientsFirstJob,
    funnel_id: job.funnel_id ?? "",
    job_id: jobId,
    job_category: job.category_name,
    job_category_id: job.category_id,
    job_zipcode: location.postal_code,
    metro_id: location.metro_id || invitee?.metro?.id,
    metro: location.metro_name || invitee?.metro?.name,
    recommendation_id: job.recommendation_id,
    job_details: job.description,
    job_draft_id: job.funnel_id ?? "",
    job_invitation_id: job.job_invitation_id ?? "",
    tasker_name: invitee.display_name,
    tasker_id: invitee.id,
    tasker_position_in_recommendation:
      recommendationIndex > -1 ? recommendationIndex + 1 : undefined,
    tasker_rating: Number(
      tasker?.attributes?.reviewStarRatingDisplay.stat
    ) || null,
    tasker_is_elite: recommendation?.elite || tasker?.attributes.elite,
    tasker_is_great_value: recommendation?.showValueBadge || false,
    tasker_review_count:
      recommendation?.categoryFamilyReviewCount || reviewCount,
    tasker_total_invoice_count_in_selected_category:
      recommendation?.categoryInvoicesCount ?? 0,
    tasker_total_invoice_count_in_category_family:
      recommendation?.categoryFamilyInvoicesCount ?? 0,
    tasker_hourly_rate: invitee.poster_hourly_rate_cents / 100,
    currency: invitee.poster_hourly_rate_currency,
    job_requested_at: DateTime.fromISO(job.schedule.date)
      .plus({ seconds: job.schedule.offset_seconds })
      .toISO(),
    job_promo_code: promoCode.promotion?.code,
    job_trust_and_support_fees: (invitee.trust_and_safety_fee_cents ?? 0) / 100,
    booking_flow_type: state.build.repost.jobRepostData?.booking_flow_type,
    booking_method:
      job.source === "tasker_replacement"
        ? "Rescue - Recommended Tasker Selected"
        : job.booking_method,
  };

  await segmentAnalyticsService.trackEvent({
    name: JOB_BOOKED,
    properties: segmentPayload,
  });
};

const performHire = () => async (dispatch, getState) => {
  const state = getState();

  // bail if hire is already in progress
  const { hireInProgress } = state.build.confirmation;
  if (hireInProgress) {
    return;
  }

  // omit unwanted fields from request payload, just send `data`
  const {
    job: { invitee, location, guid, job_draft_guid: jobDraftGuid, ...data },
    bootstrap: { ab_decision: abDecision },
  } = state.build.manager;
  const ikeaGMJob = data.category_id === 1107;
  delete data.taskTemplate;
  delete data.uuid;
  delete data.recommendationInterests;

  const {
    category_id: categoryId,
    invitation_source: invitationSource,
    source,
    invitee_id: inviteeId,
  } = state.build.manager.job;
  const { recommendation_id: recommendationId } = state.build.recommendations;
  const { values } = state.form.confirmation;
  const phoneData = {};

  phoneData.phone_number = values.phone_number;
  phoneData.phone_country_code = values.phone_country_code;
  phoneData.mobile_phone = values.phone_country_code + values.phone_number;

  const { checkout_agreement: checkoutAgreement } =
    state.form.confirmation.values;

  const determineSource = () => {
    if (source) return source;
    if (invitationSource) return invitationSource;
    if (inviteeId === "broadcast") return "broadcast";
    if (recommendationId) return "recommendation";
    return "";
  };

  // Check if category should have a fixed rate instead of the tasker's hourly rate.
  // Feature flag eligibility depends on user's metro and category_id.
  const enabledFeatures = await fetchEnabledFeatures({
    categoryId,
    metroId: location.metro_id
  });

  const { referring_job_id: referringJobId } = state.build.manager.job;
  const { autoMatchDeclined } = state.ikea.manager;
  const payload = {
    ...data,
    ...phoneData,
    job_draft_guid: guid || jobDraftGuid,
    checkout_agreement: checkoutAgreement,
    address: location,
    rabbit_id: inviteeId,
    poster_hourly_rate_cents: invitee.poster_hourly_rate_cents,
    source: determineSource(),
    charge_trust_and_safety_fee: true,
    trust_and_safety_fee_points: invitee.trust_and_safety_fee_points,
    shown_cancellation_policy: true,
    referring_job_id: referringJobId,
    auto_match_declined: autoMatchDeclined,
    fixed_rate: enabledFeatures?.includes(featureFlags.bookingAutoInviteLite)
  };

  // add validated promo codes
  const valid = state.promoCode?.valid;
  const promotion = state.promoCode?.promotion;

  if (promotion?.code && valid) payload.promotion_code = promotion?.code;

  const options = {
    headers: {
      HTTP_X_AB_DECISION_GROUP_ID: abDecision,
    },
  };

  dispatch({ type: PERFORM_HIRE });

  const {
    vehicle_requirement: vehicleRequirement,
    tr4g_opted_in: tr4gOptedIn,
    donation_amount: donationAmount,
    donation_type: donationType,
    job_add_ons: jobAddOns,
  } = payload;

  // Trying to troubleshoot PLAT-5033
  const zeroParam = Object.entries(payload).find((item) => {
    return (
      (item[0] === "email" || item[0] === "ikea_products") && item[1] === 0
    );
  });

  if (zeroParam) notifyBugsnag("Tasker Hire Payload", payload);

  const hireData = await xhr.post(RABBIT_HIRE, payload, options);

  const {
    camelCasedData: { jobId },
    data: { metrics }
  } = hireData;

  const isClientsFirstJob = metrics?.is_clients_first_job ?? false

  await segmentTrackJobBooked({ state, jobId, isClientsFirstJob });

  const metricsPayload = {
    donation_amount: donationAmount,
    donation_type: donationType,
    job_add_ons: jobAddOns,
    job_id: jobId,
    promo_code: promotion?.code,
    source: payload.source,
    tr4g_opted_in: tr4gOptedIn,
    vehicle_requirement: vehicleRequirement,
  };
  const msgKey = inviteeId === "broadcast" ? "broadcast_hired" : "tasker_hired";
  const msgId = `build.confirm.alert.${msgKey}`;
  notifyNextApp({ messages: [msgId] });
  if (ikeaGMJob) {
    dispatch(fireBuildMetric("job_posted_1107", metricsPayload));
    metricsPayload.skip = ["gtm"];
  }
  dispatch(fireBuildMetric("job_posted", metricsPayload));
  storage.set(STORAGE_KEY_ATTRIBUTE, {});

  const firstTimeUser = await isFirstTimeUser();
  window.location.href = internalPath(
    `/dashboard/active?app_source_page=confirm_and_book&returning=${!firstTimeUser}`
  );
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const confirmTask = (paymentMethodCallback) => {
  return async (dispatch, getState) => {
    // Manually toggle state here ensure the form is disabled
    dispatch(toggleFormState());
    dispatch(fireBuildMetric("confirm_and_chat_button_clicked"));

    const state = getState();
    const {
      manager: { job },
    } = state.build;
    const { cardEditing } = state.form.confirmation.values;

    try {
      if (cardEditing === true) {
        if (typeof paymentMethodCallback !== "function") {
          throw new Error(PERFORM_HIRE_ERROR);
        }
        const paymentMethodResult = await paymentMethodCallback();
        if (paymentMethodResult) {
          if (paymentMethodResult.error) {
            throw paymentMethodResult.error;
          }
        } else {
          dispatch(toggleFormState());
          return;
        }

        await xhr.post(CREATE_STRIPE_PAYMENT_METHOD_URL, {
          payment_method_id: paymentMethodResult.setupIntent.payment_method,
          payment_method_type:
            paymentMethodResult.setupIntent.payment_method_types[0],
        });
      }

      if (job.job_draft_guid) {
        const status = "booked";
        await updateJobDraftStatus(job.job_draft_guid, {
          status,
          funnelId: job.funnel_id,
        });
      }

      // Set form state back so that performHire won't bail early
      dispatch(toggleFormState());
      await dispatch(performHire());
    } catch (e) {
      dispatch(createApiErrorAlert(errorFromStripe(e)));
      dispatch({ type: PERFORM_HIRE_ERROR });
    }
  };
};
