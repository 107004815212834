import { createSelector } from "@reduxjs/toolkit";

import {
  IkeaProduct,
  LegacyProductSection,
  ProductSection,
  ProductSectionGroup,
} from "ikea/types";
import { selectJobLocation } from "shared/redux/selector";
import { getBuildJob, isIkeaJobFlow } from "build/redux/modules/selector";
import { format } from "util/money";
import { calculateAssemblyPrice } from "util/ikea";
import { V3ReduxState } from "util/reduxTypes";
import { IS_PHASE_0_1107 } from "util/constants";
// TODO: Move to task template definition
interface Field {
  field_type: string;
}

interface IkeaField extends Field {
  ikea_gm_products_extra: [
    { sections: ProductSection[]; section_groups: ProductSectionGroup[][] }
  ];
}

const getJobProducts = (state: V3ReduxState): string[] =>
  getBuildJob(state).ikea_products;

const getTaskTemplateFields = (state: V3ReduxState): Field[] | undefined =>
  getBuildJob(state).taskTemplate?.fields;

const getIkeaItems = (state: V3ReduxState): IkeaProduct[] =>
  state.ikea.manager.items;

export const getIkeaCategoryId = (state: V3ReduxState): number | undefined =>
  state.ikea.manager?.job_draft?.category_id;

export const getActiveSection = (state: V3ReduxState): string =>
  state.ikea.manager.activeSection;

const getLegacySections = (state: V3ReduxState): LegacyProductSection[] =>
  state.ikea.manager.sections;

export const getRoutingPathname = (state: V3ReduxState): string =>
  state.router.location.pathname;

export const getFunnelId = (state: V3ReduxState): string | undefined =>
  state.ikea.manager?.funnel_id;

export const getJobSize = (state: V3ReduxState): string =>
  state.ikea.manager?.job_draft?.job_size || "small";

const SMALL_JOB_TIME = 3600;
const MEDIUM_JOB_TIME = 7200;
const LARGE_JOB_TIME = 14400;

export const selectIkeaJobDraftGuid = (
  state: V3ReduxState
): string | undefined =>
  state.ikea.manager?.job_draft?.guid || state.ikea.manager?.job_draft_guid;

const isInUSQuote = (state: V3ReduxState) => {
  const pathname = getRoutingPathname(state);
  return pathname.includes("/quote") && IS_PHASE_0_1107;
};

const getTaskTemplateProducts = createSelector(
  getTaskTemplateFields,
  (steps) => {
    if (!steps) {
      return null;
    }

    const productsStep = steps.find(
      (s): s is IkeaField => s.field_type === "ikea_gm_products"
    );

    if (!productsStep) {
      return null;
    }

    const {
      // Get first array item, seems weird
      ikea_gm_products_extra: [{ sections, section_groups: sectionGroups }],
    } = productsStep;

    return { sections, sectionGroups };
  }
);

const getAllProducts = createSelector(
  getTaskTemplateProducts,
  getLegacySections,
  (products, legacySections): IkeaProduct[] => {
    if (products) {
      return products.sectionGroups.reduce(
        (acc: IkeaProduct[], sectionGroup) => {
          sectionGroup.forEach((section) =>
            section.rows.forEach((row) =>
              row.products.forEach((product) => acc.push(product))
            )
          );

          return acc;
        },
        []
      );
    }

    return legacySections.reduce((acc: IkeaProduct[], section) => {
      section.section_groups.forEach((sectionGroup) => {
        sectionGroup.rows.forEach((row) =>
          row.products.forEach((product) => acc.push(product))
        );
      });

      return acc;
    }, []);
  }
);

export const getIkeaItemsFromJob = createSelector(
  getJobProducts,
  getAllProducts,
  (productIds, products) => {
    if (!productIds?.length) {
      return [];
    }

    const productMap = productIds.reduce((map: Record<string, number>, id) => {
      if (!map[id]) {
        map[id] = 1;
      } else {
        map[id] += 1;
      }

      return map;
    }, {});

    return products.reduce((acc: IkeaProduct[], product) => {
      const quantity = productMap[product.ikea_id];

      if (quantity) {
        acc.push({
          ...product,
          quantity,
        });
      }

      return acc;
    }, []);
  }
);

export const selectItems = getIkeaItems;

export const selectSerializedItems = createSelector(selectItems, (items) => {
  const descriptions: string[] = [];
  const ikeaProducts: string[] = [];

  items.forEach(
    ({ ikea_id: ikeaId, product_name: productName, quantity = 1 }) => {
      descriptions.push(`${quantity}x ${productName}`);
      ikeaProducts.push(...Array(quantity).fill(ikeaId));
    }
  );

  return {
    assembly_item_type: "only_ikea_items",
    description: descriptions.join("\n"),
    ikea_products: ikeaProducts,
  };
});

export const selectAssemblySeconds = createSelector(selectItems, (items) =>
  items.reduce(
    (acc, { assembly_seconds: assemblySeconds, quantity }) =>
      acc + assemblySeconds * quantity,
    0
  )
);

export const selectJobEstimateSeconds = (state: V3ReduxState) => {
  const assemblySeconds = selectAssemblySeconds(state);
  let jobEstimateSeconds;
  if (assemblySeconds === 0) {
    const job_size = getJobSize(state);
    if (job_size === "large") {
      jobEstimateSeconds = LARGE_JOB_TIME;
    } else if (job_size === "medium") {
      jobEstimateSeconds = MEDIUM_JOB_TIME;
    } else {
      jobEstimateSeconds = SMALL_JOB_TIME;
    }
    return jobEstimateSeconds;
  } else {
    return assemblySeconds;
  }
};

const isUSQuoteFlow = (state: V3ReduxState): boolean => {
  return isInUSQuote(state) || isIkeaJobFlow(state);
};

export const selectLocationHourlyRate = (state: V3ReduxState) => {
  const location = selectJobLocation(state);
  if (!location) {
    return 0;
  }

  const {
    assembly_poster_hourly_rate_cents: hourlyRate,
    assembly_poster_avg_hourly_rate_cents: avgHourlyRate,
  } = location;

  return isUSQuoteFlow(state) ? avgHourlyRate : hourlyRate;
};

export const selectLocationMinimumPrice = (state: V3ReduxState) => {
  const location = selectJobLocation(state);
  if (!location) {
    return 0.0;
  }

  const {
    assembly_minimum_price_cents: assemblyMinPrice,
    assembly_poster_avg_hourly_rate_cents: assemblyAvgPrice,
  } = location;

  const minimumPrice = isUSQuoteFlow(state)
    ? assemblyAvgPrice
    : assemblyMinPrice;
  return ((minimumPrice ?? 0) / 100).toFixed(2);
};

export const selectLocationMinimumPriceFormatted = (state: V3ReduxState) => {
  const minimumPrice = selectLocationMinimumPrice(state);

  return format(minimumPrice, {
    noCentsIfWhole: true,
  });
};

function selectAssemblyPrice(state: V3ReduxState) {
  const items = selectItems(state);
  const locationHourlyRate = selectLocationHourlyRate(state);

  return calculateAssemblyPrice(items, locationHourlyRate);
}

export const selectPriceInfo = (state: V3ReduxState) => {
  const assemblyPrice = selectAssemblyPrice(state);
  const minimumPrice = selectLocationMinimumPrice(state);

  const totalCost = assemblyPrice > 0 ? Math.max(assemblyPrice, Number(minimumPrice)) : 0;

  return {
    totalCost,
    price: format(totalCost, {
      noCentsIfWhole: true,
    }),
  };
};

export const selectHourlyRateFieldName = (state: V3ReduxState) => {
  return isUSQuoteFlow(state)
    ? "assembly_poster_avg_hourly_rate_cents"
    : "assembly_poster_hourly_rate_cents";
};

export const selectMinimumPriceInfo = (state: V3ReduxState) => {
  const assemblyPrice = selectAssemblyPrice(state);
  const minimumPrice = selectLocationMinimumPrice(state);

  return {
    minimumPrice: format(minimumPrice, { noCentsIfWhole: true, rounded: true }),
    showMin: assemblyPrice < minimumPrice,
  };
};

const getTaskTemplateSectionGroup = createSelector(
  getTaskTemplateProducts,
  getActiveSection,
  (products, activeSection) => {
    if (!products) {
      return null;
    }

    const activeSectionIndex = products.sections.findIndex(
      (s) => s.key === activeSection
    );

    return products.sectionGroups[activeSectionIndex];
  }
);

const getLegacySectionGroup = createSelector(
  getLegacySections,
  getActiveSection,
  (sections, activeSection) =>
    sections.find((s) => s.key === activeSection)!.section_groups
);

export const getSections = createSelector(
  getTaskTemplateProducts,
  getLegacySections,
  (products, legacySections) => products?.sections ?? legacySections
);

export const getSectionGroup = createSelector(
  getTaskTemplateSectionGroup,
  getLegacySectionGroup,
  (taskTemplateSectionGroup, legacySectionGroup) =>
    taskTemplateSectionGroup ?? legacySectionGroup
);

export const isCanadianSalesTaxEnabled = (state: V3ReduxState) =>
  !!state.ikea.manager.location?.canadian_sales_tax_enabled;
