import xhr, { createApiErrorAlert } from "util/xhr";
import { createAlert } from "alerts/redux/modules/alerts";
import qs from "qs";
import { Observable } from "rxjs";
import { combineEpics } from "redux-observable";
import { REFERRAL_EMAIL } from "referral/routes/apiRoutes";
import { fireMetric } from "store/middleware/metricMiddleware";

const SEND_REFERRAL = "SEND_REFERRAL";
const SEND_REFERRAL_RESPONSE = "SEND_REFERRAL_RESPONSE";
const SEND_REFERRAL_ERROR = "SEND_REFERRAL_ERROR";
const FIRE_REFERRAL_METRIC = "FIRE_REFERRAL_METRIC";

export default function referralReducer(state = {}, action) {
  switch (action.type) {
    case SEND_REFERRAL:
      return { ...state, loading: true, error: false };
    case SEND_REFERRAL_RESPONSE:
      return { ...state, loading: false };
    case FIRE_REFERRAL_METRIC:
      return { ...state, loading: false, error: false };
    case SEND_REFERRAL_ERROR:
      return { ...state, loading: false, error: true };
    default:
      return state;
  }
}

export const sendBulkReferral = friend_email => {
  const payload = {
    friend_email,
    bulk_email: true,
    source: "referral_page",
  };

  return {
    type: SEND_REFERRAL,
    payload,
  };
};

export const sendReferral = payload => ({
  type: SEND_REFERRAL,
  payload,
});

export const sendReferralApi = payload => {
  const dataUrl = `${REFERRAL_EMAIL}?${qs.stringify(payload)}`;
  return xhr.post(dataUrl);
};

const sendReferralEpic = (action$, { getState }) =>
  action$.ofType(SEND_REFERRAL).switchMap(data =>
    Observable.defer(sendReferralApi.bind(null, data.payload))
      .flatMap(response => {
        return Observable.from([
          fireMetric("on_demand_referral_sent", {
            type: "email",
            to: data.payload.friend_email,
          }),
          createAlert({ messages: [response.data.message], skipI18n: true }),
          { type: SEND_REFERRAL_RESPONSE },
        ]);
      })
      .catch(error => {
        return Observable.of(createApiErrorAlert(error), {
          type: SEND_REFERRAL_ERROR,
          response: error.response,
        });
      })
  );

export const referralPageMetric = (key, data) => dispatch => {
  dispatch(fireMetric(key, data));

  return {
    type: FIRE_REFERRAL_METRIC,
  };
};

export const referralEpic = combineEpics(sendReferralEpic);
