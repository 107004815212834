import { CANCEL_TASK_V2, CANCEL_JOB_BUNDLE } from "dashboard/routes/apiRoutes";
import { DIMENSION_ENDPOINT } from "feedback/routes/apiRoutes";
import xhr, { createApiErrorAlert } from "util/xhr";
import { isIkeaLegacyCategory } from "ikea";
import { createAlert } from "alerts/redux/modules/alerts";
import { defineMessages } from "react-intl";
import {
  removeTaskFromActive,
  removeJobBundleFromActive,
  loadTaskList,
} from "dashboard/redux/modules/taskLists";
import { fireMetric } from "store/middleware/metricMiddleware";
import qs from "qs";
import find from "lodash/find";
import moment from "util/momentWithTZ";

import { snake_caseKeys } from "util/casing";
import { internalPath } from "util/internalPath";
import { cancellationState } from "enums/cancellationState";
import { REBOOK_FLOW } from "enums/bookingFlowTypes";
import {
  repostIkeaTaskRequest,
  repostIkeaTaskResponse,
} from "./repostIkeaTask";
import segmentAnalyticsService from "services/SegmentAnalyticsService";
import {
  CLIENT_CANCELLED_JOB,
  CLIENT_RESCHEDULED_JOB,
} from "enums/segmentEventNames";

const messages = defineMessages({
  cancellation_failure: {
    id: "dashboard.cancellation.notifications.failure",
    defaultMessage: "We were unable to cancel your task, please try again.",
  },
  ikea_repost_succeess: {
    id: "dashboard.cancellation.ikea_repost.success",
    defaultMessage: "Your task has been rescheduled!",
  },
  ikea_repost_failure: {
    id: "dashboard.cancellation.ikea_repost.failure",
    defaultMessage: "We were unable to rebook your task, please try again.",
  },
  remove_failure: {
    id: "dashboard.remove.notifications.failure",
    defaultMessage: "We were unable to remove your task, please try again.",
  },
  remove_success: {
    id: "dashboard.remove.notifications.success",
    defaultMessage: "Your task has been successfully removed.",
  },
});

const INITIATE_CANCELLATION = "INITIATE_CANCELLATION";
const SELECT_CANCELLATION_REASON = "SELECT_CANCELLATION_REASON";
const SET_REPOST_SCHEDULE = "SET_REPOST_SCHEDULE";
const UPDATE_OTHER_EXPLANATION = "UPDATE_OTHER_EXPLANATION";
const PROCEED_CANCELLATION = "PROCEED_CANCELLATION";
const PROCEED_REPOST = "PROCEED_REPOST";
const CANCEL_TASK_REQUEST = "CANCEL_TASK_REQUEST";
const CANCEL_TASK_RESPONSE = "CANCEL_TASK_RESPONSE";
const CANCEL_TASK_ERROR = "CANCEL_TASK_ERROR";
const REPOST_TASK_REQUEST = "REPOST_TASK_REQUEST";
const REPOST_TASK_RESPONSE = "REPOST_TASK_RESPONSE";
const REPOST_TASK_ERROR = "REPOST_TASK_ERROR";
const REMOVE_ITEM_AFTER_CANCEL = "REMOVE_ITEM_AFTER_CANCEL";

const initialState = {
  reason: "",
  other_explanation: "",
  viewState: undefined,
  job: {
    id: undefined,
  },
  client_responsible: null,
  apply_cancellation_fee: null,
  repostFlow: false,
  repostSchedule: null,
};

export default function cancelTask(state = initialState, action = {}) {
  switch (action.type) {
    case INITIATE_CANCELLATION: {
      const viewState = showFeedbackQuestions(action)
        ? cancellationState.selectReason
        : cancellationState.askRepost;

      // TODO snake_caseKeys can be removed once snake_caseKeys are cleaned up
      return {
        ...state,
        job: snake_caseKeys(action.job),
        viewState,
        cancel_dimensions: snake_caseKeys(action.cancel_dimensions),
        reason: "",
        other_explanation: "",
        jobBundle: action.jobBundle,
        tasks: action.tasks,
      };
    }
    case SELECT_CANCELLATION_REASON: {
      const reasonInfo = find(state.cancel_dimensions.items, {
        key: action.reason,
      });
      const { client_responsible, apply_cancellation_fee } = reasonInfo;
      return {
        ...state,
        reason: action.reason,
        client_responsible,
        apply_cancellation_fee,
      };
    }
    case SET_REPOST_SCHEDULE:
      return {
        ...state,
        repostFlow: true,
        repostSchedule: action.schedule,
      };
    case UPDATE_OTHER_EXPLANATION:
      return {
        ...state,
        other_explanation: action.other_explanation,
      };
    case PROCEED_CANCELLATION:
      return { ...state, viewState: action.viewState };
    case PROCEED_REPOST:
      return {
        ...state,
        viewState: action.viewState,
        repostFlow: true,
      };
    case CANCEL_TASK_REQUEST:
      return { ...state, viewState: action.viewState };
    case CANCEL_TASK_RESPONSE:
      return { ...state, viewState: cancellationState.complete };
    case REPOST_TASK_REQUEST:
      return { ...state, viewState: cancellationState.inProgress };
    case REPOST_TASK_RESPONSE:
      return { ...state, viewState: cancellationState.complete };
    case REMOVE_ITEM_AFTER_CANCEL: {
      return { ...state, viewState: cancellationState.refreshList };
    }
    default:
      return state;
  }
}

function showFeedbackQuestions({ cancel_dimensions }) {
  return (
    cancel_dimensions &&
    cancel_dimensions.items &&
    cancel_dimensions.items.length > 0
  );
}

export function initiateCancellation({
  details,
  cancelDimensions,
  jobBundle,
  tasks,
}) {
  return {
    type: INITIATE_CANCELLATION,
    job: details,
    cancel_dimensions: cancelDimensions,
    jobBundle,
    tasks,
  };
}

export function selectReason({ reason }) {
  return {
    type: SELECT_CANCELLATION_REASON,
    reason,
  };
}

export function setRepostSchedule({ schedule }) {
  return {
    type: SET_REPOST_SCHEDULE,
    schedule,
  };
}

export function updateOtherExplanation({ other_explanation }) {
  return {
    type: UPDATE_OTHER_EXPLANATION,
    other_explanation,
  };
}

export function proceed(isRepost = false) {
  return (dispatch, getState) => {
    const state = getState();
    const {
      viewState,
      // eslint-disable-next-line camelcase
      apply_cancellation_fee,
      job,
    } = state.dashboard.cancelTask;
    switch (viewState) {
      case cancellationState.askRepost: {
        dispatch(fireMetric("cancel_repost_declined", { job_id: job.id }));
        // eslint-disable-next-line camelcase
        return apply_cancellation_fee
          ? dispatch(proceedCancellation("confirm"))
          : dispatch(performCancelTask());
      }
      case cancellationState.selectReason: {
        if (isRepost === true) {
          dispatch(proceedToRepost());
          return null;
        }
        dispatch(submitCancelFeedback());
        return dispatch(proceedCancellation(cancellationState.askRepost));
      }
      case cancellationState.confirm: {
        return dispatch(performCancelTask());
      }
      default:
        return null;
    }
  };
}

export function proceedToRepost() {
  return (dispatch, getState) => {
    const state = getState();
    const { viewState, job } = state.dashboard.cancelTask;

    switch (viewState) {
      case cancellationState.gatherIkeaRepostParams:
        return dispatch(performRescheduleJob());
      case cancellationState.askRepost:
      case cancellationState.selectReason:
        if (isIkeaLegacyCategory(job.category_id)) {
          return dispatch(
            changeToRepostState(cancellationState.gatherIkeaRepostParams)
          );
        }
        return dispatch(performNonIkeaRepost());

      default:
        return null;
    }
  };
}

// handles selection of cancel reason, and showing whether fee applies
function proceedCancellation(viewState) {
  return {
    type: PROCEED_CANCELLATION,
    viewState,
  };
}

function changeToRepostState(viewState) {
  return {
    type: PROCEED_REPOST,
    viewState,
  };
}

function cancelTaskRequest(viewState) {
  return {
    type: CANCEL_TASK_REQUEST,
    viewState,
  };
}

function cancelTaskResponse(response) {
  return {
    type: CANCEL_TASK_RESPONSE,
    response,
  };
}

function cancelTaskError() {
  return { type: CANCEL_TASK_ERROR };
}

function repostTaskRequest() {
  return {
    type: REPOST_TASK_REQUEST,
  };
}

function repostTaskResponse() {
  return {
    type: REPOST_TASK_RESPONSE,
  };
}

function repostTaskError() {
  return {
    type: REPOST_TASK_ERROR,
  };
}

export const removeItemAfterCancel = () => {
  return { type: REMOVE_ITEM_AFTER_CANCEL };
};

function submitCancelFeedback() {
  return (dispatch, getState) => {
    const source = "poster_cancel";
    const {
      reason,
      job: { id, rabbit_id },
      other_explanation,
    } = getState().dashboard.cancelTask;

    const data = {
      job_id: id,
      rabbit_id,
      source,
      dimension_key: source,
      value_key: reason,
      other: other_explanation,
    };

    return xhr.post(DIMENSION_ENDPOINT, data);
  };
}

const segmentTrackClientCancelledJob = ({
  dashboard: {
    cancelTask: {
      reason,
      job,
      tasks: [
        {
          invitee,
          appointments: {
            items: [appointment],
          },
        },
      ],
    },
  },
  account: {
    profile: { data: profile },
  },
}) => {
  
  segmentAnalyticsService.trackEvent({
    name: CLIENT_CANCELLED_JOB,
    properties: {
      currency: job.currency_code,
      job_cancellation_reason: reason,
      job_category: job.title,
      job_category_id: job.category_id,
      job_details: job.description,
      job_id: job.id,
      job_invitation_id: job.invitation_id,
      job_requested_at: appointment.startDateTime,
      job_trust_and_support_fees: job.invoice_trust_and_support_fees,
      job_zipcode: job.zipcode,
      metro: profile.metro_name,
      metro_id: profile.metro_id,
      tasker_hourly_rate: invitee.posterHourlyRateCents,
      tasker_id: job.rabbit_id,
      tasker_name: invitee.displayName,
      marketplace: job.category_id === 1104 ? "ikea" : "client",
    },
  });
};

const segmentTrackClientRescheduledJob = ({
  dashboard: {
    cancelTask: {
      reason,
      job,
      tasks: [
        {
          invitee,
          appointments: {
            items: [appointment],
          },
        },
      ],
    },
  },
  account: {
    profile: { data: profile },
  },
}) => {
  segmentAnalyticsService.trackEvent({
    name: CLIENT_RESCHEDULED_JOB,
    properties: {
      job_reschedule_reason: reason,
      job_category: job.title,
      job_category_id: job.category_id,
      job_details: job.description,
      job_id: job.id,
      job_invitation_id: job.invitation_id,
      job_requested_at: appointment.startDateTime,
      job_zipcode: job.zipcode,
      marketplace: job.category_id === 1104 ? "Ikea" : "client",
      metro: profile.metro_name,
      metro_id: profile.metro_id,
      tasker_hourly_rate: invitee.formattedPosterHourlyRate,
      tasker_id: job.rabbit_id,
      tasker_name: invitee.displayName,
    },
  });
};

function performCancelTask(suppressMessages) {
  return async (dispatch, getState) => {
    const viewState = suppressMessages ? "dismiss" : "inProgress";
    const contextState = getState().dashboard.cancelTask;

    dispatch(cancelTaskRequest(viewState));
    try {
      const response = await cancelTaskApi(contextState);
      segmentTrackClientCancelledJob(getState());
      dispatch(cancelTaskResponse(response.data));
    } catch (e) {
      dispatch(createApiErrorAlert(e));
      dispatch(createAlert(messages.cancellation_failure.id));
      dispatch(cancelTaskError());
    }
  };
}
export const updateTasksAfterCancel = () => {
  return (dispatch, getState) => {
    const { jobBundle, tasks } = getState().dashboard.cancelTask;
    if (jobBundle) {
      dispatch(removeJobBundleFromActive(jobBundle));
    } else {
      dispatch(removeTaskFromActive({ id: tasks[0]?.details?.id }));
    }
  };
};

function performRescheduleJob() {
  return (dispatch, getState) => {
    const contextState = getState().dashboard.cancelTask;
    const {
      job: { id },
    } = contextState;

    dispatch(repostTaskRequest());
    dispatch(repostIkeaTaskRequest({ job_id: id }));
    return rescheduleJobApi(contextState)
      .then((response) => {
        segmentTrackClientRescheduledJob(getState());
        dispatch(repostIkeaTaskResponse(response.data));
        dispatch(createAlert(messages.ikea_repost_succeess.id));
        dispatch(repostTaskResponse());
      })
      .catch((e) => {
        dispatch(createApiErrorAlert(e));
        dispatch(createAlert(messages.ikea_repost_failure.id));
        dispatch(repostTaskError());
      });
  };
}

function performNonIkeaRepost() {
  return (dispatch, getState) => {
    const state = getState();
    // eslint-disable-next-line camelcase
    const { id, rabbit_id } = state.dashboard.cancelTask.job;
    const queryString = qs.parse(window.location.href, {
      ignoreQueryPrefix: true,
    });
    const { _ab } = queryString;

    const data = {
      date: moment().add(3, "hours").format("YYYY-MM-DD"),
      job_id: id,
      rabbit_id,
      form_referrer: "cancellation",
      target: "form",
      source: "dashboard",
      _ab,
      booking_flow_type: REBOOK_FLOW, 
    };

    const query = qs.stringify(data, { skipNulls: true });

    dispatch(fireMetric("cancel_repost_task_reposted", { job_id: id }));
    window.location.href = internalPath(`/tasks/repost?${query}`);
  };
}

export const removeTask = (job) => {
  return async (dispatch) => {
    const { id, rabbitId } = job;
    const data = {
      job_id: id,
      rabbit_id: rabbitId,
    };

    try {
      // TODO: check if we need job bundle cancellation here
      await xhr.post(CANCEL_TASK_V2, JSON.stringify(data));
      dispatch(
        removeTaskFromActive({ isJobBundle: job.jobBundleId, id: job.id })
      );
      dispatch(createAlert(messages.remove_success.id));
    } catch (e) {
      dispatch(createAlert(messages.remove_failure.id));
    }
  };
};

export const reloadActiveTasks = ()=> {
  return (dispatch) => {
    return dispatch(
      loadTaskList({ listName: "active", forcePageRefresh: true })
    );
  };
}

const cancelTaskApi = (stateData) => {
  const { job, reason, jobBundle } = stateData;
  const requestData = {
    job_id: job.id,
    rabbit_id: job.rabbit_id,
    reason,
  };
  const cancelEndpoint = jobBundle
    ? CANCEL_JOB_BUNDLE.replace(":job_bundle_id", jobBundle.id)
    : CANCEL_TASK_V2;

  return xhr.post(cancelEndpoint, JSON.stringify(requestData));
};

function rescheduleJobApi(stateData) {
  const { job, repostSchedule, jobBundle, tasks, reason } = stateData;
  const apiUrl = "/api/v3/broadcast/jobs/reschedule";
  const requestData = { schedule: repostSchedule, cancel_reason: reason };

  if (jobBundle) {
    requestData.job_bundle_id = jobBundle.id;
    requestData.reposted_job_ids = tasks.map((task) => task.details.id);
  } else {
    requestData.job_id = job.id;
  }

  return xhr.put(apiUrl, JSON.stringify(requestData));
}
