export const twoFactorModalSteps = {
  verify: "mfa_code_auth", // sent from backend
  setup: "mfaLoginSetup",
  success: "mfaActivatedSuccess",
  delete: "mfaDeleteConfirmation",
};

export const LOGIN_ORIGIN = "login";
export const ACCOUNT_ORIGIN = "account";
export const PROTECTED_SCREEN_ORIGIN = "PROTECTED_SCREEN"

//MFA Action names
export const PASSWORD = "PASSWORD";
export const PROFILE = "PROFILE";
export const AUTH_SIGN_IN = "AUTH_SIGN_IN";
export const SESSION_SIGN_IN = "SESSION_SIGN_IN";
export const PAYMENT = "PAYMENT";
