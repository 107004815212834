import React, { useCallback } from "react";
import { FormattedMessage } from "../../../FormattedMessage";
import { TrMetricsService } from "../../../../services/TrMetricsService";
import { internalPath } from "../../../../util/internalPath";
import {
  HeaderNavigationA,
  HeaderNavigationIcon,
  HeaderNavigationLink,
} from "../header-constants";
import styled from "styled-components";

export function HeaderLink({
  baseUrl,
  iconClassName,
  $displayAsButton,
  eventName,
  isInsideReactRouter,
  $mobileOnly,
  nestedUrl,
  routing,
  innerRef,
  labelMessage,
  withBorder,
}) {
  const trackOnClick = useCallback(() => {
    if (!eventName) return;

    TrMetricsService.fire(eventName + "_clicked", {
      source: "header",
    });
  }, [eventName]);

  const isBaseUrlSame = baseUrl === routing.base_url;

  const pathname = routing.location?.pathname;
  const linkIsSelected =
    isBaseUrlSame &&
    (pathname === nestedUrl || (pathname === "/" && nestedUrl === ""));

  const toPath = isBaseUrlSame ? nestedUrl : internalPath(baseUrl + nestedUrl);

  if (isInsideReactRouter && isBaseUrlSame) {
    return (
      <HeaderNavigationLink
        to={toPath}
        ref={innerRef}
        onClick={trackOnClick}
        $displayAsButton={$displayAsButton}
        $linkIsSelected={linkIsSelected}
        $mobileOnly={$mobileOnly}
      >
        <HeaderNavigationIcon className={iconClassName} />
        <FormattedMessage message={labelMessage} />
      </HeaderNavigationLink>
    );
  }

  const OptionalBorder = withBorder ? Border : React.Fragment;

  return (
    <HeaderNavigationA
      href={toPath}
      ref={innerRef}
      onClick={trackOnClick}
      $displayAsButton={$displayAsButton}
      $linkIsSelected={linkIsSelected}
      $mobileOnly={$mobileOnly}
    >
      {
        <OptionalBorder>
          <HeaderNavigationIcon className={iconClassName} />
          <FormattedMessage message={labelMessage} />
        </OptionalBorder>
      }
    </HeaderNavigationA>
  );
};

const Border = styled.div`
  display: inline-block;
  color: ${p => p.theme.color.buttonTextSecondary};
  border-width: 1px;
  border-style: solid;
  border-color: ${p => p.theme.color.buttonBorderSecondary};
  background-color: ${p => p.theme.color.buttonBackgroundSecondary};
  border-radius: ${p => p.theme.layout.borderRadius.btn};
  font-size: ${p => p.theme.typography.typeScale[100]};
  padding: 0.2em 1.38em 0.2em;
  transition: all .16s ease-in-out;

  &:hover {
    background-color: ${p => p.theme.color.buttonBackgroundSecondaryHover};
    border-color: ${p => p.theme.color.buttonTextSecondaryHover};
    color: ${p => p.theme.color.buttonTextSecondaryHover};
  }
`;