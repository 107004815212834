import React from "react";
import { HeaderNav } from "./StyledHeaderNavContainer/HeaderNav";
import { HeaderNavProvider } from "./StyledHeaderNavContainer/HeaderNavProvider";

export function StyledHeaderNavContainer({
  dontShowLinks = false,
  headerlessPages = undefined,
  isIkeaJob = false,
  isInsideReactRouter = false,
  page = undefined,
  showMyTaskersLink = false,
}) {
  return (
    <HeaderNavProvider>
      <HeaderNav
        dontShowLinks={dontShowLinks}
        headerlessPages={headerlessPages}
        isIkeaJob={isIkeaJob}
        isInsideReactRouter={isInsideReactRouter}
        page={page}
        showMyTaskersLink={showMyTaskersLink}
      />
    </HeaderNavProvider>
  );
}

export default StyledHeaderNavContainer;
