import React, { useState } from "react";
import {
  FormattedHTMLMessage,
  FormattedMessage,
} from "components/FormattedMessage";
import styled from "styled-components";
import { defineMessages } from "react-intl";
import { ACCOUNT_ORIGIN, PROTECTED_SCREEN_ORIGIN } from "enums/twoFactorAuth";
import { StyledButtonPrimary } from "components/sharedStyledComponents/StyledButton";
import { useIsDesktop } from "../../shared/hooks/use-breakpoints";
import { TWO_FACTOR_AUTH_SUPPORT } from "account/routes/apiRoutes";

const messages = defineMessages({
  title: {
    id: "two_factor_authentication_modal.verify.title",
    defaultMessage: "Verify your authentication code",
  },
  subtitle: {
    id: "two_factor_authentication_modal.verify.subtitle",
    defaultMessage:
      "Enter the 6-digit code sent to your phone number <strong>{phone_number}</strong>",
  },
  setupSubtitle: {
    id: "two_factor_authentication_modal.verify.setup_subtitle",
    defaultMessage:
      "Enter the 6-digit code sent to your phone number <strong>{phone_number}</strong>",
  },
  codeSent: {
    id: "two_factor_authentication_modal.verify.code_sent",
    defaultMessage:
      "Code sent! Please wait one minute before requesting a new code.",
  },
  verify: {
    id: "two_factor_authentication_modal.verify.verify",
    defaultMessage: "Verify",
  },
  resendCode: {
    id: "two_factor_authentication_modal.verify.resend_code",
    defaultMessage: "Resend Code",
  },
  enterCode: {
    id: "two_factor_authentication_modal.verify.enter_code",
    defaultMessage: "Enter Code",
  },
  customerSupport: {
    id: "two_factor_authentication_modal.verify.customer_support",
    defaultMessage: "Need your code sent to a new phone number?",
  },
  customerSupportLink: {
    id: "two_factor_authentication_modal.verify.enter_code.customer_support_link",
    defaultMessage: " Contact customer support",
  },
});

const REQUIRED_CODE_LENGTH = 6;

export const TwoFactorVerifyContent = ({
  error,
  codeResent,
  setup,
  origin,
  loading,
  handleBack,
  handleResendCode,
  handleVerify,
  formattedMfaPhone,
  formatMessage,
  enrolled,
  actionName
}) => {
  const isDesktop = useIsDesktop();
  const [code, setCode] = useState("");

  const renderTitleSection = () => {
    return isDesktop ? (
      <>
        <StyledModalTop>
          <StyledBackArrow>
              <StyledBackArrowButton
              type="button"
              aria-label="Back"
              onClick={handleBack}
              className="btn-transparent ss-lnr-chevron-left"
              />
          </StyledBackArrow>
          <StyledModalTitle>
            <FormattedMessage message={messages.title} />
          </StyledModalTitle>
        </StyledModalTop>
        <hr />
      </>
    ) : (
      <StyledModalTitle>
        <FormattedMessage message={messages.title} />
      </StyledModalTitle>
    );
  };

  const subtitleMessage =
    setup || (origin === ACCOUNT_ORIGIN || origin === PROTECTED_SCREEN_ORIGIN)
      ? messages.setupSubtitle
      : messages.subtitle;

  return (
    <StyledTwoFactorVerifyModal>
      {renderTitleSection()}
      <StyledModalSubtitle>
        <FormattedHTMLMessage
          message={subtitleMessage}
          values={{
            phone_number: formattedMfaPhone,
          }}
        />
      </StyledModalSubtitle>
      <StyledCodeInput
        type="text"
        autocomplete="one-time-code"
        onChange={(e) => setCode(e.target.value)}
        value={code}
        placeholder={formatMessage(messages.enterCode)}
      />
      <StyledCodeErrorResponse>{error}</StyledCodeErrorResponse>
      {codeResent ? (
        <StyledResendCodeResponse>
          <FormattedMessage message={messages.codeSent} />
        </StyledResendCodeResponse>
      ) : (
        <StyledResendCodeButton onClick={() => handleResendCode(actionName)} type="button">
          <FormattedMessage message={messages.resendCode} />
        </StyledResendCodeButton>
      )}
      <StyledBottomRow>
        <StyledButtonPrimary
          isDisabled={code.length !== REQUIRED_CODE_LENGTH}
          disabled={code.length !== REQUIRED_CODE_LENGTH}
          isLoading={loading}
          width={isDesktop ? "320px" : "100%"}
          height={isDesktop ? "54px" : "46px"}
          onClick={() => handleVerify(code, enrolled, actionName)}
        >
          <FormattedMessage message={messages.verify} />
        </StyledButtonPrimary>
      </StyledBottomRow>
      <StyledBottomRow>
        {enrolled &&
          <div>
            <FormattedMessage message={messages.customerSupport} />
              <StyledSupportLink
                href={TWO_FACTOR_AUTH_SUPPORT}
                rel="noopener noreferrer"
                target="_blank"
              > 
            <FormattedMessage message={messages.customerSupportLink} />
          </StyledSupportLink>
        </div>}
      </StyledBottomRow>
    </StyledTwoFactorVerifyModal>
  );
};

const StyledSupportLink = styled.a`
  display: block;
`;

const StyledTwoFactorVerifyModal = styled.div`
  ${(p) => p.theme.breakpoints.tabletUp} {
    padding-top: ${(props) => props.theme.spacing.xl};
    padding-bottom: ${(props) => props.theme.spacing.xl};
    padding-left: ${(props) => props.theme.spacing.xxl};
    padding-right: ${(props) => props.theme.spacing.xxl};
    width: 660px;
    min-height: 350px;
  }
  ${(p) => p.theme.breakpoints.mobile} {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: ${(props) => props.theme.spacing.md};
    padding-right: ${(props) => props.theme.spacing.md};
    margin-top: 76px;
    width: 100%;
    height: 100%;
  }
  text-align: center;
`;

const StyledModalTop = styled.div`
  display: flex;
  margin-bottom: ${(props) => props.theme.spacing.md};
`;

const StyledBackArrowButton = styled.button`
  font-size: 20px;
  align-self: center;
`;

const StyledModalSubtitle = styled.div`
  margin-top: ${(props) => props.theme.spacing.xl};
  margin-bottom: ${(props) => props.theme.spacing.sm};
  padding-top: 0;
  padding-bottom: 0;
  padding-left: ${(props) => props.theme.spacing.lg};
  padding-right: ${(props) => props.theme.spacing.lg};
`;

const StyledModalTitle = styled.div`
  padding: 0;
  ${(p) => p.theme.breakpoints.tabletUp} {
    font-size: 26px;
  }
  ${(p) => p.theme.breakpoints.mobile} {
    font-size: ${(props) => props.theme.typography.typeScale["200"]};
  }
  line-height: 30px;
  font-weight: ${(p) => p.theme.typography.weight.semiBold};
  text-align: center;
  margin: 0 auto;
`;

const StyledBottomRow = styled.div`
  margin-top: ${(props) => props.theme.spacing.xl};
  display: flex;
  justify-content: center;
`;

const StyledBackArrow = styled.div`
  align-self: center;
`;

const StyledCodeErrorResponse = styled.p`
  color: ${(props) => props.theme.color.errorText};
  margin-top: ${(props) => props.theme.spacing.sm};
  margin-bottom: ${(props) => props.theme.spacing.sm};
  margin-left: 0;
  margin-right: 0;
  font-size: ${(props) => props.theme.typography.typeScale["50"]};
`;

const StyledResendCodeResponse = styled.div`
  color: ${(props) => props.theme.color.neutral};
`;

const StyledResendCodeButton = styled.button`
  color: ${(props) => props.theme.color.buttonPrimary};
  background: none;
  border: none;
  font-weight: ${(props) => props.theme.typography.weight.regular};
  cursor: pointer;
`;

const StyledCodeInput = styled.input`
  min-width: 224px;
  margin-top: ${(props) => props.theme.spacing.md};
`;
