import React from "react";
import { twoFactorModalSteps } from "enums/twoFactorAuth";
import { TwoFactorVerifyModal } from "user/components/TwoFactorVerifyModal";
import { TwoFactorSetupModal } from "user/components/TwoFactorSetupModal";
import { TwoFactorSetupSuccessModal } from "user/components/TwoFactorSetupSucessModal";
import { TwoFactorDeleteModal } from "user/components/TwoFactorDeleteModal";

export const TwoFactorModal = ({
  twoFactorAuth: {
    next: step,
    mfaPhone,
    error,
    codeResent,
    setup = false,
    loading = false,
  },
  mobilePhone,
  origin,
  locale,
  countryIsoCode,
  enrolled,
  actionName
}) => {
  const renderData = () => {
    switch (step) {
      case twoFactorModalSteps.verify:
        return (
          <TwoFactorVerifyModal
            locale={locale}
            codeResent={codeResent}
            mfaPhone={mfaPhone}
            error={error}
            loading={loading}
            setup={setup}
            origin={origin}
            enrolled={enrolled}
            actionName={actionName}
          />
        );
      case twoFactorModalSteps.setup:
        return (
          <TwoFactorSetupModal
            mobilePhone={mobilePhone ? mobilePhone : ""}
            countryIsoCode={countryIsoCode}
            origin={origin}
            loading={loading}
            error={error}
            setup={setup}
            locale={locale}
            enrolled={enrolled}
            actionName={actionName}
          />
        );
      case twoFactorModalSteps.success:
        return <TwoFactorSetupSuccessModal origin={origin} />;
      case twoFactorModalSteps.delete:
        return <TwoFactorDeleteModal loading={loading} />;
      default:
        return null;
    }
  };

  return renderData();
};
