/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { TRModal } from "components/Modal/TRModal";
import { createPortal } from "react-dom";
import { TwoFactorAuthenticationModalController } from "user/components/TwoFactorAuthenticationModalController";
import { LOGIN_ORIGIN, ACCOUNT_ORIGIN, PROTECTED_SCREEN_ORIGIN } from "enums/twoFactorAuth";
import { ForDesktop, ForMobile } from "../../../shared/hooks/use-breakpoints";

export const WithTwoFactorAuth = ({
  portal, // if opened from modal, need portal to render mobile view
  user,
  profile = {},
  accountSecurity = {},
  origin,
  actionName
}) => {
  const showTwoAuthModal =
    (origin === ACCOUNT_ORIGIN || origin === PROTECTED_SCREEN_ORIGIN)
      ? !!accountSecurity.modal.next
      : !!user.twoFactorAuth.next;

  const retrieveData = () => {
    if (origin === ACCOUNT_ORIGIN || origin === PROTECTED_SCREEN_ORIGIN) {
      const { modal, enrolled } = accountSecurity;
      const { locale, country_iso_code: countryIsoCode } = profile;

      return {
        twoFactorAuth: modal,
        mobilePhone: profile.mobile_phone || profile.phone_number,
        locale,
        countryIsoCode,
        enrolled
      };
    }
    if (origin === LOGIN_ORIGIN) {
      const {
        locale,
        twoFactorAuth,
        loggedInPlaceholder: { mobile_phone: mobilePhone,
        country_iso_code: countryIsoCode,
        mfa_enrolled: mfaEnrolled  } = {},
      } = user;
      /* mfa_enrolled is ONLY exposed when the user does not have mfa enabled
        This is because at that point mfa is option and you are technically logged 
        If mfa is enabled you must verify first, so no additional into is available
      */
      const enrolled = mfaEnrolled === undefined ? true : false;

      return {
        twoFactorAuth,
        mobilePhone,
        locale,
        countryIsoCode,
        enrolled
      };
    }

    return null;
  };
  /* TRModal which is shared across V3, is not removing this class for some reason
    this is an older modal so eventually will be replaced, 
    So as a temp fix removing it manually here
  */
  if (!showTwoAuthModal) {
    document.body.classList.remove('ReactModal__Body--open');
 }
  return (
    <>
      <ForDesktop>
        <TRModal isOpen={showTwoAuthModal}>
          <TwoFactorAuthenticationModalController
            origin={origin}
            actionName={actionName}
            {...retrieveData()}
          />
        </TRModal>
      </ForDesktop>
      {showTwoAuthModal && (
        <ForMobile>
          {portal ? (
            createPortal(
              <TwoFactorAuthenticationModalController
                origin={origin}
                actionName={actionName}
                {...retrieveData()}
              />,
              document.getElementById("root")
            )
          ) : (
            <TwoFactorAuthenticationModalController
                origin={origin}
                actionName={actionName}
              {...retrieveData()}
            />
          )}
        </ForMobile>
      )}
    </>
  );
};