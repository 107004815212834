import React from "react";
import fullLogoImg from "./tr_full_new.svg";
import compactLogoImg from "./tr_compact_new.svg";

type LogoType = "full" | "compact";
type ImageUrl = string;

type Logos = {
  [key in LogoType]: ImageUrl;
};

const logos: Logos = {
  full: fullLogoImg,
  compact: compactLogoImg,
} as const;

type Props = {
  type: LogoType;
  className?: string;
  style?: React.CSSProperties;
  width?: React.CSSProperties["width"];
  height?: React.CSSProperties["height"];
  alt?: string;
  role?: string;
};

const TRLogo = ({
  type = "full",
  alt = "TaskRabbit logo",
  className,
  style,
  role,
  width,
  height,
}: Props) => {
  return (
    <img
      className={className}
      style={style}
      width={width}
      height={height}
      src={logos[type]}
      alt={alt}
      role={role}
    />
  );
};

export default TRLogo;
