import PropTypes from "prop-types";
import React, { forwardRef } from "react";
import classnames from "classnames";

const classMapping = {
  sm: "tr-col-sm-",
  md: "tr-col-md-",
  lg: "tr-col-lg-",
  lscp: "tr-col-lscp-",
  desk: "tr-col-desk-",
};

const propValidator = (props, propName, componentName) => {
  const { size, sm, md, lg, lscp, desk } = props;
  if (!size && !sm && !md && !lg && !lscp && !desk) {
    return new Error(
      `${componentName} requires at least one of the following props: 'size', 'sm', 'md', 'lg, 'lscp', 'desk'.`
    );
  }

  return null;
};

function Col(props, ref) {
  const {
    centered,
    size,
    className,
    // eslint-disable-next-line no-unused-vars
    sm,
    // eslint-disable-next-line no-unused-vars
    md,
    // eslint-disable-next-line no-unused-vars
    lg,
    // eslint-disable-next-line no-unused-vars
    lscp,
    // eslint-disable-next-line no-unused-vars
    desk,
    ...remainingProps
  } = props;

  const sizeClasses = Object.keys(props)
    .filter((key) => classMapping[key])
    .map((key) => `${classMapping[key]}${props[key]}`);

  return (
    <div
      ref={ref}
      className={classnames({
        "tr-col--centered": centered,
        [`tr-col-${size}`]: size,
        [sizeClasses.join(" ")]: !!sizeClasses,
        [className]: !!className,
      })}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...remainingProps}
    />
  );
}

const ForwardedCol = forwardRef(Col);

ForwardedCol.propTypes = {
  sm: propValidator,
  centered: PropTypes.bool,
  size: PropTypes.number,
  className: PropTypes.string,
};

ForwardedCol.displayName = "Col";

export default ForwardedCol;
