import React from "react";
import applePayIcon from "./apple-pay-mark-rgb.svg";

const ApplePayIcon = () => {
  return (
    <span className="confirmation--payment-show">
      <span className="confirmation--payment-wallet">
        <img
          src={applePayIcon}
          className="confirmation--payment-apple-pay"
          alt=""
        />
      </span>
    </span>
  );
};

export default ApplePayIcon;
