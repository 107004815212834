import xhr, { createApiErrorAlert } from "util/xhr";
import { BOOTSTRAP } from "dashboard/routes/apiRoutes";

const initialState = {
  support_key: "all",
  promotion: null,
};

export default function dashboard(state = initialState, action) {
  switch (action.type) {
    case BOOTSTRAP_RESPONSE: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { support_key, promotion, referral } = action.data;
      return { ...state, support_key, promotion, referral };
    }
    default:
      return state;
  }
}

const BOOTSTRAP_REQUEST = "v3/dashboardBootstrap/BOOTSTRAP_REQUEST";
export const BOOTSTRAP_RESPONSE = "v3/dashboardBootstrap/BOOTSTRAP_RESPONSE";
const BOOTSTRAP_ERROR = "v3/dashboardBootstrap/BOOTSTRAP_ERROR";

export const bootstrap = () => async (dispatch) => {
  dispatch({ type: BOOTSTRAP_REQUEST });
  try {
    const response = await xhr.get(BOOTSTRAP);
    dispatch({
      type: BOOTSTRAP_RESPONSE,
      data: response.data,
    });
  } catch (e) {
    dispatch(createApiErrorAlert(e));
    dispatch({ type: BOOTSTRAP_ERROR });
  }
};
