import createRootReducer, { rootEpic } from "../reducers";
import createStore from "store/createStore";


export default function createStoreAll(history, initialState) {
  return createStore({
    history,
    initialState,
    createRootReducer,
    rootEpic,
  });
}
