export class TimeoutError extends Error {
  constructor(message) {
    super(message);
    this.name = "TimeoutError";
  }
}

export const pTimeout = (promise, milliseconds, fallback) =>
  new Promise((resolve, reject) => {
    if (typeof milliseconds !== "number" || milliseconds < 0) {
      throw new TypeError("Expected `milliseconds` to be a positive number");
    }

    if (milliseconds === Infinity) {
      resolve(promise);
      return;
    }

    const timer = setTimeout(() => {
      if (typeof fallback === "function") {
        try {
          resolve(fallback());
        } catch (error) {
          reject(error);
        }

        return;
      }

      const message =
        typeof fallback === "string"
          ? fallback
          : `Promise timed out after ${milliseconds} milliseconds`;
      const timeoutError =
        fallback instanceof Error ? fallback : new TimeoutError(message);

      if (typeof promise.cancel === "function") {
        promise.cancel();
      }

      reject(timeoutError);
    }, milliseconds);

    promise.then(resolve, reject).finally(() => {
      clearTimeout(timer);
    });
  });

export default pTimeout;
