import xhr from "util/xhr";

import {
  JOB_CHAT_THREAD,
  CHAT_TOKEN_ENDPOINT,
  JOB_CONFERENCE_THREAD,
  CHAT_STATUS_API,
} from "dashboard/routes/apiRoutes";
import { BOOTSTRAP_RESPONSE } from "dashboard/redux/modules/bootstrapDashboard";
import pWaitFor, { TIMEOUT_ERROR } from "util/pWaitFor";
import { AnyAction } from "redux";
import { V3Thunk } from "util/reduxTypes";

export const FETCH_CHAT_TOKEN_REQUEST = "v3/chat/FETCH_CHAT_TOKEN_REQUEST";
export const FETCH_CHAT_TOKEN_SUCCESS = "v3/chat/FETCH_CHAT_TOKEN_SUCCESS";
export const FETCH_CHAT_TOKEN_FAILURE = "v3/chat/FETCH_CHAT_TOKEN_FAILURE";
export const FETCH_STREAM_CHANNEL_REQUEST =
  "v3/chat/FETCH_STREAM_CHANNEL_REQUEST";
export const FETCH_STREAM_CHANNEL_SUCCESS =
  "v3/chat/FETCH_STREAM_CHANNEL_SUCCESS";
export const FETCH_STREAM_CHANNEL_FAILURE =
  "v3/chat/FETCH_STREAM_CHANNEL_FAILURE";
const CONNECT_CONFERENCE_SUCCESS = "v3/chat/CONNECT_CONFERENCE_SUCCESS";
const CONNECT_CONFERENCE_FAILURE = "v3/chat/CONNECT_CONFERENCE_FAILURE";

interface ChatState {
  fetchingChatToken: boolean;
  fetchingStreamChannel: boolean;
  chatToken: string;
  apiKey: string;
  streamChannel: string;
  userName: string;
  avatarUrl: string;
  showRepostLink: string;
  showProfileLink: string;
  showCallLink: string;
  taskerIsActive: string;
  taskerSlug: string;
  taskerName: string;
  active: boolean;

  userId?: string;
  streamChannelId?: string;
  streamChannelType?: string;
  streamError?: string;
  streamLoading?: boolean;
}

const initialState: ChatState = {
  fetchingChatToken: false,
  fetchingStreamChannel: false,
  chatToken: "",
  apiKey: "",
  streamChannel: "",
  userName: "",
  avatarUrl: "",
  showRepostLink: "",
  showProfileLink: "",
  showCallLink: "",
  taskerIsActive: "",
  taskerSlug: "",
  taskerName: "",
  active: false,
};

export default function chat(
  state: ChatState = initialState,
  action: AnyAction
): ChatState {
  switch (action.type) {
    case FETCH_CHAT_TOKEN_REQUEST:
      return { ...state, fetchingChatToken: true };
    case FETCH_CHAT_TOKEN_SUCCESS:
      return {
        ...state,
        fetchingChatToken: false,
        chatToken: action.token,
        userId: action.userId,
      };
    case FETCH_STREAM_CHANNEL_REQUEST:
      return { ...state, streamLoading: true };
    case FETCH_STREAM_CHANNEL_SUCCESS:
      return {
        ...state,
        streamChannelId: action.streamChannelId,
        streamChannelType: action.streamChannelType,
        streamLoading: false,
        streamError: undefined,
        userName: action.userName,
        avatarUrl: action.avatarUrl,
        showRepostLink: action.showRepostLink,
        showProfileLink: action.showProfileLink,
        showCallLink: action.showCallLink,
        taskerIsActive: action.taskerIsActive,
        taskerSlug: action.taskerSlug,
        taskerName: action.taskerName,
        active: action.active,
      };
    case FETCH_STREAM_CHANNEL_FAILURE:
      return {
        ...state,
        streamError: action.error,
        streamLoading: false,
      };
    case BOOTSTRAP_RESPONSE:
      return {
        ...state,
        apiKey: action.data.stream_api_key,
      };
    default:
      return state;
  }
}

export const fetchChatToken = (): V3Thunk => {
  return async (dispatch) => {
    dispatch({
      type: FETCH_CHAT_TOKEN_REQUEST,
    });

    try {
      const response = await xhr.get(CHAT_TOKEN_ENDPOINT);
      dispatch({
        type: FETCH_CHAT_TOKEN_SUCCESS,
        token: response.data.stream.token,
        userId: response.data.stream.id,
      });
    } catch (ex) {
      dispatch({ type: FETCH_CHAT_TOKEN_FAILURE, error: ex });
    }
  };
};

export function fetchStreamChannel(job_id: string, rabbit_id: string): V3Thunk {
  return async (dispatch) => {
    dispatch({ type: FETCH_STREAM_CHANNEL_REQUEST });

    const url = JOB_CHAT_THREAD.replace(":job_id", job_id).replace(
      ":rabbit_id",
      rabbit_id
    );

    try {
      const { camelCasedData: data } = await xhr.get(url);
      const { created } = data.chat.streamChannel;
      if (!created) {
        // if not create poll every second for 5 seconds.
        await pWaitFor(
          async () => {
            const result = await xhr.get(`${CHAT_STATUS_API}/${data.chat.id}`);
            // expects a proper boolean
            return result.data.stream_channel.created;
          },
          { interval: 1000, timeout: 5000 }
        );
      }
      // either first call or polling reports channel created so
      dispatch({
        type: FETCH_STREAM_CHANNEL_SUCCESS,
        active: data.active,
        avatarUrl: data.fromAvatarUrl,
        taskerIsActive: data.rabbitIsActive,
        taskerSlug: data.rabbitSlug,
        showCallLink: data.showCallLink,
        showProfileLink: data.showProfileLink,
        showRepostLink: data.showRepostLink,
        streamChannelId: data.chat.streamChannel.id,
        streamChannelType: data.chat.streamChannel.type,
        taskerName: data.toName,
        userName: data.fromName,
      });
    } catch (errors: any) {
      dispatch({
        type: FETCH_STREAM_CHANNEL_FAILURE,
        error: errors.name === TIMEOUT_ERROR ? "Chat API timed out" : errors,
      });
    }
  };
}

export function startConference(
  job_id: string,
  rabbit_id: string,
  to_name: string
): V3Thunk {
  return async (dispatch) => {
    let url = JOB_CONFERENCE_THREAD.replace(":job_id", job_id);
    url = url.replace(":rabbit_id", rabbit_id);

    const connecting_text = `We are now connecting you to ${to_name}.`;
    try {
      await xhr.post(url, {});
      dispatch({
        type: CONNECT_CONFERENCE_SUCCESS,
        chatResponse: {
          context: connecting_text,
          status: "delivered",
          created_at: Date.now(),
        },
      });
    } catch (e) {
      dispatch({ type: CONNECT_CONFERENCE_FAILURE, error: e });
    }
  };
}
