import { combineReducers } from "redux";
import { combineEpics } from "redux-observable";

import login, {
  loginSignupEpic as _loginSignupEpic,
} from "login/redux/modules/loginSignup";

export default combineReducers({
  login,
});

export const loginSignupEpic = combineEpics(_loginSignupEpic);
