import xhr, { createApiErrorAlert } from "util/xhr";
import { RABBIT_GRANULAR_AVAILABILITY_SHOW } from "build/routes/apiRoutes";
import { modifyAvailability } from "build/redux/modules/managerUtil";
import { V3Thunk } from "util/reduxTypes";
import { AnyAction } from "redux";

interface TaskerScheduleState {
  byTasker: Record<string, unknown>;
  loading: boolean;
}

const initialState: TaskerScheduleState = {
  byTasker: {},
  loading: false,
};

const FETCH_SCHEDULE = "v3/build/taskerSchedule/FETCH_SCHEDULE";
export const FETCH_SCHEDULE_RESPONSE =
  "v3/build/taskerSchedule/FETCH_SCHEDULE_RESPONSE";
const FETCH_SCHEDULE_ERROR = "v3/build/taskerSchedule/FETCH_SCHEDULE_ERROR";

export default function taskerSchedule(
  state: TaskerScheduleState = initialState,
  action: AnyAction
): TaskerScheduleState {
  switch (action.type) {
    case FETCH_SCHEDULE:
      return {
        ...state,
        loading: true,
        byTasker: {
          [action.invitee_id]: {},
        },
      };

    case FETCH_SCHEDULE_RESPONSE:
      return {
        ...state,
        loading: false,
        byTasker: {
          ...state.byTasker,
          [action.data.id]: action.data,
        },
      };

    case FETCH_SCHEDULE_ERROR:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}

export const fetchSchedule = (invitee_id: number): V3Thunk => async (
  dispatch,
  getState
) => {
  const state = getState();

  if (state.build.taskerSchedule.loading) {
    return;
  }

  dispatch({ type: FETCH_SCHEDULE, invitee_id });

  const { location, task_template_id, category_id } = state.build.manager.job;

  const params = {
    invitee_id,
    task_template_id,
    category_id,
    location: { lat: location.lat, lng: location.lng },
  };

  try {
    const response = await xhr.get(RABBIT_GRANULAR_AVAILABILITY_SHOW, {
      params,
    });
    const { data } = modifyAvailability(response);
    dispatch({
      type: FETCH_SCHEDULE_RESPONSE,
      data: { ...data, fetchedAt: Date.now() },
    });
  } catch (e: any) {
    dispatch(createApiErrorAlert(e));
  }
};
