import xhr, { createApiErrorAlert } from "util/xhr";
import { createAlert } from "alerts/redux/modules/alerts";

const SEND_REFERRAL = "SEND_REFERRAL";
const SEND_REFERRAL_RESPONSE = "SEND_REFERRAL_RESPONSE";
const SEND_REFERRAL_ERROR = "SEND_REFERRAL_ERROR";

export default function(state = {}, action) {
  switch (action.type) {
    case SEND_REFERRAL:
      return { ...state, loading: true };
    case SEND_REFERRAL_RESPONSE:
      return { ...state, loading: false };
    case SEND_REFERRAL_ERROR:
      return { ...state, loading: false };
    default:
      return state;
  }
}

const sendReferral = () => ({ type: SEND_REFERRAL });
const referralResponse = () => ({ type: SEND_REFERRAL_RESPONSE });
const referralError = () => ({ type: SEND_REFERRAL_ERROR });

export const sendReferralShareEmail = (data = {}) => {
  return (dispatch, getState) => {
    const state = getState();
    const { advocate_share_endpoint: url } = state.dashboard.bootstrap.referral;

    dispatch(sendReferral());
    return xhr.post(url, data).then(
      ({ data }) => {
        dispatch(createAlert({ messages: [data.message], skipI18n: true }));
        dispatch(referralResponse());
      },
      e => {
        dispatch(referralError());
        dispatch(createApiErrorAlert(e.data.errors[0].message));
      }
    );
  };
};
