import xhr, { createApiErrorAlert } from "util/xhr";
import { Observable } from "rxjs";
import { combineEpics } from "redux-observable";
import { push, replace, LOCATION_CHANGE } from "connected-react-router";
import qs from "qs";

import {
  LOGIN,
  SIGNUP,
  REQUEST_RESET_PASSWORD,
  RESET_PASSWORD,
  RABBIT_SIGNUP,
} from "login/routes/apiRoutes";
import { fireMetric } from "store/middleware/metricMiddleware";
import { internalPath } from "util/internalPath";
import { notifyBugsnag } from "util/bugsnag";
import { createActionTypesFor } from "util/reducerHelpers";
import { isLoggedIn } from "shared/utils/userService";
import { selectJobDraftGuid } from "build/redux/modules/selector";
import { getIkeaLegacyMetricData } from "ikea/redux/modules/manager";
import { SESSION_SIGN_IN } from "enums/twoFactorAuth";

// Segment Analytics
import SegmentAnalyticsService from "services/SegmentAnalyticsService";
import {
  USER_LOGGED_IN as SEGMENT_USER_LOGGED_IN,
  USER_ACCOUNT_CREATED as SEGMENT_USER_ACCOUNT_CREATED,
  TASKER_ACCOUNT_CREATED,
  USER_RESET_PASSWORD as SEGMENT_USER_RESET_PASSWORD
} from "enums/segmentEventNames";
import { PropertyKeys } from "enums/segmentPropertyKeys";

const windowGlobal = typeof window === "undefined" ? {} : window;
const initialState = {
  mode: "signup",
  isLoggedIn: isLoggedIn(),
  afterAuth: windowGlobal.REDIRECT_URL,
  errorPayload: null,
  hasAdBlock: false,
  nextjsLoginFlow: false,
  promptLogin: false,
  loading: false,
};

export const VERIFY_LOGIN_TWO_FACTOR_AUTH = createActionTypesFor(
  "user",
  "verifyLoginTwoFactorAuth"
);

export const RESEND_TWO_FACTOR_LOGIN_CODE = createActionTypesFor(
  "user",
  "resendTwoFactorLoginCode"
);

export const SEND_TWO_FACTOR_LOGIN_CODE = createActionTypesFor(
  "user",
  "sendTwoFactorLoginCode"
);

export const VERIFY_SETUP_LOGIN_TWO_FACTOR_AUTH = createActionTypesFor(
  "user",
  "verifySetupLoginTwoFactorAuth"
);

export const UPDATE_TWO_FACTOR_MODAL_STEP = "user/updateTwoFactorModalStep";

export const USER_LOGGED_IN = "v3/loginSignup/USER_LOGGED_IN";
export const LOGIN_FAILED = "v3/loginSignup/LOGIN_FAILED";
export const LOGIN_FAILED_RESET = "v3/loginSignup/LOGIN_FAILED_RESET";
const PROMPT_LOGIN_SIGNUP = "v3/loginSignup/PROMPT_LOGIN_SIGNUP";
export const USER_LOCAL_LOGIN = "v3/loginSignup/USER_LOCAL_LOGIN";
const USER_LOCAL_SIGNUP = "v3/loginSignup/USER_LOCAL_SIGNUP";
const CHANGE_MODE = "v3/loginSignup/CHANGE_MODE";
const CHANGE_ADBLOCK = "v3/loginSignup/CHANGE_ADBLOCK";
const USER_CANCELLED_LOGIN = "v3/loginSignup/USER_CANCELLED_LOGIN";
const SET_QUERY_PARAMS = "v3/loginSignup/SET_QUERY_PARAMS";
const REQUEST_RESET_PASSWORD_REQUEST =
  "v3/loginSignup/REQUEST_RESET_PASSWORD_REQUEST";
const REQUEST_RESET_PASSWORD_FAILURE =
  "v3/loginSignup/REQUEST_RESET_PASSWORD_FAILURE";
const RESET_PASSWORD_REQUEST = "v3/loginSignup/RESET_PASSWORD_REQUEST";
const RESET_PASSWORD_FAILURE = "v3/loginSignup/RESET_PASSWORD_FAILURE";
const DISMISS_RESET_PASSWORD = "v3/loginSignup/DISMISS_RESET_PASSWORD";

export const SETUP_TWO_FACTOR_AUTH = "v3/loginSignup/SETUP_TWO_FACTOR_AUTH";

const safeRedirectUrl = (url) => {
  // ensure JS after_auth is always internal to our app
  let result = url.replace(/https:\/\//, "/");
  result = result.replace(/http:\/\//, "/");
  // Drop double slashes, esp. leading double slashes
  result = result.replace(/\/+/, "/");
  // Force leading slash to cover other edge cases
  result = result.replace(/^(!?\/)/, "/");
  return result;
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export default function loginSignup(state = initialState, action) {
  switch (action.type) {
    case PROMPT_LOGIN_SIGNUP:
      return {
        ...state,
        afterAuth: action.afterAuth
          ? safeRedirectUrl(action.afterAuth)
          : state.afterAuth,
        payload: action.payload || state.payload,
        promptLogin: true,
      };
    case USER_LOCAL_LOGIN:
    case USER_LOCAL_SIGNUP:
    case RESET_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
        errorPayload: null,
      };

    case LOGIN_FAILED:
    case RESET_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        errorPayload: action.payload ? action.payload.data : null,
      };

    case LOGIN_FAILED_RESET:
      return {
        ...state,
        errorPayload: null,
      };

    case USER_LOGGED_IN:
      return {
        ...state,
        isLoggedIn: true,
        loading: false,
        errorPayload: null,
        promptLogin: false,
        currentUser: action.data,
      };

    case CHANGE_MODE:
      return {
        ...state,
        mode: action.mode,
      };

    case CHANGE_ADBLOCK:
      return {
        ...state,
        hasAdBlock: action.adBlock,
      };

    case SET_QUERY_PARAMS: {
      // TODO FIX ME PLEASE
      // eslint-disable-next-line camelcase
      const { after_auth, nextjs_login_flow, ...rest } = action.query;
      return {
        ...state,
        // TODO FIX ME PLEASE
        // eslint-disable-next-line camelcase
        afterAuth: after_auth ? safeRedirectUrl(after_auth) : state.afterAuth,
        nextjsLoginFlow: nextjs_login_flow ? nextjs_login_flow === "true" : state.nextjsLoginFlow,
        query: {
          ...state.query,
          ...rest,
        },
      };
    }
    case VERIFY_LOGIN_TWO_FACTOR_AUTH.SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case DISMISS_RESET_PASSWORD: {
      return {
        ...state,
        passwordReset: false,
      };
    }
    default:
      return state;
  }
}

export const userLoggedIn = () => {
  return {
    type: USER_LOGGED_IN,
  };
};

export const changeMode = (mode) => ({
  type: CHANGE_MODE,
  mode,
});

export const changeAdBlock = (adBlock) => ({
  type: CHANGE_ADBLOCK,
  adBlock,
});

export const resetErrorMessage = () => ({
  type: LOGIN_FAILED_RESET,
});

export const dismissResetPassword = () => ({
  type: DISMISS_RESET_PASSWORD,
});

export const localLogin = (values, executeRecaptcha) => async (dispatch) => {
  try {
    const token = await executeRecaptcha("login");
    dispatch({
      type: USER_LOCAL_LOGIN,
      url: LOGIN,
      metricName: "login",
      values: { ...values, recaptcha_token: token },
    });
  } catch (ex) {
    // ReCaptcha will sometimes throw Null, esp. if the domain is not whitelisted for the site key
    notifyBugsnag("RecaptchaFailure", ex || { data: ex });
  }
};

export const cancelLogin = () => ({
  type: USER_CANCELLED_LOGIN,
});

export const localSignup = (values) => ({
  type: USER_LOCAL_SIGNUP,
  url: SIGNUP,
  metricName: "signup",
  values,
});

export const localRabbitSignup = (values) => ({
  type: USER_LOCAL_SIGNUP,
  url: RABBIT_SIGNUP,
  metricName: "rabbit_signup",
  values,
});

export const promptLoginSignup = ({
  afterAuth = window.location.pathname,
  payload,
}) => {
  return {
    type: PROMPT_LOGIN_SIGNUP,
    afterAuth,
    payload,
  };
};

export const requestResetPassword = (payload) => async (dispatch) => {
  dispatch({ type: REQUEST_RESET_PASSWORD_REQUEST });
  try {
    await xhr.post(REQUEST_RESET_PASSWORD, payload);
    if (!payload.notRedirect) {
      // TODO should return an action, or not be part of a reducer
      dispatch(push("/password/confirm"));
    }
  } catch (error) {
    dispatch({ type: REQUEST_RESET_PASSWORD_FAILURE });
    createApiErrorAlert(error);
  }
};

export const resetPassword = (payload) => async (dispatch) => {
  dispatch({ type: RESET_PASSWORD_REQUEST });
  try {
    const { data } = await xhr.post(RESET_PASSWORD, payload);
    dispatch({
      type: USER_LOGGED_IN,
      data: { ...data, redirectToDashboard: true, passwordReset: true },
    });
  } catch (error) {
    dispatch({ type: RESET_PASSWORD_FAILURE, payload: error.response });
    createApiErrorAlert(error);
  }
};

const sendLoginSignupApi = (payload) => {
  const { url, ...rest } = payload;
  return xhr.post(url, rest);
};

const localLoginSignupEpic = (action$, { getState }) =>
  action$
    .ofType(USER_LOCAL_LOGIN, USER_LOCAL_SIGNUP)
    .switchMap(({ url, metricName, values }) => {
      const state = getState();
      const { payload, mode, hasAdBlock } = state.loginSignup.login;
      const sessionData = {
        url,
        ...values,
        ...payload,
        mode,
        terms_consent_url: state.user.activeTermsVersionUrl,
        marketing_consent_enabled: !values.marketing_opted_out,
        tr_sess_id: JSON.parse(sessionStorage.getItem("tr_sess_id")),
        action_name: SESSION_SIGN_IN,
      };
      const newMetricName = values.fromConfirmLoginSignup
        ? `confirmation_${metricName}`
        : metricName;
      const jobDraftGuid = selectJobDraftGuid(state);

      if (metricName === "rabbit_signup") {
        sessionData.intent = "rabbit";
        sessionData.source = "onboarding";
      }

      return Observable.defer(() => sendLoginSignupApi(sessionData))
        .flatMap(({ data, camelCasedData }) => {
          if (metricName === "signup") {
            segmentTrackLoginSignup(SEGMENT_USER_ACCOUNT_CREATED, data);
          } else if (metricName === "rabbit_signup") {
            segmentTrackLoginSignup(TASKER_ACCOUNT_CREATED, data);
          }

          // has_ad_block key is not index so data team is using if field present
          let metricData = {
            job_draft_guid: jobDraftGuid,
          };

          if (hasAdBlock) {
            metricData.hasAdBlock = hasAdBlock;
          }

          const events = [];
          if (
            camelCasedData.mfaId &&
            camelCasedData.next === "mfa_code_auth" &&
            camelCasedData.mfaPhone
          ) {
            // using this type even though it's technically not from verify action. switched this with USER_LOCAL_LOGIN
            events.push({
              type: VERIFY_LOGIN_TWO_FACTOR_AUTH.SUCCESS,
              data: {
                mfaId: camelCasedData.mfaId,
                next: camelCasedData.next,
                mfaPhone: camelCasedData.mfaPhone,
              },
            });
          } else {
            // login_success or signup_success
            if (values.fromIkeaLegacy) {
              metricData = getIkeaLegacyMetricData(state, metricData);
            }

            if (values.fromConfirmLoginSignup && !values.fromIkeaLegacy) {
              events.push(fireMetric("confirmation_user_created", metricData));
            }

            if (values?.firedEventName) {
              events.push(fireMetric(values.firedEventName, metricData));
            }
            events.push(fireMetric(`${newMetricName}_success`, metricData));
            /*Since login and signup are squished together
              this is the only way we can accurately tell if this is a login or signup
            */
            if (
              !camelCasedData.mfaEnrolled &&
              camelCasedData.mfaShow &&
              sessionData.url === "/api/v3/login"
            ) {
              events.push({
                type: SETUP_TWO_FACTOR_AUTH,
                data,
              });
            } else {
              events.push({
                type: USER_LOGGED_IN,
                data: {
                  ...data,
                  loginType: newMetricName,
                  fromConfirmLoginSignup: values.fromConfirmLoginSignup,
                },
              });
            }
          }

          return Observable.from(events);
        })
        .catch((e) => {
          let metricData = {
            job_draft_guid: jobDraftGuid,
          };
          if (values.fromIkeaLegacy) {
            metricData = getIkeaLegacyMetricData(state, {});
          }
          return Observable.of(
            { type: LOGIN_FAILED, payload: e.response },
            // login_error or signup_error
            fireMetric(`${newMetricName}_error`, metricData)
          );
        });
    });

const setCurrentUserDataEpic = (action$) =>
  action$
    .ofType(USER_LOGGED_IN)
    .do(({ data }) => window.app.currentUser.sets(data))
    .ignoreElements();

const segmentTrackDataEpic = (action$) =>
  action$
    .ofType(USER_LOGGED_IN)
    .do(({ data }) => {
      segmentTrackLoginSignup(SEGMENT_USER_LOGGED_IN, data);
    })
    .ignoreElements();

const segmentTrackLoginSignup = async (eventName, data) => {
  const {
    id, email, first_name, last_name, rabbit, admin,
    postal_code, locale, country_iso_code, metro_id,
    metro_name, auto_created, is_first_time_auto_created_user_reset, passwordReset
  } = data;

  const userIdentity = {
    email,
    first_name,
    id,
    is_admin: admin,
    is_tasker: rabbit,
    last_name,
    locale,
    metro: metro_name,
    metro_id: metro_id,
    zipcode: postal_code,
  }

  await SegmentAnalyticsService.trackIdentity({
    properties: { country_iso_code },
    user: userIdentity,
  });

  if (passwordReset && auto_created) {
    await SegmentAnalyticsService.trackEvent({
      name: SEGMENT_USER_RESET_PASSWORD,
      properties: {
        [PropertyKeys.countryIsoCode]: country_iso_code,
        [PropertyKeys.metro]: metro_name,
        [PropertyKeys.metroId]: metro_id,
        [PropertyKeys.marketplace]: "Ikea",
        [PropertyKeys.isAccountAutocreated]: auto_created,
        [PropertyKeys.isFirstTime]: is_first_time_auto_created_user_reset,
      },
    });
  } else {
    await SegmentAnalyticsService.trackEvent({
      name: eventName,
    });
  }
};

const locationChangeEpic = (action$) =>
  action$.ofType(LOCATION_CHANGE).map(
    ({
      payload: {
        location: { search },
      },
    }) => ({
      type: SET_QUERY_PARAMS,
      query: qs.parse(search, { ignoreQueryPrefix: true }),
    })
  );

const afterAuthEpic = (action$, { getState }) =>
  action$.ofType(USER_LOGGED_IN).map(({ data }) => {
    // TODO FIX ME PLEASE
    // eslint-disable-next-line camelcase
    const { base_url } = getState().router;
    // TODO FIX ME PLEASE
    // eslint-disable-next-line camelcase
    const isIkea = base_url === "/ikea";
    if (isIkea) {
      const { pathname, search } = getState().router.location;
      const query = qs.parse(search, { ignoreQueryPrefix: true });
      query.signup_success = true;
      return replace({
        pathname,
        search: qs.stringify(query, { addQueryPrefix: true }),
      });
    }
    // I'm sorry world. Until I implement redux.sagas, I must
    // use epics to fire more that one action. And I'm sorry
    // for using the window.location.
    if (data.redirectToDashboard) {
      window.location = internalPath(
        `/dashboard${data.passwordReset ? "?passwordResetSuccess=true" : ""}`
      );
    }
    return Observable.empty;
  });

export const localSignupSubmit = (data, executeRecaptcha) => {
  return (dispatch) => {
    executeRecaptcha("signup")
      .then((token) => {
        dispatch(localSignup({ ...data, recaptcha_token: token }));
      })
      .catch((ex) =>
        // ReCaptcha will sometimes throw Null, esp. if the domain is not whitelisted for the site key
        notifyBugsnag("RecaptchaFailure", ex || { data: ex })
      );
  };
};

export const localRabbitSignupSubmit = (data, executeRecaptcha) => {
  return (dispatch) => {
    executeRecaptcha("signup")
      .then((token) => {
        dispatch(localRabbitSignup({ ...data, recaptcha_token: token }));
      })
      .catch((ex) =>
        // ReCaptcha will sometimes throw Null, esp. if the domain is not whitelisted for the site key
        notifyBugsnag("RecaptchaFailure", ex || { data: ex })
      );
  };
};

export const loginSignupEpic = combineEpics(
  localLoginSignupEpic,
  setCurrentUserDataEpic,
  segmentTrackDataEpic,
  locationChangeEpic,
  afterAuthEpic
);
