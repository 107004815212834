import { combineReducers, Reducer } from "redux";

import taskLists from "dashboard/redux/modules/taskLists";
import cancelTask from "dashboard/redux/modules/cancelTask";
import taskCounts from "dashboard/redux/modules/taskCounts";
import reviewTasker from "dashboard/redux/modules/reviewTasker";
import reschedule from "dashboard/redux/modules/rescheduleAppointment";
import reportTasker from "dashboard/redux/modules/reportTasker";
import bootstrap from "dashboard/redux/modules/bootstrapDashboard";
import advocateShare from "dashboard/redux/modules/advocateShare";
import eventPoll from "dashboard/redux/modules/eventPolling";
import simpleReschedule from "dashboard/redux/modules/simpleReschedule";

import favoriteTasker from "dashboard/redux/modules/favoriteTasker";
import categoriesAndTemplates from "dashboard/redux/modules/FetchDashboardCategoriesAndTaskTemplates";
import rebookRecommendations from "dashboard/redux/modules/rebookRecommendations";
import repostIkeaTask from "dashboard/redux/modules/repostIkeaTask";
import loadingHasDeclineInvoices from "dashboard/redux/modules/checkDeclineInvoice";

export default combineReducers({
  taskLists,
  cancelTask: cancelTask as Reducer, // TODO: Convert cancelTask to TS
  taskCounts,
  reviewTasker,
  reportTasker,
  favoriteTasker,
  reschedule,
  bootstrap,
  advocateShare,
  eventPoll,
  simpleReschedule,
  categoriesAndTemplates,
  rebookRecommendations: rebookRecommendations as Reducer,
  repostIkeaTask,
  loadingHasDeclineInvoices,
});
