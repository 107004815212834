import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

export const headerNavContentHeight = "36px";

const mobileOnlyStyle = css`
  ${(props) => props.theme.breakpoints.desktopUp} {
    display: none;
  }
`;

const displayAsButtonStyle = css`
  ${(props) => props.theme.breakpoints.desktopUp} {
    border-style: solid;
    border-width: 1px;
    border-color: ${({ theme }) => theme.color.buttonPrimary};
    border-radius: ${(props) => props.theme.layout.borderRadius.md};
    padding-top: ${(props) => props.theme.spacing.sm};
    padding-bottom: ${(props) => props.theme.spacing.sm};
    padding-left: ${(props) => props.theme.spacing.md};
    padding-right: ${(props) => props.theme.spacing.md};
    margin: 0;
    &:hover,
    &:focus,
    &:active {
      color: ${({ theme }) => theme.color.white};
      background-color: ${({ theme }) => theme.color.buttonPrimaryHover};
    }
  }
`;

const mobileHeaderLinkStyle = css`
  cursor: pointer;
  font-weight: ${(props) => props.theme.typography.weight.regular};
  padding-left: 0;
  width: 100%;
  color: ${(props) => props.theme.color.white};
  background-color: ${(props) => props.theme.color.buttonBorderPrimary};
  &:link,
  &:visited,
  &:hover,
  &:focus,
  &:active {
    color: ${(props) => props.theme.color.white};
    text-decoration: none;
  }
  padding-top: ${(props) => props.theme.spacing.sm};
  padding-bottom: ${(props) => props.theme.spacing.sm};
  padding-right: ${(props) => props.theme.spacing.md};
  display: flex;
  align-items: center;
  font-size: ${(props) => props.theme.typography.typeScale["50"]};
  border-bottom: 1px solid ${(props) => props.theme.color.buttonListBorder};
  border-top: none;
  border-left: none;
  border-right: none;
`;

const desktopHeaderLinkStyle = css`
  line-height: ${headerNavContentHeight};
  background-color: ${(props) => props.theme.color.white};
  border: none;
  font-size: ${(props) => props.theme.typography.typeScale["50"]};
  display: inline;
  padding: 0;

  margin-left: ${(props) => props.theme.spacing.md};
  margin-right: ${(props) => props.theme.spacing.md};
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
  font-weight: ${(props) =>
    props.$linkIsSelected
      ? props.theme.typography.weight.bold
      : props.theme.typography.weight.semiBold};
  &:link,
  &:visited {
    color: ${({ theme, $linkIsSelected }) =>
      $linkIsSelected ? theme.color.linkSelected : theme.color.link};
  }
  &:active,
  &:focus,
  &:hover {
    color: ${({ theme, linkIsSelected }) =>
      linkIsSelected ? theme.color.linkSelected : theme.color.linkHover};
  }
`;

const headerLinkStyle = css`
  ${mobileHeaderLinkStyle}

  ${(props) => props.theme.breakpoints.desktopUp} {
    ${desktopHeaderLinkStyle}
    ${({ displayAsButton }) => (displayAsButton ? displayAsButtonStyle : "")};
  }

  ${({ mobileOnly }) => (mobileOnly ? mobileOnlyStyle : "")};

  span {
    color: ${(props) => props.theme.color.black};
  }
`;

export const HeaderNavigationButton = styled.button`
  ${headerLinkStyle}
`;

export const HeaderNavigationA = styled.a`
  ${headerLinkStyle}
`;

export const HeaderNavigationLink = styled(Link)`
  ${headerLinkStyle}
`;

export const HeaderNavigationIcon = styled.i`
  width: ${(props) => props.theme.spacing.xxxl};
  text-align: center;
  ${(props) => props.theme.breakpoints.desktopUp} {
    display: none;
  }
`;
