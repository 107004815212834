import React, { useState, useEffect, useLayoutEffect, useCallback } from "react";
import { Row, Col } from "components/Grid";
import { Link } from "react-router-dom";
import {
  defineMessages,
  FormattedMessage as RIFormattedMessage,
} from "react-intl";
import { FormattedMessage } from "components/FormattedMessage";
import { reduxForm, Field, InjectedFormProps } from "redux-form";
import { connect, useDispatch } from "react-redux";
import { saveProfile } from "account/redux/modules/profile";
import { uploadAvatarPic } from "account/redux/modules/avatar";
import { avatar } from "util/avatar";
import qs from "qs";
import VerifyEmailModal from "account/components/VerifyEmailModal";
import { TRDefaultModal } from "components/Modal/TRDefaultModal";
import profileValidator from "./profileValidator";
import { camelCaseKeys } from "util/casing";
import { V3ReduxState } from "../../../reducers/index";
import { useIntl } from "react-intl";
import { PROTECTED_SCREEN_ORIGIN, PROFILE } from "enums/twoFactorAuth";
import { WithTwoFactorAuth } from "login/components/Login/WithTwoFactorAuth";
import {
  accountOpenVerifyTwoFactorAuth,
  accountOpenActivateTwoFactorAuth,
  retrieveTwoFactorAuth,
  accountSendTwoFactorAuthCode
} from "account/redux/modules/accountSecurity";
import { ProfileEditFormData, ProfileEditProps, FieldProps } from "./types";

import PhoneInput from "./components/PhoneInput";
import { parsePhoneNumber } from "libphonenumber-js";
import { useMountEffect } from "util/useMountEffect";


export const messages = defineMessages({
  first_name: {
    id: "account.profile.edit.first_name",
    defaultMessage: "First Name",
  },
  last_name: {
    id: "account.profile.edit.last_name",
    defaultMessage: "Last Name",
  },
  email: {
    id: "account.profile.edit.email",
    defaultMessage: "Email",
  },
  phone_number: {
    id: "account.profile.edit.mobile_phone",
    defaultMessage: "Phone Number",
  },
  postal_code: {
    id: "account.profile.edit.postal_code",
    defaultMessage: "Zip Code",
  },
  new_photo: {
    id: "account.profile.edit.new_photo",
    defaultMessage: "Upload a new photo",
  },
  business_use: {
    id: "account.edit.intended_use.business",
    defaultMessage: "Business Tasks",
  },
  personal_use: {
    id: "account.edit.intended_use.personal",
    defaultMessage: "Personal Tasks",
  },
  both_use: {
    id: "account.edit.intended_use.both",
    defaultMessage: "Personal and Business Tasks",
  },
  intended_use_label: {
    id: "account.edit.intended_use.label",
    defaultMessage: "I use this account for:",
  },
  error_label: {
    id: "alerts.error_label",
    defaultMessage: "Error",
  },
});

const ProfileEdit = (
  props: ProfileEditProps &
    InjectedFormProps<ProfileEditFormData, ProfileEditProps>
) => {
  const { avatarLoading, change, handleSubmit, submitting } = props;

  const dispatch = useDispatch();
  const intl = useIntl();
  const isRabbit = props.rabbit;

  const showMFA = props.mfa_show;
  const fetched = props.accountSecurity?.fetched;
  const enrolled = props.accountSecurity?.enrolled;
  const loading = props.accountSecurity?.loading;
  const mfaVerified = props.accountSecurity?.mfaCompletedInPastHour;
  const verified = props.accountSecurity?.verified;
  const enrolledPhone = props.accountSecurity?.enrolledPhone;
  
  const [showModal, setShowModal] = useState(false);
  const [verfiedMFA, setverfiedMFA] = useState(false);

  // This needs to run everytime someone clicks the profile tab
  useLayoutEffect(() => {
    if (showMFA) {
      dispatch(retrieveTwoFactorAuth());
    }
  // if we remove the array it will endless run
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useMountEffect(() => { // run once
    try {
      // find country code from phone number with prefix
      const parsedNumber = parsePhoneNumber(props?.profile?.mobile_phone as string);
      change('country_code', parsedNumber.country);
    } catch(e) {
      // do nothing
    }
  });

  // This means we just came back from either a successful signup or
  // verifying the sms code from user who was already enrolled
  useEffect(() => {
    if (showMFA) {
      setverfiedMFA(verified ? true : false);
    }
  }, [verified, showMFA]);


  useEffect(() => {
    if (showMFA) {
      // we need to wait for the retrival of mfa details
      if (fetched && !loading) {
        // we are already verified so show the profile page elements
        setverfiedMFA(mfaVerified ? true : false);
  
        if (!mfaVerified) {
          if (enrolled) {
            dispatch(accountOpenVerifyTwoFactorAuth());
            // Manually needs to be called when they are already enrolled
            dispatch(accountSendTwoFactorAuthCode(enrolledPhone, PROFILE));
  
          } else {
            dispatch(accountOpenActivateTwoFactorAuth());
          }
        }
      }
    }
  }, [dispatch, enrolled, enrolledPhone, fetched, loading, mfaVerified, showMFA]);



  const handleOnChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    // TODO we need to extract this logic into a utility
    if (e.target.files) {
      const file = e.target.files[0];
      if (!file) return;
      const toBase64 = (f: Blob) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(f);
          // comes back as a data url which starts with:
          // "data:image/png;base64,realcontenthere"
          reader.onload = () =>
            resolve(
              reader.result && typeof reader.result === "string"
                ? reader.result.split(",")[1]
                : ""
            );
          reader.onerror = (error) => reject(error);
        });
      dispatch(
        uploadAvatarPic({
          filename: file.name,
          type: file.type,
          size: file.size,
          raw_image: await toBase64(file),
        })
      );
    }
  };

  const handleVerifyEmail = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setShowModal(true);
  };

  const renderInput = (field: FieldProps) => {
    const { formatMessage } = intl;

    const renderErrorMessage = () => {
      if (field.hideNameOnError) {
        return formatMessage({ id: field.meta.error });
      }
      return (
        <>
          <FormattedMessage message={messages[field.input.name]} />{" "}
          {formatMessage({ id: field.meta.error }).toLowerCase()}
        </>
      );
    };

    return (
      <Col
        size={field.cols}
        className={`${
          field.meta.touched && field.meta.error ? "error-container" : ""
        }`}
      >
        <label htmlFor={field.input.name}>
          <FormattedMessage message={messages[field.input.name]} />
          <input
            id={field.input.name}
            className={field.inputClassName}
            type={field.type || "text"}
            aria-describedby={`${field.input.name}-label`}
            autoComplete={field.autocomplete}
            // required for form fields
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...field.input}
          />
        </label>
        {field.meta.touched && field.meta.error && (
          <div id={`${field.input.name}-label`} className="error">
            <i
              className="ss-icon ss-lnr-warning red"
              aria-label={formatMessage(messages.error_label)}
            />
            <span className="visually-hidden">
              <FormattedMessage message={messages.error_label} />
            </span>{" "}
            {renderErrorMessage()}
          </div>
        )}
      </Col>
    );
  };

  const renderInformation = (field: FieldProps) => {
    return (
      <Col size={field.cols}>
        <label htmlFor="disabled-email">
          <FormattedMessage message={messages[field.input.name]} />
          <div className="disabled-email" id="disabled-email">
            {field.input.value}
            &nbsp;
            {/* [MEADOW_TODO] - Button */}
            <button
              type="button"
              className="btn-link"
              onClick={handleVerifyEmail}
            >
              <i className="ss-rkt-notes" />
            </button>
          </div>
        </label>
      </Col>
    );
  };

  const renderRadio = (valueType: "business" | "personal" | "both") => {
    return (
      <label htmlFor={valueType}>
        {/* [MEADOW_TODO] - RadioButton */}
        <Field
          id={valueType}
          name="intended_use"
          component="input"
          type="radio"
          value={valueType}
        />
        <FormattedMessage message={messages[`${valueType}_use`]} />
      </label>
    );
  };

  const renderPhoneInput = useCallback((field: FieldProps) => {
    return (
      <Col size={field.cols}>
        <PhoneInput {...field} />
      </Col>
    );
  }, []);

  const submitSignup = handleSubmit(() => {
    dispatch(saveProfile());
  });

  if (loading) {
    return <span className="page-loader" style={{ display: 'block' }}/>;
  }
  return (
    <div className="account--small-form">
      {(verfiedMFA || !showMFA) &&
              <form onSubmit={submitSignup}>
              <Row guttered={true}>
                <Col size={12} lg={4} className="u-align--center">
                  <div
                    className={
                      "avatar-container-144 " +
                      (avatarLoading ? "avatar-loading" : "")
                    }
                  >
                    <img src={avatar(props)} alt={props.full_name} />
                    <input
                      id="avatar"
                      className="avatar-file-input box-no-padding box-no-margin"
                      type="file"
                      onChange={handleOnChange}
                    />
                  </div>
                  {/* Unfortunately this layout makes it difficult to associate with a the input */}
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label htmlFor="avatar">
                    <FormattedMessage message={messages.new_photo} />
                  </label>
                </Col>
      
                <Col size={12} lg={8}>
                  <Row guttered={true}>
                    <Field name="first_name" cols={6} component={renderInput} />
                    <Field name="last_name" cols={6} component={renderInput} />
                  </Row>
      
                  <Row>
                    {isRabbit && !props.initialValues.email_change_token ? (
                      <Field name="email" cols={12} component={renderInformation} />
                    ) : (
                      <Field
                        name="email"
                        cols={12}
                        component={renderInput}
                        type="email"
                        autoComplete="email"
                      />
                    )}
                  </Row>
                  <Row>
                    <Field
                      name="phone_number"
                      cols={12}
                      hideNameOnError={true}
                      inputClassName="mobilePhoneNumberWithDropdown"
                      component={renderPhoneInput}
                    />
                  </Row>
      
                  <Row>
                    <Field
                      name="postal_code"
                      cols={6}
                      component={renderInput}
                      autoComplete="postal-code"
                    />
                  </Row>
      
                  <div className="intended_use">
                    <Row>
                      <fieldset>
                        <legend>
                          <FormattedMessage message={messages.intended_use_label} />
                        </legend>
                        {renderRadio("personal")}
                        {renderRadio("business")}
                        {renderRadio("both")}
                      </fieldset>
                    </Row>
                  </div>
      
                  <Row>
                    <Col size={12} className="flex-column-mobile">
                      <Link to="/" className="btn">
                        <RIFormattedMessage id="form.cancel" />
                      </Link>
                      {/* [MEADOW_TODO] - Button */}
                      <button
                        type="submit"
                        className={
                          "btn btn-primary " + (submitting ? "is-loading" : "")
                        }
                        onClick={submitSignup}
                        disabled={submitting}
                      >
                        <RIFormattedMessage id="form.cta" />
                      </button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </form>
      }
      {showModal && (
        <TRDefaultModal onDismiss={() => setShowModal(false)}>
          <VerifyEmailModal
            email={props.initialValues.email}
            onDismiss={() => setShowModal(false)}
          />
        </TRDefaultModal>
      )}
      {showMFA &&
        <WithTwoFactorAuth
        accountSecurity={props.accountSecurity}
        profile={props.profile}
        actionName={PROFILE}
        origin={PROTECTED_SCREEN_ORIGIN} portal={undefined} user={undefined} />}
    </div>
  );
};

const mapStateToProps = (
  state: V3ReduxState,
  props: {
    location: { search: string },
    account: {
      profile: Record<string, unknown>,
      accountSecurity: Record<string, unknown>
    }
  }
) => {
  const query =
    props.location.search !== ""
      ? qs.parse(props.location.search.substring(1))
      : {};

  const { phoneCountryCode } = camelCaseKeys(state.account.profile.data) || {};
  return {
    initialValues: {
      ...state.account.profile.data,
      ...query,
      phone_country_code: phoneCountryCode,
    },
    accountSecurity: state.account.accountSecurity,
    profile: state.account.profile.data,
  };
};

export default connect(mapStateToProps)(
  reduxForm<ProfileEditFormData, ProfileEditProps>({
    form: "profile",
    validate: profileValidator,
  })(ProfileEdit)
);