import moment from "moment-timezone";
import { isEnvironmentServerSide } from "../shared/utils/is-environment-server-side";

const momentWithTimeZone = buildMomentWithTimeZone();
export default momentWithTimeZone;

/**
 * When rendering server-side, we do not have access to window. That means we
 * cannot use it to determine the user's time zone or locale. In that case, we
 * return null. By returning null, we ensure that any code that attempts to use
 * momentWithTZ server-side will throw an error. This error should signal to
 * the developer that they should not be using momentWithTZ in a server-side
 * render. One solution is to move any code that calls momentWithTZ into a
 * useEffect hook. That ensures the code is not run on first render.
 */
function buildMomentWithTimeZone() {
  if (isEnvironmentServerSide()) {
    return null;
  }
  moment.tz.setDefault(window.TIME_ZONE);
  moment.locale(window.LOCALE);
  return moment;
}
