import React, { ComponentProps, ComponentType } from "react";
import {
  FormattedMessage as RIFormattedMessage,
  FormattedHTMLMessage as RIFormattedHTMLMessage,
  MessageDescriptor,
} from "react-intl";

export type Message = MessageDescriptor;

export interface MessageProps
  extends Omit<ComponentProps<typeof RIFormattedMessage>, keyof Message> {
  message: Message;
}

const createMessageComponent =
  (isHTMLMessage: boolean): ComponentType<MessageProps> =>
  ({ message, ...props }) => {
    if (!message) {
      return null;
    }

    if (!message.id) {
      throw new Error("Message prop must have an ID");
    }

    if (isHTMLMessage && process.env.NODE_ENV !== "production") {
      // eslint-disable-next-line no-console
      console.warn(
        "FormattedHtmlMessage is going away in the next major version of react-intl, use rich text formatting instead:",
        "https://formatjs.io/docs/react-intl/upgrade-guide-3x/#enhanced-formattedmessage--formatmessage-rich-text-formatting"
      );
    }

    const Message = isHTMLMessage ? RIFormattedHTMLMessage : RIFormattedMessage;

    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Message {...message} {...props} />;
  };

export const FormattedMessage = createMessageComponent(false);
FormattedMessage.displayName = "FormattedMessage";

/**
 * @deprecated FormattedHtmlMessage is going away in the next major version of react-intl, use
 * [rich text formatting](https://formatjs.io/docs/react-intl/upgrade-guide-3x/#enhanced-formattedmessage--formatmessage-rich-text-formatting)
 * instead.
 */
export const FormattedHTMLMessage = createMessageComponent(true);
FormattedHTMLMessage.displayName = "FormattedHTMLMessage";

