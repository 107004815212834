import { getCookie } from "util/cookie";
import { TrMetricsService } from "services/TrMetricsService";

export function generateFunnelId(
  uid = getCookie("uid") || "unknown-guid",
  funnelSource?: string,
  category_id?: string,
) {
  const funnelId = `${uid}~${new Date().getTime().toString()}`.replace(
    /[~.]/g,
    "_"
  );
  TrMetricsService.fire("funnel_created", {
    category_id,
    funnel_id: funnelId,
    funnel_source: funnelSource,
  });
  return funnelId;
}
