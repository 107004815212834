import * as API from "account/routes/apiRoutes";
import { push } from "connected-react-router";
import xhr, { createApiErrorAlert } from "util/xhr";
import { createAlert } from "alerts/redux/modules/alerts";
import { defineMessages } from "react-intl";
import { internalPath } from "util/internalPath";

const messages = defineMessages({
  rabbit_profile_updated: {
    id: "alerts.rabbit_profile_changed",
    defaultMessage: "Account successfully updated.",
  },
});

const SAVE_RABBIT_PROFILE = "SAVE_RABBIT_PROFILE";
const UPDATE_RABBIT_PROFILE_REQUEST = "UPDATE_RABBIT_PROFILE_REQUEST";
const UPDATE_RABBIT_PROFILE_RESPONSE = "UPDATE_RABBIT_PROFILE_RESPONSE";
const UPDATE_RABBIT_PROFILE_ERROR = "UPDATE_RABBIT_PROFILE_ERROR";
const LOAD_RABBIT_PROFILE_REQUEST = "LOAD_RABBIT_PROFILE_REQUEST";
const LOAD_RABBIT_PROFILE_RESPONSE = "LOAD_RABBIT_PROFILE_RESPONSE";
const LOAD_RABBIT_PROFILE_ERROR = "LOAD_RABBIT_PROFILE_ERROR";

function requestSaveRabbitProfile() {
  return { type: UPDATE_RABBIT_PROFILE_REQUEST };
}

function responseSaveRabbitProfile(rabbitProfile) {
  return { type: UPDATE_RABBIT_PROFILE_RESPONSE, rabbitProfile };
}

function errorSaveRabbitProfile() {
  return { type: UPDATE_RABBIT_PROFILE_ERROR };
}

function requestLoadRabbitProfile() {
  return { type: LOAD_RABBIT_PROFILE_REQUEST };
}

function responseLoadRabbitProfile(rabbitProfile) {
  return { type: LOAD_RABBIT_PROFILE_RESPONSE, rabbitProfile };
}

function errorLoadRabbitProfile(rabbitProfile) {
  return { type: LOAD_RABBIT_PROFILE_ERROR, rabbitProfile };
}

function saveRabbitProfileForm({ formName, message, redirect } = {}) {
  return (dispatch, getState) => {
    dispatch(requestSaveRabbitProfile());
    const state = getState();
    const rabbitProfile = state.form[formName].values;
    let rabbitProfileData;

    const {
      birthdate_month,
      birthdate_day,
      birthdate_year,
      address,
      locality,
      region,
      postal_code,
      unit_number,
      house_number,
      house_name,
      custom_slug,
    } = rabbitProfile;

    rabbitProfileData = {
      house_name,
      unit_number,
      house_number,
      address,
      locality,
      region,
      postal_code,
      custom_slug,
    };

    if (birthdate_month && birthdate_day && birthdate_year) {
      // month in JS is zero-indexed so we add 1 to get correct value.
      const bday_month = parseInt(birthdate_month, 10) + 1;

      rabbitProfileData = {
        ...rabbitProfileData,
        "birthdate(1i)": String(birthdate_year),
        "birthdate(2i)": String(bday_month),
        "birthdate(3i)": String(birthdate_day),
      };
    }

    return xhr
      .put(API.RABBIT_PROFILE, JSON.stringify(rabbitProfileData))
      .then(response => {
        dispatch(responseSaveRabbitProfile(response.data));
        dispatch(createAlert(message.id));
        dispatch(push(internalPath("/tasker-info")));
      })
      .catch(e => {
        dispatch(createApiErrorAlert(e));
        dispatch(errorSaveRabbitProfile());
      });
  };
}

export function saveRabbitProfile() {
  return saveRabbitProfileForm({
    formName: "rabbitProfile",
    message: messages.rabbit_profile_updated,
  });
}

export function loadRabbitProfile() {
  return (dispatch, getState) => {
    dispatch(requestLoadRabbitProfile());
    return xhr.get(API.RABBIT_PROFILE).then(
      response => {
        dispatch(responseLoadRabbitProfile(response.data));
      },
      e => {
        dispatch(createApiErrorAlert(e));
        dispatch(errorLoadRabbitProfile());
      }
    );
  };
}

const initialState = {
  data: undefined,
  error: undefined,
  isLoading: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SAVE_RABBIT_PROFILE:
      return state;
    case LOAD_RABBIT_PROFILE_REQUEST:
      return { ...state, isLoading: true };
    case LOAD_RABBIT_PROFILE_RESPONSE:
      return {

        ...state, isLoading: false, data: action.rabbitProfile
      };
    case LOAD_RABBIT_PROFILE_ERROR:
      return { ...state, isLoading: false };
    case UPDATE_RABBIT_PROFILE_REQUEST:
      return { ...state, isLoading: true };
    case UPDATE_RABBIT_PROFILE_RESPONSE:
      return {

        ...state, isLoading: false, data: action.rabbitProfile
      };
    case UPDATE_RABBIT_PROFILE_ERROR:
      return { ...state, isLoading: false };
    default:
      return state;
  }
}
