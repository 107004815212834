import { FETCH_RECOS_RESPONSE } from "./recommendations";

const initialState = { eligibleForThreeDayExperiment: false };
export default function threeDayRecommendationExperiment(
  state = initialState,
  action
) {
  let newState;
  switch (action.type) {
    case FETCH_RECOS_RESPONSE:
      newState = {
        ...state,
        eligibleForThreeDayExperiment: action.data.eligibleForThreeDayExperiment,
      };
      return newState;
    default:
      return state;
  }
}
