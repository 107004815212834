import React from "react";
import { defineMessages } from "react-intl";
import { localeConfig } from "../../../config/localeDriven";
import { FooterLink } from "./FooterLink";

const messages = defineMessages({
  becomeTasker: {
    id: "components.Footer.StyledFooter.DiscoverLinks.becomeTasker",
    defaultMessage: "Become a Tasker",
  },
  locations: {
    id: "components.Footer.StyledFooter.DiscoverLinks.locations",
    defaultMessage: "Services By City",
  },
  servicesNearby: {
    id: "components.Footer.StyledFooter.DiscoverLinks.servicesNearby",
    defaultMessage: "Services Nearby",
  },
  allServices: {
    id: "components.Footer.StyledFooter.DiscoverLinks.allServices",
    defaultMessage: "All Services",
  },
  elite: {
    id: "components.Footer.StyledFooter.DiscoverLinks.elite",
    defaultMessage: "Elite Taskers",
  },
  giftCard: {
    id: "components.Footer.StyledFooter.DiscoverLinks.giftcard",
    defaultMessage: "Buy a Gift Card",
  },
  help: {
    id: "components.Footer.StyledFooter.DiscoverLinks.help",
    defaultMessage: "Help",
  },
});

export function DiscoverLinks({ locale }) {
  const becomeATaskerLink = localeConfig(
    locale,
    "localeRoutes.becomeATasker.url"
  );
  const supportUrl = localeConfig(locale, "support.url");
  return (
    <>
      <div>
        <FooterLink path={becomeATaskerLink} message={messages.becomeTasker} />
      </div>
      <div>
        <FooterLink path="/locations" message={messages.locations} />
      </div>
      {locale === "en-US" && (
         <div>
          <FooterLink path="/near-me" message={messages.servicesNearby} />
         </div>
        )  
      }
      <div>
        <FooterLink path="/services" message={messages.allServices} />
      </div>
      <div>
        <FooterLink path="/taskrabbit-elite" message={messages.elite} />
      </div>
      {/* UNSHIP incident omega 0001 gift cards */}
      {/*{countryConfigFromLocale(locale, "giftCard.enabled") && (*/}
      {/*  <div>*/}
      {/*    <FooterLink path="/account/gift-cards" message={messages.giftCard} />*/}
      {/*  </div>*/}
      {/*)}*/}
      <div>
        <FooterLink
          path={supportUrl}
          message={messages.help}
          target="_blank"
          rel="noopener"
        />
      </div>
    </>
  );
}
