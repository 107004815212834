import React, { useCallback, useState, useEffect, useRef } from "react";
import styled from "styled-components";

const AUTO_MAX_HEIGHT = 9999;

export function MobileLinksContainer({ children, mobileExpand }) {
  const mobileLinksContainerRef = useRef(null);
  const [maxHeightPixels, setMaxHeightPixels] = useState(
    mobileExpand ? AUTO_MAX_HEIGHT : 0
  );

  const startCollapsing = useCallback(() => {
    const currentContainerHeight = mobileLinksContainerRef.current.scrollHeight;
    setMaxHeightPixels(currentContainerHeight);
    /*
      We have to wait for the above setState call to update the max-height
      value to something that's numeric before we can set it to the final value
      of 0. If we go straight from "auto" to "0", then we won't get any CSS
      animation.
    */
    setTimeout(() => {
      setMaxHeightPixels(0);
    });
  }, []);

  const startExpanding = useCallback(() => {
    const expandingElement = mobileLinksContainerRef.current;
    setMaxHeightPixels(expandingElement.scrollHeight);
    const setAutoMaxHeight = () => setMaxHeightPixels(AUTO_MAX_HEIGHT);
    expandingElement.addEventListener("transitionend", setAutoMaxHeight);
    return () => {
      expandingElement.removeEventListener("transitionend", setAutoMaxHeight);
    };
  }, []);

  useEffect(() => {
    const shouldStartCollapsing = !mobileExpand && maxHeightPixels > 0;
    const shouldStartExpanding =
      mobileExpand && maxHeightPixels < AUTO_MAX_HEIGHT;
    if (shouldStartCollapsing) {
      startCollapsing();
    } else if (shouldStartExpanding) {
      return startExpanding();
    }

    return () => {};
  }, [maxHeightPixels, mobileExpand, startCollapsing, startExpanding]);

  const maxHeightStyle =
    maxHeightPixels >= AUTO_MAX_HEIGHT ? "auto" : `${maxHeightPixels}px`;

  return (
    <StyledMobileLinksContainer
      mobileExpand={mobileExpand}
      ref={mobileLinksContainerRef}
      maxHeightStyle={maxHeightStyle}
    >
      {children}
    </StyledMobileLinksContainer>
  );
}

const StyledMobileLinksContainer = styled.div`
  display: block;
  ${(props) => props.theme.breakpoints.desktopUp} {
    display: none;
  }

  transition: height 0.3s ease-in, max-height 0.3s ease-in;
  max-height: ${(props) => props.maxHeightStyle};
  overflow: hidden;
`;
