/* This is a very close copy from p-wait-for in npm.  They did not babel
their code so it broke ours because we don't babel node_modules
https://www.npmjs.com/package/p-wait-for
The module is used by passing in a function that returns a boolean
and a set of options. pWaitFor will execute the condition repeatedly
as per the options until it returns true or times out.
options
interval: number, default 20. milliseconds between tries
timeout: number, default infinity. milliseconds until timeout
(async () => {
  await pWaitFor(() => pathExists('unicorn.png'));
  console.log('Yay! The file now exists.');
})();
*/

import pTimeout from "./p-timeout";

export const TIMEOUT_ERROR = "TimeoutError";

const pWaitFor = async (condition, options = {}) => {
  options = {
    interval: 20,
    timeout: Infinity,
    ...options,
  };

  let retryTimeout;

  const promise = new Promise((resolve, reject) => {
    const check = async () => {
      try {
        const value = await condition();
        if (value) {
          resolve();
        } else {
          retryTimeout = setTimeout(check, options.interval);
        }
      } catch (error) {
        reject(error);
      }
    };

    check();
  });

  if (options.timeout !== Infinity) {
    try {
      return await pTimeout(promise, options.timeout);
    } catch (error) {
      if (retryTimeout) {
        clearTimeout(retryTimeout);
      }

      throw error;
    }
  }

  return promise;
};

export default pWaitFor;
