import {
  connectRouter,
  LOCATION_CHANGE,
  RouterState,
} from "connected-react-router";
import { History } from "history";
import { AnyAction } from "redux";

interface ExtendedRouterState extends RouterState {
  base_url: string;
}

const extendedRouterReducer = (
  state: ExtendedRouterState,
  action: AnyAction
): ExtendedRouterState => {
  switch (action.type) {
    case LOCATION_CHANGE: {
      // @@router/LOCATION_CHANGE is called whenever a route is changed
      // Rails handles base level routing while react router redux handles nested

      // Basepath takes the full pathname to add a helper for determining where the base route is originating from.

      const endingPathname = new RegExp(`${action.payload.location.pathname}$`);
      let basepath = window.location.pathname.replace(endingPathname, "");

      // Safety to ensures basepath starts with "/" ex; /dashboard
      basepath = basepath[0] !== "/" ? `/${basepath}` : basepath;

      return { ...state, base_url: basepath };
    }
    default:
      return state;
  }
};

export default function createCombinedRouterReducer(history: History) {
  const routerReducer = connectRouter(history);

  return (state: RouterState | undefined, action: AnyAction) => {
    const currentState = routerReducer(state, action);
    return extendedRouterReducer(currentState as ExtendedRouterState, action);
  };
}
