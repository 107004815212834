/* eslint-disable camelcase */
/* eslint-disable no-shadow */
import xhr from "util/xhr";
import isEmpty from "lodash/isEmpty";
import {
  createValidator,
  chosen,
  required,
  email,
  location as locationValidation,
} from "util/validation";
import { VERIFY_ACTIVE_METRO } from "build/routes/apiRoutes";
import { parseGooglePlaceData } from "containers/LocationAutocomplete/enhanceLocationAutocomplete";
import { TrMetricsService } from "services/TrMetricsService";
import {
  ALLOW_QUOTE_FOR_DISABLED_METRO,
  IKEA_CATEGORIES,
  FURNITURE_ASSEMBLY_CATEGORY,
} from "util/constants";

export const inServiceArea = (location, isQuote) =>
  location.in_service_area || (isQuote && ALLOW_QUOTE_FOR_DISABLED_METRO);

// For the IKEA quote flow, the user submits a zip code, which returns an APPROXIMATE result
// from the Google geocoder. This is the only case in which we want to allow the APPROXIMATE
// result to be used.
const findValidResult = (results, acceptApproximateResult = false) => {
  if (acceptApproximateResult) {
    return results.find(
      (result) => result.geometry.location_type === "APPROXIMATE"
    );
  }

  return results.find(
    (result) => result.geometry.location_type !== "APPROXIMATE"
  );
};

const validateActiveMetro = async (location, job, isQuote = false) => {
  const data = {
    lat: location.lat,
    lng: location.lng,
    address: location.freeform,
    funnel_id: job.funnel_id,
    category_id: job.category_id,
    task_template_id: job.task_template_id,
    postal_code: location.postal_code,
    is_quote: isQuote,
    source_form:
      job.taskTemplate && job.taskTemplate.is_ikea ? "ikea" : "react",
    country_iso_code: location.locale?.slice(3),
  };

  const response = await xhr.post(VERIFY_ACTIVE_METRO, data);

  return {
    ...location,
    ...response.data,
  };
};

export const updateActiveMetro = async (location, job) => {
  const data = {
    lat: location.lat,
    lng: location.lng,
    address: location.freeform,
    funnel_id: job.funnel_id,
    category_id: IKEA_CATEGORIES[0],
    task_template_id: job.task_template_id,
    postal_code: location.postal_code,
    source_form:
      job.taskTemplate && job.taskTemplate.is_ikea ? "ikea" : "react",
    country_iso_code: location.locale?.slice(3),
  };

  const response = await xhr.post(VERIFY_ACTIVE_METRO, data);

  return {
    ...location,
    ...response.data,
  };
};

export const geocodeLocation = (
  location,
  googleOptions,
  acceptApproximateResult = false
) => {
  return new Promise((resolve, reject) => {
    const errorMessage = "form.validation.incomplete_address";

    if (!location || !(window.google && window.google.maps)) {
      reject(errorMessage);
      return;
    }

    if (googleOptions.componentRestrictions == undefined) {
      googleOptions.componentRestrictions = {};
    }
    googleOptions.componentRestrictions.country = window.LOCALE.split("-")[1];

    const geocoder = new window.google.maps.Geocoder();
    let options = { address: location.freeform, ...googleOptions };

    geocoder.geocode(options, (results, status) => {
      const validResult =
        status === "OK" && findValidResult(results, acceptApproximateResult);

      const result = validResult
        ? { ...parseGooglePlaceData(validResult) }
        : { error: errorMessage };

      const { freeform, address2 } = location; // preserve freeform & address2
      resolve({ ...result, freeform, address2 });
    });
  });
};

const validateIkeaAvailable = async (location, job, isQuote = false) => {
  const { category_id } = job;

  if (category_id === FURNITURE_ASSEMBLY_CATEGORY) {
    try {
      const { assembly_minimum_price_cents, category_supported } =
        await validateActiveMetro(
          location,
          {
            category_id: IKEA_CATEGORIES[0],
          },
          isQuote
        );

      location.ikea_supported = category_supported;
      location.assembly_minimum_price_cents = assembly_minimum_price_cents;
    } catch {
      // Do nothing
    }
  }

  return location;
};

const setLocationErrors = (location, isQuote) => {
  if (location.error) {
    return;
  }

  if (!inServiceArea(location, isQuote)) {
    location.error = "form.validation.inactive_metro";
  } else if (!location.in_correct_locale) {
    location.error = "form.validation.wrong_locale";
  } else if (!location.category_supported) {
    location.error = "form.validation.category_not_supported";
  }

  if (location.error) {
    reportErrorsForAnalytics(location);
  }
};

const reportErrorsForAnalytics = (location) => {
  TrMetricsService.fire("Error_YourTaskLocation_InputError", {
    user_input: location.freeform,
    zip_code_from_google: location.postal_code,
    country_iso_code: window.LOCALE.split("-")[1],
    page_url: document.location.href,
    place_id: location.place_id,
    error_key: location.error,
  });
};

export const validateLocation = ({
  field = { required: true, address_extra: {} },
  location = {},
  job = {},
  googleOptions = {},
  isQuote = false,
}) => {
  const {
    field_name,
    address_extra: { virtual_enabled },
  } = field;
  /* eslint-disable @typescript-eslint/no-shadow */
  return new Promise((resolve) => {
    if (!!virtual_enabled && location.virtual) {
      return resolve(location);
    }

    // if no freeform, try use `address/formatted_address` instead (may exist from job drafts data)
    if (location && !location.freeform) {
      location.freeform = location.address || location.formatted_address;
    }

    // at minimum, freeform is needed, so we can try to geocode that string
    if (
      (isEmpty(location) ||
        !location.freeform ||
        !location.freeform.replace(/^\s+|\s+$/g, "")) &&
      required
    ) {
      location.error = "form.validation.location_required";
      return resolve(location);
    }
    // if lat/lng already present, skip geocode
    return (
      (
        location.lat && location.lng
          ? Promise.resolve(location)
          : geocodeLocation(location, googleOptions, isQuote)
      )
        // once we have lat/lng, ensure we're in a supported metro and category, etc.
        .then((location) =>
          location.error
            ? location
            : validateActiveMetro(location, job, isQuote)
        )
        .then((location) => validateIkeaAvailable(location, job, isQuote))
        .then((location) => {
          setLocationErrors(location, isQuote);
          resolve(location);
        })
        .catch((e) => {
          resolve({ ...location, error: e });
        })
    );
  }).then((location) => ({ field_name, value: location }));
  /* eslint-enable @typescript-eslint/no-shadow */
};

const requiredValidator = (field_type) =>
  ["radio", "datetime_windows", "ikea_typeahead"].includes(field_type)
    ? chosen
    : required;

// what validators to use if API says field is "required"
const formatValidators = {
  email,
  address: locationValidation,
};

const validateAddressFields = (addressFields, values, job) => {
  return Promise.all(
    addressFields.map((field) => {
      const { field_name } = field;
      const value = values[field_name] || {};
      return validateLocation({ field, location: value, job });
    })
  ).then((validatedAddressArray) => {
    return validatedAddressArray.reduce((validatedValues, item) => {
      validatedValues[item.field_name] = item.value;
      return validatedValues;
    }, {});
  });
};

export default function validateFields(fields, values, job) {
  const addressFields = fields.filter(
    (field) => field.field_type === "address"
  );
  return validateAddressFields(addressFields, values, job).then(
    (addressValues) => {
      // update values with location info from geocoding
      const updatedValues = { ...values, ...addressValues };
      // build validator for this groups fields
      const validatorObj = fields
        .filter(({ hidden }) => !hidden)
        // eslint-disable-next-line @typescript-eslint/no-shadow
        .reduce((validator, { field_type, field_name, required }) => {
          validator[field_name] = [];

          if (required)
            validator[field_name].push(requiredValidator(field_type));
          if (formatValidators[field_type])
            validator[field_name].push(formatValidators[field_type]);

          return validator;
        }, {});
      const validatorFn = createValidator(validatorObj);

      return {
        values: updatedValues,
        errors: validatorFn(updatedValues),
      };
    }
  );
}
