import xhr from "util/xhr";
import { notifyBugsnag } from "util/bugsnag";
import qs from "qs";
import {
  DASHBOARD_SMART_SEARCH_V2,
  CREATE_JOB_DRAFT,
  LOG_SEARCH_NAVIGATION,
  POSTAL_CODE_SEARCH,
} from "dashboard/routes/apiRoutes";
import { snake_caseKeys as snakeCaseKeys } from "util/casing";
import { sessionStorageGet } from "../shared/utils/sessionStorage";

export const ProductionNetworkService = {
  fetchTaskerSuggestions() {
    const sessionId = sessionStorageGet("tr_sess_id");
    const url = `/api/v3/web-client/dashboard/tasker_suggestions?session_id=${sessionId}`;
    return xhr.get(url).catch((error) => {
      notifyBugsnag("TaskerSuggestionsError", error.response);
      return error.response;
    });
  },
  fetchTaskerReviews(rabbitId, categoryId, page, perPage) {
    const data = {
      with_message: true,
      per_page: perPage || 5,
      page,
    };
    if (categoryId === "all") {
      data.default_all = true;
    } else {
      data.category_id = categoryId;
    }

    const url = `/api/v3/rabbits/${rabbitId}/reviews?${qs.stringify(data)}`;

    return xhr.get(url);
  },
  fetchTaskerProfile(profileSlug) {
    const url = `/api/v3/web-client/profile/${profileSlug}.json`;
    return xhr.get(url);
  },
  fetchCategoryTaskers({ categoryId, locale, perPage = 12 }) {
    const data = {
      per_page: perPage,
      category_id: categoryId,
      locale,
    };
    const url = `/api/v3/web-client/marketing/category_taskers?${qs.stringify(
      data
    )}`;

    return xhr.get(url).catch((error) => {
      notifyBugsnag("SmartSearchV2RequestError", error.response);
      return error.response;
    });
  },
  fetchSmartSearchV2Results({ searchString }) {
    const sessionId = sessionStorageGet("tr_sess_id");
    const data = qs.stringify({
      text: searchString,
      session_id: sessionId,
    });
    const baseUrl = DASHBOARD_SMART_SEARCH_V2;
    const url = `${baseUrl}?${data}`;
    return xhr.get(url).catch((error) => {
      notifyBugsnag("SmartSearchV2RequestError", error.response);
      return error.response;
    });
  },
  fetchDefaultResults() {
    const url = "/search/default_search_results";
    return xhr
      .get(url)
      .catch((error) => {
        notifyBugsnag("SmartSearchV2RequestError", error.response);
        return error.response;
      })
      .then((results) => {
        return results.data;
      });
  },
  fetchIkeaAttributes() {
    const url = "/search/ikea_attributes";
    return xhr
      .get(url)
      .catch((error) => {
        notifyBugsnag("IkeaAttributesRequestError", error.response);
        return error.response;
      })
      .then((results) => {
        return results.data;
      });
  },

  createDraft({
    attributionTag,
    categoryId,
    taskTemplateId,
    form,
    version,
    sessionId,
    funnelId,
  }) {
    const data = {
      attributionTag,
      categoryId,
      taskTemplateId,
      form,
      version,
      sessionId,
      funnelId,
    };

    return xhr.post(CREATE_JOB_DRAFT, snakeCaseKeys(data)).catch((error) => {
      notifyBugsnag("CreateJobDraftError", error.response);

      return error.response;
    });
  },

  logSearchNavigation({
    searchTerm,
    categoryId,
    taskTemplateId,
    form,
    version,
    jobDraftId,
    queryResponse,
    sessionId,
    eventType,
  }) {
    const data = {
      searchTerm,
      categoryId,
      taskTemplateId,
      form,
      version,
      sessionId,
      jobDraftId,
      queryResponse,
      eventType,
    };
    return xhr
      .post(LOG_SEARCH_NAVIGATION, snakeCaseKeys(data))
      .catch((error) => {
        notifyBugsnag("LOG_SEARCH_NAVIGATION_RESULTS_ERROR", error.response);
        return error.response;
      });
  },
  fetchLocationResult({ postalCode }) {
    const sessionId = sessionStorageGet("tr_sess_id");
    const data = {
      postal_code: postalCode,
      session_id: sessionId,
    };
    return xhr.post(POSTAL_CODE_SEARCH, data).catch((error) => {
      notifyBugsnag("POSTAL_CODE_SEARCH_RESULTS_ERROR", error.response);
      return error.response;
    });
  },
};
