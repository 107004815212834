// TODO: Rename to singular CamelCase
export enum featureFlags {
  bookingAutoInviteLite = "booking_exp-2024-04-23-auto_invite_lite_web_native",
  growthDigiohFeatureFlag = "growth-2021-06-29-digioh_web",
  growthDownloadSMSFeatureFlag = "growth-2020-10-29-styled_components_download_sms",
  growthExampleFeatureFlag = "growth-2020-03-02-example_feature_flag",
  ikeaConvergenceFlag = "ikea-2020-07-23-convergence",
  ikeaClientGuaranteedPricingFlag = "ikea-2021-01-05-client_guaranteed_pricing",
  growthRecurringCleaningOptimizationFlag = "growth-2020-09-16-recurring_cleaning_optimization",
  clientNewAddressSearchBarFlag = "client-2020-09-24-new_address_search_bar",
  clientNewTaskerProfilePageFlag = "client-2020-09-15-new_tasker_profile_page",
  growthHistogramPricingFilter = "growth-2020-11-23-histogram_pricing_filter",
  toolsOneHourPrebookingPreauth = "tools-2020-03-12-one_hour_prebooking_preauthorization",
  clientOneHourPreauth = "tools-2020-04-12-client_one_hour_prebooking_preauthorization",
  ikeaSearchByArticleNumberFlag = "ikea-2022-06-28-search_by_article_number",
  ikeaSuppressPrepaidExpense = "ikea-2022-11-14-suppress_prepaid_expense",
  searchDropdownUpdateCompleteAddressFlag = "ikea-2023-07-24-autocomplete_address_fix",
  bookingExpNextDashboard = "booking_exp-2024-06-11-next_dashboard",
}
