import { FETCH_PROMOTION_CODE } from "friend/routes/apiRoutes";

const FETCH_PROMOTION = "v3/referrals/landingPage/FETCH_PROMOTION";
const FETCH_PROMOTION_RESPONSE =
  "v3/referrals/landingPage/FETCH_PROMOTION_RESPONSE";
const FETCH_PROMOTION_ERROR = "v3/referrals/landingPage/FETCH_PROMOTION_ERROR";

export const fetchPromotion = data => {
  // data = {code_data, referral_version}
  return (dispatch, getState) => {
    return dispatch({
      types: [FETCH_PROMOTION, FETCH_PROMOTION_RESPONSE, FETCH_PROMOTION_ERROR],
      promise: xhr => {
        return xhr
          .post(FETCH_PROMOTION_CODE, data)
          .then(response => response.data);
      },
    });
  };
};

const processFetchPromotionResponse = (state, action) => {
  const { result } = action;
  return {
    ...state,
    promotion_code: result.code,
    formatted_value: result.formatted_value,
    loading: false,
  };
};

export default function reducer(state = {}, action) {
  switch (action.type) {
    case FETCH_PROMOTION:
      return {
        ...state,
        loading: true,
        error: "",
      };

    case FETCH_PROMOTION_RESPONSE:
      return processFetchPromotionResponse(state, action);

    case FETCH_PROMOTION_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error.response.data.errors[0].message,
      };

    default:
      return state;
  }
}
