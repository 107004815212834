import React from "react";
import styled from "styled-components";
import {
  HeaderNavigationA,
  HeaderNavigationIcon,
  HeaderNavigationLink,
} from "../header-constants";

export function HeaderReferralLink({
  isInsideReactRouter,
  innerRef,
  loggedIn,
  referralData,
  routing,
}) {
  if (!loggedIn || !referralData?.referral_cta || !referralData.client_referral_credits_enabled) {
    return null;
  }

  const showDesktopReferralIcon = referralData.show_icon;
  const linkIsSelected = routing.location.pathname === "/referral";

  if (isInsideReactRouter) {
    return (
      <HeaderNavigationLink
        to="/referral?source=dashboard_advocate"
        $linkIsSelected={linkIsSelected}
        ref={innerRef}
      >
        <ReferralIcon
          showDesktopReferralIcon={showDesktopReferralIcon}
          className="ss-lnr-gift"
        />
        <span>{referralData.referral_cta}</span>
      </HeaderNavigationLink>
    );
  }

  return (
    <HeaderNavigationA
      href="/dashboard/referral?source=dashboard_advocate"
      $linkIsSelected={linkIsSelected}
      ref={innerRef}
    >
      <ReferralIcon
        showDesktopReferralIcon={showDesktopReferralIcon}
        className="ss-lnr-gift"
      />
      <span> {referralData.referral_cta}</span>
    </HeaderNavigationA>
  );
}

const ReferralIcon = styled(HeaderNavigationIcon)`
  ${(props) => props.theme.breakpoints.desktopUp} {
    display: ${(props) => (props.showDesktopReferralIcon ? "inline" : "none")};
    margin-right: ${(props) => props.theme.spacing.xs};
  }
`;
