import xhr, { createApiErrorAlert } from "util/xhr";

import {
  FETCH_FAVORITE_TASKER_LIST,
  FETCH_PAST_TASKER_LIST,
  UPDATE_FAVORITE_TASKER_RELATIONSHIP,
} from "dashboard/routes/apiRoutes";

const FETCH_FAVORITE_TASKER_RESPONSE =
  "dashboard/favorite_tasker_list/FETCH_FAVORITE_TASKER_RESPONSE";

const LOAD_FAVORITE_TASKER_RESPONSE =
  "dashboard/favorite_tasker_list/LOAD_FAVORITE_TASKER_RESPONSE";

const LOAD_PAST_TASKER_RESPONSE =
  "dashboard/favorite_tasker_list/LOAD_PAST_TASKER_RESPONSE";

const REMOVE_FAVORITE_TASKER_FROM_LIST =
  "dashboard/favorite_tasker_list/REMOVE_FAVORITE_TASKER_FROM_LIST";

const ADD_FAVORITE_TASKER_TO_LIST =
  "dashboard/favorite_tasker_list/ADD_FAVORITE_TASKER_TO_LIST";

const ADD_FAVORITE_TASKER_FROM_PAST =
  "dashboard/favorite_tasker_list/ADD_FAVORITE_TASKER_FROM_PAST";

const defaultState = {
  favoriteTaskerTeam: [],
  pastTaskers: [],
  loading: false,
};
export default function favoriteTasker(state = defaultState, action) {
  switch (action.type) {
    case FETCH_FAVORITE_TASKER_RESPONSE:
      return { ...state, loading: true };
    case LOAD_FAVORITE_TASKER_RESPONSE:
      const { favoriteTaskerTeam } = action.favoriteTaskerResponse;
      return { ...state, favoriteTaskerTeam, loading: false };
    case LOAD_PAST_TASKER_RESPONSE:
      const { pastTaskers } = action.pastTaskerResponse;
      return { ...state, pastTaskers, loading: false };
    case ADD_FAVORITE_TASKER_FROM_PAST: {
      const currentFavorites = state.favoriteTaskerTeam;

      const updatedPastTasker = state.pastTaskers.map(tasker => {
        if (tasker.rabbit_id === action.rabbit.rabbit_id) {
          tasker.is_favorite = true;
        }

        return tasker;
      });

      return {
        ...state,
        favoriteTaskerTeam: [...currentFavorites, action.rabbit],
        pastTaskers: updatedPastTasker,
      };
    }
    case REMOVE_FAVORITE_TASKER_FROM_LIST: {
      const currentFavorites = state.favoriteTaskerTeam;
      const updatedTasker = currentFavorites.filter(
        item => item.rabbit_id !== action.id
      );
      return { ...state, favoriteTaskerTeam: updatedTasker };
    }
    default:
      return state;
  }
}

const fetchFavorteTaskerResponse = () => ({
  type: FETCH_FAVORITE_TASKER_RESPONSE,
});

const loadFavorteTaskerResponse = favoriteTaskerResponse => ({
  type: LOAD_FAVORITE_TASKER_RESPONSE,
  favoriteTaskerResponse,
});

const loadPastTaskerResponse = pastTaskerResponse => ({
  type: LOAD_PAST_TASKER_RESPONSE,
  pastTaskerResponse,
});

const removeFavoriteTaskerFromList = id => ({
  type: REMOVE_FAVORITE_TASKER_FROM_LIST,
  id,
});

const addFavoriteTaskerFromPast = rabbit => ({
  type: ADD_FAVORITE_TASKER_FROM_PAST,
  rabbit,
});

const addFavoriteTaskerToList = () => ({
  type: ADD_FAVORITE_TASKER_TO_LIST,
});

export const loadFavorteTaskerList = function() {
  return (dispatch, getState) => {
    dispatch(fetchFavorteTaskerResponse());

    const base_url = FETCH_FAVORITE_TASKER_LIST;
    // timestamp is used to prevent disk cache responses if user hits the back button, previous responses is loaded on request.
    // const base_url = FETCH_FAVORITE_TASKER_LIST + `?timestamp=${new Date().getTime()}`;

    xhr
      .get(base_url)
      .then(res => {
        const { items } = res.data;
        dispatch(
          loadFavorteTaskerResponse({
            favoriteTaskerTeam: items,
          })
        );
      })
      .catch(() => {
        dispatch(
          createApiErrorAlert({
            error: "Something went wrong, support has been notified",
          })
        );
      });
  };
};

export const loadPastTaskerList = function() {
  return (dispatch, getState) => {
    dispatch(fetchFavorteTaskerResponse());
    const base_url = FETCH_PAST_TASKER_LIST;
    // timestamp is used to prevent disk cache responses if user hits the back button, previous responses is loaded on request.
    // const base_url = FETCH_PAST_TASKER_LIST + `?timestamp=${new Date().getTime()}`;

    xhr
      .get(base_url)
      .then(res => {
        const { items } = res.data;
        dispatch(loadPastTaskerResponse({ pastTaskers: items }));
      })
      .catch(() => {
        dispatch(
          createApiErrorAlert({
            error: "Something went wrong, support has been notified",
          })
        );
      });
  };
};

export const removeFavoriteTaskerStatus = function(rabbit_id) {
  xhr
    .delete(
      UPDATE_FAVORITE_TASKER_RELATIONSHIP.replace(":rabbit_id", rabbit_id)
    )
    .catch(error => {
      console.log("Error removing favorite tasker", error);
    });
};

export const removeFavoriteTasker = function(id) {
  return dispatch => {
    dispatch(removeFavoriteTaskerFromList(id));
    return removeFavoriteTaskerStatus(id);
  };
};

export const addFavoriteTaskerStatus = function (id, favorite_source) {
  xhr
    .post(UPDATE_FAVORITE_TASKER_RELATIONSHIP.replace(":rabbit_id", id), {
      source: favorite_source,
    })
    .catch((error) => {
      console.log("Error adding favorite tasker", error);
    });
};

export const addFavoriteTasker = function (id, rabbit) {
  return (dispatch) => {
    let favorite_source;
    if (rabbit) {
      favorite_source = "past_tasker";
      dispatch(addFavoriteTaskerFromPast(rabbit));
    } else {
      favorite_source = "tasker_feedback";
      dispatch(addFavoriteTaskerToList());
    }
    return addFavoriteTaskerStatus(id, favorite_source);
  };
};
