import React, { Component } from "react";
import classnames from "classnames";
import { Motion, spring } from "react-motion";
import Swipeable from "react-swipeable";

class CarouselSection extends Component {
  componentDidUpdate(prevProps) {
    this.carousel.scrollLeft = this.props.scrollLeft;
    const currentChildren = this.props.children;
    const previousChildren = prevProps.children;

    if (currentChildren != previousChildren) {
      const handleFocusRight =
        this.props.focusRight &&
        currentChildren.flat().length > previousChildren.flat().length;

      if (handleFocusRight) {
        this.adjustContainer();
      } else {
        this.measureContainer();
      }
    }
  }

  componentDidMount() {
    this.measureContainer();
    window.addEventListener("resize", this.measureContainer);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.measureContainer);
  }

  measureContainer = () => {
    const scrollWidth = this.carousel ? this.carousel.scrollWidth : 0;
    const containerWidth = this.carousel
      ? this.carousel.getBoundingClientRect().width
      : 0;

    this.props.calculateContainer(containerWidth, scrollWidth);
  };

  adjustContainer = () => {
    const scrollWidth = this.carousel ? this.carousel.scrollWidth : 0;
    const containerWidth = this.carousel
      ? this.carousel.getBoundingClientRect().width
      : 0;

    const scrollLeft = scrollWidth - containerWidth;
    this.props.setContainerStates(containerWidth, scrollWidth, scrollLeft);
  };

  render() {
    const { innerClassName, children } = this.props;

    return (
      <div className={innerClassName} ref={node => (this.carousel = node)}>
        {children}
      </div>
    );
  }
}

class Carousel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      containerWidth: 0,
      scrollLeft: 0,
      scrollWidth: 0,
    };
  }

  handleNavigate = (dir, e) => {
    if (!this.isNavigationDisabled(dir)) {
      const { scrollLeft, containerWidth, scrollWidth } = this.state;
      const { numberOfElements } = this.props;
      if (dir === "left") {
        if (numberOfElements) {
          this.setState({
            scrollLeft: scrollLeft - scrollWidth / numberOfElements,
          });
        } else {
          this.setState({
            scrollLeft: scrollLeft - containerWidth - 5,
          });
        }
      } else if (dir === "right") {
        if (numberOfElements) {
          this.setState({
            scrollLeft: scrollLeft + scrollWidth / numberOfElements,
          });
        } else {
          this.setState({
            scrollLeft: scrollLeft + containerWidth + 5,
          });
        }
      }
    }
  };

  calculateContainer = (containerWidth, scrollWidth) => {
    this.setState({ containerWidth, scrollWidth });
  };

  setContainerStates = (containerWidth, scrollWidth, scrollLeft) => {
    this.setState({ containerWidth, scrollWidth, scrollLeft });
  };

  isNavigationDisabled(dir) {
    if (dir === "left") {
      return this.state.scrollLeft <= 0;
    } if (dir === "right") {
      return (
        this.state.scrollLeft >=
        this.state.scrollWidth - this.state.containerWidth
      );
    }
  }

  navigationArrow(dir) {
    const { navigateRightClassName, navigateLeftClassName } = this.props;
    const baseClassName = classnames([
      { "carousel_navigate--prev": dir === "left" },
      { "carousel_navigate--next": dir === "right" },
      {
        "cluster-carousel__navigate--disabled": this.isNavigationDisabled(dir),
      },
      { [navigateRightClassName]: navigateRightClassName && dir === "right" },
      { [navigateLeftClassName]: navigateLeftClassName && dir === "left" },
    ]);

    return (
      <div
        className={baseClassName}
        onClick={this.handleNavigate.bind(this, dir)}
      >
        <i className={`ss-lnr-chevron-${dir}`} />
      </div>
    );
  }

  render() {
    const { wrapperClassName, swipeableClassName, showDisabled } = this.props;
    const { scrollLeft } = this.state;

    return (
      <Swipeable
        onSwipedLeft={this.handleNavigate.bind(this, "right")}
        onSwipedRight={this.handleNavigate.bind(this, "left")}
        className={swipeableClassName}
      >
        {(!this.isNavigationDisabled("left") || showDisabled) &&
          this.navigationArrow("left")}

        <Motion
          defaultStyle={{ scrollLeft: 0 }}
          style={{ scrollLeft: spring(scrollLeft) }}
        >
          {interpolatingStyle => (
            <div className={wrapperClassName}>
              <CarouselSection
                scrollLeft={interpolatingStyle.scrollLeft}
                calculateContainer={this.calculateContainer}
                setContainerStates={this.setContainerStates}
                {...this.props}
              />
            </div>
          )}
        </Motion>

        {(!this.isNavigationDisabled("right") || showDisabled) &&
          this.navigationArrow("right")}
      </Swipeable>
    );
  }
}

export default Carousel;
