import { compact, flatten } from "lodash";
import { localeConfig } from "config/localeDriven";

function localizeRouteDef(routeDef, intl) {
  let { path, pathLocaleConfig, pathMessageId, routes, ...defAttrs } = routeDef;

  if (typeof path === "undefined") {
    if (pathLocaleConfig && intl.locale) {
      // if pathLocaleConfig string proviced, look up in localeDrive config
      path = localeConfig(intl.locale, pathLocaleConfig);
    } else if (pathMessageId && intl.formatMessage) {
      // if pathMessageId provided, look up as localized message
      path = intl.formatMessage({ id: pathMessageId });
    }
  }

  if (Array.isArray(routes)) {
    routes = localizeRoutes(routes, intl);
  }

  return { path, routes, ...defAttrs };
}

function localizeRoutes(routes, intl) {
  const listOfRouteDefs = compact(flatten([routes]));

  return listOfRouteDefs.map(r => localizeRouteDef(r, intl));
}

export default localizeRoutes;
