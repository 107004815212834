import React, { Component } from "react";
import { Row, Col } from "components/Grid";
import { defineMessages } from "react-intl";
import {
  FormattedMessage,
  FormattedHTMLMessage,
} from "components/FormattedMessage";
import * as API from "account/routes/apiRoutes";
import xhr from "util/xhr";

const messages = defineMessages({
  change_email_title: {
    id: "account.verify_email.modal.change_email_title",
    defaultMessage: "Change your email address",
  },
  change_email_content: {
    id: "account.verify_email.modal.change_email_content",
    defaultMessage:
      "To ensure the security of your account, we'll send instructions to change your email " +
      'to <span class="bolden-email">{email}</span>. ' +
      'If you need help, please visit <a href="http://help.tr.co">help.tr.co</a>.',
  },
  thank_you_title: {
    id: "account.verify_email.modal.thank_you_title",
    defaultMessage: "Thank you!",
  },
  thank_you_content: {
    id: "account.verify_email.modal.thank_you_content",
    defaultMessage:
      'We\'ve sent an email to <span class="bolden-email">{email}</span>. Please check your email for next steps. ' +
      'If you need help, please visit <a href="http://help.tr.co">help.tr.co</a>.',
  },
  error_title: {
    id: "account.verify_email.modal.error_title",
    defaultMessage: "Error",
  },
  error_content: {
    id: "account.verify_email.modal.error_content",
    defaultMessage: "There was an error, please try again later.",
  },
  send_instructions: {
    id: "account.verify_email.modal.send_instructions",
    defaultMessage: "Send instructions",
  },
  cancel: {
    id: "account.verify_email.modal.cancel",
    defaultMessage: "Cancel",
  },
});

export default class VerifyEmailModal extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: false, show: "info" };
    this.handleVerifyEmail = this.handleVerifyEmail.bind(this);
    this.cancel = this.cancel.bind(this);
  }

  getText(show) {
    const { email } = this.props;
    switch (show) {
      case "info":
        return {
          title: <FormattedMessage message={messages.change_email_title} />,
          text: (
            <FormattedHTMLMessage
              message={messages.change_email_content}
              values={{ email }}
            />
          ),
        };
      case "success":
        return {
          title: <FormattedMessage message={messages.thank_you_title} />,
          text: (
            <FormattedHTMLMessage
              message={messages.thank_you_content}
              values={{
                email,
              }}
            />
          ),
        };
      default:
        return null;
    }
  }

  handleVerifyEmail(e) {
    e.preventDefault();
    this.setState({ loading: true });
    return xhr
      .post(API.ACCOUNT_VERIFY_EMAIL)
      .then(() => {
        this.setState({ loading: false, show: "success" });
      })
      .catch(() => {
        this.setState({ loading: false, show: "error" });
      });
  }

  cancel(e) {
    e.preventDefault();
    this.props.onDismiss();
  }

  render() {
    const { loading, show } = this.state;
    if (loading) return <div className="page-loader" />;
    return (
      <div>
        <h3 className="u-align--center">{this.getText(show).title}</h3>
        <hr className="marginless" />
        <Row className="confirm-lightbox--content-row">
          <Col size={12} className="u-align--center">
            <div className="explanation-space">{this.getText(show).text}</div>
          </Col>
        </Row>
        {show === "info" && (
          <>
            <Row>
              <Col size={12} className="u-align--center">
                {/* [MEADOW_TODO] - Button */}
                <button
                  type="button"
                  onClick={this.handleVerifyEmail}
                  className="btn btn-primary"
                >
                  <FormattedMessage message={messages.send_instructions} />
                </button>
              </Col>
            </Row>
            <Row>
              <Col size={12} className="u-align--center">
                {/* [MEADOW_TODO] - Button */}
                <button
                  type="button"
                  className="btn-link"
                  onClick={this.cancel}
                >
                  <FormattedMessage message={messages.cancel} />
                </button>
              </Col>
            </Row>
          </>
        )}
      </div>
    );
  }
}
