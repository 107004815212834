import { History } from "history";
import { combineReducers } from "redux";
import { combineEpics } from "redux-observable";
import { reducer as form } from "redux-form";

import account from "account/redux/accountReducer";
import bootstrap from "bootstrap/redux/bootstrapReducer";
import dashboard from "dashboard/redux/dashboardReducer";
import feedback from "feedback/redux/feedbackReducer";
import friend from "friend/redux/friendReducer";
import alerts from "alerts/redux/modules/alerts";
import currentPromo from "currentPromo/redux/modules/promos";
import searchBar from "containers/SearchBar/redux/searchBar";
import build, { buildEpic } from "build/redux/buildReducer";
import ikea, { ikeaEpic } from "ikea/redux/ikeaReducer";
import promoCode from "promoCode/redux/modules/promoCode";
import referral, { referralEpic } from "referral/redux/modules/referral";
import loginSignup, { loginSignupEpic } from "login/redux/loginSignupReducer";
import createCombinedRouterReducer from "locations/combinedRouterReducer";
import { personalInformationEpic } from "account/redux/modules/personalInformation";
import { eventPollEpic } from "dashboard/redux/modules/eventPolling";
import chat from "dashboard/redux/modules/chat";
import user from "../user/redux/modules/index";
import tasker from "../tasker/redux/taskerReducer";

const createRootReducer = (history: History) =>
  combineReducers({
    router: createCombinedRouterReducer(history),
    form,
    account,
    bootstrap,
    alerts,
    dashboard,
    currentPromo,
    searchBar,
    feedback,
    friend,
    build,
    ikea,
    promoCode,
    referral,
    loginSignup,
    chat,
    user,
    tasker,
  });

export type V3ReduxState = ReturnType<ReturnType<typeof createRootReducer>>;

export const rootEpic = combineEpics(
  personalInformationEpic,
  buildEpic,
  ikeaEpic,
  referralEpic,
  loginSignupEpic,
  eventPollEpic
);

export default createRootReducer;
