/**
 * This helper function wraps an element with common a11y attributes. The defaults
 * are set up to wrap a button, but you may pass an options object to customize
 * for different elements
 *
 * @param {Function} handlerFn - the event handler
 * @param {Object} [options={}]
 * @param {String} [options.ariaLabel] - label passed to aria-label
 * @param {Boolean} [options.excludeRole] - set to true to not set a role
 * @param {String} [options.eventType] - an eventType different than the default onClick
 * @param {Function} [options.keyCheck] - predicate that takes event & checks keys
 *   different than space & enter.
 *   example: (e) => e.keyCode === 1 && e.shiftKey === false
 * @param {String} [options.role] - set role to something other than the default button
 * @param {String} [options.tabIndex] - set tabIndex to something other than "0"
 */
export const a11yizeEvent = (
  handlerFn,
  { ariaLabel, excludeRole, eventType, keyCheck, role, tabIndex } = {}
) => {
  const roleAttr = excludeRole ? {} : { role: role || "button" };
  const ariaLabelAttr = ariaLabel ? { "aria-label": ariaLabel } : {};

  return {
    tabIndex: tabIndex || "0",
    [eventType || "onClick"]: handlerFn,
    onKeyDown: (e) => {
      if (e.keyCode !== 9) {
        e.preventDefault();
      }

      const runFunction = keyCheck
        ? keyCheck(e)
        : e.keyCode === 13 || e.keyCode === 32;

      if (runFunction) {
        handlerFn();
      }
    },
    ...roleAttr,
    ...ariaLabelAttr,
  };
};

export const a11yizeClickEvent = (handlerFn, options) =>
  a11yizeEvent(handlerFn, { ...options });
