import React, { useCallback } from "react";
import { twoFactorModalSteps } from "enums/twoFactorAuth";
import { connect } from "react-redux";
import {
  verifyTwoFactorLogin,
  updateTwoFactorAuthModalStep,
  resendTwoFactorLoginCode,
  resendSetupLoginCode,
  verifySetupLoginCode,
  loginFromTwoFactorSetup,
} from "user/redux/modules/index";
import { TwoFactorVerifyContent } from "./TwoFactorVerifyContent";

const TwoFactorLoginVerify_ = ({
  setup,
  formattedMfaPhone,
  error,
  loading,
  codeResent,
  formatMessage,
  verifyTwoFactorLoginAction,
  verifySetupLoginCodeAction,
  updateTwoFactorAuthModalStepAction,
  resendTwoFactorLoginCodeAction,
  resendSetupLoginCodeAction,
  enrolled
}) => {
  const handleBack = useCallback(() => {
    if (setup) {
      updateTwoFactorAuthModalStepAction(twoFactorModalSteps.setup);
    } else {
      updateTwoFactorAuthModalStepAction(null);
    }
  }, [updateTwoFactorAuthModalStepAction, setup]);

  const handleVerify = useCallback(
    (code) => {
      if (setup) {
        verifySetupLoginCodeAction(code);
      } else {
        verifyTwoFactorLoginAction(code);
      }
    },
    [setup, verifySetupLoginCodeAction, verifyTwoFactorLoginAction]
  );

  const handleResendCode = useCallback(() => {
    if (setup) {
      resendSetupLoginCodeAction();
    } else {
      resendTwoFactorLoginCodeAction();
    }
  }, [setup, resendSetupLoginCodeAction, resendTwoFactorLoginCodeAction]);

  return (
    <TwoFactorVerifyContent
      handleResendCode={handleResendCode}
      codeResent={codeResent}
      error={error}
      loading={loading}
      handleVerify={handleVerify}
      formattedMfaPhone={formattedMfaPhone}
      formatMessage={formatMessage}
      handleBack={handleBack}
      enrolled={enrolled}
    />
  );
};

export const TwoFactorLoginVerify = connect(null, {
  verifyTwoFactorLoginAction: verifyTwoFactorLogin,
  verifySetupLoginCodeAction: verifySetupLoginCode,
  updateTwoFactorAuthModalStepAction: updateTwoFactorAuthModalStep,
  resendTwoFactorLoginCodeAction: resendTwoFactorLoginCode,
  resendSetupLoginCodeAction: resendSetupLoginCode,
  loginFromTwoFactorSetupAction: loginFromTwoFactorSetup,
})(TwoFactorLoginVerify_);
