import { combineReducers } from "redux";
import { combineEpics } from "redux-observable";

import manager, { managerEpic } from "build/redux/modules/manager";
import progress, { progressEpic } from "build/redux/modules/progress";
import form, { formEpic } from "build/redux/modules/form";
import recommendations, {
  recommendationsEpic,
} from "build/redux/modules/recommendations";
import confirmation from "build/redux/modules/confirmation";
import reviews, {
  reviewsEpic,
} from "build/redux/modules/recommendationReviews";
import taskerSchedule from "build/redux/modules/taskerSchedule";
import repost from "build/redux/modules/repost";
import calendar from "build/redux/modules/calendar";
import threeDayRecommendationExperiment from "build/redux/modules/threeDayRecommendationExperiment";

export default combineReducers({
  manager,
  progress,
  form,
  recommendations,
  confirmation,
  reviews,
  taskerSchedule,
  calendar,
  repost,
  threeDayRecommendationExperiment,
});

export const buildEpic = combineEpics(
  managerEpic,
  formEpic,
  recommendationsEpic,
  reviewsEpic,
  progressEpic
);
