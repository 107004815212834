import React from "react";
import { connect } from "react-redux";
import { twoFactorModalSteps } from "enums/twoFactorAuth";
import {
  accountVerifyTwoFactorAuthCode,
  accountResendTwoFactorCode,
  accountUpdateTwoFactorAuthModalStep,
  accountHandleBack
} from "account/redux/modules/accountSecurity";
import { TwoFactorVerifyContent } from "./TwoFactorVerifyContent";

const TwoFactorAccountVerify_ = ({
  error,
  codeResent,
  loading,
  formattedMfaPhone,
  formatMessage,
  enrolled,
  actionName,
  accountUpdateTwoFactorAuthModalStepAction,
  accountHandleBackAction,
  accountVerifyTwoFactorAuthCodeAction,
  accountResendTwoFactorCodeAction,
  origin
}) => {
  return (
    <TwoFactorVerifyContent
      origin={origin}
      codeResent={codeResent}
      error={error}
      loading={loading}
      formattedMfaPhone={formattedMfaPhone}
      formatMessage={formatMessage}
      handleResendCode={accountResendTwoFactorCodeAction}
      handleVerify={accountVerifyTwoFactorAuthCodeAction}
      enrolled={enrolled}
      actionName={actionName}
      handleBack={() => enrolled ? accountHandleBackAction(actionName) :    accountUpdateTwoFactorAuthModalStepAction(twoFactorModalSteps.setup)
      }
    />
  );
};

export const TwoFactorAccountVerify = connect(null, {
  accountVerifyTwoFactorAuthCodeAction: accountVerifyTwoFactorAuthCode,
  accountUpdateTwoFactorAuthModalStepAction: accountUpdateTwoFactorAuthModalStep,
  accountResendTwoFactorCodeAction: accountResendTwoFactorCode,
  accountHandleBackAction: accountHandleBack
})(TwoFactorAccountVerify_);
