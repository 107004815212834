import React from "react";
import styled from "styled-components";
import { defineMessages } from "react-intl";
import { FormattedMessage } from "../../FormattedMessage";
import { SocialLinks } from "./SocialLinksContainer/SocialLinks";

const messages = defineMessages({
  socialTitle: {
    id: "components.Footer.StyledFooter.SocialLinksContainer.socialTitle",
    defaultMessage: "Follow us! We're friendly:",
  },
});

export function SocialLinksContainer({ formatMessage }) {
  return (
    <SocialContainer>
      <FooterTitle>
        <FormattedMessage message={messages.socialTitle} />
      </FooterTitle>
      <SocialLinks formatMessage={formatMessage} />
    </SocialContainer>
  );
}

const SocialContainer = styled.div`
  margin-bottom: ${(props) => props.theme.spacing.lg};
`;
const FooterTitle = styled.span`
  color: ${(props) => props.theme.color.footerText};
`;
