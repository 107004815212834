import countryIsoCodeFromLocale from "../util/countryIsoCodeFromLocale";
import { getCookie, setCookie } from "../util/cookie";
import guid from "../util/guid";
import { omitKeys } from "../shared/utils/omitKeys";
import { safeJSONParse } from "../shared/utils/safeJSONParse";
import { findClickId } from "../util/clickId";

// Based on EventProperties: https://github.com/segmentio/analytics-next/blob/master/packages/core/src/events/interfaces.ts
// The Properties type in Segment Analytics is a Record<string, any> type.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Properties = Record<string, any>;


interface SegmentAnalyticsEventParameters {
  name: string;
  properties?: Properties
}

interface SegmentAnalyticsIdentityParameters {
  properties?: Properties;
  user: {
    email: string;
    first_name: string;
    id: string | number;
    is_admin?: boolean;
    is_auto_created?: boolean;
    is_first_time?: boolean;
    is_tasker?: boolean;
    last_name: string;
    locale: string;
    metro?: string;
    metro_id?: number;
    zipcode: string;
  };
}

export enum SegmentIdentityKeys {
  country_iso_code = 'country_iso_code',
  email = "email",
  id = "id",
  first_name = "first_name",
  is_admin = "is_admin",
  is_tasker = "is_tasker",
  last_name = "last_name",
  metro = "metro",
  metro_id = "metro_id",
  server_locale = "server_locale",
  zipcode = "zipcode"
}

export interface IdentityTraits {
  [SegmentIdentityKeys.country_iso_code]: string | null;
  [SegmentIdentityKeys.email]: string;
  [SegmentIdentityKeys.first_name]?: string;
  [SegmentIdentityKeys.id]: string;
  [SegmentIdentityKeys.is_admin]?: boolean;
  [SegmentIdentityKeys.is_tasker]?: boolean;
  [SegmentIdentityKeys.last_name]?: string;
  [SegmentIdentityKeys.metro]?: string;
  [SegmentIdentityKeys.metro_id]?: string;
  [SegmentIdentityKeys.server_locale]?: string;
  [SegmentIdentityKeys.zipcode]?: string;
}


interface SegmentAnalyticsPageParameters {
  name: string;
  properties?: Properties;
}

interface Campaign {
  medium: string;
  name: string;
  source: string;
  [key: string]: string;
}

export const eventMetadataKey = "event_metadata";
export const eventMetadataMaxAge = 1800;

export const getEventMetadataCookie = () => {
  const cookie = getCookie(eventMetadataKey);

  return typeof cookie === "string" ? cookie : null;
};

export const setEventMetadataCookie = (value: string, opts = {}) => {
  setCookie(eventMetadataKey, value, { maxAge: eventMetadataMaxAge, ...opts });
};

/**
 * Update the event_metadata cookie with the most recent job_draft_id and
 * returns a boolean based on the job_draft_id.
 */
export const updateEventMetadataRecentJobDraftId = (jobDraftId?: string) => {
  const cookie = getEventMetadataCookie();
  const eventMetadata = safeJSONParse(cookie) as Record<string, string | null>;
  const updatedEventMetadata = { ...eventMetadata };

  if (!updatedEventMetadata.segment_session_id) {
    updatedEventMetadata.segment_session_id = guid();
  }

  if (!jobDraftId) {
    updatedEventMetadata.recent_job_draft_id = null;
  } else {
    updatedEventMetadata.recent_job_draft_id = `${updatedEventMetadata.segment_session_id}-${jobDraftId}`;
  }

  setEventMetadataCookie(JSON.stringify({ ...updatedEventMetadata }), {
    maxAge: eventMetadataMaxAge,
  });

  return updatedEventMetadata;
};

export const getSegmentSessionId = () => {
  const cookie = getEventMetadataCookie();
  const eventMetadata = safeJSONParse(cookie) as Record<string, never>;

  const segmentSessionId = eventMetadata.segment_session_id
    ? eventMetadata.segment_session_id
    : guid();

  setEventMetadataCookie(
    JSON.stringify({ ...eventMetadata, segment_session_id: segmentSessionId }),
    {
      maxAge: eventMetadataMaxAge,
    }
  );
  return String(segmentSessionId);
};

export const getUtmParams = (search: string) => {
  const searchEntries = [...new URLSearchParams(search).entries()];

  return searchEntries.reduce((acc, [key, value]) => {
    if (key.startsWith("utm_")) {
      return { ...acc, [key]: value };
    }
    return acc;
  }, {});
};

// UTM key parser, removes the utm_ prefix and update the "campaign" key to "name"
const parseUtmKeys = (utm: Record<string, string>) => {
  return Object.entries(utm).reduce((acc, [key, value]) => {
    if (key === "utm_campaign") {
      return { ...acc, name: value };
    }

    const parsedKey = key.substring(4).toLocaleLowerCase();
    return { ...acc, [parsedKey]: value };
  }, {});
};

export const setEventMetadataUtm = (utmParams: Record<string, string>) => {
  const hasRequiredUtm = ["utm_campaign", "utm_medium", "utm_source"].every(
    (requiredUtm) => requiredUtm in utmParams
  );
  if (!hasRequiredUtm) return;

  const cookie = getEventMetadataCookie();
  const eventMetadata = safeJSONParse(cookie);

  const utm = parseUtmKeys(utmParams);
  setEventMetadataCookie(JSON.stringify({ ...eventMetadata, utm }), {
    maxAge: eventMetadataMaxAge,
  });
};

export const getCampaignParams = () => {
  const cookie = getEventMetadataCookie();

  if (typeof cookie !== 'string') return {};

  try {
    const { utm, ...rest  } = JSON.parse(cookie) as { utm: Record<string, Campaign> | { [key: string]: string; } };
    const additionalData = rest as { [key: string]: string; };
    const clickId = findClickId(additionalData);

    return utm || clickId ? { campaign: { ...clickId, ...utm } } : {};
  } catch(e) {
    return {};
  }
};

export const transformIdsToString = <T extends Properties>(fullProperties?: T): T => {
  const transformed = {} as T;

  if (!fullProperties) return transformed;

  for (const key in fullProperties) {
    if (key === "id" || key.endsWith("_id")) {
      const value = fullProperties[key];
      const isEmptyValue = (!value && value !== 0);

      transformed[key] = (isEmptyValue ? null : String(value)) as T[typeof key];
    } else {
      transformed[key] = fullProperties[key];
    }
  }

  return transformed;
};

const commonProps = () => ({
  server_locale: window.LOCALE,
  country_iso_code: countryIsoCodeFromLocale(window.LOCALE),
  segment_session_id: getSegmentSessionId(),
});

const SegmentAnalyticsService = {
  trackEvent: async ({ name, properties }: SegmentAnalyticsEventParameters) => {
    return globalThis.analytics.track(
      name,
      {
        ...commonProps(),
        ...transformIdsToString(properties),
        page: properties?.page || globalThis.location.pathname,
      },
      {
        context: {
          ...getCampaignParams(),
        },
      }
    );
  },

  trackIdentity: async ({
    user,
    properties,
  }: SegmentAnalyticsIdentityParameters) => {
    const mergedTraits = {
      country_iso_code: countryIsoCodeFromLocale(user.locale),
      server_locale: user.locale,
      ...user,
      ...properties,
    }
    const transformedTraits = transformIdsToString(mergedTraits)
    const allowedTraits = omitKeys(transformedTraits, 'locale') as IdentityTraits

    return globalThis.analytics.identify(allowedTraits.id, allowedTraits);
  },

  trackPage: async ({ name, properties }: SegmentAnalyticsPageParameters) => {
    return globalThis.analytics.page(
      name,
      {
        ...commonProps(),
        ...transformIdsToString(properties),
      },
      {
        context: {
          ...getCampaignParams(),
        },
      }
    );
  },

  reset: () => globalThis.analytics.reset(),
};

export default SegmentAnalyticsService;
