export function calculateBadgeDimensions({ width, height, aspectRatio }) {
  if (width == null && height == null) {
    throw Error(
      "Neither a width nor a height was provided. Please provide a width or a height."
    );
  }

  if (width != null && height != null) {
    throw Error(
      "Both a width and height were provided. Please only provide a width or a height."
    );
  }

  if (height == null) {
    return {
      badgeHeight: Math.round(width / aspectRatio),
      badgeWidth: width,
    };
  }
  return {
    badgeHeight: height,
    badgeWidth: height * aspectRatio,
  };
}
