import React from "react";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";

Bugsnag.start({
  apiKey: process.env.WEB_BUGSNAG_API_KEY,
  plugins: [new BugsnagPluginReact()],
  releaseStage: process.env.NODE_ENV,
});
const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);

const BugsnagErrorBoundary = ({ children }) => {
  return <ErrorBoundary>{children}</ErrorBoundary>;
};

export default BugsnagErrorBoundary;
