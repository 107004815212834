import React, { useCallback, useState } from "react";
import { defineMessages, injectIntl } from "react-intl";
import styled, { css } from "styled-components";
import { internalPath } from "../../../../../util/internalPath";
import {
  HeaderNavigationA,
  HeaderNavigationIcon,
} from "../../header-constants";
import {
  servicesMenuZIndex,
  DesktopList,
} from "./DesktopServicesMenu/DesktopList";
import { FormattedMessage } from "../../../../FormattedMessage";

const messages = defineMessages({
  services: {
    id: "DesktopServicesMenu.services",
    defaultMessage: "Services",
  },
});

function DesktopServicesMenuBase({
  links,
  serviceIndexPath,
  mobileExpandInitialState,
}) {
  const [isMenuOpen, setIsMenuOpen] = useState(mobileExpandInitialState);
  const openMenu = useCallback(() => {
    setIsMenuOpen(true);
  }, []);
  const closeMenu = useCallback(() => {
    setIsMenuOpen(false);
  }, []);

  if (!isMenuRenderable({ links, serviceIndexPath })) {
    return null;
  }

  const servicesNavigationIconClassName = isMenuOpen
    ? "ss-lnr-circle-minus"
    : "ss-lnr-plus-circle";

  return (
    <ServicesLinksContainer onMouseEnter={openMenu} onMouseLeave={closeMenu}>
      <ServicesTab isMenuOpen={isMenuOpen}>
        <ServicesLink
          isMenuOpen={isMenuOpen}
          href={internalPath(serviceIndexPath)}
        >
          <HeaderNavigationIcon className={servicesNavigationIconClassName} />
          <FormattedMessage message={messages.services} />
        </ServicesLink>
      </ServicesTab>
      <DesktopList isMenuOpen={isMenuOpen} links={links} />
    </ServicesLinksContainer>
  );
}

function isMenuRenderable({ links, serviceIndexPath }) {
  return links != null && serviceIndexPath != null;
}

const servicesTabZIndex = servicesMenuZIndex + 1;

const openStyle = css`
  background-color: ${(props) => props.theme.color.white};
  border-top: 4px solid ${(props) => props.theme.color.buttonBorderPrimary};
  border-left: 1px solid ${(props) => props.theme.color.buttonBorder};
  border-right: 1px solid ${(props) => props.theme.color.buttonBorder};
  border-bottom: 1px solid ${(props) => props.theme.color.white};
  padding-top: ${(props) => props.theme.spacing.sm};
  padding-bottom: ${(props) => props.theme.spacing.sm};
  position: relative;
  top: 1px;
  z-index: ${servicesTabZIndex};
`;

const closedStyle = css`
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
`;

const ServicesLinksContainer = styled.span`
  position: relative;
  display: none;

  ${(props) => props.theme.breakpoints.desktopUp} {
    display: inline-block;
  }
`;

const ServicesTab = styled.span`
  padding-right: ${(props) => props.theme.spacing.md};
  padding-left: ${(props) => props.theme.spacing.md};
  ${(props) => (props.isMenuOpen ? openStyle : closedStyle)}
`;

const ServicesLink = styled(HeaderNavigationA)`
  position: relative;
  top: ${(props) => (props.isMenuOpen ? "-1px" : "0")};
`;

export const DesktopServicesMenu = injectIntl(DesktopServicesMenuBase);
