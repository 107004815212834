import {
  JOB_RESCHEDULE_AVAILABILITY_V3_1,
  JOB_RESCHEDULE_APPT_V3_1,
} from "dashboard/routes/apiRoutes";
import { AnyAction, Dispatch, bindActionCreators } from "redux";
import * as TaskListActionsCreator from "dashboard/redux/modules/taskLists";
import { getAxios } from "util/xhr";
import { notifyBugsnag } from "util/bugsnag";
import { NotifiableError } from "@bugsnag/js";
import { AvailabilitySlot } from "shared/types/Schedule";

interface ActionsDictionary {
  CHANGE_SELECTION: string;
  CORRECT_SELECTION: string;
  FETCHING_AVAILABILITY: string;
  FETCHED_AVAILABILITY: string;
  RESCHEDULING_APPOINTMENT: string;
  RESCHEDULED_APPOINTMENT: string;
  BACK_TO_CONFIRMING: string;
  END_RESCHEDULING: string;
}

type ActionsDictionaryKey = keyof ActionsDictionary;

/**
 * in such a format "2021-01-10"
 */
type DateString = string;

/**
 * translated dayname e.g. in english "Sun", "Mon"
 */
type ShortDayName = string;
interface CalendarBlock {
  date: DateString;
  disabled: boolean;
  sameday: boolean;
  slots: AvailabilitySlot[];
}

interface Date {
  date: DateString;
  disabled: boolean;
  label: string;
  month_name: string;
  sameday: false;
  slots: AvailabilitySlot[];
}

interface CalendarDataWithNames {
  title: string;
  rows: DateString[];
  day_names: ShortDayName[];
  dates: Date[];
}

interface AvailabilityData {
  any_future_availability_present: boolean;
  calendar_blocks: CalendarBlock[];
  calendar_data_with_names: CalendarDataWithNames;
  job_id: number;
  rabbit_id: number;
}

interface ParsedAvailabilityRow {
  date: DateString;
  slots: AvailabilitySlot[];
  items: AvailabilitySlot[];
}

interface ParsedAvailabilityData {
  availability: ParsedAvailabilityRow[];
  calendarData: CalendarDataWithNames;
  anyFutureAvailabilityPresent: boolean;
}

interface RescheduleParams {
  date: DateString;
  offset_seconds: number;
  estimated_seconds: number;
}

interface Selection {
  selectedDate: DateString;
  selectedDateIndex: number;
  selectedTimeSlot: AvailabilitySlot;
  timeSlots: AvailabilitySlot[];
}

interface GeoLocation {
  lat: number;
  lng: number;
}

const prefixActions = (
  prefix: string,
  actions: ActionsDictionary
): ActionsDictionary => {
  Object.keys(actions).forEach((key) => {
    actions[key as ActionsDictionaryKey] = `${prefix}${
      actions[key as ActionsDictionaryKey]
    }`;
  });
  return actions;
};

export const actions = prefixActions("NEW_RESCHEDULE/", {
  CHANGE_SELECTION: "CHANGE_SELECTION",
  CORRECT_SELECTION: "CORRECT_SELECTION",
  FETCHING_AVAILABILITY: "FETCHING_AVAILABILITY",
  FETCHED_AVAILABILITY: "FETCHED_AVAILABILITY",
  RESCHEDULING_APPOINTMENT: "RESCHEDULE_APPOINTMENT",
  RESCHEDULED_APPOINTMENT: "RESCHEDULED_APPOINTMENT",
  BACK_TO_CONFIRMING: "BACK_TO_CONFIRMING",
  END_RESCHEDULING: "END_RESCHEDULING",
} as ActionsDictionary);

export const steps = {
  SELECT: "select",
  CONFIRM: "confirm",
  CHANGE_SUMMARY: "change_summary",
};

export const getAvailabilityURL = (jobId: number, rabbitId: number) => {
  return JOB_RESCHEDULE_AVAILABILITY_V3_1.replace(
    ":job_id",
    jobId.toString()
  ).replace(":rabbit_id", rabbitId.toString());
};

const parseAvailabilityPayload = ({
  calendar_data_with_names,
  any_future_availability_present,
}: AvailabilityData): ParsedAvailabilityData => {
  const availability = calendar_data_with_names.dates.map(
    ({ date, slots, sameday }) => ({
      date,
      disabled: slots.every(slot => slot.disabled),
      sameday,
      slots,
      items: [...slots],
    })
  );

  return {
    availability,
    calendarData: calendar_data_with_names,
    anyFutureAvailabilityPresent: any_future_availability_present,
  };
};

export const fetchAvailability = (
  jobId: number,
  rabbitId: number,
  location: GeoLocation
) => {
  return async (dispatch: Dispatch) => {
    const { fetchingAvailability: fetching, fetchedAvailability: fetched } =
      bindActionCreators(
        {
          fetchingAvailability,
          fetchedAvailability,
        },
        dispatch
      );

    fetching();

    try {
      const res = await getAxios().get(getAvailabilityURL(jobId, rabbitId), {
        params: { location },
      });

      fetched(true, parseAvailabilityPayload(res.data));
      return;
    } catch (err: unknown) {
      console.log(err);
      notifyBugsnag(err as NotifiableError);
    }

    fetched(false);
  };
};

export const getRescheduleURL = (jobId: number, appointmentId: number) => {
  return JOB_RESCHEDULE_APPT_V3_1.replace(":job_id", jobId.toString()).replace(
    ":appointment_id",
    appointmentId.toString()
  );
};

export const fetchingAvailability = () => {
  return (dispatch: Dispatch) => {
    dispatch({ type: actions.FETCHING_AVAILABILITY });
  };
};

export const fetchedAvailability = (
  success: boolean,
  availabilityPayload?: ParsedAvailabilityData
) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: actions.FETCHED_AVAILABILITY,
      success,
      ...(success ? availabilityPayload : {}),
    });
  };
};

export const rescheduling = () => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: actions.RESCHEDULING_APPOINTMENT,
    });
  };
};

export const rescheduled = (success: boolean) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: actions.RESCHEDULED_APPOINTMENT,
      success,
    });
  };
};

export const rescheduleJobAppointment = (
  jobId: number,
  appointmentId: number,
  rabbitId: number,
  rescheduleParams: RescheduleParams
) => {
  return async (dispatch: Dispatch) => {
    rescheduling()(dispatch);

    const { refreshRescheduledTask } = bindActionCreators(
      TaskListActionsCreator,
      dispatch
    );

    let success;
    try {
      await getAxios().post(
        getRescheduleURL(jobId, appointmentId),
        rescheduleParams
      );
      success = true;
    } catch (err: unknown) {
      success = false;
      console.log(err);
      notifyBugsnag(err as NotifiableError);
    }

    rescheduled(success)(dispatch);
    refreshRescheduledTask(jobId, rabbitId, appointmentId);
  };
};

export const changeSelection = (selection: Selection) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: actions.CHANGE_SELECTION,
      selection,
    });
  };
};

export const correctSelection = () => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: actions.CORRECT_SELECTION,
    });
  };
};

export const backToConfirming = () => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: actions.BACK_TO_CONFIRMING,
    });
  };
};

export const endRescheduling = () => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: actions.END_RESCHEDULING,
    });
  };
};

export const getInitialState = () => ({
  step: steps.SELECT,
  selection: null,
  loading: false,
  availability: null,
  calendarData: null,
  error: null,
});

export const getStateAfterError = () => {
  return {
    ...getInitialState(),
    error: true,
  };
};

export default function simpleReschedule(
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state = getInitialState(),
  action: AnyAction
) {
  switch (action.type) {
    case actions.FETCHING_AVAILABILITY: {
      return {
        ...state,
        availability: null,
        calendarData: null,
        loading: true,
        error: null,
      };
    }

    case actions.FETCHED_AVAILABILITY: {
      if (action.success) {
        return {
          ...state,
          availability: action.availability,
          calendarData: action.calendarData,
          anyFutureAvailabilityPresent: action.anyFutureAvailabilityPresent,
          loading: false,
          error: null,
        };
      } else {
        return getStateAfterError();
      }
    }

    case actions.CHANGE_SELECTION: {
      return {
        ...state,
        step: steps.CONFIRM,
        selection: action.selection,
        error: null,
      };
    }

    case actions.CORRECT_SELECTION: {
      return {
        ...state,
        step: steps.SELECT,
      };
    }

    case actions.RESCHEDULING_APPOINTMENT: {
      return {
        ...state,
        loading: true,
      };
    }

    case actions.RESCHEDULED_APPOINTMENT: {
      if (action.success) {
        return {
          ...state,
          step: steps.CHANGE_SUMMARY,
          loading: false,
          availability: null,
          calendarData: null,
          error: null,
        };
      } else {
        return getStateAfterError();
      }
    }

    case actions.BACK_TO_CONFIRMING: {
      return {
        ...state,
        step: steps.CONFIRM,
      };
    }

    case actions.END_RESCHEDULING: {
      return getInitialState();
    }

    default:
      return state;
  }
}
