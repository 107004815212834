import React, { useCallback } from "react";
import { connect } from "react-redux";
import { updateTwoFactorAuthModalStep } from "user/redux/modules/index";
import {
  accountUpdateTwoFactorAuthModalStep,
  accountHandleBack,
  accountCloseModal
} from "account/redux/modules/accountSecurity";
import styled from "styled-components";
import {
  LOGIN_ORIGIN,
  ACCOUNT_ORIGIN,
  PROTECTED_SCREEN_ORIGIN,
  twoFactorModalSteps,
} from "enums/twoFactorAuth";
import TRLogo from "components/Logos/TRLogo";
import { TwoFactorModal } from "./TwoFactorAuthenticationModalController/TwoFactorModal";
import { ForDesktop, ForMobile } from "../../shared/hooks/use-breakpoints";

/*
 origin [String]: Determine if came from Login or Account
 setup [Boolean]: Check if setting up two factor or logging in. Default is false.
 mobilePhone: [String]: Users current phone number
 mfaPhone: [String]: Users phone number set up with two factor auth
*/

const TwoFactorAuthenticationModalController_ = (props) => {
  const {
    twoFactorAuth: { setup },
    twoFactorAuth,
    mobilePhone,
    origin,
    updateTwoFactorAuthModalStepAction,
    accountUpdateTwoFactorAuthModalStepAction,
    accountHandleBackAction,
    accountCloseModalAction,
    locale,
    countryIsoCode,
    enrolled,
    actionName,
  } = props;

  //This is for the mobile back button
  const handleBack = useCallback(() => {
    /*
      For when user is in the middle of a setup in account security tab
      or in the middle of setup while on a protected screen
    */
    const notEnrolledUserVerify = origin === PROTECTED_SCREEN_ORIGIN && !enrolled && twoFactorAuth.next == twoFactorModalSteps.verify; 
    const accountTabMidSetup = origin === ACCOUNT_ORIGIN && twoFactorAuth.next == twoFactorModalSteps.verify;

    if (accountTabMidSetup || notEnrolledUserVerify) {
      accountUpdateTwoFactorAuthModalStepAction(twoFactorModalSteps.setup);
    }
    /*
      Enrolled users who just need to verify sms auth
      or not enrolled users who are at the beginning of a setup on a protected screen
    */
    const enrolledUserVerify = origin === PROTECTED_SCREEN_ORIGIN && enrolled && twoFactorAuth.next == twoFactorModalSteps.verify;
    const notEnrolledUserSetup = origin === PROTECTED_SCREEN_ORIGIN && !enrolled && twoFactorAuth.next == twoFactorModalSteps.setup;
    const accountTabSetup = origin === ACCOUNT_ORIGIN && twoFactorAuth.next == twoFactorModalSteps.setup;
    const accountDeleteMFA = origin === ACCOUNT_ORIGIN && twoFactorAuth.next == twoFactorModalSteps.delete;
    
    if (accountTabSetup || accountDeleteMFA) {
      accountCloseModalAction();
    }
    if (enrolledUserVerify || notEnrolledUserSetup) {
      accountHandleBackAction(actionName);
    }
    if (origin === LOGIN_ORIGIN) {
      if (setup) {
        updateTwoFactorAuthModalStepAction(twoFactorModalSteps.setup);
      } else {
        updateTwoFactorAuthModalStepAction(null);
      }
    }
    return null;
  }, [
    updateTwoFactorAuthModalStepAction,
    accountUpdateTwoFactorAuthModalStepAction,
    accountHandleBackAction,
    accountCloseModalAction,
    origin,
    setup,
    enrolled,
    actionName,
    twoFactorAuth.next
  ]);

  return (
    <>
      <ForDesktop>
        <StyledTwoFactorModalController>
          <TwoFactorModal
            twoFactorAuth={twoFactorAuth}
            mobilePhone={mobilePhone ? mobilePhone : ""}
            origin={origin}
            locale={locale}
            countryIsoCode={countryIsoCode}
            enrolled={enrolled}
            actionName={actionName}
          />
        </StyledTwoFactorModalController>
      </ForDesktop>
      <ForMobile>
        <StyledMobileView>
          <StyledMobileBanner>
            {(origin === LOGIN_ORIGIN && twoFactorAuth.next === twoFactorModalSteps.setup) ? <></> :
            <StyledBackArrowButton
                type="button"
                aria-label="Back"
                onClick={handleBack}
                className="btn-transparent ss-lnr-chevron-left"
              />
            }
            <StyledLogo />
          </StyledMobileBanner>
          <StyledMobileTwoFactorModalController>
            <TwoFactorModal
              twoFactorAuth={twoFactorAuth}
              mobilePhone={mobilePhone ? mobilePhone : ""}
              origin={origin}
              locale={locale}
              countryIsoCode={countryIsoCode}
              enrolled={enrolled}
              actionName={actionName}
            />
          </StyledMobileTwoFactorModalController>
        </StyledMobileView>
      </ForMobile>
    </>
  );
};

export const TwoFactorAuthenticationModalController = connect(null, {
  accountUpdateTwoFactorAuthModalStepAction: accountUpdateTwoFactorAuthModalStep,
  updateTwoFactorAuthModalStepAction: updateTwoFactorAuthModalStep,
  accountHandleBackAction: accountHandleBack,
  accountCloseModalAction: accountCloseModal
})(TwoFactorAuthenticationModalController_);

const StyledMobileTwoFactorModalController = styled.div`
  background: ${(props) => props.theme.color.white};
  box-sizing: border-box;
  height: 100%;
  padding-top: 24px;
`;

const StyledTwoFactorModalController = styled.div`
  background: ${(props) => props.theme.color.white};
  border: 1px solid ${(props) => props.theme.color.trGrey30};
  box-sizing: border-box;
  border-radius: ${(props) => props.theme.layout.borderRadius.btn};
  height: 100%;
`;

const StyledBackArrowButton = styled.button`
  font-size: ${(props) => props.theme.typography[300]};
  height: 100%;
  margin-left: ${(props) => props.theme.spacing.md};
  position: absolute;
`;

const StyledMobileBanner = styled.div`
  height: 60px;
  width: 100%;
  display: flex;
  position: absolute;
  border-bottom: 1px solid ${(props) => props.theme.color.trGrey30};
`;

const StyledLogo = styled(TRLogo)`
  align-self: center;
  width: 100%;
  height: 32px;
`;

const StyledMobileView = styled.div`
  position: fixed;
  background-color: ${(props) => props.theme.color.white};
  z-index: 10001;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`;
