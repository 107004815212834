import React from "react";
import { FooterLink } from "../FooterLink";

export function FooterReferralLink({ loggedIn, referralData }) {
  if (!loggedIn || !referralData) return null;

  const showGiftIcon = referralData.show_icon;
  const linkText = referralData.referral_cta;
  const referralPath = "/dashboard/referral?source=dashboard_advocate";

  return (
    <div>
      <FooterLink path={referralPath}>
        {showGiftIcon ? <i className="ss-lnr-gift" /> : null} {linkText}
      </FooterLink>
    </div>
  );
}
