import xhr, { createApiErrorAlert } from "util/xhr";
import { BOOTSTRAP_PATH } from "bootstrap/routes/apiRoutes";

export const initialState = {
  bootstrapFetched: false,
  paymentMethodTypes: [],
};

export const BOOTSTRAP_RESPONSE = "v3/bootstrap/BOOTSTRAP_RESPONSE";

export const fetchBootstrapData = () => async (dispatch, getState) => {
  const {
    bootstrap: { bootstrapFetched },
  } = getState();

  if (bootstrapFetched) {
    return;
  }

  try {
    const response = await xhr.get(BOOTSTRAP_PATH);
    const data = response.camelCasedData;
    dispatch({
      type: BOOTSTRAP_RESPONSE,
      data,
    });
  } catch (e) {
    dispatch(createApiErrorAlert(e));
  }
};

export default function bootstrap(state = initialState, action) {
  switch (action.type) {
    case BOOTSTRAP_RESPONSE: {
      const { paymentMethodTypes, debitAuthorizationMandate } = action.data;
      return {
        ...state,
        bootstrapFetched: true,
        paymentMethodTypes,
        debitAuthorizationMandate,
      };
    }
    default:
      return state;
  }
}
