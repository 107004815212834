import {
  FEEDBACK_REQUEST_ENDPOINT,
  createReviewFromFeedbackRequest,
} from "feedback/routes/apiRoutes";
import xhr from "util/xhr";
import { createAlert } from "alerts/redux/modules/alerts";
import { defineMessages } from "react-intl";
import { internalPath } from "util/internalPath";
import { POSITIVE_STAR_RATING } from "../../../util/constants";

const messages = defineMessages({
  success: {
    id: "feedback_request.api.review.success",
    defaultMessage: "Rating has been submitted",
  },
});

const FETCH_FEEDBACK_REQUEST = "FETCH_FEEDBACK_REQUEST";
const FETCH_FEEDBACK_REQUEST_ERROR = "FETCH_FEEDBACK_REQUEST_ERROR";
const FETCH_FEEDBACK_REQUEST_RESPONSE = "FETCH_FEEDBACK_REQUEST_RESPONSE";
const FEEDBACK_POST_REVIEW = "FEEDBACK_POST_REVIEW";
const FEEDBACK_SET_REVIEW_RATING = "FEEDBACK_SET_REVIEW_RATING";
const FEEDBACK_SET_REVIEW_MESSAGE = "FEEDBACK_SET_REVIEW_MESSAGE";
const FEEDBACK_SET_FAVORITE_TASKER = "FEEDBACK_SET_FAVORITE_TASKER";

const initialState = {
  requestReceived: false,
  loading: false,
  errorLoading: false,
};

const processShowFeedbackRequestResponse = (state, action) => {
  const { data } = action;
  return {
    ...state,
    errorLoading: false,
    requestReceived: true,
    loading: false,
    taskId: data.taskId,
    taskerId: data.taskerId,
    reviewState: data.taskerReview.state,
    rating: data.taskerReview.rating,
    tipState: data.taskerTip.state,
  };
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export default function feedback(state = initialState, action) {
  switch (action.type) {
    case FETCH_FEEDBACK_REQUEST:
      return { ...state, loading: true, requestReceived: false };
    case FETCH_FEEDBACK_REQUEST_RESPONSE:
      return processShowFeedbackRequestResponse(state, action);
    case FETCH_FEEDBACK_REQUEST_ERROR:
      return {
        ...state,
        loading: false,
        requestReceived: false,
        errorLoading: true,
      };
    case FEEDBACK_SET_REVIEW_MESSAGE:
      return { ...state, message: action.message };
    case FEEDBACK_SET_FAVORITE_TASKER:
      return { ...state, isFavorite: action.isFavorite };
    case FEEDBACK_SET_REVIEW_RATING:
      return { ...state, rating: action.rating };
    default:
      return state;
  }
}

export const redirectToNextPage = (feedbackRequestId) => {
  return (dispatch, getState) => {
    const state = getState();
    const { rating, tipState } = state.feedback.posterFeedbackRequest;
    const positiveReview = rating >= POSITIVE_STAR_RATING;
    let url = "/dashboard/active";
    if (positiveReview && tipState === "open") {
      url = `/feedback/tip_request?feedback_request_id=${feedbackRequestId}`;
    }
    window.location.href = internalPath(url);
  };
};

export const setRating = (rating) => ({
  type: FEEDBACK_SET_REVIEW_RATING,
  rating,
});

export const setMessage = (message) => ({
  type: FEEDBACK_SET_REVIEW_MESSAGE,
  message,
});

export const setFavorite = (isFavorite) => ({
  type: FEEDBACK_SET_FAVORITE_TASKER,
  isFavorite,
});

const postReview = () => ({
  type: FEEDBACK_POST_REVIEW,
});

export const throwBootstrapError = () => (dispatch) => {
  dispatch({
    type: FETCH_FEEDBACK_REQUEST_ERROR,
  });
};

export const fetchFeedbackRequest = (feedbackRequestId) => async (dispatch) => {
  dispatch({ type: FETCH_FEEDBACK_REQUEST });
  try {
    const response = await xhr.get(
      FEEDBACK_REQUEST_ENDPOINT + "/" + feedbackRequestId
    );
    dispatch({
      type: FETCH_FEEDBACK_REQUEST_RESPONSE,
      data: response.camelCasedData,
    });
  } catch (err) {
    dispatch({
      type: FETCH_FEEDBACK_REQUEST_ERROR,
      data: err.response.data.message,
    });
  }
};

export const submitReview =
  ({ feedbackRequestId, message, rating }) =>
  async (dispatch) => {
    const data = {
      rating: rating,
      public_review_text: message,
      closed_reason: "client_submitted",
    };
    const url = createReviewFromFeedbackRequest(feedbackRequestId);
    dispatch(postReview());
    return xhr.patch(url, data).then(() => {
      dispatch(createAlert(messages.success.id));
      dispatch(redirectToNextPage(feedbackRequestId));
    });
  };
