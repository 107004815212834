import { AnyAction } from "redux";
import { V3Thunk } from "util/reduxTypes";
import xhr from "util/xhr";
import { VALIDATE_PROMO_CODE } from "promoCode/routes/apiRoutes";
import { IKEA_CATEGORIES, IKEA_TASK_TEMPLATE } from "util/constants";
import { APPLY_PROMO_CODE_TO_JOB } from "dashboard/routes/apiRoutes";
import { createAlert } from "alerts/redux/modules/alerts";
import { createActionTypesFor } from "util/reducerHelpers";
import {
  fetchSingleTask,
  fetchJobDataResponse,
} from "dashboard/redux/modules/taskLists";
import { Promotion } from "promoCode/types";
import { Location } from "shared/types";

interface PromoCodeState {
  valid: boolean;
  loading: boolean;
  promotion?: Promotion;
  promotionApplied: boolean;
  errorMessage?: string;
}

const initialState: PromoCodeState = {
  valid: false,
  loading: false,
  promotionApplied: false,
};

const VALIDATE_PROMO = createActionTypesFor("promoCode", "validatePromo");
const APPLY_PROMO = createActionTypesFor("promoCode", "applyPromo");

export default function promoCode(
  state: PromoCodeState = initialState,
  action: AnyAction
): PromoCodeState {
  switch (action.type) {
    case VALIDATE_PROMO.SUCCESS: {
      return {
        ...state,
        valid: action.valid,
        promotion: action.promotion,
        errorMessage: action.errorMessage,
        loading: false,
      };
    }
    case VALIDATE_PROMO.REQUEST:
    case APPLY_PROMO.REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case VALIDATE_PROMO.FAILURE:
    case APPLY_PROMO.FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case APPLY_PROMO.SUCCESS:
      return {
        ...state,
        loading: false,
        promotionApplied: true,
      };
    default:
      return state;
  }
}

interface PromoCodeOptions {
  address?: Location;
  categoryId: number;
  code: string;
  formType: string;
  inviteeId: number;
  isJobDraftTemplate?: boolean;
  jobId: number;
  secondsBetween: number;
}

export const setPromoCode =
  ({
    jobId,
    code,
    formType,
    address,
    inviteeId,
    categoryId,
    secondsBetween,
    isJobDraftTemplate = false,
  }: PromoCodeOptions): V3Thunk =>
  async (dispatch) => {
    dispatch({
      type: VALIDATE_PROMO.REQUEST,
      code,
      formType,
    });

    if (!code) {
      dispatch({
        type: VALIDATE_PROMO.SUCCESS,
        data: { code: "" },
      });
      return;
    }

    const params =
      formType === "ikea"
        ? {
            rabbit_id: 971,
            category_id: IKEA_CATEGORIES[0],
            task_template_id: IKEA_TASK_TEMPLATE,
            code,
          }
        : {
            job_id: jobId,
            category_id: categoryId,
            address,
            metro_id: address?.metro_id,
            rabbit_id: inviteeId,
            seconds_between: secondsBetween,
            code,
          };

    try {
      const { camelCasedData: data } = await xhr.get(VALIDATE_PROMO_CODE, {
        params,
      });

      // break out if the default promocode is invalid
      if (isJobDraftTemplate && !data.valid) {
        return;
      }

      dispatch({
        type: VALIDATE_PROMO.SUCCESS,
        valid: data.valid,
        promotion: data.promotion,
        errorMessage: data.errorMessage,
      });
    } catch (e) {
      dispatch({ type: VALIDATE_PROMO.FAILURE });
    }
  };

export const applyPromoCode =
  (
    code: string,
    jobId: number | string,
    inviteeId: number,
    metroId: number,
    // sorry about this but alert is used on 28 pages and I can't be refactoring that here
    successMessageId: string,
    errorMessageId: string
  ): V3Thunk =>
  async (dispatch) => {
    dispatch({ type: APPLY_PROMO.REQUEST });
    const url = APPLY_PROMO_CODE_TO_JOB.replace(":job_id", jobId.toString());
    try {
      const { camelCasedData: data } = await xhr.post(url, { code, metroId });
      if (data.errors) {
        throw new Error(data.errors);
      }
      dispatch({
        type: APPLY_PROMO.SUCCESS,
        promotionApplied: data.isValid,
      });
      dispatch(
        createAlert({
          messages: successMessageId,
          values: { code },
        })
      );
      return await fetchSingleTask(jobId, inviteeId).then((item) => {
        dispatch(fetchJobDataResponse(item));
      });
    } catch (e) {
      dispatch({ type: APPLY_PROMO.FAILURE });
      dispatch(createAlert(errorMessageId));
    }
    return null;
  };
