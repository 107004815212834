import {
  createValidator,
  required,
  email,
  phone,
  postalCode,
} from "util/validation";

const profileValidator = createValidator({
  first_name: [required],
  last_name: [required],
  email: [required, email],
  phone_number: [required, phone],
  postal_code: [required, postalCode],
});

export default profileValidator;
