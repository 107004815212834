import xhr, { createApiErrorAlert } from "util/xhr";
import { PAYMENT_INTENTS_URL } from "util/constants";

export const createSetupIntent = ({ win, paymentMethodType }) => {
  return async (dispatch, getState) => {
    return xhr
      .post(PAYMENT_INTENTS_URL, {
        locale: win.LOCALE,
        payment_method_type: paymentMethodType,
      })
      .catch((e) => {
        dispatch(createApiErrorAlert(e));
      });
  };
};
