import { combineReducers } from "redux";
import addresses from "account/redux/modules/addresses";
import profile from "account/redux/modules/profile";
import rabbitProfile from "account/redux/modules/rabbitProfile";
import password from "account/redux/modules/password";
import taxId from "account/redux/modules/taxId";
import accountLink from "account/redux/modules/accountLink";
import transactions from "account/redux/modules/transactions";
import notifications from "account/redux/modules/notifications";
import deactivation from "account/redux/modules/deactivation";
import billingInfo from "account/redux/modules/billingInfo";
import bankAccount from "account/redux/modules/bankAccount";
import avatar from "account/redux/modules/avatar";
import personalInformation from "account/redux/modules/personalInformation";
import accountSecurity from "account/redux/modules/accountSecurity";

export default combineReducers({
  addresses,
  personalInformation,
  profile,
  rabbitProfile,
  password,
  taxId,
  accountLink,
  transactions,
  notifications,
  deactivation,
  billingInfo,
  bankAccount,
  avatar,
  accountSecurity,
});
