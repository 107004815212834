import React, { Component } from "react";
import AddressSearchResultsItem from "./AddressSearchResultsItem";

export default class AddressSearchResults extends Component {
  render() {
    const { results: { items } = {}, onSelectItem, position } = this.props;
    return (
      <div style={position} className="sb-container--results">
        <ul className="sb-results--list">
          {items &&
            items.map((result, index) => {
              return (
                <AddressSearchResultsItem
                  {...result}
                  key={index}
                  handleClick={e => {
                    onSelectItem(result);
                  }}
                />
              );
            })}
        </ul>
      </div>
    );
  }
}
