import { PERSONAL_INFORMATION } from "account/routes/apiRoutes";
import xhr, { createApiErrorAlert } from "util/xhr";
import { combineEpics } from "redux-observable";
import { Observable } from "rxjs";

const NEXT_CONSENT_PAGE = "NEXT_CONSENT_PAGE";
const PREVIOUS_CONSENT_PAGE = "PREVIOUS_CONSENT_PAGE";
const PERFORM_PERSONAL_INFORMATION = "PERFORM_PERSONAL_INFORMATION";
const SEND_PI_RESPONSE = "SEND_PI_RESPONSE";
const PERFORM_PI_ERROR = "PERFORM_PI_ERROR";

const initialState = {
  currentPage: 1,
};

export default function reducer(state = initialState, action) {
  let current;
  const { currentPage } = state;
  switch (action.type) {
    case NEXT_CONSENT_PAGE:
      current = currentPage + 1;
      if (current > 5) current = 5;
      return {
        ...state,
        currentPage: current,
      };
    case PREVIOUS_CONSENT_PAGE:
      current = currentPage - 1;
      if (current === 0) current = 1;
      return {
        ...state,
        currentPage: current,
      };
    case SEND_PI_RESPONSE:
      return {
        ...state,
        currentPage: "thank-you",
      };
    default:
      return state;
  }
}

export const nextConsentPage = page => ({
  type: NEXT_CONSENT_PAGE,
  page,
});

export const previousConsentPage = () => ({
  type: PREVIOUS_CONSENT_PAGE,
});

export const performPersonalInformation = payload => ({
  type: PERFORM_PERSONAL_INFORMATION,
  payload,
});

const preparePersonalInformationData = getState => {
  const state = getState();
  const {
    first_name,
    last_name,
    ssn,
    address,
    locality,
    region,
    postal_code,
    requested_background_check_copy,
    consent_signature2,
  } = state.form.personal_information.values;

  return {
    first_name,
    last_name,
    ssn,
    address,
    locality,
    region,
    postal_code,
    requested_background_check_copy,
    consent_signature2,
  };
};

const performPersonalInformationEpic = (action$, { getState }) =>
  action$
    .ofType(PERFORM_PERSONAL_INFORMATION)
    .map(() => preparePersonalInformationData(getState))
    .switchMap(data =>
      Observable.from(xhr.put(PERSONAL_INFORMATION, data))
        .map(() => ({ type: SEND_PI_RESPONSE }))
        .catch(error => {
          return Observable.of(createApiErrorAlert(error), {
            type: PERFORM_PI_ERROR,
            error: error.response,
          });
        })
    );

export const personalInformationEpic = combineEpics(
  performPersonalInformationEpic
);
