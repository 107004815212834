import { format } from "util/money";
import { localeConfig } from "config/localeDriven";
import { IkeaProduct } from "ikea/types";
import { Location } from "shared/types";
import { IS_PHASE_ARTICLE_PRICING } from "util/constants";

const IKEA_PATH_KEY_PREFIX = "ikea.url_paths";

export function calculateJobAssemblySeconds(
  _hourlyRateCents: number,
  minimumFixedSeconds: number,
  items: IkeaProduct[] = []
) {
  if (!items.length) {
    return 0;
  }

  const totalSeconds = items.reduce((total, item) => {
    return total + item.assembly_seconds * (item.quantity || 1);
  }, 0);

  return Math.max(minimumFixedSeconds, totalSeconds);
}

export const calculatePrice = (seconds: number, hourlyRateCents: number) => {
  const hours = seconds / (60 * 60);
  const amount = hours * hourlyRateCents;

  if (IS_PHASE_ARTICLE_PRICING) {
    return amount;
  }

  // Round simplified products up to nearest whole dollar
  return Math.ceil(amount / 100) * 100;
};

export const calculateAssemblyPrice = (
  items: IkeaProduct[],
  hourlyRateCents = 0
) =>
  items.reduce((sum, item) => {
    let price =
      item.assembly_price ??
      calculatePrice(item.assembly_seconds, hourlyRateCents);

    price *= item.quantity ?? 1;

    return sum + price;
  }, 0) / 100;

/**
 * @deprecated Will no longer be using minimum fixed seconds or delivery cost
 */
export function calculateJobAssemblyPrice(
  hourlyRateCents: number,
  minimumFixedSeconds: number,
  items: IkeaProduct[]
) {
  if (!items.length) {
    return 0;
  }

  let totalBillableAmount = calculateAssemblyPrice(items, hourlyRateCents);

  const minimumPrice = calculatePrice(minimumFixedSeconds, hourlyRateCents);
  totalBillableAmount = Math.max(totalBillableAmount, minimumPrice / 100);

  return Math.ceil(totalBillableAmount);
}

export function getSecondsDisplayPrice(
  assemblySeconds: number,
  hourlyRateCents: number
) {
  return format((assemblySeconds * hourlyRateCents) / 100 / 3600, {
    noCentsIfWhole: true,
    rounded: true,
  });
}

export function getSecondsDisplayPriceForLocation(
  assemblySeconds: number,
  location?: Location,
  priceField:
    | "assembly_poster_hourly_rate_cents"
    | "assembly_poster_avg_hourly_rate_cents" = "assembly_poster_hourly_rate_cents"
) {
  const hourlyRateCents = location?.[priceField];
  if (!hourlyRateCents) {
    return "";
  }
  return getSecondsDisplayPrice(assemblySeconds, hourlyRateCents);
}

export function ikeaPath(viewName: string, locale = window.LOCALE) {
  let configKey = viewName;

  if (!configKey.includes(IKEA_PATH_KEY_PREFIX)) {
    configKey = `${IKEA_PATH_KEY_PREFIX}.${configKey}`;
  }

  const viewPath = localeConfig(locale, configKey);

  return viewPath;
}

export function ikeaPathMatch(path: string, ...expectedViewNames: string[]) {
  return expectedViewNames.some((viewName) => path === ikeaPath(viewName));
}

export const roundToNearestQuarterHour = (seconds: number) =>
  Math.max(1, Math.ceil(seconds / 3600 / 0.25) * 0.25);

export const formatEstimatedTotal = (seconds: number, hourlyRate: number) => {
  const hours = roundToNearestQuarterHour(seconds);
  const total = hourlyRate * hours;

  // Round to nearest $5 increment
  return Math.round(total / 500) * 5;
};
