/* eslint-disable no-case-declarations */
import xhr from "util/xhr";
import qs from "qs";
import get from "lodash/get";
import { addInDecimal } from "util/math";
import { format } from "util/money";
import { createAlert } from "alerts/redux/modules/alerts";
import { ADD_TIP_ENDPOINT, TIP_DATA_ENDPOINT } from "feedback/routes/apiRoutes";
import { defineMessages } from "react-intl";
import { internalPath } from "util/internalPath";
import { fireMetric } from "../../../store/middleware/metricMiddleware";

const messages = defineMessages({
  success: {
    id: "feedback.tips.tip_added_success",
    defaultMessage: "Thanks, your tip has been added",
  },
});

const initialState = {
  data: {},
  selected: null,
  customTipAmount: "",
  rabbit: {},
};

export const BOOTSTRAP_TIP_FORM =
  "v3/feedback/posterTipTasker/BOOTSTRAP_TIP_FORM";
export const FETCH_TIP_DATA = "v3/feedback/posterTipTasker/FETCH_TIP_DATA";
export const FETCH_TIP_DATA_RESPONSE =
  "v3/feedback/posterTipTasker/FETCH_TIP_DATA_RESPONSE";
export const FETCH_TIP_DATA_ERROR =
  "v3/feedback/posterTipTasker/FETCH_TIP_DATA_ERROR";
export const ADD_TIP = "v3/feedback/posterTipTasker/ADD_TIP";
export const ADD_TIP_RESPONSE = "v3/feedback/posterTipTasker/ADD_TIP_RESPONSE";
export const ADD_TIP_ERROR = "v3/feedback/posterTipTasker/ADD_TIP_ERROR";
export const SELECT_TIP_AMOUNT =
  "v3/feedback/posterTipTasker/SELECT_TIP_AMOUNT";

const fetchTipDataResponse = (data) => ({
  type: FETCH_TIP_DATA_RESPONSE,
  data,
});

const fetchTipDataError = (error) => ({
  type: FETCH_TIP_DATA_ERROR,
  error,
});

const addTip = () => ({
  type: ADD_TIP,
});

const addTipResponse = () => ({
  type: ADD_TIP_RESPONSE,
});

const addTipError = (error) => ({
  type: ADD_TIP_ERROR,
  error,
});

function buildMetricData({
  invoiceId,
  items,
  selected,
  customTipAmount,
  totalBare,
  currencyCode,
}) {
  const metricData = { invoiceId };
  if (selected != null) {
    Object.assign(metricData, { ...items[selected] });
  } else if (customTipAmount !== "") {
    const label = "Custom";
    const amount = customTipAmount.toString();
    const display = format(customTipAmount, {
      currencyCode,
      noCentsIfWhole: true,
      rounded: false,
    });
    const total = format(addInDecimal(customTipAmount, totalBare), {
      currencyCode,
      noCentsIfWhole: true,
      rounded: false,
    });
    Object.assign(metricData, { label, amount, display, total });
  }
  return metricData;
}

// eslint-disable-next-line @typescript-eslint/default-param-last
export default function tip(state = initialState, action) {
  switch (action.type) {
    case FETCH_TIP_DATA:
      return { ...state, isLoading: true };
    case FETCH_TIP_DATA_RESPONSE:
      // this shuts off tipping if API returns back a null `data` key
      const data = action.data ? action.data : { tippingDisabled: true };
      return {
        ...state,
        isLoading: false,
        ...data,
      };
    case FETCH_TIP_DATA_ERROR:
      const error = action.error === 403 ? "forbidden_error" : "generic_error";
      return { ...state, isLoading: false, error };
    case ADD_TIP:
      return { ...state, isLoading: true };
    case ADD_TIP_ERROR:
      return { ...state, isLoading: false, error: add_tip_err(action.error) };
    case ADD_TIP_RESPONSE:
      return { ...state, isLoading: false };
    case SELECT_TIP_AMOUNT:
      return {
        ...state,
        selected: action.index,
        customTipAmount: action.customTipAmount,
      };
    default:
      return state;
  }
}

const add_tip_err = (err) =>{
  if (/25/.test(err)){
    return "tipping_error";
   }else {
    return "invalid_input_error";
   }
}

export const bootstrapTipForm = ({ invoiceId }) => {
  return async (dispatch) => {
    dispatch({
      type: BOOTSTRAP_TIP_FORM,
      invoiceId,
    });
    try {
      const { camelCasedData: data } = await xhr.get(
        TIP_DATA_ENDPOINT + "?invoice_id=" + invoiceId
      );
      dispatch(fetchTipDataResponse(data));
    } catch (error) {
      dispatch(fetchTipDataError(error.response.status));
    }
  };
};

export const submitTip = () => (dispatch, getState) => {
  const state = getState();
  const {
    data: { items },
    invoiceId,
    selected,
    customTipAmount,
    totalBare,
    currencyCode,
    jobGuid,
    prepaid,
  } = get(state, "feedback.posterTipTasker");
  const {
    router: {
      location: { search },
    },
  } = state;

  const { amount } = items[selected]
    ? items[selected]
    : { amount: customTipAmount };

  const metricData = buildMetricData({
    invoiceId,
    items,
    selected,
    customTipAmount,
    totalBare,
    currencyCode,
  });
  dispatch(addTip());

  dispatch(fireMetric("poster_left_tip_for_tasker", metricData));

  const url = ADD_TIP_ENDPOINT.replace(":invoiceId", invoiceId);
  const { addFavorite } = qs.parse(search);

  return xhr.post(url + "?" + qs.stringify({ amount })).then(
    () => {
      dispatch(addTipResponse());
      if (amount > 0){
        dispatch(createAlert(messages.success.id));
      }

      const redirectUrl = prepaid ? `/dashboard/details/${jobGuid}?` : "/dashboard/active?";
      const queryStrings = {};

      if (addFavorite) {
        queryStrings.addFavorite = true;
      }

      if (prepaid) {
        queryStrings.invoiceId = invoiceId;
        queryStrings.tipped = amount > 0;
      }

      window.location = internalPath(
        redirectUrl + qs.stringify(queryStrings)
      );
    },
    (e) => dispatch(addTipError(e.response.data.message))
  )
};

export const selectTipAmount = (index) => (dispatch, getState) => {
  const state = getState();
  const {
    data: { items },
    invoiceId,
  } = get(state, "feedback.posterTipTasker");
  const metricData = buildMetricData({ invoiceId, items, selected: index });
  dispatch({
    type: SELECT_TIP_AMOUNT,
    index,
    customTipAmount: "",
  });
  dispatch(fireMetric("poster_toggled_tip_amount", metricData));
};

export const deselectTipAmount = () => (dispatch) => {
  dispatch({
    type: SELECT_TIP_AMOUNT,
    index: null,
    customTipAmount: "",
  });
};

export const selectCustomTipAmount = (amount) => (dispatch) => {
  dispatch({
    type: SELECT_TIP_AMOUNT,
    index: null,
    customTipAmount: amount,
  });
};
