import { createStore as _createStore, applyMiddleware, compose } from "redux";
import { routerMiddleware } from "connected-react-router";
import thunkMiddleware from "redux-thunk";
import metricMiddleware from "store/middleware/metricMiddleware";
import promiseMiddleware from "store/middleware/promiseMiddleware";
import { createEpicMiddleware } from "redux-observable";
import xhr from "util/xhr";
import { createLogger } from "redux-logger";
import { composeWithDevTools } from "redux-devtools-extension";

let store; // make store become singleton

export default function createStore({ history, initialState, createRootReducer, rootEpic }) {
  if (store && !initialState) return store;

  let middlewareCompose = compose;
  // Sync dispatched route actions to the history
  const reduxRouterMiddleware = routerMiddleware(history);
  const epicMiddleware = createEpicMiddleware(rootEpic);
  const middleware = [
    metricMiddleware,
    promiseMiddleware(xhr),
    reduxRouterMiddleware,
    thunkMiddleware,
    epicMiddleware,
  ];

  if (process.env.REDUX_LOGGER) {
    const options = {
      collapsed: true,
    };
    middleware.push(createLogger(options));
  }

  // this is useful if you have redux-devtools
  // https://github.com/zalmoxisus/redux-devtools-extension in your browser
  if (process.env.DEVTOOLS) {
    middlewareCompose = composeWithDevTools;
  }
  store = _createStore(
    createRootReducer(history),
    initialState,
    middlewareCompose(applyMiddleware(...middleware))
  );

  if (process.env.NODE_ENV === "development" && module.hot) {
    module.hot.accept("../reducers", () => {
      // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
      store.replaceReducer(require("../reducers").default);
    });
  }

  return store;
}
