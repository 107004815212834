import React from "react";
import styled from "styled-components";
import { defineMessages } from "react-intl";
import { localeConfig } from "../../../config/localeDriven";
import countryIsoCodeFromLocale from "../../../util/countryIsoCodeFromLocale";
import { HeaderReferralLink } from "./HeaderLinks/HeaderReferralLink";
import { HeaderLink } from "./HeaderLinks/HeaderLink";
import { ServicesMenu } from "./HeaderLinks/ServicesMenu";

const messages = defineMessages({
  contactlessTasks: {
    id: "StyledHeaderNavContainer.HeaderNav.HeaderLinks.contactlessTasks",
    defaultMessage: "Contactless Tasks & Delivery",
  },
  bookATask: {
    id: "StyledHeaderNavContainer.HeaderNav.HeaderLinks.bookATask",
    defaultMessage: "Book a Task",
  },
  myTasks: {
    id: "StyledHeaderNavContainer.HeaderNav.HeaderLinks.myTasks",
    defaultMessage: "My Tasks",
  },
  myTaskers: {
    id: "StyledHeaderNavContainer.HeaderNav.HeaderLinks.myTaskers",
    defaultMessage: "My Taskers",
  },
  taskerProfile: {
    id: "StyledHeaderNavContainer.HeaderNav.HeaderLinks.taskerProfile",
    defaultMessage: "Tasker Profile",
  },
  account: {
    id: "StyledHeaderNavContainer.HeaderNav.HeaderLinks.account",
    defaultMessage: "Account",
  },
  aboutOurAds: {
    id: "StyledHeaderNavContainer.HeaderNav.HeaderLinks.aboutOurAds",
    defaultMessage: "About Our Ads",
  },
  logIn: {
    id: "StyledHeaderNavContainer.HeaderNav.HeaderLinks.logIn",
    defaultMessage: "Sign up / Log in",
  },
  becomeATasker: {
    id: "StyledHeaderNavContainer.HeaderNav.HeaderLinks.becomeATasker",
    defaultMessage: "Become a Tasker",
  },
});

export function HeaderLinks({
  deviceType,
  dontShowLinks,
  firstMobileLinkRef,
  hasActiveTasks,
  hasCompletedTasks,
  isInsideReactRouter,
  isRabbit,
  locale,
  loggedIn,
  mobileExpandInitialState,
  referralData,
  routing,
  serviceIndexPath,
  servicesLinks,
  showMyTaskersLink,
  testid,
}) {
  const contactlessTasksUrl = localeConfig(
    locale,
    "localeRoutes.covid19.contactless.url"
  );
  const becomeATaskerUrl = localeConfig(
    locale,
    "localeRoutes.becomeATasker.url"
  );

  if (dontShowLinks) {
    return null;
  }

  const needContactlessTasks = localeConfig(
    locale,
    "localeRoutes.covid19.showContactlessTab"
  );

  const showContactlessTasks = needContactlessTasks && contactlessTasksUrl;

  return (
    <div data-testid={testid}>
      {showContactlessTasks && (
        <HeaderLink
          key="Contactless Tasks & Delivery"
          innerRef={firstMobileLinkRef}
          baseUrl={contactlessTasksUrl}
          labelMessage={messages.contactlessTasks}
          iconClassName="ss-arrow-right"
          eventName="contactless_header"
          isInsideReactRouter={isInsideReactRouter}
          nestedUrl=""
          routing={routing}
        />
      )}

      <ServicesMenu
        deviceType={deviceType}
        links={servicesLinks}
        mobileExpandInitialState={mobileExpandInitialState}
        serviceIndexPath={serviceIndexPath}
      />

      <HeaderReferralLink
        isInsideReactRouter={isInsideReactRouter}
        loggedIn={loggedIn}
        referralData={referralData}
        routing={routing}
        innerRef={showContactlessTasks ? undefined : firstMobileLinkRef}
      />

      {loggedIn && (
        <HeaderLink
          key="Book a Task"
          baseUrl="/dashboard"
          labelMessage={messages.bookATask}
          iconClassName="ss-arrow-right"
          isInsideReactRouter={isInsideReactRouter}
          nestedUrl="/explore"
          routing={routing}
        />
      )}

      {loggedIn && (
        <HeaderLink
          key="My Tasks"
          baseUrl="/dashboard"
          labelMessage={messages.myTasks}
          iconClassName="ss-rkt-tasks"
          isInsideReactRouter={isInsideReactRouter}
          nestedUrl="/active"
          routing={routing}
        />
      )}
      {loggedIn && hasActiveTasks && <Dot />}

      {loggedIn && showMyTaskersLink && hasCompletedTasks && (
        <HeaderLink
          key="My Taskers"
          baseUrl="/dashboard"
          labelMessage={messages.myTaskers}
          iconClassName="ss-goto-team"
          isInsideReactRouter={isInsideReactRouter}
          nestedUrl="/team"
          routing={routing}
        />
      )}

      {isRabbit && (
        <HeaderLink
          key="Tasker Profile"
          baseUrl="/settings"
          labelMessage={messages.taskerProfile}
          iconClassName="ss-user"
          isInsideReactRouter={isInsideReactRouter}
          nestedUrl="/profile"
          routing={routing}
        />
      )}

      {loggedIn && (
        <HeaderLink
          baseUrl="/account"
          iconClassName="ss-rkt-profile"
          innerRef={firstMobileLinkRef}
          isInsideReactRouter={isInsideReactRouter}
          key="Account"
          labelMessage={messages.account}
          nestedUrl=""
          routing={routing}
        />
      )}

      {loggedIn && countryIsoCodeFromLocale(locale).toUpperCase() === "CA" && (
        <HeaderLink
          key="About Our Ads"
          baseUrl="/adpreferences"
          labelMessage={messages.aboutOurAds}
          iconClassName="ss-info-lined"
          isInsideReactRouter={isInsideReactRouter}
          $mobileOnly={true}
          nestedUrl=""
          routing={routing}
        />
      )}

      {!loggedIn && (
        <HeaderLink
          key="Sign up / Log in"
          baseUrl="/login?source=header"
          labelMessage={messages.logIn}
          iconClassName="ss-lnr-lock"
          isInsideReactRouter={isInsideReactRouter}
          nestedUrl=""
          routing={routing}
        />
      )}

      {!loggedIn && (
        <HeaderLink
          key="Become a Tasker"
          baseUrl={becomeATaskerUrl}
          labelMessage={messages.becomeATasker}
          iconClassName="ss-goto-team"
          $displayAsButton={true}
          isInsideReactRouter={isInsideReactRouter}
          nestedUrl=""
          routing={routing}
          withBorder={true}
        />
      )}
    </div>
  );
}

const Dot = styled.span`
  width: 12px;
  height: 12px;
  background-color: ${({ theme }) => theme.color.reviewStar};
  border-radius: 999px;
  position: absolute;
  margin-left: -${(props) => props.theme.spacing.md};
`;
