export const scheduleValid = (schedule) =>
  schedule && schedule.date && typeof schedule.offset_seconds !== "undefined";

export const modifyAvailability = (response) => {
  const {
    data: { availability },
  } = response;

  if (!availability) return response;

  return {
    ...response,
    data: {
      ...response.data,
      availability: {
        items: Object.keys(availability).map((date) => ({
          date,
          items: availability[date].slots,
          ...availability[date],
        })),
      },
    },
  };
};
