import React, { Component, createElement } from "react";
import ReactModal from "react-modal";
import { useMediaQuery } from "react-responsive";
import cn from "classnames";

export const defaultStyle = {
  overlay: {
    position: "fixed",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    opacity: 1,
    animationName: "lightboxFadeIn",
    animationDuration: "0.25s",
    zIndex: 200,
    backgroundColor: "rgba(235, 236, 236, .85)",
  },
  content: {
    padding: 0,
    cursor: "default",
    overflowY: "auto",
    boxShadow: "0px 2px 2px 0px rgba(#323A3B, 0.1)",
    outline: "none",
    borderRadius: "8px",
  },
};

const enhanceWithModal = (WrappedComponent, options = {}) => {
  class ModalContainerWrapper extends Component {
    mounted = false;

    constructor(props) {
      super(props);
      this.state = {
        isOpen: this.props.isOpen,
      };

      this.close = this.close.bind(this);
      this.open = this.open.bind(this);
      this.affirm = this.affirm.bind(this);
      this.decline = this.decline.bind(this);
    }

    componentDidMount() {
      this.mounted = true;
    }

    componentWillUnmount() {
      this.mounted = false;
    }

    close() {
      if (this.mounted) {
        this.setState({ isOpen: false });
      }
    }

    open() {
      if (this.mounted) {
        this.setState({ isOpen: true });
      }
    }

    affirm(data) {
      if (this.props.affirmFn instanceof Function) this.props.affirmFn(data);
      this.close();
    }

    decline() {
      if (this.props.declineFn instanceof Function) {
        this.props.declineFn();
      }
      this.close();
    }

    render() {
      const {
        isMobileView,
        isLoginForm,
        recommendationsModal,
        style,
      } = this.props;
      let finalStyle = defaultStyle;
      if (isMobileView && isLoginForm && style) {
        finalStyle = style;
      } else if (!isLoginForm && style) {
        finalStyle = { ...style, overlay: { ...style.overlay, zIndex: "600" } };
      } else if (!isLoginForm) {
        finalStyle = {
          ...defaultStyle,
          overlay: { ...defaultStyle.overlay, zIndex: "600" },
        };
      }

      const wrappedElement = createElement(WrappedComponent, {
        ...this.props,
        // replace original affirmFn/declineFn w/ version to close modal
        affirmFn: this.affirm,
        declineFn: this.decline,
      });
      const modalCls = cn({
        "lightbox--internal": !isLoginForm,
        "login-signup-lightbox--internal": isLoginForm,
        confirm__recommendations__modal: recommendationsModal,
      });

      ReactModal.setAppElement(this.props.appElement || "body");

      return (
        <ReactModal
          style={finalStyle}
          isOpen={this.state.isOpen}
          onRequestClose={this.props.dismissable ? this.decline : () => {}}
          className={modalCls}
          contentLabel="Modal"
          aria={this.props.aria}
          role="dialog"
        >
          {wrappedElement}
        </ReactModal>
      );
    }
  }

  ModalContainerWrapper.defaultProps = {
    dismissable: true,
    appElement: null,
    aria: {},
  };

  return (props) => {
    const phone = useMediaQuery({ query: "(max-width: 800px)" });

    return (
      <ModalContainerWrapper {...props} isMobileView={phone} {...options} />
    );
  };
};

export default enhanceWithModal;
