const rawColors = {
  green05: "#f7fffa",
  green20: "#e5ffef",
  green30: "#c5ffdc",
  green55: "#32ab77",
  green60: "#022022",
  green75: "#0a2b14",
  green500: "#0d7a5f",
  green600: "#055240",
  green700: "#113D28",
};

const colors = {
  black: "#000000",
  white: "#ffffff",

  "tr-red-10": "#d14200",
  "tr-red-20": "#e23200",

  "tr-green-10": "#cbedc0",
  "tr-green-20": "#a4df90",
  "tr-green-30": "#7dd161",
  "tr-green-40": "#63c841",
  "tr-green-50": "#51af33",
  "tr-green-60": "#438e29",
  "tr-green-70": "#346f20",
  "tr-green-80": "#254f17",
  "tr-green-90": "#162f0e",

  "tr-blue-10": "#e5f3f5",
  "tr-blue-20": "#cce9ec",
  "tr-blue-30": "#b1dee3",
  "tr-blue-40": "#81ced5",
  "tr-blue-50": "#36b5bb",
  "tr-blue-60": "#31a7a8",
  "tr-blue-70": "#2d9994",
  "tr-blue-80": "#288c81",
  "tr-blue-90": "#206d5f",

  "tr-yellow-10": "#fef9eb",
  "tr-yellow-20": "#fdedc4",
  "tr-yellow-30": "#fce19c",
  "tr-yellow-40": "#facf61",
  "tr-yellow-50": "#f9c339",
  "tr-yellow-60": "#f8b612",
  "tr-yellow-70": "#da9e07",
  "tr-yellow-80": "#b28106",
  "tr-yellow-90": "#8b6404",

  "tr-gray-10": "#f9fafb",
  "tr-gray-20": "#e8ebef",
  "tr-gray-30": "#dce0e6",
  "tr-gray-40": "#b1b9c3",
  "tr-gray-50": "#86919d",
  "tr-gray-60": "#67727e",
  "tr-gray-70": "#545e69",
  "tr-gray-80": "#404852",
  "tr-gray-90": "#242a30",
  "tr-gray-94": "#f0f0f0",

  // Colors from rebranding START
  // blue - active
  blue10: "#e5f3f5",
  blue50: "#36b5bb",
  blue60: "#31aa7a8",
  blue70: "#2d9994",
  blue90: "#206d5f",

  // blue - inactive
  blue20: "#cce9ec",
  blue30: "#b1dee3",
  blue40: "#81ced5",
  blue80: "#288c81",

  // gray
  grey10: "#f9fafb",
  grey20: "#e8ebef",
  grey30: "#dce0e6",
  grey40: "#b1b9c3",
  grey50: "#86919d",
  grey60: "#67727e",
  grey70: "#545e69",
  grey80: "#404852",
  grey90: "#242a30",
  grey90alpha60: "#242a3099",

  // green - active
  green10: "#cbedc0",
  green30: "#7dd161",
  green50: "#51af33",
  green60: "#438e29",
  green70: "#346f20",

  // green - inactive
  green20: "#a4df90",
  green40: "#63c841",
  green80: "#254f17",
  green90: "#162f0e",

  // yellow - active
  yellow30: "#fce19c",
  yellow50: "#f9c339",
  yellow60: "#f8b612",
  yellow70: "#da9e07",

  // yellow - inactive
  yellow10: "#fef9eb",
  yellow20: "#fdedc4",
  yellow40: "#facf61",
  yellow80: "#b28106",
  yellow90: "#8b6404",

  // other
  rocketGray: "#fafafa",
  orange50: "#d14200",
  pink50: "#ea5f68",
  shimmerDefault3: "#d7dcd9",

  primaryDefault: rawColors.green75,
  primaryLight: rawColors.green05,
  primaryMedium: rawColors.green20,
  primaryDark: rawColors.green30,
  primarySubtle: rawColors.green500,
  primarySubtleLight: rawColors.green20,
  primarySubtle2: rawColors.green55,
  primaryEmphasis: rawColors.green600,
  impactEmphasis: rawColors.green700,
  tertiaryMain: "#894fb0",

  textDisplay: rawColors.green60,
  textLabelValue: "#3d463d",
  deviceBackground: "#fbfbfb",
  textNavBarTitle: "#0d7a5f",

  // Colors from rebranding END
};

export const MaxWidthBreakpoint = {
  mobile: 500,
};

export const MinWidthBreakpoint = {
  tablet: 501,
  desktop: 801,
  lgDesktop: 1025,
  xlDesktop: 1201,
};

export const theme = {
  breakpoints: {
    /**
     * DEPRECATED: It's recommended that you not use the `mobile` breakpoint
     * when writing CSS. The recommended approach is to write mobile CSS first,
     * and then use the `tabletUp`, `desktopUp`, `lgDesktopUp`, and
     * `xlDesktopUp` breakpoints to override that CSS for larger screen sizes.
     *
     * 500 px and below.
     */
    mobile: "@media screen and (max-width: 500px)",

    /** 501 px and up. */
    tabletUp: "@media screen and (min-width: 501px)",
    /** 801 px and up. */
    desktopUp: "@media screen and (min-width: 801px)",
    /** 1025 px and up. */
    lgDesktopUp: "@media screen and (min-width: 1025px)",
    /** 1201 px and up. */
    xlDesktopUp: "@media screen and (min-width: 1201px)",
  },
  layout: {
    borderRadius: {
      /** 4px, 0.25rem */
      md: "0.25rem",
      /** 4px, 0.25rem */
      btn: "0.5rem",
      round: "50%",
    },
  },
  spacing: {
    /** 4px, 0.25rem */
    xs: "0.25rem",
    /** 8px, 0.5rem */
    sm: "0.5rem",
    /** 16px, 1rem */
    md: "1rem",
    /** 24px, 1.5rem */
    lg: "1.5rem",
    /** 32px, 2rem */
    xl: "2rem",
    /** 40px, 2.5rem */
    xxl: "2.5rem",
    /** 48px, 3rem */
    xxxl: "3rem",
    /** 56px, 3.5rem */
    xxxxl: "3.5rem",
  },
  typography: {
    baseFont: '"Inter", "Arial", "Helvetica", sans-serif',
    typeScale: {
      /** 10px, 0.625rem */
      12: "0.625rem",
      /** 12px, 0.75rem */
      25: "0.75rem",
      /** 14px, 0.875rem */
      50: "0.875rem",
      /** 16px, 1rem */
      100: "1rem",
      /** 18px, 1.125rem */
      200: "1.125rem",
      /** 19px, 1.1875rem */
      250: "1.1875rem",
      /** 20px, 1.25rem */
      300: "1.25rem",
      /** 22px, 1.375rem */
      350: "1.375rem",
      /** 24px, 1.5rem */
      400: "1.5rem",
      /** 28px, 1.75rem */
      500: "1.75rem",
      /** 32px, 2rem */
      600: "2rem",
      /** 40px, 2.5rem */
      700: "2.5rem",
      /** 48px, 3rem */
      800: "3rem",
      /** 56px, 3.5rem */
      900: "3.5rem",
      /** 64px, 4rem */
      1000: "4rem",
    },
    weight: {
      /** 300 */
      light: 300,
      /** 400, normal */
      regular: 400,
      /** 500, slightBold */
      slightBold: 500,
      /** 600 */
      semiBold: 600,
      /** 700, bold */
      bold: 700,
      /** 800, extraBold */
      extraBold: 800,
    },
  },
  color: {
    white: colors.white,
    black: colors.black,
    trGrey10: colors["tr-gray-10"], // Deprecated: Do not use colors named for their appearance.
    trGrey20: colors["tr-gray-20"], // Deprecated: Do not use colors named for their appearance.
    trGrey30: colors["tr-gray-30"], // Deprecated: Do not use colors named for their appearance.
    trGrey40: colors["tr-gray-40"], // Deprecated: Do not use colors named for their appearance.
    trGrey50: colors["tr-gray-50"], // Deprecated: Do not use colors named for their appearance.
    trGrey60: colors["tr-gray-60"], // Deprecated: Do not use colors named for their appearance.
    trGrey70: colors["tr-gray-70"], // Deprecated: Do not use colors named for their appearance.
    trGrey80: colors["tr-gray-80"], // Deprecated: Do not use colors named for their appearance.
    trGreen10: colors["tr-green-10"],
    trGreen50: colors["tr-green-50"], // Deprecated: Do not use colors named for their appearance.
    trGreen80: colors["tr-green-80"],
    trGreen90: colors["tr-green-90"],
    trBlue10: colors["tr-blue-10"], // Deprecated: Do not use colors named for their appearance.
    trBlue60: colors["tr-blue-60"], // Deprecated: Do not use colors named for their appearance.
    trBlue90: colors["tr-blue-90"], // Deprecated: Do not use colors named for their appearance.
    trYellow10: colors["tr-yellow-10"], // Deprecated: Do not use colors named for their appearance.
    trYellow90: colors["tr-yellow-90"], // Deprecated: Do not use colors named for their appearance.

    primaryDefault: colors.primaryDefault,
    textDisplay: colors.textDisplay,
    textLabelValue: colors.textLabelValue,
    primarySubtle: rawColors.green500,
    primarySubtleLight: rawColors.green20,
    primarySubtle2: rawColors.green55,
    primaryEmphasis: rawColors.green600,
    impactEmphasis: rawColors.green700,
    subtitleText: colors.grey90,

    loadingButtonText: colors["tr-gray-10"],
    loadingButtonBackground: colors["tr-gray-10"],
    loadingButtonBorder: colors["tr-gray-40"],

    neutral: colors["tr-gray-50"],

    highlighted: colors["tr-blue-60"],

    accentSeparator: colors.primarySubtle,
    accentIcon: colors.primarySubtle,

    chevronIcon: colors.primarySubtle,

    bodyText: colors.textDisplay,
    errorText: colors["tr-red-10"],
    error: colors["tr-red-10"],

    link: colors.primarySubtle,
    linkVisited: colors.primarySubtle,
    linkHover: colors["tr-green-70"],
    linkActive: colors["tr-green-70"],
    linkSelected: colors["tr-blue-60"],
    linkDisabled: colors["tr-gray-40"],

    buttonPrimary: colors.primarySubtle,
    buttonPrimaryHover: colors.black,

    buttonSecondary: colors.primarySubtle,
    buttonSecondaryHover: colors.white,
    attention: colors["tr-yellow-70"],

    reviewStar: colors.primaryDefault,
    grayBorder: colors["tr-gray-30"],
    horizontalRule: colors["tr-gray-30"],
    boxShadow: colors["tr-gray-20"],
    loadingSpinner: colors["tr-gray-30"],
    loadingSpinnerLeft: colors["tr-gray-50"],
    heartRed: colors["tr-red-20"],
    background: colors["tr-gray-10"],

    bigLinkButtonText: colors.primarySubtle,
    bigLinkButtonBorder: colors.primarySubtle,
    alternateRow: colors["tr-gray-20"],

    buttonText: colors["tr-gray-70"],
    buttonBorder: colors["tr-gray-40"],
    buttonBackground: colors.white,

    buttonTextHover: colors["tr-gray-90"],
    buttonBackgroundHover: colors["tr-gray-20"],

    primeInverseButtonText: colors.primarySubtle,
    primeInverseButtonBorder: colors.primarySubtle,
    primeInverseButtonBackground: colors.white,

    primeInverseButtonTextHover: colors["tr-green-60"],
    primeInverseButtonBorderHover: colors["tr-green-60"],
    primeInverseButtonBackgroundHover: colors["tr-gray-10"],

    buttonListBorder: colors["tr-green-30"],

    buttonTextPrimary: colors.white,
    buttonBorderPrimary: colors.primarySubtle,
    buttonBackgroundPrimary: colors.primarySubtle,

    buttonTextPrimaryHover: colors.white,
    buttonBorderPrimaryHover: colors.primaryEmphasis,
    buttonBackgroundPrimaryHover: colors.primaryEmphasis,

    buttonTextSecondary: colors.primarySubtle,
    buttonBorderSecondary: colors.primarySubtle,
    buttonBackgroundSecondary: colors.white,

    buttonBorderSecondaryHover: colors.primaryEmphasis,
    buttonBackgroundSecondaryHover: colors.white,
    buttonTextSecondaryHover: colors.primaryEmphasis,

    categoryPriceDiscount: colors["tr-blue-50"],
    greyedOutText: colors["tr-gray-70"],

    placeholderText: colors["tr-gray-50"],

    /** tr-gray-90 @ 75% opacity */
    modalBackgroundOverlay: `rgba(36, 42, 48, 0.75)`,
    /** tr-gray-90 @ 100% opacity */
    modalBackgroundOverlayOpaque: `rgba(36, 42, 48, 1)`,

    accentText: colors["tr-blue-50"],
    mutedText: colors["tr-gray-70"],

    backgroundAlternate: colors["tr-gray-10"],

    subduedText: colors["tr-gray-50"],

    border: colors["tr-gray-30"],
    darkBorder: colors["tr-gray-90"],

    inputBorder: colors["tr-gray-40"],
    errorBorder: colors["tr-red-10"],
    footerBackground: colors["tr-gray-60"],
    footerText: colors["tr-gray-40"],

    /** tr-gray-90 @ 15% opacity */
    dropShadow: `rgba(36, 42, 48, 0.15)`,

    buttonBackgroundDisabled: colors["tr-gray-30"],
    buttonTextDisabled: colors.white,
    buttonBorderColorDisabled: colors["tr-gray-30"],
    buttonCloseIcon: colors["tr-gray-90"],

    stepNumber: colors.shimmerDefault3,
    stepNumberText: colors.textDisplay,

    visitedStepNumber: colors.primaryDefault,
    visitedStepNumberText: colors.white,

    progressBar: colors.shimmerDefault3,
    visitedProgressBar: colors.primaryDefault,

    headerBackground: colors.white,

    selectMenuBackground: colors.white,
    selectMenuBorder: colors["tr-gray-30"],

    taskNotificationIndicator: colors.tertiaryMain,

    pillFilterText: colors.primarySubtle,
    pillFilterBackground: colors.white,
    pillFilterSelectedBackground: colors.primarySubtle,
    eliteTaskerBadge: colors["tr-blue-60"],
    valueBadge: colors["tr-blue-20"],
    valueBadgeText: colors["tr-blue-80"],
    readMoreText: colors.primaryDefault,
    paginationColor: rawColors.green60,
  },
};

export type Theme = typeof theme;

declare module "styled-components" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  export interface DefaultTheme extends Theme {}
}
