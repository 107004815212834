import { useEffect, useState } from "react";

export function useWindowInnerWidth(): number {
  /*
  We don't set the default value of `windowInnerWidth` to `window.innerWidth`
  because this would cause differences between server-side and client-side
  renderings. We have to wait until after the first render to access the
  `window` global.
  */
  const [windowInnerWidth, setWindowInnerWidth] = useState(0);

  useEffect(() => {
    const updateWindowInnerWidth = () => setWindowInnerWidth(window.innerWidth);
    updateWindowInnerWidth();
    window.addEventListener("resize", updateWindowInnerWidth);
    return () => {
      window.removeEventListener("resize", updateWindowInnerWidth);
    };
  }, []);

  return windowInnerWidth;
}
