export function encourageOnDismissUsage(onDismiss) {
  if (onDismiss == null && process.env.NODE_ENV === "development") {
    // eslint-disable-next-line no-console
    console.warn(
      "The callback function onDismiss is called when the user taps outside of " +
        "a modal or presses the escape key. The onDismiss callback you provide " +
        "should close the modal when called. It is highly recommended that you " +
        "provide an onDismiss callback for accessibility reasons. If you " +
        "really do not want to provide an onDismiss callback, you can provide " +
        "a no-op function instead: `<TRModal onDismiss={() => {}} />`."
    );
  }
}
