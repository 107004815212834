import React, { Component } from "react";

export default class AddressSearchResultsItem extends Component {
  render() {
    const { handleClick, description } = this.props;
    return (
      <li
        className="sb-results--item"
        data-state={this.props.selected ? "highlighted" : "default"}
        tabIndex="-1"
      >
        <a className="js-sb--template-link" onMouseDown={handleClick}>
          <div className="media--object">
            <div className="media--figure">
              <i className="ss-icon ss-rkt-pin" />
            </div>
            <div className="media--content">{description}</div>
          </div>
        </a>
      </li>
    );
  }
}
