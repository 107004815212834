let cache;

export function prepareLocaleMessages() {
  if (!cache) {
    cache = {
      messages: window.LOCALE_MESSAGES,
      locale: window.LOCALE,
    };
  }
  return cache;
}
