import { useEffect, useState } from "react";

export function useWindowInnerHeight() {
  /*
  We don't set the default value of `windowInnerHeight` to `window.innerHeight`
  because this would cause differences between server-side and client-side
  renderings. We have to wait until after the first render to access the
  `window` global.
  */
  const [windowInnerHeight, setWindowInnerHeight] = useState(0);

  useEffect(() => {
    const updateWindowInnerHeight = () =>
      setWindowInnerHeight(window.innerHeight);
    updateWindowInnerHeight();
    window.addEventListener("resize", updateWindowInnerHeight);
    return () => {
      window.removeEventListener("resize", updateWindowInnerHeight);
    };
  }, []);
  return windowInnerHeight;
}
