import { useState, useEffect } from "react";
import transparentPixel from "../../../../../shared/utils/transparentPixel";
import { useIsMountedRef } from "../../../../../util/useIsMountedRef";
import { getBaseUrl } from '../../../../../util/getBaseUrl';

const storeBadgePaths = {
  appStore: "appstore_badge",
  playStore: "google_play_badge",
};

const badgeAspectRatios = {
  appStore: 119.664 / 40,
  playStore: 577 / 176,
};

export function useBadgeImageData({ storeType, locale }) {
  const [imgSrc, setImgSrc] = useState(transparentPixel);
  const [loaded, setLoaded] = useState(false);
  const aspectRatio = badgeAspectRatios[storeType];
  const isMountedRef = useIsMountedRef();
  const badgePath = `${getBaseUrl()}v3/assets/web/${locale}/${storeBadgePaths[storeType]}.svg`;

  useEffect(() => {
    if (isMountedRef.current) {
      setLoaded(true);
      setImgSrc(badgePath);
    }
  }, [locale, badgePath, storeType, isMountedRef]);

  const testid = `${storeType}-badge-${loaded ? "loaded" : "loading"}`;

  return { imgSrc, aspectRatio, testid };
}
