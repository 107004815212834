import { DIMENSION_ENDPOINT } from "feedback/routes/apiRoutes";
import { removeFeedbackActionFromTask } from "dashboard/redux/modules/taskLists";
import { snake_caseKeys } from "util/casing";

const CHANGE_FEEDBACK_REASON = "CHANGE_FEEDBACK_REASON";
const SET_VIEW_STATE = "SET_VIEW_STATE";
const SUBMIT_FEEDBACK = "SUBMIT_FEEDBACK";
const SUBMIT_FEEDBACK_RESPONSE = "SUBMIT_FEEDBACK_RESPONSE";
const SUBMIT_FEEDBACK_ERROR = "SUBMIT_FEEDBACK_ERROR";
const INITIALIZE = "INITIALIZE";

const initialState = {
  reason: null,
  viewState: "ask",
};

export default function reportTasker(state = initialState, action) {
  switch (action.type) {
    case INITIALIZE:
      return initialState;
    case SET_VIEW_STATE:
      return { ...state, viewState: action.viewState };
    case CHANGE_FEEDBACK_REASON:
      return { ...state, reason: action.reason };
    default:
      return state;
  }
}

export const setFeedbackReason = reason => ({
  type: CHANGE_FEEDBACK_REASON,
  reason,
});

export const initialize = () => ({
  type: INITIALIZE,
});

export const submitTaskerFeedback = ({ jobId, rabbitId }) => {
  return (dispatch, getState) => {
    const { reason } = getState().dashboard.reportTasker;

    const source = "poster_reported";
    const data = {
      value_key: reason,
      jobId,
      rabbitId,
      source,
      dimension_key: source,
    };
    const snakeCaseData = snake_caseKeys(data);
    return dispatch({
      types: [SUBMIT_FEEDBACK, SUBMIT_FEEDBACK_RESPONSE, SUBMIT_FEEDBACK_ERROR],
      promise: xhr => {
        return xhr.post(DIMENSION_ENDPOINT, snakeCaseData).then(response => {
          dispatch({ type: SET_VIEW_STATE, viewState: "thanks" });
          dispatch(removeFeedbackActionFromTask(jobId));
        });
      },
    });
  };
};
