import { combineReducers, Reducer } from "redux";
import taskerProfile from "./modules/taskerProfile";
import taskerPhotos from "./modules/taskerPhotos";
import taskerReviews from "./modules/taskerReviews";
import taskerProfileState from "./modules/taskerProfileState";
import rescueTasker from "./modules/rescueTasker";

export default combineReducers({
  taskerProfile: taskerProfile as Reducer, // TODO: Convert taskerProfile to TS
  taskerPhotos,
  taskerReviews,
  taskerProfileState,
  rescueTasker,
});
