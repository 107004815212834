const supportArticleIds = {
  cancelFee: "204411530",
  trustSupportFee: "204940570",
  covidSupport: "360040752692",
  isTaskRabbitInMyCity: "204411090",
  fairCreditReportingAct: "115002070966",
  backgroundCheckPerCaliforniaLaw: "115002056203",
  backgroundCheckUpdate: "115005623926",
  sirenLearnMore: "360025182111",
  b3LearnMore: "360024883532",
  taxSupport: "360052804852"
};

const supportSectionsIds = {
  sirenSupportCenter: "360006922212",
};

const buildSupportUrl = (articleId) => {
  return `https://support.taskrabbit.com/hc/articles/${articleId}`;
};

const buildSupportSectionUrl = (sectionId) => {
  return `https://support.taskrabbit.com/hc/sections/${sectionId}`;
};

export const supportUrl = (key) => {
  const supportArticleId = supportArticleIds[key];

  if (supportArticleId) {
    return buildSupportUrl(supportArticleIds[key]);
  }
  throw new Error(`Missing support article id for key: ${key}`);
};

export const supportSectionUrl = (key) => {
  const supportSectionId = supportSectionsIds[key];

  if (supportSectionId) {
    return buildSupportSectionUrl(supportSectionId);
  }
  throw new Error(`Missing support section id for key: ${key}`);
};
