import xhr from "util/xhr";
import {
  FETCH_JOB_REPOST_DATA,
  INCOMPLETE_DATA_REDIRECT,
} from "build/routes/apiRoutes";
import get from "lodash/get";
import { IKEA_CATEGORIES } from "util/constants";
import {
  ORIGINAL_TIME,
  WITHIN_24_HOURS,
} from "enums/rescueDateRanges";

const UPDATE_JOB_REPOST_DATA_REQUEST =
  "v3/repost/UPDATE_JOB_REPOST_DATA_REQUEST";
const UPDATE_JOB_REPOST_DATA_SUCCESS =
  "v3/repost/UPDATE_JOB_REPOST_DATA_SUCCESS";
const TASK_RESCUE_TIME_RANGES = [ORIGINAL_TIME, WITHIN_24_HOURS];

const initialState = {
  jobRepostData: {},
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export default function repost(state = initialState, action) {
  switch (action.type) {
    case UPDATE_JOB_REPOST_DATA_SUCCESS:
      return {
        ...state,
        jobRepostData: action.jobRepostData,
      };
    default:
      return state;
  }
}

const formatJobRepostData = (jobData, queryData, rescueDateRange) => {
  const hasCompleteSchedule =
    jobData.schedule &&
    jobData.schedule.date &&
    typeof jobData.schedule.offset_seconds !== "undefined";
  jobData.location =
    jobData.location || jobData.address || get(jobData, "locations.items.0");
  jobData.promotion_code = get(jobData, "promotion.code");

  jobData.tools = jobData.tools || [];
  jobData.form_referrer = "repost_choice";

  delete jobData.id;
  delete jobData.address;
  delete jobData.locations;
  delete jobData.descriptions;
  delete jobData.lat;
  delete jobData.lng;
  delete jobData.promotion;
  delete jobData.country_iso_code;

  if (!hasCompleteSchedule || TASK_RESCUE_TIME_RANGES.includes(rescueDateRange)) {
    // Task Rescue doesn't need schedule since sameday and future dates cause issues
    delete jobData.schedule;
  }

  return { ...queryData, ...jobData };
};

export const updateJobRepostData = (params) => async (dispatch) => {
  dispatch({ type: UPDATE_JOB_REPOST_DATA_REQUEST });
  try {
    const { data } = await xhr.get(FETCH_JOB_REPOST_DATA, { params });
    const { rescueDateRange } = params;
    const jobRepostData = formatJobRepostData(data, params, rescueDateRange);
    const isIkeaCategory = jobRepostData.category_id === IKEA_CATEGORIES[0];

    jobRepostData.target = isIkeaCategory ? "form" : jobRepostData.target;
    jobRepostData.page = jobRepostData.target || "form";

    dispatch({
      type: UPDATE_JOB_REPOST_DATA_SUCCESS,
      jobRepostData,
    });
  } catch (error) {
    window.location.href = INCOMPLETE_DATA_REDIRECT;
  }
};
