import qs from "qs";
import { createMemoryHistory, createBrowserHistory } from "history";

import { isEnvironmentServerSide } from "../shared/utils/is-environment-server-side";

const history = buildHistory();
export default history;

function buildHistory() {
  return isEnvironmentServerSide()
    ? buildServerSideHistory()
    : buildClientSideHistory();
}

function buildServerSideHistory() {
  return createMemoryHistory();
}

function buildClientSideHistory() {
  const basename = buildBasename();
  return createBrowserHistory({
    parseQueryString: qs.parse,
    basename,
  });
}

function buildBasename() {
  // `null` is handled differently than `undefined` on purpose here. I do not
  // know the exact reason why.
  if (typeof window.REACT_APP_BASENAME !== "undefined") {
    return window.REACT_APP_BASENAME;
  }
  // DYNAMIC BASENAME for "ROPING OFF" w/ fake URLs, whatever that means.
  // Use '/' and whatever the first part that comes after
  return dropLastSegment(window.location.pathname);
}

function dropLastSegment(path) {
  return path.split("/").slice(0, -1).join("/");
}
