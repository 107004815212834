/* eslint-disable no-case-declarations */
import findIndex from "lodash/findIndex";

const SEARCH_BAR_INITIALIZE = "components/search_bar/SEARCH_BAR_INITIALIZE";
const SEARCH_BAR_SET_QUERY = "components/search_bar/SET_QUERY";
const SEARCH_BAR_CLEAR_QUERY = "components/search_bar/CLEAR_QUERY";
const SEARCH_BAR_NAVIGATE_LIST =
  "components/search_bar/SEARCH_BAR_NAVIGATE_LIST";
const SEARCH_BAR_SEARCH_REQUEST = "components/search_bar/SEARCH_REQUEST";
const SEARCH_BAR_SEARCH_RESPONSE = "components/search_bar/SEARCH_RESPONSE";
const SEARCH_BAR_SEARCH_ERROR = "components/search_bar/SEARCH_ERROR";
const SEARCH_BAR_SET_SELECTED = "components/search_bar/SEARCH_BAR_SET_SELECTED";

export function initializeSearchBar(name) {
  return { type: SEARCH_BAR_INITIALIZE, name };
}

export function prev(name) {
  return { type: SEARCH_BAR_NAVIGATE_LIST, change: -1, name };
}

export function next(name) {
  return { type: SEARCH_BAR_NAVIGATE_LIST, change: 1, name };
}

export function selectItem(name, item) {
  return { type: SEARCH_BAR_SET_SELECTED, item };
}

function searchRequest(name) {
  return { type: SEARCH_BAR_SEARCH_REQUEST, name };
}

function searchResponse(name, response) {
  return {
    type: SEARCH_BAR_SEARCH_RESPONSE,
    name,
    response,
  };
}

function searchError(name, error) {
  return {
    type: SEARCH_BAR_SEARCH_ERROR,
    name,
    error,
  };
}

export function setQuery(name, query) {
  return {
    type: SEARCH_BAR_SET_QUERY,
    name,
    query,
  };
}

export function clearQuery(name) {
  return { type: SEARCH_BAR_CLEAR_QUERY, name };
}

export function setSelected(name, item) {
  return {
    type: SEARCH_BAR_SET_SELECTED,
    name,
    item,
  };
}

export function performSearch(name, resultsPromise) {
  return (query) => (dispatch) => {
    dispatch(searchRequest(name));
    return dispatch(resultsPromise(query)).then(
      (response) => {
        dispatch(searchResponse(name, response.data));
      },
      () => {
        const err = "search error";
        dispatch(searchError(name, err));
      }
    );
  };
}

const initialState = {};

export default function reducer(state = initialState, action = { type: null }) {
  if (!action) return state;
  const { name } = action;

  switch (action.type) {
    case SEARCH_BAR_INITIALIZE:
      if (!state[name]) state[name] = { results: { items: [] } };
      return { ...state };

    case SEARCH_BAR_SET_QUERY:
      return { ...state, [name]: { ...state[name], query: action.query } };

    case SEARCH_BAR_CLEAR_QUERY:
      return {
        ...state,
        [name]: {
          ...state[name],
          query: null,
          results: { ...state[name].results, items: [] },
        },
      };

    case SEARCH_BAR_NAVIGATE_LIST:
      // if not items, do nothing
      const { items } = state[name].results;
      if (!items.length) return state;
      let newSelected;

      // remove `selected` from prev. selected item.
      const prevSelected = findIndex(items, { selected: true });
      if (~prevSelected) {
        items[prevSelected].selected = false;
        newSelected = prevSelected + action.change;
      } else {
        newSelected = 0;
      }

      // rotate through and select the next selected item
      if (newSelected >= items.length) {
        newSelected = 0;
      }

      if (newSelected < 0) newSelected = items.length - 1;
      items[newSelected].selected = true;

      // build up new array
      const newItems = [
        ...items.slice(0, newSelected),
        items[newSelected],
        ...items.slice(newSelected + 1),
      ];

      return {
        ...state,
        [name]: {
          ...state[name],
          results: { ...state[name].results, items: newItems },
        },
      };

    case SEARCH_BAR_SEARCH_REQUEST:
      return { ...state, [name]: { ...state[name], isLoading: false } };

    case SEARCH_BAR_SEARCH_RESPONSE:
      if (!(action.response.items && action.response.items instanceof Array))
        throw Error("Response must contain array called `items`");
      return {
        ...state,
        [name]: { ...state[name], isLoading: false, results: action.response },
      };

    case SEARCH_BAR_SEARCH_ERROR:
      return {
        ...state,
        [name]: {
          ...state[name],
          isLoading: false,
          results: { items: [], error: action.error },
        },
      };

    case SEARCH_BAR_SET_SELECTED:
      if (!action.item) return state;
      return {
        ...state,
        [name]: { ...state[name], selected: { ...action.item } },
      };

    default:
      return state;
  }
}
