import React from "react";
import googlePayIcon from "./google-pay.png";

const GooglePayIcon = () => {
  return (
    <span className="confirmation--payment-show">
      <span className="confirmation--payment-wallet">
        <img
          src={googlePayIcon}
          className="confirmation--payment-apple-pay confirmation--payment-apple-pay-booking google-pay"
          alt="Google Pay"
        />
      </span>
    </span>
  );
};

export default GooglePayIcon;
