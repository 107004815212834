import { push } from "connected-react-router";
import { createAlert } from "alerts/redux/modules/alerts";
import { SubmissionError } from "redux-form";
import { defineMessages } from "react-intl";
import { CREATE_STRIPE_PAYMENT_METHOD_URL } from "util/constants";
import xhr, { createApiErrorAlert, errorFromStripe } from "util/xhr";
import { internalPath } from "util/internalPath";

const messages = defineMessages({
  card_updated: {
    id: "alerts.billing_info_updated.card",
    defaultMessage: "Your credit card has successfully been updated.",
  },
  sepa_debit_updated: {
    id: "alerts.billing_info_updated.sepa_debit",
    defaultMessage: "Your SEPA Direct Debit has successfully been updated.",
  },
});

const UPDATE_BILLING_INFO_RESPONSE =
  "account/billing_info/UPDATE_BILLING_INFO_RESPONSE";

function responseSaveBillingInfo(response) {
  return { type: UPDATE_BILLING_INFO_RESPONSE, response };
}

function mapApiResponseToFormProps(data) {
  data.card_number = data.last_4;
  return data;
}

export function handleSaveBillingInfo(stripeResult) {
  return async (dispatch) => {
    try {
      if (stripeResult.error) {
        throw stripeResult.error;
      }

      await xhr
        .post(CREATE_STRIPE_PAYMENT_METHOD_URL, {
          payment_method_id: stripeResult.setupIntent.payment_method,
          payment_method_type: stripeResult.setupIntent.payment_method_types[0],
        })
        .then((response) => {
          dispatch(
            responseSaveBillingInfo(mapApiResponseToFormProps(response.data))
          );
        });
      const paymentMethodType =
        stripeResult.setupIntent.payment_method_types[0];
      dispatch(createAlert(messages[`${paymentMethodType}_updated`].id));
      dispatch(push(internalPath("/")));
    } catch (e) {
      dispatch(createApiErrorAlert(errorFromStripe(e)));
      throw new SubmissionError();
    }
  };
}

// eslint-disable-next-line @typescript-eslint/default-param-last
export default function reducer(state = { data: {} }, action) {
  switch (action.type) {
    case UPDATE_BILLING_INFO_RESPONSE:
      return {
        ...state,
        data: action.response,
      };
    default:
      return state;
  }
}
