import React, { useCallback } from "react";
import { CardElement } from "@stripe/react-stripe-js";
import { theme } from "components/theme";

const StripeCardForm = ({ handlePaymentMethodChange }) => {
  const onChange = useCallback(
    (cardStatus) => handlePaymentMethodChange(cardStatus.complete),
    [handlePaymentMethodChange]
  );
  const options = {
    style: {
      base: {
        fontSize: "16px",
        '::placeholder': {
          color: theme.color.subduedText,
        },
      }
    },
  };

  return (
    // All Stripe Elements render iframes that have limited styling options. The container can
    // be styled freely, but the styles within the iframe are limited via their options:
    // https://stripe.com/docs/stripe-js/reference#stripe-elements
    <div data-testid="card_form">
      <CardElement
        className="stripe--card-form"
        onChange={onChange}
        options={options}
      />
    </div>
  );
};
export default StripeCardForm;
