import { flatten, find } from "lodash";
import { fireBuildMetric } from "build/redux/modules/metrics";
import { push } from "connected-react-router";
import qs from "qs";
import { selectAssemblySeconds } from "ikea/redux/modules/selector";
import { isIkeaJobFlow, isAutoTaskerSelectionFlow } from "../selector";

export const getLocationAndDescFields = (groups) => {
  const fields = flatten(groups.map((group) => group.fields));
  const locationField = find(fields, { field_name: "location" });
  const descField = find(fields, { field_name: "description" });
  return [locationField, descField];
};

export const onFormComplete = () => (dispatch, getState) => {
  const state = getState();
  const {
    job: {
      taskTemplate,
      uuid,
      invitee,
      invitation_source: invitationSource,
      location,
    },
  } = state.build.manager;

  const { page } = state.build.progress;
  let nextUrl;
  let metricKey;
  let metricData = {};
  const query = qs.parse(state.router.location.search, {
    ignoreQueryPrefix: true,
  });

  if (page === "hire" || page === "book") {
    metricKey = "direct_hire_form_completed";
    const nextPage = isIkeaJobFlow(state) ? "choose-date-time" : "confirm";

    nextUrl =
      `${nextPage}?` +
      qs.stringify({
        ...query,
        uuid,
        invitee_id: invitee.id,
        invitation_source: invitationSource,
      });
  } else if (page === "form" && isAutoTaskerSelectionFlow(state)) {
    metricKey = "auto_tasker_selection_form_completed";
    const nextPage = "choose-date-time";
    nextUrl =
      `${nextPage}?` +
      qs.stringify({
        ...query,
        uuid,
      });
  } else {
    metricKey = "posting_form_completed";
    if (isIkeaJobFlow(state)) {
      metricKey = "ikea_select_items_completed";
      metricData = {
        est_total_time: selectAssemblySeconds(state),
        est_metro_hourly_rate: location.assembly_poster_avg_hourly_rate_cents,
      };
    }
    nextUrl = taskTemplate.skip_recommendations
      ? "confirm?" +
        qs.stringify({
          ...query,
          uuid,
          invitee_id: "broadcast",
          invitation_source: "broadcast",
        })
      : `recommendations?` + qs.stringify({ ...query, uuid });
  }

  dispatch(fireBuildMetric(metricKey, metricData));
  dispatch(push(nextUrl, { referrer: state.router.location }));
};
