import { ACCOUNT_CHANGE_PASSWORD } from "account/routes/apiRoutes";
import { push } from "connected-react-router";
import { createAlert } from "alerts/redux/modules/alerts";
import { defineMessages } from "react-intl";
import xhr, { createApiErrorAlert } from "util/xhr";
import { internalPath } from "util/internalPath";

const messages = defineMessages({
  password_changed: {
    id: "alerts.password_changed",
    defaultMessage: "Your password has been updated.",
  },
});

const CHANGE_PASSWORD_REQUEST = "account/password/CHANGE_PASSWORD_REQUEST";
const CHANGE_PASSWORD_RESPONSE = "account/password/CHANGE_PASSWORD_RESPONSE";

export function requestChangePw() {
  return { type: CHANGE_PASSWORD_REQUEST };
}

export function responseChangePw(response) {
  return { type: CHANGE_PASSWORD_RESPONSE, response };
}

export function changePassword(passwords) {
  return (dispatch, getState) => {
    const passwords = getState().form.change_password.values;
    dispatch(requestChangePw());
    return xhr
      .put(ACCOUNT_CHANGE_PASSWORD, JSON.stringify(passwords))
      .then(response => {
        dispatch(responseChangePw());
        dispatch(push(internalPath("/")));
        dispatch(createAlert(messages.password_changed.id));
      })
      .catch(e => dispatch(createApiErrorAlert(e)));
  };
}

export default function reducer(state = {}, action) {
  switch (action.type) {
    case CHANGE_PASSWORD_REQUEST:
      return state;
    default:
      return state;
  }
}
