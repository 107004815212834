/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ACCOUNT_PAYMENTS_TAXID } from "account/routes/apiRoutes";
import { push } from "connected-react-router";
import { createAlert } from "alerts/redux/modules/alerts";
import { defineMessages } from "react-intl";
import xhr, { createApiErrorAlert } from "util/xhr";
import { invalidateProfile } from "account/redux/modules/profile";
import { internalPath } from "util/internalPath";

const messages = defineMessages({
  tax_id_changed: {
    id: "alerts.tax_id_changed",
    defaultMessage: "Your Tax ID information has been updated.",
  },
});

const SAVE_TAX_ID = "account/tax_id/SAVE_TAX_ID";
const LOAD_TAX_ID_REQUEST = "account/tax_id/LOAD_TAX_ID_REQUEST";
const LOAD_TAX_ID_RESPONSE = "account/tax_id/LOAD_TAX_ID_RESPONSE";
const LOAD_TAX_ID_ERROR = "account/tax_id/LOAD_TAX_ID_ERROR";
const UPDATE_TAX_ID_REQUEST = "account/tax_id/UPDATE_TAX_ID_REQUEST";
const UPDATE_TAX_ID_RESPONSE = "account/tax_id/UPDATE_TAX_ID_RESPONSE";
const UPDATE_TAX_ID_ERROR = "account/tax_id/UPDATE_TAX_ID_ERROR";

export function requestSaveTaxId() {
  return { type: UPDATE_TAX_ID_REQUEST };
}

export function responseSaveTaxId(response) {
  return { type: UPDATE_TAX_ID_RESPONSE, response };
}

export function errorSaveTaxId(response) {
  return { type: UPDATE_TAX_ID_ERROR, response };
}

export function saveTaxId() {
  return (dispatch, getState) => {
    const settings = getState().form.tax_id.values;
    const apiPath = ACCOUNT_PAYMENTS_TAXID.replace(
      ":id",
      settings.payment_merchant_id
    );
    return xhr
      .put(apiPath, JSON.stringify(settings))
      .then(response => {
        dispatch(responseSaveTaxId(response.data));
        dispatch(invalidateProfile());
        dispatch(createAlert(messages.tax_id_changed.id));
        dispatch(push(internalPath("/tax-id/")));
      })
      .catch(e => {
        dispatch(createApiErrorAlert(e));
        dispatch(errorSaveTaxId());
      });
  };
}

const initialState = {
  isLoading: false,
  error: undefined,
  data: undefined,
  urlsFor1099K: undefined,
  is1099kLoading: false
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SAVE_TAX_ID:
      return state;
    case LOAD_TAX_ID_REQUEST:
      return { ...state, isLoading: true };
    case LOAD_TAX_ID_RESPONSE:
      return {
        ...state,
        isLoading: false,
        data: action.response
      };
    case LOAD_TAX_ID_ERROR:
      return { ...state, isLoading: false };
    case UPDATE_TAX_ID_REQUEST:
      return state;
    case UPDATE_TAX_ID_RESPONSE:
      return {
        ...state, isLoading: false, data: action.response
      };
    case UPDATE_TAX_ID_ERROR:
      return { ...state, isLoading: false, data: null };
    default:
      return state;
  }
}
