import xhr from "util/xhr";

type FetchFeatureParams = {
  categoryId?: number;
  metroId?: number;
};

const ENABLED_FEATURES_PATH = "/api/v3/feature_manager/enabled_features.json";

export const fetchEnabledFeatures = async ({
  categoryId,
  metroId,
}: FetchFeatureParams) => {
  const params = {
    ...(categoryId && { category_id: categoryId }),
    ...(metroId && { metro_id: metroId }),
  };

  try {
    const response = await xhr.get(ENABLED_FEATURES_PATH, {
      params,
    });
    return response.data.enabled_features;
  } catch (e) {
    return [];
  }
};
