import xhr, { createApiErrorAlert } from "util/xhr";
import { FETCH_HAS_DECLINE_INVOICES } from "dashboard/routes/apiRoutes";
import { createAlert } from "alerts/redux/modules/alerts";
import { defineMessages } from "react-intl";

const messages = defineMessages({
  declineAlertMessage: {
    id: "dashboard.decline_invoices.banner_message_html",
    defaultMessage:
      'You owe funds on a previous task. Update your <a href="/account/billing-info">billing info</a> and contact Support at <a href="http://help.tr.co" target="_blank">help.tr.co</a> to pay your balance and resume your account',
  },
});

const LOAD_HAS_DECLINE_INVOICES_REQUEST =
  "dashboard/decline_invoice/HAS_DECLINE_INVOICES_REQUEST";
const LOAD_HAS_DECLINE_INVOICES_RESPONSE =
  "dashboard/decline_invoice/HAS_DECLINE_INVOICES_RESPONSE";
const LOAD_HAS_DECLINE_INVOICES_ERROR =
  "dashboard/decline_invoice/HAS_DECLINE_INVOICES_ERROR";

export default function loadingHasDeclineInvoices(state = false, action) {
  switch (action.type) {
    case LOAD_HAS_DECLINE_INVOICES_REQUEST:
      return true;
    case LOAD_HAS_DECLINE_INVOICES_RESPONSE:
      return false;
    case LOAD_HAS_DECLINE_INVOICES_ERROR:
      return false;
    default:
      return state;
  }
}

export const fetchHasDeclineInvoices = () => {
  return dispatch => {
    dispatch({ type: LOAD_HAS_DECLINE_INVOICES_REQUEST });
    return xhr.get(FETCH_HAS_DECLINE_INVOICES).then(
      response => {
        const { has_submitted_invoices: hasSubmittedInvoices } = response.data;
        dispatch({
          type: LOAD_HAS_DECLINE_INVOICES_RESPONSE,
        });
        if (hasSubmittedInvoices) {
          dispatch(
            createAlert({
              messages: messages.declineAlertMessage.id,
              type: "error",
              autoRemove: 0,
            })
          );
        }
      },
      e => {
        dispatch(createApiErrorAlert(e));
        dispatch({ type: LOAD_HAS_DECLINE_INVOICES_ERROR });
      }
    );
  };
};
