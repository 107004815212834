import React from "react";
import {
  FormattedHTMLMessage,
  FormattedMessage,
} from "components/FormattedMessage";
import styled from "styled-components";
import { defineMessages } from "react-intl";
import { connect } from "react-redux";
import { LOGIN_ORIGIN, ACCOUNT_ORIGIN, PROTECTED_SCREEN_ORIGIN } from "enums/twoFactorAuth";
import { loginFromTwoFactorSetup } from "user/redux/modules/index";
import { accountTwoFactorSuccessCta } from "account/redux/modules/accountSecurity";
import { StyledButtonPrimary } from "components/sharedStyledComponents/StyledButton";
import verifiedSvg from "./TwoFactorSetupSuccessModal/ic_verified.svg";
import { ForDesktop, ForMobile } from "../../shared/hooks/use-breakpoints";

const messages = defineMessages({
  title: {
    id: "two_factor_authentication_modal.setup_success.title",
    defaultMessage: "Two-factor authentication is activated!",
  },
  subtitle: {
    id: "two_factor_authentication_modal.setup_success.subtitle",
    defaultMessage:
      "Edit your authentication preferences anytime under your account settings.",
  },
  gotIt: {
    id: "two_factor_authentication_modal.setup_success.gotIt",
    defaultMessage: "Got it",
  },
  continue: {
    id: "two_factor_authentication_modal.setup_success.continue",
    defaultMessage: "Continue",
  },
});

const TwoFactorSetupSuccessModal_ = ({
  loginFromTwoFactorSetupAction,
  accountTwoFactorSuccessCtaAction,
  origin,
}) => {
  const cta = () => {
    if (origin === LOGIN_ORIGIN) {
      return loginFromTwoFactorSetupAction();
    }
    if (origin === ACCOUNT_ORIGIN || origin === PROTECTED_SCREEN_ORIGIN) {
      return accountTwoFactorSuccessCtaAction();
    }

    return null;
  };

  return (
    <>
      <ForDesktop>
        <StyledTwoFactorSetupSuccessModal>
          <StyledModalTop>
            <StyledModalTitle>
              <FormattedMessage message={messages.title} />
            </StyledModalTitle>
          </StyledModalTop>
          <hr />
          <StyledModalSubtitle>
            <FormattedHTMLMessage message={messages.subtitle} />
          </StyledModalSubtitle>
          <StyledBottomRow>
            <StyledButtonPrimary width="320px" height="54px" onClick={cta}>
            <FormattedMessage message={origin === PROTECTED_SCREEN_ORIGIN ? messages.continue : messages.gotIt} />
            </StyledButtonPrimary>
          </StyledBottomRow>
        </StyledTwoFactorSetupSuccessModal>
      </ForDesktop>
      <ForMobile>
        <StyledMobileTwoFactorSetupSuccessModal>
          <StyledImg src={verifiedSvg} alt="" />
          <StyledModalTop>
            <StyledModalTitle>
              <FormattedMessage message={messages.title} />
            </StyledModalTitle>
          </StyledModalTop>
          <StyledModalSubtitle>
            <FormattedHTMLMessage message={messages.subtitle} />
          </StyledModalSubtitle>
          <StyledMobileBottomRow>
            <StyledButtonPrimary width="100%" height="54px" onClick={cta}>
              <FormattedMessage message={origin === PROTECTED_SCREEN_ORIGIN ? messages.continue : messages.gotIt} />
            </StyledButtonPrimary>
          </StyledMobileBottomRow>
        </StyledMobileTwoFactorSetupSuccessModal>
      </ForMobile>
    </>
  );
};

export const TwoFactorSetupSuccessModal = connect(null, {
  loginFromTwoFactorSetupAction: loginFromTwoFactorSetup,
  accountTwoFactorSuccessCtaAction: accountTwoFactorSuccessCta,
})(TwoFactorSetupSuccessModal_);

const StyledMobileTwoFactorSetupSuccessModal = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${(props) => props.theme.spacing.md};
`;

const StyledImg = styled.img`
  margin-bottom: ${(props) => props.theme.spacing.xl};
  height: 100px;
`;

const StyledTwoFactorSetupSuccessModal = styled.div`
  width: 660px;
  min-height: 250px;
  padding-top: ${(props) => props.theme.spacing.xl};
  padding-bottom: ${(props) => props.theme.spacing.xl};
  padding-left: ${(props) => props.theme.spacing.xxl};
  padding-right: ${(props) => props.theme.spacing.xxl};
  height: 100%;
  width: 100%;
  text-align: center;
`;

const StyledModalTop = styled.div`
  display: flex;
  margin-bottom: ${(props) => props.theme.spacing.sm};
`;

const StyledMobileBottomRow = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0;
  padding: ${(props) => props.theme.spacing.md};
  width: 100%;
  display: flex;
`;

const StyledModalTitle = styled.div`
  padding: 0;
  font-size: 26px;
  line-height: 30px;
  font-weight: ${(props) => props.theme.typography.weight.semiBold};
  text-align: center;
  margin: 0 auto;
`;

const StyledModalSubtitle = styled.div`
  margin-bottom: ${(props) => props.theme.spacing.xl};
  text-align: center;
`;

const StyledBottomRow = styled.div`
  margin-top: ${(props) => props.theme.spacing.md};
  display: flex;
  justify-content: center;
`;
