export default function promiseMiddleware(xhr) {
  return ({ dispatch, getState }) => {
    return next => action => {
      const { promise, types, ...rest } = action; // eslint-disable-line no-redeclare
      if (!promise) {
        return next(action);
      }

      const [REQUEST, SUCCESS, FAILURE] = types;
      next({ ...rest, type: REQUEST });

      promise(xhr).then(
        result => {
          return next({ ...rest, result, type: SUCCESS });
        },
        error => next({ ...rest, error, type: FAILURE })
      );

      return promise;
    };
  };
}
