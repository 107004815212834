import React, { useState } from "react";
import { defineMessages, injectIntl } from "react-intl";
import {
  FormattedHTMLMessage,
  FormattedMessage,
} from "components/FormattedMessage";
import classnames from "classnames";
import { countryConfigFromLocale } from "config/countryDriven";
import { internalPath } from "util/internalPath";
import { connect } from "react-redux";
import { updateUserConsentInfo } from "user/redux/modules";
import { TRModal } from "components/Modal/TRModal";

const messages = defineMessages({
  title: {
    id: "gdprModal.title",
    defaultMessage: "Updated Terms of Service and Privacy Policy",
  },
  explanation: {
    id: "gdprModal.explanation",
    defaultMessage:
      'Here at TaskRabbit, Inc. we’ve updated our <a href="{active_terms_version_url}">Terms of Service</a> and <a href="{privacy_path}">Privacy Policy</a> to explain how TaskRabbit works and how we use your data to deliver, improve, and promote the Platform and our website, how you can exercise your rights to control the use of your data, and to reflect changes in EU data protection law. If you do not agree to these updates <a href="{logout_path}">click here</a> to log out.',
  },
  termsCheckbox: {
    id: "gdprModal.termsCheckbox",
    defaultMessage:
      'By ticking this box, I acknowledge that I have reviewed and agree to the <a href="{active_terms_version_url}">Terms of Service</a> and <a href="{privacy_path}">Privacy Policy</a>.',
  },
  termsCheckboxWithoutPrivacy: {
    id: "gdprModal.termsCheckboxWithoutPrivacy",
    defaultMessage:
      'By ticking this box, I acknowledge that I have reviewed and agree to the <a href="{active_terms_version_url}">Terms of Service</a>.',
  },
  marketingCheckbox: {
    id: "gdprModal.marketingCheckbox",
    defaultMessage:
      "I do not want to receive promotional communications from TaskRabbit",
  },
  cta: {
    id: "gdprModal.cta",
    defaultMessage: "Continue",
  },
});

const GDPRConsentContainer = ({
  loggedIn,
  showGDPRConsentModal,
  active_terms_version_url,
  showMarketingCheckbox,
  loadingUpdateConsentInfo,
  updateUserConsentInfo,
  intl,
}) => {
  const [disabled, setDisabled] = useState(true);
  const [marketingConsentEnabled, setMarketingConsentEnabled] = useState(true);

  const handleSubmit = () => {
    const consentData = {
      marketingConsentEnabled,
    };

    updateUserConsentInfo(consentData);
  };

  let termsConsentMessageKey;
  if (countryConfigFromLocale(intl.locale, "terms.includePrivacyInConsent")) {
    termsConsentMessageKey = "termsCheckbox";
  } else {
    termsConsentMessageKey = "termsCheckboxWithoutPrivacy";
  }

  if (!loggedIn) {
    return null;
  }

  return !loggedIn || !showGDPRConsentModal ? null : (
    <TRModal>
      <div className="lightbox--content-wrapper">
        <div className="lightbox--content">
          <div className="gdpr-title">
            <FormattedMessage message={messages.title} />
          </div>
          <div>
            <p>
              <FormattedHTMLMessage
                message={messages.explanation}
                values={{
                  active_terms_version_url,
                  privacy_path: internalPath("/privacy"),
                  logout_path: internalPath("/logout"),
                }}
              />
            </p>
            <p className="gdpr-checkbox-copy">
              <label>
                {/* [MEADOW_TODO] - Checkbox */}
                <input
                  type="checkbox"
                  className="terms-checkbox gdpr-checkbox"
                  onChange={() => setDisabled(!disabled)}
                />
                <FormattedHTMLMessage
                  message={messages[termsConsentMessageKey]}
                  values={{
                    active_terms_version_url,
                    privacy_path: internalPath("/privacy"),
                  }}
                />
              </label>
            </p>
            {showMarketingCheckbox && (
              <p className="gdpr-checkbox-copy">
                <label>
                  {/* [MEADOW_TODO] - Checkbox */}
                  <input
                    type="checkbox"
                    className="marketing-checkbox gdpr-checkbox"
                    onChange={() =>
                      setMarketingConsentEnabled(!marketingConsentEnabled)
                    }
                    value={marketingConsentEnabled}
                  />
                  <FormattedHTMLMessage message={messages.marketingCheckbox} />
                </label>
              </p>
            )}
          </div>
          <div className="gdpr-button-section">
            {/* [MEADOW_TODO] - Button */}
            <button
              className={classnames("btn btn-primary gdpr-button", {
                "is-loading": loadingUpdateConsentInfo,
                disabled,
              })}
              disabled={disabled}
              onClick={handleSubmit}
            >
              <FormattedHTMLMessage message={messages.cta} />
            </button>
          </div>
        </div>
      </div>
    </TRModal>
  );
};

const mapStateToProps = (state) => {
  return {
    loggedIn: state?.user?.loggedIn,
    loadingUpdateConsentInfo: state?.user?.loadingUpdateConsentInfo,
    showGDPRConsentModal: !!state?.user?.updateTermsConsentKey,
    // this is very unfortunate but I would have to get translations for
    // this if I change the case, and this is part of a very large pr.
    // TODO clean this up
    active_terms_version_url: state?.user?.activeTermsVersionUrl,
    marketing_consent_enabled: state?.user?.marketingConsentEnabled,
    showMarketingCheckbox:
      state?.user?.updateTermsConsentKey ===
      "terms_and_marketing_consent_needed",
  };
};

export default connect(mapStateToProps, { updateUserConsentInfo })(
  injectIntl(GDPRConsentContainer)
);
