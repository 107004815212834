import React from "react";
import styled from "styled-components";
import { FormattedMessage } from "components/FormattedMessage";

export function FooterColumn({ titleMessage, children }) {
  return (
    <div>
      <FooterTitle>
        <FormattedMessage message={titleMessage} />
      </FooterTitle>
      {children}
    </div>
  );
}

const FooterTitle = styled.span`
  color: ${(props) => props.theme.color.footerText};
`;
