/* eslint-disable no-case-declarations, camelcase, consistent-return */

import {
  BUNDLE_DASHBOARD_TASKS_BASE,
  RETRIEVE_SPECIFIC_JOB_ID,
} from "dashboard/routes/apiRoutes";

import xhr from "util/xhr";
import qs from "qs";
import indexOf from "lodash/indexOf";

const POLLING_DASHBOARD_UPDATE = "dashboard/task_list/POLLING_DASHBOARD_UPDATE";

const LOAD_TASK_LIST = "dashboard/task_list/LOAD_TASK_LIST";
const LOAD_TASK_LIST_REQUEST = "dashboard/task_list/LOAD_TASK_LIST_REQUEST";
const LOAD_TASK_LIST_RESPONSE = "dashboard/task_list/LOAD_TASK_LIST_RESPONSE";
const LOAD_TASK_LIST_ERROR = "dashboard/task_list/LOAD_TASK_LIST_ERROR";
const FETCH_JOB_DATA_RESPONSE = "FETCH_JOB_DATA_RESPONSE";
const REPLACE_TASK = "REPLACE_TASK";
const REMOVE_TASK_FROM_ACTIVE = "dashboard/task_list/REMOVE_TASK_FROM_ACTIVE";
const REMOVE_JOB_BUNDLE_FROM_ACTIVE =
  "dashboard/task_list/REMOVE_JOB_BUNDLE_FROM_ACTIVE";
const REMOVE_FEEDBACK_ACTION_FROM_TASK =
  "dashboard/task_list/REMOVE_FEEDBACK_ACTION_FROM_TASK";

function startLoadTaskList() {
  return { type: LOAD_TASK_LIST };
}

function loadTaskListRequest() {
  return { type: LOAD_TASK_LIST_REQUEST };
}

export function removeTaskFromActive({ isJobBundle, id }) {
  return {
    type: REMOVE_TASK_FROM_ACTIVE,
    isJobBundle,
    id,
  };
}

export function removeJobBundleFromActive(jobBundle) {
  return {
    type: REMOVE_JOB_BUNDLE_FROM_ACTIVE,
    jobBundle,
  };
}

export function removeFeedbackActionFromTask(job_id) {
  return {
    type: REMOVE_FEEDBACK_ACTION_FROM_TASK,
    job_id,
  };
}

function loadTaskListResponse(response, meta) {
  return {
    type: LOAD_TASK_LIST_RESPONSE,
    response,
    meta,
  };
}

export function fetchJobDataResponse(response) {
  return {
    type: FETCH_JOB_DATA_RESPONSE,
    response,
  };
}

function replaceTask(task, appointmentId) {
  return {
    type: REPLACE_TASK,
    task,
    appointmentId,
  };
}

export function fetchSingleTask(job_id, rabbit_id) {
  const url = RETRIEVE_SPECIFIC_JOB_ID.replace(":job_id", job_id).replace(
    ":rabbit_id",
    rabbit_id
  );
  return xhr.get(url).then(({ camelCasedData: data }) => data);
}

function fetchTaskList({ listName, page, perPage }) {
  return (dispatch) => {
    const urlPath = listName === "refer" ? "active" : listName;
    const url = `${BUNDLE_DASHBOARD_TASKS_BASE}${urlPath}?${qs.stringify({
      page,
      per_page: perPage,
    })}`;

    dispatch(loadTaskListRequest());

    return xhr.get(url).then((response) => {
      dispatch(
        loadTaskListResponse(response.camelCasedData, {
          listName,
          page,
          perPage,
        })
      );
    });
  };
}

export function refreshRescheduledTask(jobId, rabbitId, appointmentId) {
  return (dispatch) => {
    fetchSingleTask(jobId, rabbitId).then((task) => {
      dispatch(replaceTask(task, appointmentId));
    });
  };
}

export function loadTaskList({
  listName,
  page = 1,
  perPage = 5,
  forcePageRefresh = false,
}) {
  return (dispatch, getState) => {
    dispatch(startLoadTaskList());

    // fetch page if current selected items for this page are missing/ undefined
    const pageNeedsFetch = () => {
      if (forcePageRefresh) return true;
      const list = getState().dashboard.taskLists[listName];
      if (!list || !list.items) return true;
      const pageItems = list.items.slice((page - 1) * perPage, perPage * page);
      if (!pageItems.length || !!~indexOf(pageItems, undefined)) return true; // eslint-disable-line no-bitwise
    };

    if (pageNeedsFetch())
      return dispatch(fetchTaskList({ listName, page, perPage }));
  };
}

const processPollDashboardMessage = (message) => ({
  type: POLLING_DASHBOARD_UPDATE,
  message,
});

// Updates Dashboard Counter
export function updateDashboardMessageNotification(events) {
  return (dispatch) => {
    events.forEach((event) => {
      if (event.realtime_observer === "messaging_interaction_created") {
        dispatch(processPollDashboardMessage(event.message));
      }
    });
  };
}

// eslint-disable-next-line @typescript-eslint/default-param-last
export default function taskLists(state = {}, action) {
  switch (action.type) {
    case POLLING_DASHBOARD_UPDATE: {
      if (!state.active || !state.active.items || action.message.system) {
        // No active property / Not of dashboard page
        return state;
      }

      const currentWorkId = Number(action.message.job_id);
      const currentActiveItems = [...state.active.items];

      currentActiveItems.forEach((item, itemIndex) =>
        item.tasks.forEach((nestedTask, taskIndex) => {
          if (nestedTask.details.id === currentWorkId) {
            currentActiveItems[itemIndex].tasks[
              taskIndex
            ].chat.unreadCount += 1;
          }
        })
      );

      return {
        ...state,
        active: { ...state.active, items: currentActiveItems },
      };
    }
    case LOAD_TASK_LIST_REQUEST:
      return { ...state, isLoading: true };
    case LOAD_TASK_LIST_ERROR:
      return { ...state, isLoading: false };
    case LOAD_TASK_LIST_RESPONSE:
      const { page, perPage, listName: fromList } = action.meta;
      const oldItems = state[fromList] ? state[fromList].items : [];
      let { items } = action.response;

      if (fromList === "completed") {
        items = items.map((item) => {
          return {
            ...item,
            tasks: item.tasks.map((x) => {
              return {
                details: x.task,
                appointments: { items: [x.invoice.appointment] },
                locations: x.task.locations,
                tasker: x.rabbit,
                notification: x.notification,
                invoice: x.invoice,
                review: x.review,
                task: x.task,
                rabbit: x.rabbit,
              };
            }),
          };
        });
      }
      const replace = items.reduce((memo, newItem, index) => {
        const idx = (page - 1) * perPage + index;
        memo[idx] = newItem;
        return memo;
      }, oldItems);

      const newList = {
        [fromList]: {
          ...state[fromList],
          meta: { ...action.response.meta },
          items: replace,
        },
      };

      return { ...state, ...newList, isLoading: false };

    case REMOVE_TASK_FROM_ACTIVE:
      let filteredList;
      if (action.isJobBundle) {
        filteredList = state.active.items.map((item) => {
          const bundleId = item.jobBundle && item.jobBundle.id;
          return bundleId === action.id ? action.job : item;
        });
      } else {
        filteredList = state.active.items.filter((item) => {
          return item.tasks[0].details.id !== action.id;
        });
      }

      return {
        ...state,
        active: {
          ...state.active,
          items: filteredList,
          total_items: filteredList.length,
        },
      };

    case REMOVE_JOB_BUNDLE_FROM_ACTIVE:
      const filteredBundles = state.active.items.filter((item) => {
        if (!item.jobBundle) return true;
        return item.jobBundle.id !== action.jobBundle.id;
      });

      return {
        ...state,
        active: {
          ...state.active,
          items: filteredBundles,
          totalItems: filteredBundles.length,
        },
      };

    case REMOVE_FEEDBACK_ACTION_FROM_TASK:
      const filteredActionList = state.active.items.map((item) => {
        if (item.job.id === action.job_id) {
          item.actions = item.actions.filter(
            (itemAction) => itemAction !== "feedback"
          );
        }

        return item;
      });

      return {
        ...state,
        active: { ...state.active, items: filteredActionList },
      };

    case FETCH_JOB_DATA_RESPONSE: {
      const currentActiveItems = [...state.active.items];

      currentActiveItems.forEach((item, itemIndex) => {
        const jobBundleResponse = action.response;
        const jobBundlesMatch =
          jobBundleResponse.jobBundle &&
          item.jobBundle &&
          jobBundleResponse.jobBundle.id === item.jobBundle.id;

        if (jobBundlesMatch) {
          currentActiveItems[itemIndex] = jobBundleResponse;
          return;
        }

        item.tasks.forEach((nestedTask) => {
          if (nestedTask.details.id === jobBundleResponse.tasks[0].details.id) {
            currentActiveItems[itemIndex] = jobBundleResponse;
          }
        });
      });

      return {
        ...state,
        active: { ...state.active, items: currentActiveItems },
        jobData: action.response,
      };
    }

    case REPLACE_TASK: {
      const index = state.active.items.findIndex((item) => {
        return item.tasks[0].appointments.items.find(
          ({ appointmentId }) => appointmentId === action.appointmentId
        );
      });

      if (index > -1) {
        const newState = {
          ...state,
          active: { ...state.active, items: [...state.active.items] },
        };
        newState.active.items[index] = action.task;
        return newState;
      }

      return state;
    }

    default:
      return state;
  }
}
