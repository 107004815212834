import { combineEpics } from "redux-observable";
import { Observable } from "rxjs";
import { getCookie } from "util/cookie";
import { generateFunnelId } from "util/generateFunnelId";
import { IKEA_STORE_COOKIE_NAME } from "util/constants";
import xhr from "util/xhr";
import trqs from "util/trqs";
import { replace } from "connected-react-router";
import storage from "util/localStorage";
import guid from "util/guid";
import {
  INCOMPLETE_DATA_REDIRECT,
  TASK_TEMPLATE_SHOW,
  RABBIT_GRANULAR_AVAILABILITY_SHOW,
  BOOTSTRAP_ENDPOINT,
  FETCH_DRAFT_DATA,
} from "build/routes/apiRoutes";
import { modifyAvailability } from "build/redux/modules/managerUtil";
import { selectSerializedItems } from "ikea/redux/modules/selector";
import { onFormComplete } from "build/redux/modules/form/util";
import { internalPath } from "util/internalPath";

import { createActionTypesFor } from "util/reducerHelpers";
import { DateTime } from "luxon";
import { cleanUpUrl } from "./pageNavigationHelper";
import transformFieldsToGroups from "./form/transformFieldsToGroups";
import { featureFlags } from "enums/featureFlags";
import { fetchEnabledFeatures } from "../../util/getEnabledFeatures";

const STORAGE_KEY_ATTRIBUTE = "tr-post-data";
const actionType = (actionName) => `build/manager/${actionName}`;
const BOOTSTRAP = actionType("BOOTSTRAP");
const SET_BOOTSTRAP_DATA = actionType("SET_BOOTSTRAP_DATA");
export const BOOTSTRAP_COMPLETE = actionType("BOOTSTRAP_COMPLETE");
const TASK_TEMPLATE_LOADED = actionType("TASK_TEMPLATE_LOADED");
const INVITEE_LOADED = actionType("INVITEE_LOADED");
const LOCAL_STORAGE_WRITTEN = actionType("LOCAL_STORAGE_WRITTEN");
const LOCAL_STORAGE_FAIL = actionType("LOCAL_STORAGE_FAIL");
export const UPDATE_JOB_DATA = actionType("UPDATE_JOB_DATA");
export const UPDATE_RECO_INTERESTS = actionType("UPDATE_RECO_INTERESTS");
const FETCH_TEMPLATE = actionType("FETCH_TEMPLATE");
const CLEAR_PREPOPULATED_FILTER = "CLEAR_PREPOPULATED_FILTER";
const UPDATE_INVITEE_AVAILABILITY = createActionTypesFor(
  "build.manager",
  "updateInviteeAvailability"
);

const initialState = {
  bootstrap: {},
  job: {},
  query: {},
  availability: {},
};

export default function manager(state = initialState, action) {
  switch (action.type) {
    case SET_BOOTSTRAP_DATA:
      return {
        ...state,
        job: action.job,
        query: action.query,
        bootstrap: action.bootstrap,
      };
    case UPDATE_INVITEE_AVAILABILITY.REQUEST: {
      return { ...state, availability: { updating: true } };
    }
    case UPDATE_INVITEE_AVAILABILITY.SUCCESS: {
      return {
        ...state,
        availability: {
          updating: false,
          hasAvailability: action.hasAvailability,
          lastUpdated: DateTime.now(),
        },
      };
    }
    case UPDATE_INVITEE_AVAILABILITY.FAiLURE: {
      return { ...state, availability: { updating: false } };
    }

    case UPDATE_JOB_DATA:
      return {
        ...state,
        job: { ...state.job, ...action.data },
      };
    case CLEAR_PREPOPULATED_FILTER:
      return {
        ...state,
        job: {
          ...state.job,
          job_draft_template_filter: {
            ...state.job.job_draft_template_filter,
            filterEnabled: false,
          },
        },
      };
    default:
      return state;
  }
}

export const bootstrap = (queryData) => ({ type: BOOTSTRAP, queryData });

export const updateJobData = (data) => {
  return { type: UPDATE_JOB_DATA, data };
};

const setBootstrapData = (getState, { bootstrap: bootstrapData, ...query }) => {
  const { page } = getState().build.progress;
  const uid = getCookie("uid") || "unknown-guid";

  query.funnel_id =
    query.funnel_id ||
    generateFunnelId(
      uid,
      query.isRescue ? "tasker_replacement" : undefined,
      query.category_id
    );

  let job = {
    uuid: guid(),
    job_type: "Template",
    ...query,
  };

  const storageData = storage.get(STORAGE_KEY_ATTRIBUTE) || {};
  job.job_draft_template =
    query.job_draft_template === "true" || query.job_draft_template === true;

  if (job.uuid === storageData.identifier) {
    job = { ...job, ...storageData.attributes };
  }
  if (job.jobRepost) {
    const { jobRepostData } = getState().build.repost;
    job = { ...job, ...jobRepostData };
  }

  // if url is on draft, use whatever is in local storage
  if (page === "draft") {
    job = { ...storageData.attributes };
    job.uuid = storageData.identifier;
  }

  if (!(job.category_id || job.task_template_id)) {
    throw new Error({ message: "No task template or category ID!" });
  }

  // need to infer a task template from a category, offset the category by 1M.
  job.task_template_id =
    job.task_template_id || 1000000 + parseInt(job.category_id, 10);

  // query.tid overrides invitee_id
  job.invitee_id = query.tid || job.invitee_id;

  return {
    job,
    query,
    bootstrap: bootstrapData,
  };
};

export const fetchTemplateObservable = (job) =>
  Observable.create(async (subscriber) => {
    const {
      task_template_id: taskTemplateId,
      marketing_group_id: marketingGroupId,
      invitee_id: inviteeId,
      ab_decision: abDecision,
      simplified_quote: simplifiedQuote,
      job_draft_template: jobDraftTemplate,
    } = job;

    if (job.taskTemplate?.id === taskTemplateId) {
      subscriber.next({ type: TASK_TEMPLATE_LOADED, job });
      subscriber.complete();
      return;
    }

    const url = TASK_TEMPLATE_SHOW.replace(":task_template_id", taskTemplateId);

    const params = {
      marketing_group_id: marketingGroupId,
      invitee_id: inviteeId,
      ab_decision: abDecision,
      simplified_quote: simplifiedQuote,
      job_draft_template: jobDraftTemplate,
    };

    if (Number(taskTemplateId) === 6303) {
      params.taxonomy = 1;
    }

    subscriber.next({ type: FETCH_TEMPLATE });
    const response = await xhr.get(url, { params });
    const { groups, fields } = transformFieldsToGroups(response.data, job);
    const taskTemplate = {
      ...response.data,
      groups,
      fields,
    };

    subscriber.next({ type: UPDATE_JOB_DATA, taskTemplate });
    subscriber.next({
      type: TASK_TEMPLATE_LOADED,
      job: { ...job, taskTemplate },
    });
    subscriber.complete();
  });

export const bootstrapRabbitInvitee = async (job) => {
  const {
    taskTemplate,
    category_id: categoryId,
    task_template_id: taskTemplateId,
    invitee: {
      metro: { id: metroId },
    },
    invitee_id: inviteeId,
    seconds_between: secondsBetween,
    location: { lat, lng } = {},
  } = job;

  let useInviteeId;
  if (taskTemplate.is_ikea) useInviteeId = 971;
  const enabledFeatures = await fetchEnabledFeatures({
    categoryId: categoryId ?? taskTemplate.category_id,
    metroId,
  });
  const params = {
    category_id: categoryId,
    fixed_rate: enabledFeatures?.includes(featureFlags.bookingAutoInviteLite),
    task_template_id: taskTemplateId,
    invitee_id: useInviteeId || inviteeId,
    seconds_between: secondsBetween,
    location: { lat, lng },
  };
  return xhr
    .get(RABBIT_GRANULAR_AVAILABILITY_SHOW, { params })
    .then((response) => modifyAvailability(response));
};

export const initialJobSchedule = (availability) => {
  const firstAvailableDay = availability.find(
    (avail) => avail.date && !avail.disabled
  );
  return firstAvailableDay || {};
};

export const fetchInviteeObservable = (job) =>
  Observable.create((subscriber) => {
    const needsInviteeRefresh = () => {
      if (job.taskTemplate?.is_ikea) return true;
      if (!job.invitee_id) return false;
      if (!job.invitee) return true;
      if (job.invitee.id !== job.invitee_id) return true;
      if (job.invitee.lastFetchedAt < Date.now() - 15 * 60) return true;
      return false;
    };

    if (!needsInviteeRefresh()) {
      subscriber.next({ type: INVITEE_LOADED, job });
      subscriber.complete();
      return;
    }
    const {
      taskTemplate,
      invitee_id: inviteeId,
      seconds_between: secondsBetween,
    } = job;
    let useInviteeId;
    if (taskTemplate.is_ikea) useInviteeId = 971;
    const params = {
      category_id: taskTemplate.category_id,
      task_template_id: taskTemplate.id,
      invitee_id: useInviteeId || inviteeId,
      seconds_between: secondsBetween,
    };
    xhr
      .get(RABBIT_GRANULAR_AVAILABILITY_SHOW, { params })
      .then(({ data: { metro } }) => {
        bootstrapRabbitInvitee({ ...job, invitee: { metro } }).then(
          ({ data }) => {
            const update = {
              invitee: { ...data, lastFetchedAt: Date.now() },
              invitee_id: job.invitee_id,
            };

            if (!job.schedule)
              update.schedule = initialJobSchedule(data.availability.items);

            subscriber.next({ type: UPDATE_JOB_DATA, data: update });
            subscriber.next({ type: INVITEE_LOADED, job: { ...job, invitee: data } });
            subscriber.complete();
          }
        );
      });
  });

export const updateInviteeAvailability =
  (location) => async (dispatch, getState) => {
    const { job } = getState().build.manager;
    dispatch({ type: UPDATE_INVITEE_AVAILABILITY.REQUEST });
    try {
      const response = await bootstrapRabbitInvitee({ ...job, location });
      const update = {
        invitee: { ...response.data, lastFetchedAt: Date.now() },
        invitee_id: job.invitee_id,
      };
      const hasAvailability = response.data.availability.items.some(
        (date) => !date.disabled
      );

      if (hasAvailability) {
        dispatch(updateJobData(update));
      }
      dispatch({
        type: UPDATE_INVITEE_AVAILABILITY.SUCCESS,
        hasAvailability,
      });

      // returning 'hasAvailability' for legacy implementation that
      // awaits the result of the action, rather that the update of the store
      return hasAvailability;
    } catch (error) {
      dispatch({ type: UPDATE_INVITEE_AVAILABILITY.FAILURE, error });
    }
    return false;
  };

export const saveSelectedItems = () => (dispatch, getState) => {
  const jobData = selectSerializedItems(getState());

  dispatch(updateJobData(jobData));
  dispatch(onFormComplete());
};

const applyTemplateDataToJob = ({ taskTemplate }) => {
  const {
    id,
    category_id: categoryId,
    default_marketing_group_id: defaultMarketingGroupId,
    title,
    category_name: categoryName,
    form_hide_vehicles: formHideVehicles,
  } = taskTemplate;

  const applyData = {
    task_template_id: id,
    category_id: categoryId,
    category_name: categoryName,
    marketing_group_id: defaultMarketingGroupId,
    title,
  };

  const source = getCookie(IKEA_STORE_COOKIE_NAME);
  if (source) applyData.source = source;

  if (formHideVehicles) {
    applyData.vehicle_requirement = "none";
  }

  return applyData;
};

const persistJobDataEpic = (action$, { getState }) =>
  action$.ofType(UPDATE_JOB_DATA).map(() => {
    const { uuid, ...rest } = getState().build.manager.job;
    // attempt to store
    const stored = storage.set(STORAGE_KEY_ATTRIBUTE, {
      identifier: uuid,
      attributes: rest,
    });

    return stored
      ? { type: LOCAL_STORAGE_WRITTEN }
      : { type: LOCAL_STORAGE_FAIL };
  });

const bootstrapApiObservableFactory = (queryData) =>
  Observable.fromPromise(xhr.get(BOOTSTRAP_ENDPOINT))
    // side-effect to show mass promo
    .do(({ data }) => {
      // Yep it can be both an int and a string. And yep, it's a hardcoded id
      // this is to prevent the promo banner from being display for ikea. This
      // promo banner will either be replace by a 3rd party or me. So this will go away
      if (
        queryData?.category_id !== 1107 &&
        queryData?.category_id !== "1107"
      ) {
        window.app.pageContext.set(
          "currentPromo",
          Promise.resolve(data.promotion)
        );
      }
    })
    .map(({ data }) => ({ ...queryData, bootstrap: data }));

const fetchDraftDataObservableFactory = ({
  job_draft_guid: jobDraftGuid,
  ...query
}) =>
  Observable.defer(
    xhr.get.bind(xhr, FETCH_DRAFT_DATA, {
      params: { job_draft_guid: jobDraftGuid },
    })
  ).map(({ data }) => ({
    job_draft_guid: jobDraftGuid,
    ...query,
    ...data,
    rabbit_ids: query.rabbit_ids,
  })); // draft trumps query data;

const bootstrapEpic = (action$, { getState }) =>
  action$
    .ofType(BOOTSTRAP)
    .map(({ queryData }) => {
      const data = trqs.parse(queryData);
      // I'm sorry cruel world
      data.seconds_between = data.seconds_between
        ? data.seconds_between.toString()
        : data.seconds_between;
      return data;
    })
    // adds job draft data from draft endpoint
    .flatMap((queryData) => {
      /*
        skip loading job draft when both `category_id` and `task_template_id` are presented
        because ikea quote job draft can be created with wrong template and category id
        also convergence flow needs only ikea products from job draft and it's handled in ikea redux
      */
      return queryData.job_draft_guid &&
        !queryData.job_draft_template &&
        !(queryData.category_id && queryData.task_template_id)
        ? fetchDraftDataObservableFactory(queryData)
        : Observable.of(queryData);
    })
    // adds bootstrap key onto data
    .flatMap((queryData) => bootstrapApiObservableFactory(queryData))
    // sets up job/category/funnel_id etc.
    .map(setBootstrapData.bind(null, getState))
    .flatMap((bootstrapData) => {
      const actions = [
        {
          type: SET_BOOTSTRAP_DATA,
          ...bootstrapData,
        },
      ];

      return Observable.from(actions);
    })
    .catch(() => {
      return Observable.of(() => {
        window.location.href = internalPath(INCOMPLETE_DATA_REDIRECT);
        return window.location.href;
      });
    });

const fetchTemplateEpic = (action$) =>
  action$
    .ofType(SET_BOOTSTRAP_DATA)
    .switchMap(({ job }) => fetchTemplateObservable(job));

const fetchInviteeEpic = (action$) =>
  action$
    .ofType(TASK_TEMPLATE_LOADED)
    .switchMap(({ job }) => fetchInviteeObservable(job));

const applyTemplateDataToJobEpic = (action$) =>
  action$.ofType(TASK_TEMPLATE_LOADED).switchMap(({ job }) =>
    Observable.of({
      type: UPDATE_JOB_DATA,
      data: { ...job, ...applyTemplateDataToJob(job) },
    })
  );

const bootstrapCompleteEpic = (action$) =>
  action$.ofType(BOOTSTRAP).switchMap(() =>
    Observable.zip(
      action$.ofType(TASK_TEMPLATE_LOADED),
      action$.ofType(INVITEE_LOADED)
    )
      .take(1)
      .concat(Observable.of({ type: BOOTSTRAP_COMPLETE }))
      .last()
  );

const cleanURLEpic = (action$, { getState }) =>
  action$.ofType(BOOTSTRAP_COMPLETE).map(() => {
    return replace(cleanUpUrl(getState()));
  });

export const clearFilter = () => ({ type: CLEAR_PREPOPULATED_FILTER });

export const managerEpic = combineEpics(
  bootstrapCompleteEpic,
  bootstrapEpic,
  fetchTemplateEpic,
  fetchInviteeEpic,
  applyTemplateDataToJobEpic,
  persistJobDataEpic,
  cleanURLEpic
);
