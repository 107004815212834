import React, { Component } from "react";
import { renderRoutes } from "react-router-config";
import localizeRoutes from "util/localizeRoutes";
import { injectIntl } from "react-intl";

class IntlRoutes extends Component {
  render() {
    let { intl, routes } = this.props;
    routes = localizeRoutes(routes, intl);
    return renderRoutes(routes);
  }
}

export default injectIntl(IntlRoutes);
