import React from "react";
import {
  HeaderNavigationA,
  HeaderNavigationIcon,
} from "../../../header-constants";
import { internalPath } from "../../../../../../util/internalPath";

export function MobileList({ isMenuOpen, links }) {
  if (!isMenuOpen) {
    return null;
  }

  return links.map((link) => (
    <HeaderNavigationA
      key={link.title + link.path}
      href={internalPath(link.path)}
    >
      <HeaderNavigationIcon />
      {link.title}
    </HeaderNavigationA>
  ));
}
