import React, { useContext } from "react";
import styled from "styled-components";
import { injectIntl, defineMessages } from "react-intl";
import { FormattedMessage } from "components/FormattedMessage";
import { SocialLinksContainer } from "./StyledFooter/SocialLinksContainer";
import { DiscoverLinks } from "./StyledFooter/DiscoverLinks";
import { CompanyLinks } from "./StyledFooter/CompanyLinks";
import { NativeAppBadges } from "./StyledFooter/NativeAppBadges";
import { StyledFooterContext } from "./StyledFooterProvider";
import { FooterColumn } from "./StyledFooter/FooterColumn";

const messages = defineMessages({
  discover: {
    id: "components.Footer.StyledFooter.discover",
    defaultMessage: "Discover",
  },
  company: {
    id: "components.Footer.StyledFooter.company",
    defaultMessage: "Company",
  },
  mobileTitle: {
    id: "components.Footer.StyledFooter.mobileTitle",
    defaultMessage: "Download our app",
  },
  mobileMessage: {
    id: "components.Footer.StyledFooter.mobileMessage",
    defaultMessage:
      "Tackle your to-do list wherever you are with our mobile app.",
  },
});

function StyledFooterBase({ intl: { locale, formatMessage } }) {
  const { loggedIn, referralData } = useContext(StyledFooterContext);

  return (
    <StyledComponentFooter>
      <WideContainer>
        <SocialLinksContainer formatMessage={formatMessage} />
        <ColumnsContainer>
          <FooterColumn titleMessage={messages.discover}>
            <DiscoverLinks locale={locale} formatMessage={formatMessage} />
          </FooterColumn>
          <FooterColumn titleMessage={messages.company}>
            <CompanyLinks
              locale={locale}
              loggedIn={loggedIn}
              referralData={referralData}
            />
          </FooterColumn>
          <FooterColumn titleMessage={messages.mobileTitle}>
            <StyledP>
              <FormattedMessage message={messages.mobileMessage} />
            </StyledP>
            <NativeAppBadges locale={locale} />
          </FooterColumn>
        </ColumnsContainer>
      </WideContainer>
    </StyledComponentFooter>
  );
}

export const StyledFooter = injectIntl(StyledFooterBase);

const StyledP = styled.p`
  color: ${(props) => props.theme.color.white};
  font-size: ${(props) => props.theme.typography.typeScale["300"]};
  line-height: ${(props) => props.theme.typography.typeScale["400"]};
`;

const ColumnsContainer = styled.div`
  color: ${(props) => props.theme.color.white};
  display: grid;
  grid-template-columns: 1fr;
  row-gap: ${(props) => props.theme.spacing.md};

  ${(props) => props.theme.breakpoints.desktopUp} {
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: ${(props) => props.theme.spacing.md};
  }
`;

const StyledComponentFooter = styled.footer`
  background: ${(props) => props.theme.color.footerBackground};
  font-size: ${(props) => props.theme.typography.typeScale["300"]};
  padding-top: ${(props) => props.theme.spacing.xl};
  padding-bottom: ${(props) => props.theme.spacing.xl};
  width: 100%;
`;

const WideContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  padding-left: 4%;
  padding-right: 4%;

  ${(props) => props.theme.breakpoints.tabletUp} {
    padding-left: 2%;
    padding-right: 2%;
  }

  ${(props) => props.theme.breakpoints.xlDesktopUp} {
    padding-left: 0;
    padding-right: 0;
  }
`;
