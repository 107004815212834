import { formatAddress } from "../selectors/formatAddress";

export const getBuildJob = (state) => state.build.manager.job;

export const selectIsActiveDashboard = (state) =>
  state.build?.progress?.page === "active";

export const selectIsCompletedDashboard = (state) =>
  state.build?.progress?.page === "completed";

export const isIkeaJobFlow = (state) =>
  state.build?.manager?.job?.category_id === 1107;

export const selectIsIkeaAssembly = (state) => {
  const categoryId = state.build?.manager?.job?.category_id;
  return categoryId === 1107 || categoryId === 1104;
};

export const isDirectHireFlow = (state) => {
  const {
    form_referrer: formReferrer,
    target,
    invitee_id: inviteeId,
  } = state.build.manager.job;

  return !!(
    inviteeId &&
    (/dashboard_recommended_taskers_.*/.exec(formReferrer) ||
      formReferrer === "profile" ||
      (formReferrer === "repost_choice" && target === "book") ||
      (formReferrer === "repost_choice" && target === "form"))
  );
};

export const selectIkeaLocale = (state) => {
  const { locale } = state.ikea.manager?.location || {};
  return locale;
};

export const selectIkeaManagerLocation = (state) =>
  state.ikea.manager?.location;

export const selectIkeaJobDraftLocation = (state) =>
  state.ikea.manager?.job_draft?.location;

export const isAutoTaskerSelectionFlow = (state) =>
  isIkeaJobFlow(state) &&
  state.build?.manager?.job?.location?.auto_tasker_selection_v2;

export const selectJobDraftGuid = (state) =>
  state.build?.manager?.job?.job_draft_guid ||
  state.ikea?.manager?.job_draft?.guid ||
  null;

export const selectFormReferrer = (state) =>
  state.build?.manager?.query?.form_referrer || "tr_gm_fa_redirect";

export const formatInitialLocation = (state) => {
  return formatAddress(state.build.manager.job.location);
};

export const formatSecondaryLocation = (state) => {
  return formatAddress(state.build.manager.job.secondary_location);
};

export const selectFunnelId = (state) => {
  return state?.ikea?.manager?.funnel_id;
};

export const selectInviteeId = (state) => {
  return state?.build?.manager?.job?.invitee_id;
};

export const selectFormHideVehicles = (state) => {
  return state?.build.manager?.job?.taskTemplate?.form_hide_vehicles
}
