import {
  fireMetric,
  fireMetricPreserveCase,
} from "store/middleware/metricMiddleware";

export const BADGE_FILTER_CHANGED_EVENT_NAME =
  "recommendations_badge_filter_changed";
export const RECURRING_FILTER_CHANGED_EVENT_NAME =
  "recommendations_recurring_filter_changed";
export const SCHEDULE_FILTER_CHANGED_EVENT_NAME =
  "recommendations_schedule_filter_changed";
export const SORT_FILTER_CHANGED_EVENT_NAME = "recommendations_sort_changed";
export const TIME_FILTER_CHANGED_EVENT_NAME =
  "recommendations_time_filter_changed";
export const PRICE_FILTER_CHANGED_EVENT_NAME =
  "recommendations_price_filter_changed";
export const TASKER_PROFILE_CLICKED_EVENT_NAME =
  "recommendations_tasker_profile_clicked";
export const TASKER_PROFILE_CONTINUE_PURCHASE_FLOW_CLICKED_EVENT_NAME =
  "tasker_profile_continue_purchase_flow_clicked";
export const CONFIRMATION_PAGE_CONTINUE_AND_CHAT_CLICKED_EVENT_NAME =
  "confirmation_page_continue_and_chat_clicked";
export const JOB_BOOKED_AUTO_MATCH_FAILED = "job_booked_auto_match_failed";
export const JOB_BOOKED_AUTO_MATCH_SUCCESS = "job_booked_auto_match_success";
export const TASK_ADDRESS_CHANGED_EVENT_NAME = "task_address_changed";
export const TASKER_UNAVAILABLE_IN_THE_NEW_ADDRESS =
  "tasker_unavailable_in_the_new_address";
export const VEHICLE_FILTER_CHANGED_EVENT_NAME =
  "recommendations_vehicle_filter_changed";
export const TASK_DETAILS_SAVED_EVENT_NAME = "task_details_saved";
export const RECOMMENDATIONS_VIEWED = "recommendations_viewed";

// thunk action creator to fire metrics related to hiring flow
export const fireBuildMetric = (key, data) => (dispatch, getState) => {
  const { job = {}, job: { taskTemplate = {} } = {} } =
    getState().build.manager;
  const { job_draft } = getState().ikea?.manager ?? {};

  const {
    recommendationInterests,
    recommendation_id: recommendationId,
    funnel_id: funnelId,
    job_draft_guid: jobDraftGuid,
    schedule: {
      date: scheduleDate,
      duration_seconds: scheduleDurationSeconds,
      offset_seconds: scheduleOffsetSeconds,
    } = {},
    slot: scheduleSlot,
    seconds_between: secondsBetween,
    form_referrer: formReferrer,
    location: { metro_id: metroId, metro_name: metroName } = {},
  } = job;

  const {
    id: taskTemplateId,
    admin_name: taskTemplateName,
    category_id: categoryId,
    category_name: categoryName,
    default_marketing_group_id: marketingGroupId,
    default_marketing_group_name: marketingGroupName,
  } = taskTemplate;

  const draftGuid = jobDraftGuid || job_draft?.guid;

  const recommendationInterestIds = recommendationInterests
    ? Object.keys(recommendationInterests)
    : null;

  const metricData = {
    ...(recommendationInterestIds ? { recommendationInterestIds } : {}),
    job_draft_guid: draftGuid,
    recommendation_id: recommendationId,
    funnel_id: funnelId,
    task_template_id: taskTemplateId,
    task_template_name: taskTemplateName,
    category_id: categoryId,
    category_name: categoryName,
    marketing_group_id: marketingGroupId,
    marketing_group_name: marketingGroupName,
    metro_name: metroName,
    metro_id: metroId,
    schedule_date: scheduleDate,
    schedule_duration_seconds: scheduleDurationSeconds,
    schedule_offset_seconds: scheduleOffsetSeconds,
    schedule_slot: scheduleSlot,
    seconds_between: secondsBetween,
    form_referrer: formReferrer,
    source_form: "v3_react",
    ...data,
  };

  dispatch(fireMetricPreserveCase(key, metricData));
};

export const fireRecommendationFilterMetric = (
  metricName,
  metricParams = {}
) => (dispatch, getState) => {
  const {
    filterBy,
    currentSort,
    filterMaximumPriceCents,
    filterMinimumPriceCents,
  } = getState().build.recommendations;

  let badgeFilters = Object.keys(filterBy).filter(
    (filterKey) => filterBy[filterKey]
  );
  badgeFilters = badgeFilters.length === 0 ? ["none"] : badgeFilters;

  const {
    recommendationDateRange,
    activeDateKey,
    offsetSeconds,
    activeTimeRanges,
    timeRangeOptions,
  } = getState().build.calendar;

  const { recommendationId } = getState().build.recommendations;

  const scheduleDates = recommendationDateRange.map(({ date }) => date);

  const {
    funnel_id: funnelId,
    seconds_between: secondsBetween,
    job_draft_guid: jobDraftGuid,
  } = getState().build.manager.job;

  const metricsTimeRanges = metricParams.day_time_ranges
    ? metricParams.day_time_ranges
    : activeTimeRanges;

  const dayTimeRanges = Object.keys(metricsTimeRanges).reduce(
    (ranges, range) => {
      if (!metricsTimeRanges[range]) return ranges;
      const activeRange = timeRangeOptions.find(
        (option) => option.key === range
      );
      if (activeRange) ranges.push(activeRange.label);
      return ranges;
    },
    []
  );

  delete metricParams.day_time_ranges;

  dispatch(
    fireMetric(metricName, {
      funnel_id: funnelId,
      job_draft_guid: jobDraftGuid,
      schedule_filter: activeDateKey,
      schedule_dates: scheduleDates,
      current_sort: currentSort,
      badge_filters: badgeFilters,
      seconds_between: secondsBetween,
      day_time_ranges: dayTimeRanges,
      offset_seconds: offsetSeconds,
      recommendation_id: recommendationId,
      max_price_cents: filterMaximumPriceCents,
      min_price_cents: filterMinimumPriceCents,
      ...metricParams,
    })
  );
};

export default fireBuildMetric;
