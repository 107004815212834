import {
  ACCOUNT_PAYMENTS_BANK_ACCOUNT,
  ACCOUNT_PAYMENTS_BANK_ACCT_UPDATE,
} from "account/routes/apiRoutes";
import xhr, { createApiErrorAlert } from "util/xhr";
import { createAlert } from "alerts/redux/modules/alerts";
import { defineMessages } from "react-intl";
import { push } from "connected-react-router";
import { internalPath } from "util/internalPath";

const messages = defineMessages({
  bank_account_updated: {
    id: "alerts.bank_account_updated",
    defaultMessage:
      "Your bank account information has successfully been updated.",
  },
});

const SAVE_BANK_ACCOUNT = "account/bank_account/SAVE_BANK_ACCOUNT";
const LOAD_BANK_ACCOUNT_REQUEST =
  "account/bank_account/LOAD_BANK_ACCOUNT_REQUEST";
const LOAD_BANK_ACCOUNT_RESPONSE =
  "account/bank_account/LOAD_BANK_ACCOUNT_RESPONSE";
const LOAD_BANK_ACCOUNT_ERROR = "account/bank_account/LOAD_BANK_ACCOUNT_ERROR";
const UPDATE_BANK_ACCOUNT_REQUEST =
  "account/bank_account/UPDATE_BANK_ACCOUNT_REQUEST";
const UPDATE_BANK_ACCOUNT_RESPONSE =
  "account/bank_account/UPDATE_BANK_ACCOUNT_RESPONSE";
const UPDATE_BANK_ACCOUNT_ERROR =
  "account/bank_account/UPDATE_BANK_ACCOUNT_ERROR";

export function receiveSettings(response) {
  return { type: LOAD_BANK_ACCOUNT_RESPONSE, response };
}

export function requestSettings() {
  return { type: LOAD_BANK_ACCOUNT_REQUEST };
}

export function errorSettings() {
  return { type: LOAD_BANK_ACCOUNT_ERROR };
}

export function requestSaveBankAccountSettings() {
  return { type: UPDATE_BANK_ACCOUNT_REQUEST };
}

export function receiveSaveBankAccountSettings(response) {
  return { type: UPDATE_BANK_ACCOUNT_RESPONSE, response };
}

export function errorSaveBankAccountSettings(response) {
  return { type: UPDATE_BANK_ACCOUNT_ERROR, response };
}

function fetchSettings(id) {
  return dispatch => {
    dispatch(requestSettings());
    const apiPath = ACCOUNT_PAYMENTS_BANK_ACCOUNT.replace(":id", id);
    return xhr
      .get(apiPath)
      .then(response => dispatch(receiveSettings(response.data)))
      .catch(e => {
        dispatch(createApiErrorAlert(e));
        dispatch(errorSettings());
      });
  };
}

function shouldFetchSettings(state) {
  if (!state.account.bankAccount.data) return true;
  if (state.account.bankAccount.isLoading) return false;
}

export function fetchSettingsIfNeeded(id) {
  // eslint-disable-next-line consistent-return
  return (dispatch, getState) => {
    if (shouldFetchSettings(getState())) {
      return dispatch(fetchSettings(id));
    }
  };
}

export function saveBankAccountSettings() {
  return (dispatch, getState) => {
    dispatch(requestSaveBankAccountSettings());
    const settings = getState().form.bank_account.values;
    const apiPath = ACCOUNT_PAYMENTS_BANK_ACCT_UPDATE;
    return xhr
      .put(apiPath, JSON.stringify(settings))
      .then(response => {
        dispatch(receiveSaveBankAccountSettings(response.data));
        dispatch(createAlert(messages.bank_account_updated.id));
        dispatch(push(internalPath("")));
      })
      .catch(e => {
        dispatch(createApiErrorAlert(e));
        dispatch(errorSaveBankAccountSettings());
      });
  };
}

const initialState = {
  isLoading: false,
  error: undefined,
  data: undefined,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SAVE_BANK_ACCOUNT:
      return state;
    case LOAD_BANK_ACCOUNT_REQUEST:
      return { ...state, isLoading: true };
    case LOAD_BANK_ACCOUNT_RESPONSE:
      return {

        ...state, isLoading: false, data: action.response
      };
    case LOAD_BANK_ACCOUNT_ERROR:
      return { ...state, isLoading: false };
    case UPDATE_BANK_ACCOUNT_REQUEST:
      return state;
    case UPDATE_BANK_ACCOUNT_RESPONSE:
      return {

        ...state, isLoading: false, data: action.response
      };
    case UPDATE_BANK_ACCOUNT_ERROR:
      return { ...state, isLoading: false, data: null };
    default:
      return state;
  }
}
