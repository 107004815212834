import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import { useIntl } from "react-intl";
import { countries } from "countries-list";
import { defineMessages } from "react-intl";

export const messages = defineMessages({
  input: {
    id: "country_dropdown.input",
    defaultMessage: "Search for your country",
  },
});

export function findCountryByCountryCode(countryCode) {
  if (!countryCode) {
    return null;
  }
  const allowedCountriesSet = new Set(allowedCountryCodes);
  const foundCountry = Object.entries(countries)
    .filter((country) => allowedCountriesSet.has(country[0]))
    .find((country) => {
        return country[0] === countryCode;
    });
  if (foundCountry) {
    return {
      key: foundCountry[0],
      country: foundCountry[1],
    };
  }
  return null;
}

export function findCountryByCallingCode(callingCode) {
  if (!callingCode) {
    return null;
  }
  const allowedCountriesSet = new Set(allowedCountryCodes);
  const foundCountry = Object.entries(countries)
    .filter((country) => allowedCountriesSet.has(country[0]))
    .reverse()
    .find((country) => {
      return `+${country[1].phone}` === callingCode;
    });
  if (foundCountry) {
    return {
      key: foundCountry[0],
      country: foundCountry[1],
    };
  }
  return null;
}

// https://taskrabbit.atlassian.net/browse/PLAT-5790
const allowedCountryCodes = [
  "US",
  "CA",
  "GB",
  "ES",
  "FR",
  "IT",
  "PT",
  "DE",
  "CH",
  "SE",
  "AD",
  "AT",
  "BE",
  "CZ",
  "DK",
  "FI",
  "HU",
  "IE",
  "LU",
  "MD",
  "MC",
  "NL",
  "NO",
  "PL",
  "RO",
  "RU",
  "SK",
  "SI",
  "UA"
];

// eslint-disable-next-line react/display-name
const CountryDropdownListItem = React.forwardRef(
  ({ onClick, countryItem, index, selectedIndex, localeCountryNames }, ref) => {
    return (
      // [MEADOW_TODO] - Button
      <button
        ref={ref}
        type="button"
        className={`countryDropdown--list-item ${
          index === selectedIndex ? "selected" : ""
        }`}
        onClick={onClick}
      >
        <span
          className={`countryDropdown--flag countryDropdown--list-item--flag flag:${countryItem.key.toUpperCase()}`}
        >
          &nbsp;
        </span>
        <span className="countryDropdown--list-item--name">
          {localeCountryNames.of(countryItem.key)}
        </span>
        <span className="countryDropdown--list-item--code">
          +{countryItem.country.phone}
        </span>
      </button>
    );
  }
);

// US default because 'Merica
const defaultCountry = {
  key: "US",
  country: {
    name: "United States",
    phone: "1",
  },
};

/**
 * CountryDropdown
 * @param defaultCountryCode Initial country code value. Used only during initial render
 * @param selectedCountryCode Selected country code value. Has priority over value
 * @param value Phone number pefix code
 * @param onChange Called with both value(phone code) and country code as the second parameter
 */
const CountryDropdown = ({
  onChange,
  value = null,
  defaultCountryCode = "",
  selectedCountryCode = "",
  type = "",
  tabIndex = 0,
}) => {
  const inputRef = useRef(null);

  const intl = useIntl();

  const localeCountryNames = useMemo(() => {
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DisplayNames
    const regionNames = new Intl.DisplayNames(
      [intl.locale], { type: 'region' }
    );
    return regionNames;
  }, [intl.locale]);

  const dropdownRef = useRef(null);
  const countriesListRef = useRef(null);
  const itemRef = useRef([]);

  const [isOpen, setIsOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  // calling code has lower priority than country code because it's not unique. E.g: US and CA have the same calling code +1
  const [selectedCountry, setSelectedCountry] = useState(
    findCountryByCountryCode(defaultCountryCode) ||
    findCountryByCountryCode(selectedCountryCode) ||
    findCountryByCallingCode(value) ||
    defaultCountry);

  const initialCountries = useMemo(() => {
    const countriesList = [];

    Object.entries(countries).forEach(([key, country]) => {
      if (allowedCountryCodes.includes(key.toUpperCase())) {
        countriesList.push({
          country,
          key,
        });
      }
    });
    countriesList.sort((a, b) => {
      if (localeCountryNames.of(a.key) < localeCountryNames.of(b.key)) {
        return -1;
      }
      if (localeCountryNames.of(a.key) > localeCountryNames.of(b.key)) {
        return 1;
      }
      return 0;
    });
    return countriesList;
  }, [localeCountryNames]);

  const [availableCountries, setAvailableCountries] =
    useState(initialCountries);

  const dropdownOnClick = (event) => {
    event.stopPropagation();
    setIsOpen(!isOpen);
    setAvailableCountries(initialCountries);
  };

  const selectCountry = (key, country) => {
    setSelectedCountry({
      key,
      country,
    });
    setIsOpen(false);
    if (onChange) onChange(`+${country.phone}`, key);
  };

  useEffect(() => {
    if (selectedCountry && selectedCountryCode) {
      if (selectedCountry.key !== selectedCountryCode) {
        setSelectedCountry(findCountryByCountryCode(selectedCountryCode));
      }
    } else if (selectedCountry && value) {
      if (`+${selectedCountry.country.phone}` !== value) {
        setSelectedCountry(findCountryByCallingCode(value));
      }
    }
  }, [selectedCountry, selectedCountryCode, value]);

  useEffect(() => {
    if (onChange && defaultCountryCode && selectedCountry) {
      onChange(`+${selectedCountry.country.phone}`, defaultCountryCode);
    }
  }, [onChange, defaultCountryCode, selectedCountry]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        isOpen
      ) {
        setIsOpen(false);
      }
    }
    function handleArrowPress(event) {
      const { key } = event;
      if (isOpen) {
        if (key === "ArrowDown") {
          if (selectedIndex < Object.entries(countries).length) {
            setSelectedIndex(selectedIndex + 1);
            itemRef.current[selectedIndex + 1].scrollIntoView();
          }
        } else if (key === "ArrowUp") {
          if (selectedIndex > 0) {
            setSelectedIndex(selectedIndex - 1);
            itemRef.current[selectedIndex - 1].scrollIntoView();
          }
        } else if (key === "Enter") {
          itemRef.current[selectedIndex].click();
          dropdownRef.current.blur();
          event.stopPropagation();
          event.preventDefault();
        }
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleArrowPress);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleArrowPress);
    };
  }, [dropdownRef, isOpen, selectedIndex]);

  const onSearch = useCallback(
    (event) => {
      const searchTerm = event.target.value.toLowerCase();
      const filteredCountries = initialCountries.filter((country) => {
        return localeCountryNames.of(country.key).toLowerCase().includes(searchTerm);
      });
      setAvailableCountries(filteredCountries);
    },
    [initialCountries, localeCountryNames]
  );

  const inputClear = (ev) => {
    ev.preventDefault();
    inputRef.current.value = "";
    setAvailableCountries(initialCountries);
  };

  return (
    <div className={`countryDropdown ${type}`} ref={dropdownRef}>
      {/* [MEADOW_TODO] - Button */}
      {selectedCountry ? (
        <button
          type="button"
          className="countryDropdown--selectedCountry"
          onClick={dropdownOnClick}
          tabIndex={tabIndex}
          data-testid="countryDropdownSelectedCountry"
        >
          <span
            className={`countryDropdown--flag countryDropdown--list-item--flag flag:${selectedCountry.key}`}
          >
            &nbsp;
          </span>
          +{selectedCountry.country.phone}
          <i className="ss-icon ss-lnr-chevrons-expand-vertical" />
        </button>
      ) : null}
      {isOpen && (
        <div className="countryDropdown--list">
          <div className="countryDropdown--list-search">
            <input
              type="text"
              className="countryDropdown--list-search-input"
              data-testid="countryDropdownSearch"
              onKeyUp={onSearch}
              placeholder={intl.formatMessage(messages.input)}
              ref={inputRef}
            />
            {inputRef?.current !== null && inputRef?.current?.value !== "" ? (
              <button
                className="countryDropdown--list-search-clear"
                onClick={inputClear}
                type="button"
              >
                <i className="ss-lnr-cross2"></i>
              </button>
            ) : null}
          </div>
          <div ref={countriesListRef}>
            <button
              type="button"
              className="countryDropdown--list-item countryDropdown--list--selected"
              onClick={(e) => e.preventDefault()}
            >
              <span
                className={`countryDropdown--flag countryDropdown--list-item--flag flag:${selectedCountry.key}`}
              >
                &nbsp;
              </span>
              <span className="countryDropdown--list-item--name">
                {localeCountryNames.of(selectedCountry.key)}
              </span>
              <span className="countryDropdown--list-item--code">
                +{selectedCountry.country.phone}
              </span>
            </button>
            <div className="countryDropdown--list-items">
              {availableCountries.map((countryItem, index) => (
                <CountryDropdownListItem
                  key={countryItem.key}
                  // eslint-disable-next-line no-return-assign
                  ref={(el) => (itemRef.current[index] = el)}
                  onClick={() =>
                    selectCountry(countryItem.key, countryItem.country)
                  }
                  countryItem={countryItem}
                  index={index}
                  selectedIndex={selectedIndex}
                  localeCountryNames={localeCountryNames}
                />
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CountryDropdown;
