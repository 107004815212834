const WALLET_TYPE_APPLE_PAY = "apple_pay";
const WALLET_TYPE_GOOGLE_PAY = "google_pay";
const WALLET_TYPE_CREDIT_CARD = "credit_card";

class Wallet {
  constructor(type) {
    this.type = type || '';
  }

  get isApplePay() {
    return this.type === WALLET_TYPE_APPLE_PAY;
  }

  get isGooglePay() {
    return this.type === WALLET_TYPE_GOOGLE_PAY;
  }

  get isCreditCard() {
    return this.type === WALLET_TYPE_CREDIT_CARD || this.type === null;
  }
}

export default Wallet;
