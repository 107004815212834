import { combineEpics } from "redux-observable";
import { SubmissionError } from "redux-form";
import { push } from "connected-react-router";
import { defineMessages } from "react-intl";
import findIndex from "lodash/findIndex";
import findLastIndex from "lodash/findLastIndex";
import isEmpty from "lodash/isEmpty";
import pick from "lodash/pick";
import omit from "lodash/omit";
import qs from "qs";

import { createAlert } from "alerts/redux/modules/alerts";
import {
  GET_DEFAULT_ADDRESSES,
  IKEA_POST_JOB_DRAFT,
  SAVE_IKEA_JOB_DRAFT,
  SET_DEFAULT_ADDRESSES,
} from "build/routes/apiRoutes";
import { updateActiveMetro } from "build/redux/modules/form/validation";
import {
  BOOTSTRAP_COMPLETE,
  fetchTemplateObservable,
  updateInviteeAvailability,
  updateJobData,
} from "build/redux/modules/manager";
import {
  dispatchJobDraft,
  INVITEE_UPDATED,
} from "build/redux/modules/recommendations";
import { fireBuildMetric as fbm } from "build/redux/modules/metrics";
import { scheduleValid } from "build/redux/modules/managerUtil";
import { setJobDraft as setIkeaJobDraft } from "ikea/redux/modules/manager";
import { selectFormReferrer } from "build/redux/modules/selector";

import {
  IKEA_CATEGORIES,
  IKEA_STORE_COOKIE_NAME,
  IKEA_TASK_TEMPLATE,
  IS_PHASE_ARTICLE_PRICING,
  IS_PHASE_0_1107,
} from "util/constants";
import {  } from "util/constants";
import { setCookie } from "util/cookie";
import { internalPath } from "util/internalPath";
import xhr from "util/xhr";

import validateFields from "./validation";
import transformFieldsToGroups, {
  emailFieldGroup,
  storeSelectGroup,
} from "./transformFieldsToGroups";
import { getLocationAndDescFields, onFormComplete } from "./util";
import { TrMetricsService } from "services/TrMetricsService";
import segmentAnalyticsService from "services/SegmentAnalyticsService";
import { JOB_ADDRESS_ENTERED } from "enums/segmentEventNames";

export const fireBuildMetric = fbm;

const messages = defineMessages({
  quote_submitted_success: {
    id: "build.form.quote_submitted_success",
    defaultMessage:
      "Task details submitted. Customer should check email to finalize booking.",
  },
  quote_submitted_failure: {
    id: "build.form.quote_submitted_failure",
    defaultMessage: "Something went wrong, please try again later.",
  },
});

const initialState = {
  focusedGroup: {},
  newDefaultAddress: undefined,
  existingDefaultAddress: undefined,
};

const INITIALIZE_FORM = "v3/build/form/INITIALIZE_FORM";
const SET_FOCUSED_GROUP = "v3/build/form/SET_FOCUSED_GROUP";
const ADJUST_SCHEDULE_FIELD = "v3/build/form/ADJUST_SCHEDULE_FIELD";
const SET_JOB_DRAFT_GUID = "v3/build/form/SET_JOB_DRAFT_GUID";
const SET_NEW_DEFAULT_ADDRESS = "v3/build/form/SET_NEW_DEFAULT_ADDRESS";
const GET_DEFAULT_ADDRESS = "v3/build/form/GET_DEFAULT_ADDRESS";
const UPDATE_EXISTING_DEFAULT_ADDRESS =
  "v3/build/form/UPDATE_EXISTING_DEFAULT_ADDRESS";

// eslint-disable-next-line @typescript-eslint/default-param-last
export default function form(state = initialState, action) {
  switch (action.type) {
    case INITIALIZE_FORM:
      return {
        ...state,
        groups: action.groups,
        focusedGroup: action.focusedGroup,
      };
    case SET_FOCUSED_GROUP:
      return { ...state, focusedGroup: action.group };
    case ADJUST_SCHEDULE_FIELD:
      return {
        ...state,
        focusedGroup: state.focusedGroup,
        groups: action.groups,
      };
    case SET_JOB_DRAFT_GUID:
      return {
        ...state,
        job_draft_guid: action.job_draft_guid,
      };
    case SET_NEW_DEFAULT_ADDRESS:
      return {
        ...state,
        newDefaultAddress: action.newDefaultAddress,
      };
    case GET_DEFAULT_ADDRESS:
      return {
        ...state,
        existingDefaultAddress: action.existingDefaultAddress,
      };
    case UPDATE_EXISTING_DEFAULT_ADDRESS:
      return {
        ...state,
        existingDefaultAddress: action.newDefaultAddress,
      };
    default:
      return state;
  }
}

const initializeForm = ({ groups, focusedGroup }) => ({
  type: INITIALIZE_FORM,
  focusedGroup,
  groups,
});

export const focusGroup = (group) => {
  return {
    type: SET_FOCUSED_GROUP,
    group,
  };
};

const setJobDraftGuid = (guid) => ({
  type: SET_JOB_DRAFT_GUID,
  job_draft_guid: guid,
});

export const setNewDefaultAddress = (newDefaultAddress) => ({
  type: SET_NEW_DEFAULT_ADDRESS,
  newDefaultAddress,
});

export const getDefaultAddress = (existingDefaultAddress) => ({
  type: GET_DEFAULT_ADDRESS,
  existingDefaultAddress,
});

const updateExistingDefaultAddress = () => ({
  type: UPDATE_EXISTING_DEFAULT_ADDRESS,
});

export const fetchDefaultAddress = () => {
  return (dispatch, getState) => {
    const state = getState();
    const {
      build: {
        manager: {
          job: { funnel_id: funnelId },
        },
      },
    } = state;

    const params = {
      key: "home",
    };

    xhr.get(GET_DEFAULT_ADDRESSES, { params: params }).then(
      (response) => {
        const responseToDispatch =
          response.data.items.length === 0 ? {} : response.data.items[0];
        dispatch(getDefaultAddress(responseToDispatch.address));
        if (responseToDispatch.address) {
          TrMetricsService.fire("booking_flow_default_address_exists", {
            funnel_id: funnelId,
          });
        }

        return responseToDispatch;
      },
      (e) => {
        return e;
      }
    );
  };
};

const validateTaskerAvailabilityForLocation =
  (location) => async (dispatch) => {
    const anyAvailability = await dispatch(updateInviteeAvailability(location));
    let { error } = location;
    if (!anyAvailability) {
      error = "form.validation.tasker_not_available";
    }
    return { ...location, error };
  };

const validateTaskerAvailabilityForDirectHire =
  (job, data) => async (dispatch) => {
    const { values, errors } = data;

    if (
      !isEmpty(errors) ||
      !values.location ||
      !job.invitee ||
      job.taskTemplate.modify_page_state !== "hire"
    ) {
      return data;
    }

    const location = await dispatch(
      validateTaskerAvailabilityForLocation(values.location)
    );
    if (location.error) {
      errors.location = location.error;
    }

    return { values, errors };
  };

export const saveValues = () => async (dispatch, getState) => {
  const state = getState();
  const {
    form: {
      focusedGroup: { field_names: fieldNames, fields },
      newDefaultAddress,
    },
    manager: { job },
  } = state.build;
  const { values: taskTemplateValues } = state.form.task_template;
  // extract values for fields of the group that is focused
  const fieldValues = fields.reduce((memo, { field_name: name }) => {
    memo[name] = taskTemplateValues[name];
    return memo;
  }, {});

  if (fieldNames.includes("assembly_item_type")) {
    dispatch(fireBuildMetric("furniture_items_type_captured", fieldValues));
  }

  if (fieldNames.includes("ikea_redirect")) {
    dispatch(
      fireBuildMetric("ikea_redirect_choice", {
        ikea_redirect_choice: "opt_out",
      })
    );
  }

  const data = await validateFields(fields, fieldValues, job);

  // job data required for location verification
  const { values, errors } = await dispatch(
    validateTaskerAvailabilityForDirectHire(job, data)
  );

  if (!isEmpty(errors)) {
    throw new SubmissionError(errors);
  }

  if (newDefaultAddress) dispatch(setNewDefaultAddress(values.location));
  dispatch(updateJobData(values));
};

export const saveIkeaJobDraft =
  (payload = {}) =>
  async (dispatch) => {
    const { data } = await xhr.post(SAVE_IKEA_JOB_DRAFT, payload);

    // Persist job_draft_guid to build/manager/job store
    dispatch(setJobDraftGuid(data.guid));

    // Persist job_draft to ikea/manager store
    const jobDraftData = { ...data, location: { isTouched: true } };
    dispatch(setIkeaJobDraft(jobDraftData));

    return data.guid;
  };

export const setIkeaSearchParams =
  (jobDraftGuid) => async (dispatch, getState) => {
    const state = getState();
    const location = new URL(window.location);
    const params = location.searchParams;
    const uuid = params.get("uuid");
    const funnelId = state.ikea?.manager?.funnel_id || params.get("funnel_id");

    const searchParams = qs.stringify(
      {
        job_draft_guid: jobDraftGuid,
        funnel_id: funnelId,
        uuid,
        v3: true,
      },
      { addQueryPrefix: true, skipNulls: true }
    );
    const redirectPath = location.pathname + searchParams;
    history.pushState({ searchParams }, "", redirectPath);
    return true;
  };

export const isGroupEmpty = (job, group, page) => {
  return group.fields.some(({ field_name: fieldName, required }) => {
    if (page === "book") return true;

    if (fieldName === "schedule") {
      return !scheduleValid(job.schedule);
    }

    return isEmpty(job[fieldName]) && required;
  });
};

const lastVisibleIndex = (groups) => {
  return findLastIndex(groups, (group) => {
    const { fields } = group;
    const isHidden = fields.every((field) => field.hidden);

    return !isHidden;
  });
};

const firstEmptyGroup = (job, groups, page) => {
  // if no empty groups focus on last group, unless on direct hire form, then none
  // also make sure all of the fields aren't empty
  const index = findIndex(groups, (group) => {
    const { fields } = group;
    const isHidden = fields.every((field) => field.hidden);
    const test = isGroupEmpty(job, group, page) && !isHidden;

    return test;
  });

  if (index === -1) {
    const idx = lastVisibleIndex(groups);

    return page === "hire" || page === "book" ? groups[1] : groups[idx];
  }

  return groups[index];
};

const bootstrapFormEpic = (action$, { getState }) =>
  action$.ofType(BOOTSTRAP_COMPLETE).switchMap(() => {
    const {
      manager: { job },
      progress: { page },
    } = getState().build;

    let { groups } = transformFieldsToGroups(job.taskTemplate, job);
    if (page === "quote" && job.taskTemplate.is_ikea) {
      groups = [storeSelectGroup, ...groups, emailFieldGroup];
    }

    const focusedGroup = firstEmptyGroup(job, groups, page);
    if (job.form_referrer === "business_partnership") {
      focusedGroup.field_names = ["location", "description"];
      focusedGroup.fields = getLocationAndDescFields(groups);
    }
    const hiddenFieldValues = job.taskTemplate.fields.reduce(
      (result, field) => {
        const { field_name: fieldName, field_type: fieldType } = field;

        if (field.hidden && fieldName !== "schedule") {
          // only update init if we don't have a value.
          if (isEmpty(job[fieldName])) {
            result[fieldName] = field[`${fieldType}_value`];
          }
        }

        return result;
      },
      {}
    );

    return [
      updateJobData(hiddenFieldValues),
      initializeForm({ groups, focusedGroup }),
    ];
  });

const onQuoteComplete = () => async (dispatch, getState) => {
  const state = getState();
  const {
    progress: { page },
    manager: { job },
  } = state.build;
  const ikeaFields = [
    "category_id",
    "task_template_id",
    "email",
    "mobile_phone",
    "schedule",
    "location",
    "source",
  ];

  const data = pick(job, ikeaFields);

  if (job.ikea_products) {
    data.ikea_products = job.ikea_products.map((product) => product.ikea_id);
  }

  setCookie(IKEA_STORE_COOKIE_NAME, data.source, 23 * 365);

  try {
    await xhr.post(IKEA_POST_JOB_DRAFT, data);
    dispatch(createAlert(messages.quote_submitted_success.id));
    dispatch(fireBuildMetric("quote_form_completed"));
    dispatch(
      push(
        `/tasks/r/${page}` +
          qs.stringify(
            { task_template_id: job.task_template_id },
            { addQueryPrefix: true }
          )
      )
    );
  } catch {
    dispatch(
      createAlert({
        messages: messages.quote_submitted_failure.id,
        type: "error",
      })
    );
  }
};

const nextVisibleGroup = (groups, currentIndex) =>
  groups
    .slice(currentIndex + 1)
    .find(({ fields }) => fields.some((field) => !field.hidden));

const segmentTrackJobAddressEntered = (state) => {
  const {
    build: {
      manager: {
        job,
        job: { location },
      },
    },
  } = state;

  const properties = {
    category: job.category_name,
    category_id: job.category_id,
    job_details: job.description,
    job_draft_id: job.job_draft_guid,
    job_length: job.job_size,
    job_zipcode: location.postal_code,
    metro: location.metro_name,
    metro_id: location.metro_id,
  };
  segmentAnalyticsService.trackEvent({ name: JOB_ADDRESS_ENTERED, properties });
};

// Calls this function when button is clicked
export const handleSubmit = () => async (dispatch, getState) => {
  await dispatch(saveValues());

  const state = getState();
  const {
    build: {
      form: { focusedGroup, groups, newDefaultAddress, existingDefaultAddress },
      manager: {
        job,
        job: {
          assembly_item_type: assemblyItemType,
          funnel_id: funnelId,
          location,
        },
      },
      progress: { page },
    },
  } = state;
  const formReferrer = selectFormReferrer(state);

  const data = omit(job, "taskTemplate");
  const groupIndex = findIndex(groups, { title: focusedGroup.title });
  const isAddressGroup =
    focusedGroup.fields[0].field_type == "address" &&
    focusedGroup.field_names.includes("location");

  if (isAddressGroup && newDefaultAddress) {
    // Send API endpoint to save address
    const params = {
      location: newDefaultAddress,
      key: "home",
    };

    TrMetricsService.fire("booking_flow_default_address_set", {
      funnel_id: funnelId,
    });

    xhr
      .post(SET_DEFAULT_ADDRESSES, params)
      .then(() => {
        dispatch(updateExistingDefaultAddress());
      })
      .catch(() => {});
  }

  if (isAddressGroup) {
    segmentTrackJobAddressEntered(state);
  }

  let nextVisible =
    page !== "affiliate" && nextVisibleGroup(groups, groupIndex);

  if (nextVisible && nextVisible.ikeaRedirectInfoModal) {
    const showIkeaRedirect =
      assemblyItemType === "only_ikea_items" &&
      location &&
      location.ikea_supported;

    if (!showIkeaRedirect) {
      nextVisible = nextVisibleGroup(groups, groupIndex + 1);
    }
  }

  if (nextVisible) {
    dispatch(focusGroup(nextVisible));
  } else if (["form", "book", "hire", "affiliate"].includes(page)) {
    if (data.job_draft_guid) {
      dispatch(dispatchJobDraft({ data }));
    }
    dispatch(onFormComplete());
  } else if (page === "quote") {
    dispatch(onQuoteComplete());
  } else if (data.job_draft_template && page === "recommendations") {
    dispatch(onFormComplete());
  }
};

export const ikeaRedirect =
  () =>
  async (dispatch, getState) => {
    console.log(`ikeaRedirect: ${ikeaRedirect}`);
    const state = getState();
    const {
      build: {
        form: { job_draft_guid: jobDraftGuid },
        manager: {
          job: {
            funnel_id: funnelId,
            uuid,
            location: { metro_id: metroId },
          },
          job,
        },
      },
      ikea: {
        manager: { job_draft: ikeaJobDraft },
      },
    } = state;
    const formReferrer = selectFormReferrer(state);
    const location = new URL(window.location);
    let ikeaCategoryJobDraftGuid = jobDraftGuid;
    const loggedIn = state.user?.loggedIn;

    const updatedLocation = await updateActiveMetro(job.location, job);

    // If there is a category_33 job draft here
    // We need to create a new job draft in our correct ikea category
    if (ikeaJobDraft?.category_id !== IKEA_CATEGORIES[0]) {
      ikeaCategoryJobDraftGuid = await dispatch(
        saveIkeaJobDraft({
          address: updatedLocation,
          bypass_location: false,
          skip_first_notification: true,
          source: "taskrabbit_gm_referral",
        })
      );
    }
    dispatch(
      fireBuildMetric("ikea_redirect_choice", {
        ikea_redirect_choice: "opt_in",
        convergence_redirect: IS_PHASE_0_1107,
      })
    );
    if (IS_PHASE_0_1107) {
      // TODO: This update is why convergence can't revisit old flows
      // Not sure why but this is worth a revisit in the future
      const changedJobData = {
        form_referrer: formReferrer,
        task_template_id: IKEA_TASK_TEMPLATE,
        category_id: IKEA_CATEGORIES[0],
        location: updatedLocation,
      };
      dispatch(updateJobData(changedJobData));

      // we need to refresh taskTemplate here because job category_id is changed but taksTemplate is not synced
      // how rxjs observable works https://rxjs.dev/guide/observable
      await fetchTemplateObservable({ ...job, ...changedJobData }).subscribe({
        next(actionPayload) {
          dispatch(actionPayload);
        },
      });

      location.pathname = internalPath("/tasks/r/form");
      location.search = qs.stringify(
        {
          form_referrer: formReferrer,
          job_draft_guid: ikeaCategoryJobDraftGuid,
          funnel_id: funnelId,
          uuid,
          v3: true, // add v3 query param to pin users to v3 flow for Ikea (instead of Nextjs) as there's no category ID here
        },
        { addQueryPrefix: true }
      );

      history.pushState({}, "", location.pathname + location.search);
    } else if (IS_PHASE_ARTICLE_PRICING) {
      location.pathname = internalPath("/ikea/search");
      location.search = qs.stringify(
        {
          job_draft_guid: ikeaCategoryJobDraftGuid,
          funnel_id: funnelId,
          form_referrer: formReferrer || "homepage_v2_hero_buttons",
          uuid,
          v3: true,
        },
        { addQueryPrefix: true, skipNulls: true }
      );
      window.location.assign(location);
    } else {
      location.pathname = internalPath("/ikea/hire");
      location.search = qs.stringify(
        {
          job_draft_guid: ikeaCategoryJobDraftGuid,
          funnel_id: funnelId,
          form_referrer: formReferrer,
          metro_id: metroId,
          uuid,
          v3: true,
        },
        { addQueryPrefix: true, skipNulls: true }
      );
      window.location.assign(location);
    }

    // redirect user back to the category_33 form and start a new form
    // we have to do this because ikea flow and category_33 flow does not share the same build flow.
    window.addEventListener("popstate", () => {
      const currentState = history.state;

      if (!currentState || !currentState.searchParams) {
        let prevLocation = qs.stringify(
          {
            form_referrer: formReferrer,
            task_template_id: job?.task_template_id,
            v3: true,
          },
          { addQueryPrefix: true }
        );

        if (loggedIn) {
          const searchParams = new URLSearchParams(window.location.search);
          const categoryId = searchParams.get("category_id");
          const searchFunnelId = searchParams.get("funnel_id");
          const guid = searchParams.get("job_draft_guid");
          prevLocation += `& + ${qs.stringify({
            category_id: categoryId,
            funnel_id: searchFunnelId,
            job_draft_guid: guid,
            v3: true,
          })}`;
        }
        const redirectPath = location.pathname + prevLocation;
        window.location.assign(redirectPath);
      }
    });
  };

const selectInviteeEpic = (action$, { getState }) =>
  action$.ofType(INVITEE_UPDATED).map(() => {
    const state = getState();
    const { job } = state.build.manager;
    const { groups } = transformFieldsToGroups(job.taskTemplate, job);

    return { type: ADJUST_SCHEDULE_FIELD, groups };
  });

export const formEpic = combineEpics(bootstrapFormEpic, selectInviteeEpic);
