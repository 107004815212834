import { IKEA_STORES } from "util/constants";
import moment from "util/momentWithTZ";
import range from "lodash/range";

export default function transformFieldsToGroups(taskTemplate = {}, jobData) {
  const { fields, groups } = taskTemplate;
  if (!fields || !fields.length) return [];
  const { invitee } = jobData;
  const updatedFields = fields.map(field => {
    const updated = modifyField(invitee, field, jobData);
    return updated;
  });
  const groupsWithFields = groups.map(group =>
    addFieldsToGroup(group, updatedFields)
  );

  return { groups: groupsWithFields, fields: updatedFields };
}

const extractFieldsForGroup = (group, fields) =>
  group.field_names
    .map(name => fields.find(f => name === f.field_name))
    .filter(field => field);

const addFieldsToGroup = (group, fields) => ({
  ...group,
  title: group.title,
  fields: extractFieldsForGroup(group, fields),
});

const modifyOptions = (availableOptions, field, extraScope) => {
  const newField = { ...field };
  newField[extraScope + "_extra"].options.forEach(option => {
    if (!~availableOptions.indexOf(option.value)) {
      option.disabled = true;
    }
  });
  return newField;
};

const modifyVehicleRequirements = (invitee, field, job) => {
  if (invitee && job.invitee_id !== "broadcast") {
    field = modifyOptions(invitee.vehicles.concat(["none"]), field, "radio");
  }

  if (job.category_id == 59) {
    field.radio_value = "none";
  }

  return field;
};

const buildEnabledFutureWindow = (windowsTemplate, iso) => {
  return {
    date: iso,
    disabled: false,
    items: windowsTemplate.map(win => ({ ...win })),
  };
};

const currentOffsetSeconds = () => {
  const now = new Date();
  return now.getHours() * 3600 + now.getMinutes() * 60 + now.getSeconds();
};

export const createScheduleWindows = fieldData => {
  const {
    future_days,
    future_windows,
    today_windows,
    today_buffer,
  } = fieldData.datetime_windows_extra;
  const todayThreshold = currentOffsetSeconds() + today_buffer;
  const date = moment();
  const iso = date.format("YYYY-MM-DD");
  let items = [];
    const indexCache = {};

  const samedayTodayWindows = today_windows.map(win => {
    win.disabled =
      win.offset_seconds != 0 && win.offset_seconds < todayThreshold;
    return win;
  });

  items = [
    ...items,
    {
      date: iso,
      sameday: true,
      disabled: samedayTodayWindows
        .filter(({ offset_seconds }) => offset_seconds !== 0)
        .every(win => win.disabled),
      items: samedayTodayWindows,
    },
  ];

  indexCache[iso] = items.length - 1;

  range(future_days).forEach(day => {
    const iso = date.add(1, "days").format("YYYY-MM-DD");
    items.push(buildEnabledFutureWindow(future_windows, iso));
    indexCache[iso] = items.length - 1;
  });

  return { items, indexCache };
};

export const modifySchedule = (field, job) => {
  const windows = createScheduleWindows(field);
  return { ...field, adjustedWindows: windows };
};

const modifyField = (invitee, field, job) => {
  if (field.field_name === "vehicle_requirement")
    return modifyVehicleRequirements(invitee, field, job);
  if (field.field_name === "schedule") return modifySchedule(field, job);
  return field;
};

export const emailFieldGroup = {
  title: "Contact Info",
  fields: [
    {
      field_type: "email",
      field_name: "email",
      field_icon: "envelope-alt-lined",
      title: "Email Address",
      blank_instructions: "Enter email address",
      editable: true,
      required: true,
      hidden: false,
      alert_message: null,
      alert_icon: null,
      header_html: "Enter email address",
      footer_html: null,
      show_title: false,
      email_value: null,
      email_initial_value: null,
      email_extra: {
        placeholder: "Enter email address",
      },
    },
    {
      field_type: "mobile_phone",
      field_name: "mobile_phone",
      field_icon: "phone-lined",
      title: "Mobile Phone",
      blank_instructions: "Enter phone number (optional)",
      editable: true,
      required: false,
      hidden: false,
      alert_message: null,
      alert_icon: null,
      header_html: "Enter phone number",
      footer_html:
        "By providing your mobile phone number, you agree to allow us to send you an SMS message regarding your interest.<br/>Message & data rates from your plan carrier may apply.",
      show_title: false,
      mobile_phone_value: null,
      mobile_phone_initial_value: null,
      mobile_phone_extra: {
        placeholder: "Enter phone number (optional)",
      },
    },
  ],
};

export const storeSelectGroup = {
  title: "Store Location",
  fields: [
    {
      field_type: "select",
      field_name: "source",
      field_icon: "location",
      title: "Select Store Location",
      blank_instructions: "Select",
      editable: true,
      required: true,
      hidden: false,
      alert_message: null,
      alert_icon: null,
      header_html: "Select Store Location",
      footer_html: null,
      show_title: false,
      select_value: null,
      select_initial_value: null,
      select_extra: {
        options: IKEA_STORES,
      },
    },
  ],
};
