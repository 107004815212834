import React from "react";
import {
  FormattedHTMLMessage,
  FormattedMessage,
} from "components/FormattedMessage";
import { defineMessages } from "react-intl";
import {
  accountDeactivateTwoFactorAuth,
  accountCloseModal,
} from "account/redux/modules/accountSecurity";
import { StyledButtonPrimary } from "components/sharedStyledComponents/StyledButton";
import { connect } from "react-redux";
import styled from "styled-components";
import alertSvg from "./TwoFactorDeleteModal/ic_alert.svg";
import { ForDesktop, ForMobile } from "../../shared/hooks/use-breakpoints";

const messages = defineMessages({
  title: {
    id: "two_factor_authentication_modal.delete.title",
    defaultMessage: "Turn off two-factor authentication?",
  },
  description: {
    id: "two_factor_authentication_modal.delete.description",
    defaultMessage:
      "If you turn off two-factor authentication, you will no longer receive a code if we notice an attempted login from an unrecognized device or browser.",
  },

  cancel: {
    id: "two_factor_authentication_modal.delete.cancel",
    defaultMessage: "Cancel",
  },
  turnOff: {
    id: "two_factor_authentication_modal.delete.turn_off",
    defaultMessage: "Turn Off",
  },
  learnMore: {
    id: "two_factor_authentication_modal.delete.learn_more",
    defaultMessage: "Learn more about two-factor authentication",
  },
});

const TwoFactorDeleteModal_ = ({
  error,
  loading,
  accountCloseModalAction,
  accountDeactivateTwoFactorAuthAction,
}) => {
  return (
    <>
      <ForDesktop>
        <StyledTwoFactorDeleteModal>
          <StyledModalTop>
            <StyledModalTitle>
              <FormattedMessage message={messages.title} />
            </StyledModalTitle>
          </StyledModalTop>
          <hr />
          <StyledSubtitle>
            <FormattedHTMLMessage message={messages.description} />
          </StyledSubtitle>
          <StyledCodeErrorResponse>{error}</StyledCodeErrorResponse>
          <StyledBottomRow>
            <StyledButtonPrimary
              isLoading={loading}
              onClick={accountDeactivateTwoFactorAuthAction}
              width="320px"
              height="54px"
            >
              <FormattedMessage message={messages.turnOff} />
            </StyledButtonPrimary>
          </StyledBottomRow>
          <StyledCancelButton onClick={accountCloseModalAction}>
            <FormattedMessage message={messages.cancel} />
          </StyledCancelButton>
        </StyledTwoFactorDeleteModal>
      </ForDesktop>
      <ForMobile>
        <StyledMobileTwoFactorDeleteModal>
          <StyledImg src={alertSvg} alt="" />
          <StyledModalTop>
            <StyledModalTitle>
              <FormattedMessage message={messages.title} />
            </StyledModalTitle>
          </StyledModalTop>
          <div>
            <FormattedHTMLMessage message={messages.description} />
          </div>
          <StyledCodeErrorResponse>{error}</StyledCodeErrorResponse>
          <StyledMobileBottomRow>
            <StyledButtonPrimary
              isLoading={loading}
              onClick={accountDeactivateTwoFactorAuthAction}
              width="100%"
              height="54px"
              margin="0px"
            >
              <FormattedMessage message={messages.turnOff} />
            </StyledButtonPrimary>
            <StyledCancelButton onClick={accountCloseModalAction}>
              <FormattedMessage message={messages.cancel} />
            </StyledCancelButton>
          </StyledMobileBottomRow>
        </StyledMobileTwoFactorDeleteModal>
      </ForMobile>
    </>
  );
};

export const TwoFactorDeleteModal = connect(null, {
  accountDeactivateTwoFactorAuthAction: accountDeactivateTwoFactorAuth,
  accountCloseModalAction: accountCloseModal,
})(TwoFactorDeleteModal_);

const StyledImg = styled.img`
  margin-bottom: ${(props) => props.theme.spacing.xl};
  height: 100px;
`;

const StyledSubtitle = styled.div`
  padding: 0px ${(props) => props.theme.spacing.md};
`;

const StyledModalTitle = styled.div`
  padding: 0;
  ${(props) => props.theme.breakpoints.tabletUp} {
    font-size: 26px;
  }
  ${(props) => props.theme.breakpoints.mobile} {
    font-size: ${(props) => props.theme.typography.typeScale["200"]};
  }
  line-height: 30px;
  font-weight: normal;
  text-align: center;
  margin: 0 auto;
`;
const StyledModalTop = styled.div`
  display: flex;
  margin-bottom: ${(props) => props.theme.spacing.md};
`;

const StyledMobileTwoFactorDeleteModal = styled.div`
  width: 100%;
  height: 80%;
  display: flex;
  padding: ${(props) => props.theme.spacing.md};
  flex-direction: column;
  justify-content: center;
  text-align: center;
}`;

const StyledTwoFactorDeleteModal = styled.div`
  width: 660px;
  min-height: 350px;
  padding-top: ${(props) => props.theme.spacing.xl};
  padding-bottom: ${(props) => props.theme.spacing.xl};
  padding-left: ${(props) => props.theme.spacing.xxl};
  padding-right: ${(props) => props.theme.spacing.xxl};
  height: 100%;
  width: 100%;
  text-align: center;
`;

const StyledCancelButton = styled.button`
  margin-top: ${(props) => props.theme.spacing.md};
  background: none;
  border: none;
  cursor: pointer;
`;

const StyledBottomRow = styled.div`
  margin-top: ${(props) => props.theme.spacing.xl};
  display: flex;
  justify-content: center;
`;

const StyledMobileBottomRow = styled.div`
  margin-top: ${(props) => props.theme.spacing.md};
  display: flex;
  justify-content: center;
  position: absolute;
  flex-direction: column;
  width: 100%;
  left: 0;
  bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: ${(props) => props.theme.spacing.xl};
  padding-right: ${(props) => props.theme.spacing.xl};
  margin-bottom: ${(props) => props.theme.spacing.xl};
`;

const StyledCodeErrorResponse = styled.div`
  color: ${(props) => props.theme.color.errorText};
  margin-top: ${(props) => props.theme.spacing.sm};
  margin-bottom: ${(props) => props.theme.spacing.sm};
  margin-left: 0;
  margin-right: 0;
  font-size: ${(props) => props.theme.typography.typeScale["50"]};
`;
