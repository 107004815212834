import { combineEpics } from "redux-observable";
import { Observable } from "rxjs";
import xhr, {
  createApiErrorAlert,
  snakeCaseKeysAndQueryStringify,
} from "util/xhr";
import qs from "qs";
import moment from "util/momentWithTZ";
import { push } from "connected-react-router";
import {
  updateJobData,
  fetchInviteeObservable,
  UPDATE_JOB_DATA,
  clearFilter,
} from "build/redux/modules/manager";
import {
  handleCalendarResponse,
  fetchCalendar,
  fetchCalendarError,
  SET_RECO_DATE_TIME_RANGE,
} from "build/redux/modules/calendar";
import {
  SORT_FILTER_CHANGED_EVENT_NAME,
  BADGE_FILTER_CHANGED_EVENT_NAME,
  fireBuildMetric,
  fireRecommendationFilterMetric,
} from "build/redux/modules/metrics";
import {
  UPDATE_JOB_DRAFT,
  MULTIDAY_RECOMMENDATIONS_SHOW,
  BOOTSTRAP_MULTIDAY_RECOMMENDATIONS,
  BOOTSTRAP_CALENDAR_DATA,
} from "build/routes/apiRoutes";
import { DEFAULT_VEHICLE_REQUIREMENT } from "util/constants";
import { featureFlags } from "enums/featureFlags";
import { fetchEnabledFeatures } from "../../util/getEnabledFeatures";

export { fireBuildMetric, fireRecommendationFilterMetric };

const initialState = {
  currentSort: "recommended",
  loadingRecos: false,
  autoMatchLoading: false,
  recommendationsLoaded: false,
  recommendationInterests: [],
  filterBy: {
    elite: false,
    greatValue: false,
  },
  histogram: {
    bars: [],
    maximumPriceCents: 0,
    minimumPriceCents: 0,
    currencyCode: "USD",
  },
  filterMaximumPriceCents: null,
  filterMinimumPriceCents: null,
  recommendations: [],
  recommendationId: null,
  vehicleRequirement: DEFAULT_VEHICLE_REQUIREMENT,
};

const FETCH_RECOS = "FETCH_RECOS";
export const FETCH_RECOS_RESPONSE = "FETCH_RECOS_RESPONSE";
export const RESTORE_RECOS_RESPONSE = "RESTORE_RECOS_RESPONSE";
const FETCH_RECOS_ERROR = "FETCH_RECOS_ERROR";
export const INVITEE_SELECTED = "INVITEE_SELECTED";
export const INVITEE_UPDATED = "INVITEE_UPDATED";
const CHANGE_SORT_BY = "CHANGE_SORT_BY";
const CHANGE_FILTER_BY = "CHANGE_FILTER_BY";
const CHANGE_PRICE_FILTER = "CHANGE_PRICE_FILTER";
const CHANGE_VEHICLE_FILTER = "CHANGE_VEHICLE_FILTER";
const RECOMMENDATION_INTEREST_CREATED = "RECOMMENDATION_INTEREST_CREATED";
const UPDATE_RECOMMENDATION_INTERESTS = "UPDATE_RECOMMENDATION_INTERESTS";
const RECOMMENDATION_INTERESTS_UPDATED = "RECOMMENDATION_INTERESTS_UPDATED";
const SET_LOADING_AUTO_MATCH = "SET_LOADING_AUTO_MATCH";

const addRecommendedId = (recommendations) => {
  const recommendedIdsExist = recommendations.every((recommendation) => {
    return Number.isInteger(recommendation.recommendedId);
  });

  if (recommendedIdsExist) {
    return recommendations;
  }
  return recommendations.map((recommendation, i) => {
    return { ...recommendation, recommendedId: i };
  });
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export default function recommendationsReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_RECOS:
      return { ...state, loadingRecos: true, recommendationsLoaded: false };
    case SET_LOADING_AUTO_MATCH:
      return { ...state, autoMatchLoading: action.value };
    case RESTORE_RECOS_RESPONSE:
    case FETCH_RECOS_RESPONSE: {
      const {
        recommendations,
        histogram,
        recommendationId,
      } = action.data;
      const recommendationsWithRecommendedId = addRecommendedId(
        recommendations.items
      );
      const filterMinimumPriceCents =
        state.filterMinimumPriceCents || histogram.minimumPriceCents;
      const filterMaximumPriceCents =
        state.filterMaximumPriceCents ||
        histogram.expensiveThresholdCents ||
        histogram.maximumPriceCents;
      return {
        ...state,
        loadingRecos: false,
        recommendationsLoaded: true,
        recommendations: recommendationsWithRecommendedId,
        recommendationId,
        histogram,
        filterMinimumPriceCents,
        filterMaximumPriceCents,
      };
    }
    case UPDATE_RECOMMENDATION_INTERESTS:
      return {
        ...state,
        recommendationInterests: action.data.rabbit_ids,
      };
    case FETCH_RECOS_ERROR:
      return { ...state, loadingRecos: false };
    case CHANGE_SORT_BY: {
      return {
        ...state,
        currentSort: action.sortBy,
      };
    }
    case CHANGE_FILTER_BY: {
      return {
        ...state,
        filterBy: action.filterBy,
      };
    }
    case CHANGE_PRICE_FILTER: {
      return {
        ...state,
        filterMinimumPriceCents: action.payload.filterMinimumPriceCents,
        filterMaximumPriceCents: action.payload.filterMaximumPriceCents,
      };
    }
    case CHANGE_VEHICLE_FILTER: {
      return {
        ...state,
        vehicleRequirement: action.vehicleRequirement,
      };
    }
    default:
      return state;
  }
}

const fetchRecommendations = () => ({
  type: FETCH_RECOS,
});

const fetchRecommendationsResponse = (data) => ({
  type: FETCH_RECOS_RESPONSE,
  data,
});

export const setLoadingAutoMatchValue = (value) => ({
  type: SET_LOADING_AUTO_MATCH,
  value
});

export const fetchRecommendationsError = () => ({
  type: FETCH_RECOS_ERROR,
});

export const changeSortBy = (sortBy) => (dispatch) => {
  dispatch(
    fireRecommendationFilterMetric(SORT_FILTER_CHANGED_EVENT_NAME, {
      current_sort: sortBy,
    })
  );
  dispatch({
    type: CHANGE_SORT_BY,
    sortBy,
  });
};

export const getDefaultSort = (sortBy) => (dispatch) => {
  dispatch({
    type: CHANGE_SORT_BY,
    sortBy,
  });
};

export const changeFilterBy = (filterBy) => (dispatch) => {
  let badgeFilters = Object.keys(filterBy).filter(
    (filterKey) => filterBy[filterKey]
  );
  badgeFilters = badgeFilters.length === 0 ? ["none"] : badgeFilters;

  dispatch(
    fireRecommendationFilterMetric(BADGE_FILTER_CHANGED_EVENT_NAME, {
      badgeFilters,
    })
  );
  dispatch({
    type: CHANGE_FILTER_BY,
    filterBy,
  });
  dispatch(fetchRecommendationsAsync());
};

export const changePriceFilter =
  (filterMinimumPriceCents, filterMaximumPriceCents) => (dispatch) => {
    dispatch({
      type: CHANGE_PRICE_FILTER,
      payload: {
        filterMinimumPriceCents,
        filterMaximumPriceCents,
      },
    });
  };

export const changeVehicleFilter = (vehicleRequirement) => (dispatch) => {
  dispatch({
    type: CHANGE_VEHICLE_FILTER,
    vehicleRequirement,
  });
};

export const createRecommendationInterest = (recommendation) => ({
  type: RECOMMENDATION_INTEREST_CREATED,
  recommendation,
});

const validateJobData = (dispatch, getState) => {
  const state = getState();
  const {
    uuid,
    category_id: categoryId,
    location = {},
    description,
    taskTemplate: { fields },
    form_referrer: formReferrer,
  } = state.build.manager.job;

  const descriptionField = fields.find(
    ({ field_name: fieldName }) => fieldName === "description"
  );
  const descriptionValid =
    descriptionField && descriptionField.required ? !!description : true;
  const locationValid = !!location.virtual || !!(location.lat && location.lng);

  // kick them back if all proper data is not present !
  if (!categoryId || !locationValid || !descriptionValid) {
    let path = "form";
    if (formReferrer === "business_partnership") {
      path = "affiliate";
    }

    dispatch(push(`/${path}?uuid=${uuid}`));
    return true;
  }
  return false;
};

/**
 * Determines if all fields necessary to fetch recommendations are valid
 * First fetches data to fill calendar & button options, the default option will be fetched from recos
 */
export const bootstrapRecommendations = () => (dispatch, getState) => {
  if (validateJobData(dispatch, getState)) {
    return;
  }
  const state = getState();
  dispatch(setDefaultJobSchedule(true));
  const { recommendationDateRange } = state.build.calendar;
  if (recommendationDateRange && recommendationDateRange.length > 0) {
    dispatch(fetchRecommendationsAsync());
    return;
  }

  dispatch(setDefaultJobSchedule(true));
  dispatch(boostrapMultidayRecommendations());
};

const boostrapMultidayRecommendations = () => async (dispatch, getState) => {
  dispatch(fetchRecommendations());

  const state = getState();
  const {
    job: { category_id, location: { metro_id } },
  } = state.build.manager;

  const { taskTemplate } = state.build.manager.job;

  const scheduleField = taskTemplate.fields.find(
    ({ field_name: fieldName }) => fieldName === "schedule"
  );
  const todayOption = scheduleField.adjustedWindows.items.find(
    (date) => date.sameday
  );
  const todayEnabled =
    !todayOption.disabled &&
    todayOption.items.some(({ disabled }) => !disabled);

  const enabledFeatures = await fetchEnabledFeatures({
    categoryId: category_id,
    metroId: metro_id
  });

  const fixedRateEnabled = enabledFeatures?.includes(featureFlags.bookingAutoInviteLite)
  
  return xhr
    .get(buildRecosUrl(state, fixedRateEnabled, true, todayEnabled))
    .then(({ camelCasedData: data }) => {
      const { recommendationId } = data.multidayRecommendations;
      data.userIsInDefaultCalendarExperiment = false;
      handleCalendarResponse(dispatch, todayEnabled, data);
      dispatch(
        fetchRecommendationsResponse({
          ...data.multidayRecommendations,
          eligibleForThreeDayExperiment: data.eligibleForThreeDayExperiment,
          histogram: data.histogram,
        })
      );
      dispatch({
        type: UPDATE_JOB_DATA,
        data: {
          recommendation_id: recommendationId,
        },
      });
    })
    .catch((e) => {
      dispatch(fetchRecommendationsError());
      dispatch(createApiErrorAlert(e));
    });
};

// boostra calendar data, this will be called from only IKEA ConvergenceV1
export const bootstrapCalendarData = () => (dispatch, getState) => {
  if (validateJobData(dispatch, getState)) return;

  const state = getState();

  // fetch calendar data from server side
  dispatch(setDefaultJobSchedule(true));
  const { taskTemplate } = state.build.manager.job;

  const scheduleField = taskTemplate.fields.find(
    (field) => field.field_name === "schedule"
  );
  const todayOption = scheduleField.adjustedWindows.items.find(
    (date) => date.sameday
  );
  const todayEnabled =
    !todayOption.disabled &&
    todayOption.items.some(({ disabled }) => !disabled);
  dispatch(fetchCalendar());
  xhr
    .get(buildRecosUrl(state, false, true, todayEnabled, true))
    .then(({ camelCasedData: data }) => {
      handleCalendarResponse(dispatch, todayEnabled, data, true);
    })
    .catch((e) => {
      dispatch(fetchCalendarError());
      dispatch(createApiErrorAlert(e));
    });
};

export const setDefaultJobSchedule = () => (dispatch, getState) => {
  const { build } = getState();
  const { fields } = build.manager.job.taskTemplate;
  const { adjustedWindows } = fields.find(
    (field) => field.field_name === "schedule"
  );

  const defaultDate = moment().add(0, "days").format("YYYY-MM-DD");
  let firstAvailableDay = adjustedWindows.items.find(
    ({ date, disabled }) => defaultDate === date && !disabled
  );
  if (!firstAvailableDay)
    firstAvailableDay = adjustedWindows.items.find(({ disabled }) => !disabled);
  const firstEnabledSlot =
    firstAvailableDay.items.find(({ disabled }) => !disabled) || {};
  const { date } = firstAvailableDay;
  const { offset_seconds: offsetSeconds, duration_seconds: durationSeconds } =
    firstEnabledSlot;

  const data = {
    date,
    offset_seconds: offsetSeconds,
    duration_seconds: durationSeconds,
  };

  dispatch(updateJobData({ schedule: data }));
};

const getTimeWindows = ({ manager }) => {
  const scheduleField = manager.job.taskTemplate.fields.find(
    (field) => field.field_name === "schedule"
  );
  const { future_windows: futureWindows, today_windows: todayWindows } =
    scheduleField.datetime_windows_extra;
  const { days_ahead: daysAhead } =
    scheduleField.datetime_windows_initial_value;
  return {
    future_windows: futureWindows,
    today_windows: todayWindows,
    days_ahead: daysAhead,
  };
};

const getScheduledTime = (schedule, todayWindows) => {
  const isNotDefined = (property) => typeof property === "undefined";
  const firstEnabledSlot = todayWindows.find(
    ({ disabled, offset_seconds: offsetSeconds }) =>
      !disabled && offsetSeconds !== 0
  );
  const noTodaySlots =
    isNotDefined(firstEnabledSlot) ||
    isNotDefined(firstEnabledSlot.offset_seconds) ||
    isNotDefined(firstEnabledSlot.duration_seconds);

  let {
    date,
    offset_seconds: offsetSeconds,
    duration_seconds: durationSeconds,
  } = schedule;
  if (!date) {
    date = date || moment().format("YYYY-MM-DD");
  }

  let isSameDay = date === moment().format("YYYY-MM-DD");

  // thinks it's sameday but server sent no today slots
  if (isSameDay && noTodaySlots) {
    isSameDay = false;
    date = moment().add(1, "days").format("YYYY-MM-DD");
  }

  const findSeconds = (value) =>
    isSameDay ? firstEnabledSlot[value] : todayWindows[0][value];

  if (isNotDefined(offsetSeconds)) {
    offsetSeconds = findSeconds("offset_seconds");
  }

  if (isNotDefined(durationSeconds)) {
    durationSeconds = findSeconds("duration_seconds");
  }

  return {
    date,
    offset_seconds: offsetSeconds,
    duration_seconds: durationSeconds,
  };
};

export const updateJobSchedule =
  (schedule = {}, skipFetchRecommendations = false) =>
  (dispatch, getState) => {
    const state = getState();
    const { build } = state;
    const { today_windows: todayWindows, days_ahead: daysAhead } =
      getTimeWindows(build);
    const scheduleTime = getScheduledTime(schedule, todayWindows, daysAhead);

    const { recommendationId } = build.recommendations;

    const metricData = {
      schedule_date: scheduleTime.date,
      schedule_offset_seconds: scheduleTime.offset_seconds,
      schedule_duration_seconds: scheduleTime.duration_seconds,
      recommendation_id: recommendationId,
    };

    dispatch(
      fireBuildMetric("recommendations_job_schedule_changed", metricData)
    );

    const data = {
      date: scheduleTime.date,
      offset_seconds: scheduleTime.offset_seconds,
      duration_seconds: scheduleTime.duration_seconds,
    };

    dispatch(updateJobData({ schedule: data }));

    if (!skipFetchRecommendations) {
      dispatch(fetchRecommendationsAsync());
    }
  };

export const dispatchJobDraft = (data) => {
  return (dispatch, getState) => {
    const { funnel_id: funnelId } = getState().build.manager.job;
    updateJobDraft({ ...data, funnel_id: funnelId });
  };
};

const updateJobDraft = ({ data }) => {
  const { job_draft_guid: jobDraftGuid, ...rest } = data;
  const updateURL = `${UPDATE_JOB_DRAFT.replace(":guid", jobDraftGuid)}`;

  return xhr.put(updateURL, rest);
};

export const updateJobInvitee = (invitee) => ({
  type: INVITEE_SELECTED,
  invitee,
});

export function buildRecosQuery(state, todayEnabled = true) {
  const {
    category_id: categoryId,
    location,
    location: { lat, lng },
    vehicle_requirement: vehicleRequirement,
    job_size: jobSize,
    tools,
    badges,
    funnel_id: funnelId,
    form_referrer: formReferrer,
    description,
    job_draft_template_filter: jobDraftTemplateFilter,
    job_draft_guid: jobDraftGuid,
  } = state.build.manager.job;

  let maxPrice;

  // pre-pop filters are enabled by default.

  if (jobDraftTemplateFilter?.filterEnabled) {
    if (jobDraftTemplateFilter?.maxHourlyRate !== undefined) {
      maxPrice = jobDraftTemplateFilter?.maxHourlyRate;
    }
  }

  const { recommendationDateRange, offsetSeconds, activeTimeRanges } =
    state.build.calendar;

  let dates = recommendationDateRange;
  if (offsetSeconds > 0) {
    dates = dates.map((date) => ({
      ...date,
      offsetSeconds,
    }));
  }

  let dayTimeRanges = [];
  if (activeTimeRanges) {
    dayTimeRanges = Object.keys(activeTimeRanges).filter(
      (range) => activeTimeRanges[range]
    );
  }

  return {
    categoryId,
    location,
    lat,
    lng,
    vehicleRequirement,
    jobSize,
    tools,
    badges,
    funnelId,
    description,
    todayEnabled,
    schedule: { dates, dayTimeRanges },
    jobType: "Template",
    isRecosPage: true,
    maxPrice,
    formReferrer,
    jobDraftGuid,
  };
}

export function buildRecosQueryFromDraft(state, todayEnabled = true) {
  const {
    funnel_id: funnelId,
    form_referrer: formReferrer,
  } = state.build.manager.job;

  const {
    category_id: categoryId,
    location,
    location: { lat, lng },
    vehicle_requirement: vehicleRequirement,
    job_size: jobSize,
    tools,
    badges,
    description,
    job_draft_template_filter: jobDraftTemplateFilter,
    guid: jobDraftGuid,
  } = state.ikea.manager?.job_draft;

  let maxPrice;

  // pre-pop filters are enabled by default.

  if (jobDraftTemplateFilter?.filterEnabled) {
    if (jobDraftTemplateFilter?.maxHourlyRate !== undefined) {
      maxPrice = jobDraftTemplateFilter?.maxHourlyRate;
    }
  }

  const dates = [];
  for (let i =1; i<=14; i++) {
    dates.push({ date: moment().add(i, "days").format("YYYY-MM-DD"), disabled: false, sameday: false });
  }


  return {
    categoryId,
    location,
    lat,
    lng,
    vehicleRequirement,
    jobSize,
    tools,
    badges,
    funnelId,
    description,
    todayEnabled,
    schedule: { dates, dayTimeRanges: [] },
    jobType: "Template",
    isRecosPage: false,
    maxPrice,
    formReferrer,
    jobDraftGuid,
  };
}

/**
 * Build reco url & query params
 * @param {object} job details
 */
export const buildRecosUrl = (
  state,
  fixedRateEnabled,
  bootstrap = false,
  todayEnabled = true,
  calendarOnly = false
) => {
  const recoQs = snakeCaseKeysAndQueryStringify(
    { ...buildRecosQuery(state, todayEnabled), fixed_rate: fixedRateEnabled }
  );

  let baseUrl = MULTIDAY_RECOMMENDATIONS_SHOW;
  if (bootstrap && calendarOnly) {
    baseUrl = BOOTSTRAP_CALENDAR_DATA;
  } else if (bootstrap) {
    baseUrl = BOOTSTRAP_MULTIDAY_RECOMMENDATIONS;
  }

  return `${baseUrl}?${recoQs}`;
};

export const clearPrePopulatedFilter = () => (dispatch) => {
  dispatch(clearFilter());
  dispatch(fetchRecommendationsAsync());
  window.scrollTo(0, 0);
};

/**
 * Fetch recommendations
 */
export const fetchRecommendationsAsync = () => async (dispatch, getState) => {
  const state = getState();
  const {
    job: { category_id, schedule, location: { metro_id } },
  } = state.build.manager;
  const { loadingRecos } = state.build.recommendations;

  if (loadingRecos || !(schedule && schedule.date)) return;

  dispatch(fetchRecommendations());

  const enabledFeatures = await fetchEnabledFeatures({
    categoryId: category_id,
    metroId: metro_id
  });

  const fixedRateEnabled = enabledFeatures?.includes(featureFlags.bookingAutoInviteLite)

  try {
    const { camelCasedData } = await xhr.get(buildRecosUrl(state, fixedRateEnabled));
    dispatch(fetchRecommendationsResponse(camelCasedData));
    dispatch({
      type: UPDATE_JOB_DATA,
      data: {
        recommendation_id: camelCasedData.recommendationId,
      },
    });
  } catch (e) {
    dispatch(fetchRecommendationsError());
    dispatch(createApiErrorAlert(e));
  }
};

const recommendationInterestCreatedEpic = (action$, { getState }) =>
  action$
    .ofType(RECOMMENDATION_INTEREST_CREATED)
    .switchMap(({ recommendation }) => {
      const state = getState();
      const {
        query,
        job: { funnel_id: funnelId, uuid, draft, job_draft_guid: jobDraftGuid },
      } = state.build.manager;
      const { recommendationInterests } = state.build.recommendations;
      const rabbitIds = [
        ...new Set(recommendationInterests.concat([recommendation.user_id])),
      ];
      const guid = draft ? draft.guid : jobDraftGuid;
      const detailPath = `tasker-detail?${qs.stringify(
        {
          ...query,
          tid: recommendation.user_id,
          job_draft_guid: guid,
          uuid,
          funnel_id: funnelId,
          rabbit_ids: rabbitIds,
        },
        {
          arrayFormat: "brackets",
        }
      )}`;

      return Observable.from([
        {
          type: UPDATE_RECOMMENDATION_INTERESTS,
          data: {
            rabbit_ids: rabbitIds,
            job_draft_guid: guid,
          },
        },
        () => window.open(detailPath),
      ]);
    });

const updateRecommendationInterestsEpic = (action$, { getState }) =>
  action$.ofType(UPDATE_RECOMMENDATION_INTERESTS).switchMap(({ data }) => {
    return updateJobDraft({
      ...data,
      funnelId: getState().build.manager.job,
    }).then(() => ({
      type: RECOMMENDATION_INTERESTS_UPDATED,
    }));
  });

const inviteeSelectedEpic = (action$, { dispatch, getState }) =>
  action$.ofType(INVITEE_SELECTED).switchMap(({ invitee }) => {
    const state = getState();
    const { job } = state.build.manager;
    const { loggedIn } = state.user.loggedIn;
    dispatch({
      type: UPDATE_JOB_DATA,
      data: {
        source: invitee.userId ? "recommendation" : "broadcast",
        rabbit_rating: invitee.rabbitRating,
        rabbit_category_description: invitee.rabbitCategoryDescription,
        invitee_id: invitee.userId,
        invitee,
        by_logged_in_user: loggedIn,
      },
    });

    return fetchInviteeObservable({
      ...job,
      invitee,
      invitee_id: invitee.userId,
    });
  });

const fetchRecommendationsEpic = (action$, { getState }) =>
  action$
    .ofType(SET_RECO_DATE_TIME_RANGE)
    .filter(() => getState().build.progress.page === "recommendations")
    .map(fetchRecommendationsAsync);

export const recommendationsEpic = combineEpics(
  recommendationInterestCreatedEpic,
  inviteeSelectedEpic,
  updateRecommendationInterestsEpic,
  fetchRecommendationsEpic
);
