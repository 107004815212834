import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";

const ExpansionStatus = {
  Expanding: "Expanding",
  Expanded: "Expanded",
  Collapsed: "Collapsed",
  Collapsing: "Collapsing",
};

export default function ExpandableArea({ children, expanded }) {
  const [expansionStatus, setExpansionStatus] = useState(
    expanded ? ExpansionStatus.Expanded : ExpansionStatus.Collapsed
  );
  const { elementRef, elementScrollHeight } = useElementScrollHeight();

  useEffect(() => {
    if (
      expanded &&
      [ExpansionStatus.Collapsed, ExpansionStatus.Collapsing].includes(
        expansionStatus
      )
    ) {
      setExpansionStatus(ExpansionStatus.Expanding);
    }
    if (
      !expanded &&
      [ExpansionStatus.Expanded, ExpansionStatus.Expanding].includes(
        expansionStatus
      )
    ) {
      setExpansionStatus(ExpansionStatus.Collapsing);
    }
  }, [expanded, expansionStatus]);

  useEffect(() => {
    if (elementRef.current) {
      const updateExpansionStatus = () => {
        if (expansionStatus === ExpansionStatus.Expanding && expanded) {
          setExpansionStatus(ExpansionStatus.Expanded);
        }
      };
      const element = elementRef.current;
      element.addEventListener("transitionend", updateExpansionStatus);
      return () => {
        element.removeEventListener("transitionend", updateExpansionStatus);
      };
    }
    return () => {};
  }, [elementRef, expanded, expansionStatus]);

  useEffect(() => {
    if (expansionStatus === ExpansionStatus.Collapsing) {
      setExpansionStatus(ExpansionStatus.Collapsed);
    }
  }, [expansionStatus]);

  let expanderMaxHeight;
  if (expansionStatus === ExpansionStatus.Collapsed) {
    expanderMaxHeight = "0";
  } else if (expansionStatus === ExpansionStatus.Expanding) {
    expanderMaxHeight = `${elementScrollHeight}px`;
  } else if (expansionStatus === ExpansionStatus.Expanded) {
    expanderMaxHeight = "auto";
  } else if (expansionStatus === ExpansionStatus.Collapsing) {
    expanderMaxHeight = `${elementScrollHeight}px`;
  }
  return (
    <Expander
      isExpanded={expansionStatus === ExpansionStatus.Expanded}
      aria-hidden={!expanded}
      ref={elementRef}
      expanderMaxHeight={expanderMaxHeight}
    >
      {children}
    </Expander>
  );
}

function useElementScrollHeight() {
  const elementRef = useRef(null);
  const [elementScrollHeight, setElementScrollHeight] = useState(0);

  useEffect(() => {
    if (elementRef.current) {
      setElementScrollHeight(elementRef.current.scrollHeight);
    }
  }, []);

  useEffect(() => {
    const handleWindowResize = () => {
      if (elementRef.current) {
        setElementScrollHeight(elementRef.current.scrollHeight);
      }
    };
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return { elementRef, elementScrollHeight };
}

const Expander = styled.div`
  overflow: ${(props) => props.isExpanded ? "visible" : "hidden"};
  transition: max-height 0.3s;
  transition-timing-function: ease-out;
  max-height: ${(props) => props.expanderMaxHeight};
`;
