import React, { Component } from "react";
import { defineMessages } from "react-intl";
import { FormattedMessage } from "components/FormattedMessage";

const messages = defineMessages({
  adBlockPlaceholder: {
    id: "loginSignup.adBlocker.placeholder",
    defaultMessage: "Checking for Adblock",
  },
});

export default class DetectAdBlock extends Component {
  constructor(props) {
    super(props);
    this.adblockWrapper = React.createRef();
    this.state = {
      adBlockDetected: false,
      checkedBlocker: false,
    };
  }

  componentDidUpdate() {
    const { adBlockDetected, checkedBlocker } = this.state;

    const currentAdblockWrapper = this.adblockWrapper.current;
    if (!checkedBlocker) {
      if (!adBlockDetected && currentAdblockWrapper.offsetHeight === 0) {
        this.onAdBlockCheck(true);
      }

      // Removes content after check has been completed
      if (currentAdblockWrapper.offsetHeight !== 0) {
        this.onAdBlockCheck(false);
        currentAdblockWrapper.textContent = "";
      }
    }
  }

  onAdBlockCheck = (value) => {
    const { handleAdblockDetected } = this.props;

    handleAdblockDetected(value);
    this.setState({
      checkedBlocker: true,
      adBlockDetected: value,
    });
  };

  render() {
    return (
      <div id="adblock-detection-wrapper">
        <div className="adBlock" ref={this.adblockWrapper}>
          <FormattedMessage message={messages.adBlockPlaceholder} />
        </div>
      </div>
    );
  }
}
