export const INCOMPLETE_DATA_REDIRECT = "/services";
export const FETCH_JOB_REPOST_DATA = "/api/v3/jobs/repost_data.json";
export const FETCH_RABBIT_REVIEWS = "/api/v3/rabbits/:rabbit_id/reviews";
export const FETCH_CATEGORY_FAMILY_RABBIT_REVIEWS =
  "/api/v3/rabbits/:rabbit_id/category_family_reviews";
export const TASK_TEMPLATE_SHOW =
  "/api/v3.3/web-client/task_templates/:task_template_id.json";
export const DEFAULT_RECOMMENDATIONS_SHOW = "/api/v3/jobs/post/recommend.json";
export const SET_DEFAULT_ADDRESSES =
  "/api/v3/web-client/set_user_location.json";
export const GET_DEFAULT_ADDRESSES =
  "/api/v3/web-client/get_user_location.json";
export const VERIFY_ACTIVE_METRO = "/api/v3/geospatial/in_service_area.json";
export const BOOTSTRAP_MULTIDAY_RECOMMENDATIONS =
  "/api/v3/jobs/post/bootstrap_multiday_recommend";
export const BOOTSTRAP_CALENDAR_DATA =
  "/api/v3/jobs/post/bootstrap_calendar_data";
export const MULTIDAY_RECOMMENDATIONS_SHOW =
  "/api/v3/jobs/post/multiday_recommend.json";
export const RABBIT_AVAILABILITY_SHOW =
  "/api/v3/web-client/build/rabbit_availability.json";
export const RABBIT_GRANULAR_AVAILABILITY_SHOW =
  "/api/v3/web-client/build/rabbit_granular_availability.json";
export const FETCH_IKEA_JOB_DATA = "/api/v3/jobs/ikea/:id.json";
export const IKEA_PRODUCT_SEARCH = "/api/v3/web-client/ikea/search.json";
export const IKEA_POST_JOB_DRAFT = "/api/v3/web-client/ikea/quote.json";
export const IKEA_POST_SIMPLE_JOB_DRAFT =
  "/api/v3/web-client/ikea/quote_simple.json";
export const FETCH_DRAFT_DATA = "/api/v3/jobs/job_draft_data.json";
export const RABBIT_HIRE = "/api/v3/jobs/post/hire.json";
export const BROADCAST_HIRE = "/api/v3/broadcast/jobs.json";
export const BOOTSTRAP_ENDPOINT = "/api/v3/web-client/bootstrap.json";
export const ACCOUNT_PROFILE = "/api/v3/account.json";
export const FETCH_IKEA_PRODUCTS = "/api/v3/jobs/ikea_products.json";
export const TASKER_PROFILE_SHOW = "/api/v3/web-client/profile/:slug.json";
export const POST_COMPARISON_JOB_DRAFT =
  "/api/v3/web-client/job_draft/comparison_draft_create.json";
export const UPDATE_JOB_DRAFT =
  "/api/v3/web-client/job_draft/:guid/update.json";
export const SAVE_IKEA_JOB_DRAFT =
  "/api/v3/web-client/job_draft/ikea_redirect_create";
export const CALENDAR_DATA = "/api/v3/web-client/calendar_data.json";
export const UPDATE_IKEA_JOB = "/api/v3/jobs/ikea/:id/update.json";
export const PHOTO_FEEDBACK_SUPPORT =
  "support.taskrabbit.com/hc/articles/360031685171";
export const IKEA_AUTO_MATCH = "/api/v3/jobs/post/ikea_recommend.json";
export const GENERAL_RABBIT_AVAILABILITY =
  "/api/v3/web-client/build/general_rabbit_availability.json";
export const ASSEMBLY_SERVICES = "/api/v3/assembly_services/product.json";
export const PHASE_MANAGER_INFO = "/api/v3/ikea/phase_manager/get_phase.json";
export const IKEA_NAME_SEARCH = "/api/v3/assembly_services/name_search.json";
export const PARTNER_SERVICES = "/api/v3/partner_services";
export const DESIGN_CODE_SERVICES =
  "/api/v3/ikea/design_code_service/item_list.json";
