import { combineReducers } from "redux";

import manager, { managerEpic } from "ikea/redux/modules/manager";
import confirm from "ikea/redux/modules/confirm";

export default combineReducers({
  manager,
  confirm,
});

export const ikeaEpic = managerEpic;
