import xhr from "util/xhr";

import { DASHBOARD_TASKS_METRO } from "dashboard/routes/apiRoutes";

const GET_METRO_CATEGORY = "dashboard/smart_search/GET_METRO_CATEGORY";

const defaultState = {
  initial_task_templates: [],
  remaining_task_templates: [],
  initial_categories: [],
  timestamp: 0,
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export default function categoriesAndTemplates(state = defaultState, action) {
  switch (action.type) {
    case GET_METRO_CATEGORY:
      return { ...state, ...action.categories_available };
    default:
      return state;
  }
}

function metroCategoriesFound(categories_available) {
  return { type: GET_METRO_CATEGORY, categories_available };
}

// Category suggestion based on fallback yaml
export function getFallbackCategories() {
  return dispatch => {
    const request_uri = `${DASHBOARD_TASKS_METRO}?fallback=true`;
    xhr.get(request_uri).then(res => {
      const categories_available = res.data;
      dispatch(metroCategoriesFound(categories_available));
    });
  };
}
