import React, { createElement } from "react";
import { render, unmountComponentAtNode } from "react-dom";
import { Provider } from "react-redux";
import { IntlProvider } from "react-intl";
import ModalContainer from "components/Modal/ModalContainer";
import CarouselModalContainer from "components/Modal/CarouselModalContainer";
import hist from "hist";
import createStoreAll from "store/createStoreAll";

function getAppElement() {
  return document.getElementById("modal-container");
}

const messages = window.LOCALE_MESSAGES;
const locale = window.LOCALE;

export function showModal(props = {}) {
  const modalContainer = props.carousel
    ? CarouselModalContainer
    : ModalContainer;
  const store = createStoreAll(hist);
  const node = getAppElement();
  const element = createElement(modalContainer, { isOpen: true, ...props });

  unmountComponentAtNode(node);

  render(
    <IntlProvider locale={locale} messages={messages} textComponent="span">
      <Provider store={store}>{element}</Provider>
    </IntlProvider>,
    node
  );
}
