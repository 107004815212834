import { notifyBugsnag } from "util/bugsnag";

const localStorage = () => ({
  get: (key) => {
    try {
      if (!window.localStorage) return false;
      const storage = window.localStorage;

      const encoded = storage.getItem(key);
      return encoded != null ? JSON.parse(encoded) : encoded;
    } catch (err) {
      notifyBugsnag(new Error("LocalStorageError"), {
        msg: "retrieving key: " + key,
        error: err,
      });
      return false;
    }
  },

  set: (key, value) => {
    let str;

    try {
      if (!window.localStorage) return;
      const storage = window.localStorage;

      str = JSON.stringify(value);
      storage.setItem(key, str);
    } catch (err) {
      notifyBugsnag(new Error("LocalStorageError"), {
        msg: "setting key: " + key,
        error: err,
      });
    }
  },

  clear: (key) => {
    try {
      if (!window.localStorage) return;
      const storage = window.localStorage;

      storage.removeItem(key);
    } catch (err) {
      notifyBugsnag(new Error("LocalStorageError"), {
        msg: "clearing values for key: " + key,
        error: err,
      });
    }
  },
});

export default localStorage();
