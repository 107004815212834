import xhr from "util/xhr";

import { UPDATE_JOB_DRAFT_STATUS } from "dashboard/routes/apiRoutes";

export const updateJobDraftStatus = async (guid, payload) => {
  try {
    await xhr.put(UPDATE_JOB_DRAFT_STATUS.replace(":guid", guid), payload);
  } catch (e) {
    console.error("Error updating job draft", e);
  }
};
