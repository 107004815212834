import React, { useMemo, useState, useCallback } from "react";
import {
  FormattedHTMLMessage,
  FormattedMessage,
} from "components/FormattedMessage";
import styled from "styled-components";
import { connect } from "react-redux";
import { defineMessages, injectIntl } from "react-intl";
import { phone } from "util/validation";
import { StyledButtonPrimary } from "components/sharedStyledComponents/StyledButton";
import CountryDropdown from "build/components/common/CountryDropdown"
import {
  sendTwoFactorCode,
  loginFromTwoFactorSetup,
} from "user/redux/modules/index";
import {
  accountSendTwoFactorAuthCode,
  accountCloseModal,
  accountHandleBack
} from "account/redux/modules/accountSecurity";
import {
  LOGIN_ORIGIN,
  ACCOUNT_ORIGIN,
  PROTECTED_SCREEN_ORIGIN,
} from "enums/twoFactorAuth";

import countryIsoCodeFromLocale from "util/countryIsoCodeFromLocale";
import { getCountryCallingCode, parsePhoneNumberFromString } from "libphonenumber-js";
import { useIsDesktop } from "../../shared/hooks/use-breakpoints";

const messages = defineMessages({
  loginTitle: {
    id: "two_factor_authentication_modal.setup_login.title",
    defaultMessage: "Keep your account secure",
  },
  accountTitle: {
    id: "two_factor_authentication_modal.setup_account.title",
    defaultMessage: "Two Factor Authentication",
  },
  accountMfaTitle: {
    id: "two_factor_authentication_modal.mfa_account.title",
    defaultMessage: "Authentication Required",
  },
  loginSubtitle: {
    id: "two_factor_authentication_modal.setup_login.subtitle",
    defaultMessage: `To keep your account secure, set up two-factor authentication. 
    Enter your phone number to receive the security code and activate
     two-factor authentication. 
      `,
  },
  accountSubtitle: {
    id: "two_factor_authentication_modal.setup_account.subtitle",
    defaultMessage:
      "Enter your phone number to receive your authentication code",
  },
  accountMfaSubtitle: {
    id: "two_factor_authentication_modal.mfa_account.subtitle",
    defaultMessage:
      "To keep your account secure, set up two-factor authentication to edit this information. Enter your phone number to receive the security code and activate two-factor authentication.",
  },
  learnMore: {
    id: "two_factor_authentication_modal.setup_login.learn_more",
    defaultMessage: "Learn more about two-factor authentication",
  },
  sendCode: {
    id: "two_factor_authentication_modal.setup.send_code",
    defaultMessage: "Send Code",
  },
  notNow: {
    id: "two_factor_authentication_modal.setup.not_now",
    defaultMessage: "Not now",
  },
  invalidPhone: {
    id: "two_factor_authentication_modal.setup.invalid_phone",
    defaultMessage: "Phone number is not valid",
  },
  enterPhoneNumber: {
    id: "two_factor_authentication_modal.setup.enter_phone_number",
    defaultMessage: "Enter phone number",
  },
});


const StyledModalTop = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: ${(props) => props.theme.spacing.md};
`;

const StyledModalTitle = styled.div`
  padding: 0;
  ${(p) => p.theme.breakpoints.tabletUp} {
    font-size: 26px;
  }
  ${(p) => p.theme.breakpoints.mobile} {
    font-size: ${(props) => props.theme.typography.typeScale["200"]};
  }
  line-height: 30px;
  font-weight: ${(props) => props.theme.typography.weight.semiBold};
  text-align: center;
  margin: 0 auto;
`;

const StyledModalSubtitle = styled.div`
  ${(p) => p.theme.breakpoints.tabletUp} {
    margin-top: ${(props) => props.theme.spacing.xl};
    font-size: ${(props) => props.theme.typography.typeScale["100"]};
  }
  ${(p) => p.theme.breakpoints.mobile} {
    margin-top: 0px;
    font-size: ${(props) => props.theme.typography.typeScale["50"]};
  }
  padding-top: 0;
  padding-bottom: 0;
  padding-right: ${(props) => props.theme.spacing.lg};
  padding-left: ${(props) => props.theme.spacing.lg};
`;

const StyledCodeInputSection = styled.div`
  margin-top: ${(props) => props.theme.spacing.lg};
  margin-bottom: ${(props) => props.theme.spacing.xl};
`;

const StyledTwoFactorSetupModal = styled.div`
  ${(p) => p.theme.breakpoints.tabletUp} {
    padding-top: ${(props) => props.theme.spacing.xl};
    padding-bottom: ${(props) => props.theme.spacing.xl};
    padding-left: ${(props) => props.theme.spacing.xxl};
    padding-right: ${(props) => props.theme.spacing.xxl};
    width: 660px;
    min-height: 350px;
  }
  ${(p) => p.theme.breakpoints.mobile} {
    margin-top: 76px;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: ${(props) => props.theme.spacing.md};
    padding-right: ${(props) => props.theme.spacing.md};
    width: 100%;
    height: 100%;
  }
  text-align: center;
`;

const StyledBottomRow = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledCodeErrorResponse = styled.div`
  color: ${(props) => props.theme.color.errorText};
  margin-top: ${(props) => props.theme.spacing.sm};
  margin-bottom: ${(props) => props.theme.spacing.sm};
  margin-left: 0;
  margin-right: 0;
  font-size: ${(props) => props.theme.typography.typeScale["50"]};
`;

const StyledBackArrowButton = styled.button`
  font-size: 20px;
  align-self: center;
`;

const StyledBackArrow = styled.div`
  align-self: center;
`;

const StyledCancelButton = styled.button`
  margin-top: ${(props) => props.theme.spacing.md};
  background: none;
  border: none;
  cursor: pointer;
`;

const TwoFactorSetupModalContent = ({
  error,
  loading,
  origin,
  setup,
  mobilePhone = "",
  sendTwoFactorCodeAction,
  accountSendTwoFactorAuthCodeAction,
  accountCloseModalAction,
  accountHandleBackAction,
  loginFromTwoFactorSetupAction,
  intl: { formatMessage },
  locale,
  countryIsoCode = "",
  actionName,
}) => {
  const parsedNumber = useMemo(() => {
      // Parse the initial mobile phone number and extract the country code
      return parsePhoneNumberFromString(mobilePhone, countryIsoCodeFromLocale(locale)) || {};
    }, [locale, mobilePhone]);
  const [phoneNumber, setPhoneNumber] = useState(parsedNumber.nationalNumber || "");
  const [countryCode, setCountryCode] = useState(parsedNumber.country || countryIsoCode);
  const [phoneCountryCode, setPhoneCountryCode] = useState(`+${parsedNumber.countryCallingCode || getCountryCallingCode(countryIsoCode)}`);
  const [phoneError, setPhoneError] = useState(false);
  const isDesktop = useIsDesktop();

  const handleSelectPhoneCountryCode = useCallback((_phoneCountryCode, _countryCode) => {
    setCountryCode(_countryCode);
    setPhoneCountryCode(_phoneCountryCode);
  }, []);

  const renderError = () => {
    const errorExists = phoneError || error;
    if (!errorExists || phoneNumber === "") {
      return null;
    }

    if (phoneError) {
      return <FormattedMessage message={messages.invalidPhone} />;
    }
    return error;
  };

  const handleSendCode = () => {
    // This is done to properly handle number formatting in TwoFactorVerifyModal
    const formattedNumber = phoneCountryCode + phoneNumber;
    // For verification
    if (phone(phoneNumber, phoneCountryCode) === undefined) {
      if (origin === LOGIN_ORIGIN) {
        sendTwoFactorCodeAction(formattedNumber);
      }
      if (origin === ACCOUNT_ORIGIN || origin === PROTECTED_SCREEN_ORIGIN) {
        accountSendTwoFactorAuthCodeAction(formattedNumber, actionName);
      }
    } else {
      setPhoneError(true);
    }
  };

  const handleBack = () => {
    if (origin === PROTECTED_SCREEN_ORIGIN) {
      return (
        <StyledBackArrowButton
        type="button"
        aria-label="Back"
        onClick={() => accountHandleBackAction(actionName)}
        className="btn-transparent ss-lnr-chevron-left"
        />
      );
    }
    /* 
      Because the protected screens require a param
      Account security and login have to be separate
      or else the modal won't open on protected screens 
    */
    if (origin === ACCOUNT_ORIGIN) {
      return (
        <StyledBackArrowButton
        type="button"
        aria-label="Back"
        onClick={accountCloseModalAction}
        className="btn-transparent ss-lnr-chevron-left"
        />
    );
    }
    if (setup && origin === LOGIN_ORIGIN) {
      return <></>
    }

  };

  const handleExit = () => {
    if (origin === PROTECTED_SCREEN_ORIGIN) {
      return (
        <></>
      );
    }
    let cancelFunc = null;

    if (origin === ACCOUNT_ORIGIN) {
      cancelFunc = accountCloseModalAction;
    }
    if (setup && origin === LOGIN_ORIGIN) {
      cancelFunc = loginFromTwoFactorSetupAction;
    }

    const message =
      origin === LOGIN_ORIGIN ? (
        <FormattedMessage message={messages.notNow} />
      ) : (
        <FormattedMessage message={messages.cancel} />
      );

    return (
      <StyledCancelButton onClick={cancelFunc}>{message}</StyledCancelButton>
    );
  };
  // Account Security Tab has a slightly diffent name hence the distinction
  const titleMessage =
    origin === LOGIN_ORIGIN ? messages.loginTitle :
      origin === PROTECTED_SCREEN_ORIGIN ? messages.accountMfaTitle : messages.accountTitle;

  const subtitleMessage =
    origin === LOGIN_ORIGIN ? messages.loginSubtitle :
      origin === PROTECTED_SCREEN_ORIGIN ? messages.accountMfaSubtitle : messages.accountSubtitle;

  return (
    <StyledTwoFactorSetupModal>
      <StyledModalTop>
        {isDesktop && <StyledBackArrow>
          {handleBack()}
        </StyledBackArrow>}
        <StyledModalTitle>
          <FormattedMessage message={titleMessage} />
        </StyledModalTitle>
      </StyledModalTop>
      {isDesktop && <hr />}
      <StyledModalSubtitle>
        <FormattedHTMLMessage message={subtitleMessage} />
      </StyledModalSubtitle>
      <StyledCodeInputSection>
        <CountryDropdown
          defaultCountryCode={countryCode}
          onChange={handleSelectPhoneCountryCode}
          value={phoneCountryCode} />
        <input
          type="text"
          onChange={(e) => setPhoneNumber(e.target.value)}
          placeholder={formatMessage(messages.enterPhoneNumber)}
          value={phoneNumber ? phoneNumber : ""}
        />
      </StyledCodeInputSection>
      <StyledCodeErrorResponse>{renderError()}</StyledCodeErrorResponse>
      <StyledBottomRow>
        <StyledButtonPrimary
          width={isDesktop ? "320px" : "100%"}
          height={isDesktop ? "54px" : "46px"}
          isLoading={loading}
          onClick={handleSendCode}
          disabled={phoneNumber ? false : true}
        >
          <FormattedMessage message={messages.sendCode} />
        </StyledButtonPrimary>
      </StyledBottomRow>
      {handleExit()}
    </StyledTwoFactorSetupModal>
  );
};

export const TwoFactorSetupModal = connect(null, {
  sendTwoFactorCodeAction: sendTwoFactorCode,
  accountSendTwoFactorAuthCodeAction: accountSendTwoFactorAuthCode,
  accountCloseModalAction: accountCloseModal,
  loginFromTwoFactorSetupAction: loginFromTwoFactorSetup,
  accountHandleBackAction: accountHandleBack
})(injectIntl(TwoFactorSetupModalContent));
