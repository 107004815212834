/* globals google */
import React, { Component } from "react";
import enhanceSearchBar from "containers/SearchBar/enhanceSearchBar";
import AddressSearchResults from "components/AddressSearchBar/AddressSearchResults";
import find from "lodash/find";
import compact from "lodash/compact";
import get from "lodash/get";
import isEqual from "lodash/isEqual";
import { notifyBugsnag } from "util/bugsnag";

const generateResultsPromise = config => input => () => {
  return new Promise((resolve, reject) => {
    const service = new google.maps.places.AutocompleteService();
    service.getPlacePredictions(
      {
        input,
        ...config,
      },
      (predictions, status) => {
        return status == google.maps.places.PlacesServiceStatus.OK
          ? resolve({ data: { items: predictions } })
          : reject(status);
      }
    );
  });
};

export default function enhanceLocationAutocomplete(config) {
  const { name, processResults, options } = config;
  return WrappedComponent => {
    class LocationAutocompleteWrapper extends Component {
      state = { location: {} };

      constructor() {
        super();
        this.onSelectItem = this.onSelectItem.bind(this);
        this.geocodeInput = this.geocodeInput.bind(this);
        this.mapRef = React.createRef();
      }

      componentWillMount() {
        const exists = document.getElementById("gmap_tag");
        if (!exists) this.addScriptTag();
      }

      componentDidUpdate(prevProps) {
        if (!isEqual(this.props.selected, prevProps.selected)) {
          this.onSelectItem(this.props.selected);
        }
      }

      addScriptTag() {
        const callbackScript = document.createElement("script");
        const callbackBody = document.createTextNode("function enhanceLocationAutocompleteNoOpCallback() { }")
        const script = document.createElement("script");

        // HACK to satisfy apparent new requirement for even synchronous loads to have a callback
        callbackScript.appendChild(callbackBody);
        document.body.appendChild(callbackScript);

        script.src = `//maps.googleapis.com/maps/api/js?key=${
          window.GMAP_KEY
        }&libraries=places&callback=enhanceLocationAutocompleteNoOpCallback`;
        script.noAsync = true;
        script.id = "gmap_tag";
        document.body.appendChild(script);
      }

      onSelectItem(prediction) {
        this.getPlaceDetails(prediction.place_id, (place, status) => {
          if (status === "OK") {
            const location = parseGooglePlaceData(place);
            this.setState({ location });

            if (this.props.onSelectItem instanceof Function)
              this.props.onSelectItem(location);
          } else {
            notifyBugsnag("Google Places API Error", {
              data: { error: status },
            });
          }
        });
      }

      getPlaceDetails(placeId, cb) {
        const map = this.mapRef.current;
        this.placesService =
          this.placesService || new google.maps.places.PlacesService(map);
        this.placesService.getDetails({ placeId }, (place, status) =>
          cb(place, status)
        );
      }

      geocodeInput(input) {
        this.geocoder = this.geocoder || new google.maps.Geocoder();
        const addressOptions = { address: input };
        return new Promise((resolve, reject) => {
          this.geocoder.geocode(addressOptions, (results, status) => {
            if (status === "OK") {
              const location = {
                ...parseGooglePlaceData(results[0]),
                freeform: input,
              };
              this.setState({ location });
              resolve(location);
            } else {
              reject(status);
            }
          });
        });
      }

      render() {
        return (
          <div>
            <WrappedComponent
              {...this.props}
              geocodeInput={this.geocodeInput}
              location={this.state.location}
            />
            <div ref={this.mapRef} className="u-hidden" />
          </div>
        );
      }
    }

    return enhanceSearchBar({
      name,
      resultsPromise: generateResultsPromise(options),
      processResults,
      ResultsClass: AddressSearchResults,
    })(LocationAutocompleteWrapper);
  };
}

export function parseGooglePlaceData(place) {
  const address1 = compact([
    parseGoogleAddressComponent(place, "street_number"),
    parseGoogleAddressComponent(place, "route"),
  ]).join(" ");

  const region =
    parseGoogleAddressComponent(place, "administrative_area_level_1") ||
    parseGoogleAddressComponent(place, "administrative_area_level_2");
  const [lat, lng] = ["lat", "lng"].map(
    v =>
      get(place, "geometry.location." + v) instanceof Function
        ? place.geometry.location[v]()
        : null
  );

  const result = {
    formatted_address: place.formatted_address || place.name,
    freeform: place.formatted_address,
    address1,
    address2: place.address2,
    region,
    locality: parseGoogleAddressComponent(place, "locality"),
    country: parseGoogleAddressComponent(place, "country"),
    postal_code: parseGoogleAddressComponent(place, "postal_code"),
    neighborhood: parseGoogleAddressComponent(place, "neighborhood"),
    place_id: place.place_id,
    place_source: "google",
    provider: "google",
    lat,
    lng,
    locale: window.LOCALE,
  };
  return result;
}

function parseGoogleAddressComponent(place, name) {
  if (!place.address_components) return;
  const comp = find(place.address_components, component => {
    return !!~component.types.indexOf(name);
  });
  if (!comp) return;
  return comp.long_name;
}
