import { combineReducers } from "redux";

import posterFeedback from "feedback/redux/modules/posterFeedback";
import posterFeedbackRequest from "feedback/redux/modules/posterFeedbackRequest";

import posterTipTasker from "feedback/redux/modules/posterTipTasker";
import posterFeedbackTipTasker from "feedback/redux/modules/posterFeedbackTipTasker"

export default combineReducers({
  posterFeedback,
  posterFeedbackTipTasker,
  posterFeedbackRequest,
  posterTipTasker,
});
