import React from "react";
import styled from "styled-components";
import { FormattedMessage } from "components/FormattedMessage";
import { internalPath } from "util/internalPath";

export function BlockFooterLink({
  children,
  href,
  message,
  path,
  rel,
  target,
}) {
  return (
    <div>
      <FooterLink
        href={href}
        message={message}
        path={path}
        rel={rel}
        target={target}
      >
        {children}
      </FooterLink>
    </div>
  );
}

export function FooterLink({ children, href, message, path, rel, target }) {
  return (
    <StyledA href={href || internalPath(path)} target={target} rel={rel}>
      {message ? <FormattedMessage message={message} /> : children}
    </StyledA>
  );
}

const StyledA = styled.a`
  color: ${(props) => props.theme.color.white};
  &:active,
  &:focus,
  &:hover,
  &:visited {
    color: ${(props) => props.theme.color.white};
    text-decoration: none;
  }
`;
