/* Usage example

import React, {useState} from "react";
import { TRModal } from 'components/Modal/TRModal';

const Consumer = ({ name }) => {
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <button onClick={() => setShowModal(true)}>Show The Modal already</button>
      <TRModal isOpen={showModal} onDismiss={() => setShowModal(false)}>
        <div>
          <div>Hi! I'm a modal named {name}!</div>
          <button onClick={() => setShowModal(false)}> Kill me </button>
        </div>
      </TRModal>
    </>
  );
};
 */

import React, { ReactElement } from "react";
import ReactModal, { Styles } from "react-modal";
import styled from "styled-components";
import { useWindowInnerHeight } from "./TRModal/use-window-inner-height";
import { encourageOnDismissUsage } from "./TRModal/encourage-on-dismiss-usage";
import { BodyScrollLocker } from "./TRModal/BodyScrollLocker";

const defaultStyle: Styles = {
  overlay: {
    position: "fixed",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    zIndex: 1000,
    backgroundColor: "rgba(36, 42, 48, 0.75)", // tr-gray-90 @ 75% opacity
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  content: {
    padding: 0,
    cursor: "default",
    overflowY: "auto",
    boxShadow: "0px 2px 2px 0px rgba(#323A3B, 0.1)",
    outline: "none",
    borderRadius: "8px",
  },
};

interface TRModalProps {
  fullscreen?: boolean;
  style?: Styles;
  overrideStyle?: Styles;
  className?: string;
  isOpen?: boolean;
  onDismiss: ReactModal.Props["onRequestClose"];
  children: ReactElement;
}

export function TRModal({
  // When fullscreen is true, the modal will take up 100% of the vertical
  // screen space.
  fullscreen = false,
  // Merge style object with defaultStyle
  // with your styles taking precedence
  style = {},
  // Override the defaultStyle completely
  overrideStyle,
  className = "lightbox--internal",
  isOpen = true,
  onDismiss,
  children,
}: TRModalProps) {
  ReactModal.setAppElement("body");
  encourageOnDismissUsage(onDismiss);

  const windowInnerHeight = useWindowInnerHeight();

  const finalStyle: Styles = {
    ...defaultStyle,
    overlay: { ...defaultStyle.overlay, ...style.overlay },
    content: {
      ...defaultStyle.content,
      ...style.content,
      maxHeight: windowInnerHeight,
      ...(fullscreen && {
        height: windowInnerHeight,
        borderRadius: 0,
      }),
    },
  };

  return (
    <>
      <BodyScrollLocker />
      <StyledReactModal
        style={overrideStyle ?? finalStyle}
        isOpen={isOpen}
        onRequestClose={onDismiss}
        contentClassName={className}
        contentLabel="Modal"
        shouldCloseOnOverlayClick={true}
      >
        {children}
      </StyledReactModal>
    </>
  );
}

interface ReactModalAdapterProps extends ReactModal.Props {
  children: ReactElement;
  className?: string;
  contentClassName: string;
}

function ReactModalAdapter({
  className,
  contentClassName,
  ...props
}: ReactModalAdapterProps) {
  return (
    <ReactModal
      className={contentClassName}
      portalClassName={className}
      // The purpose of this component is to assign the Styled Components class
      // name to the React Modal portal. The ReactModal `className` prop
      // applies to the content of the modal rather than the portal, so we
      // can't simply style ReactModal directly as we do with most other components.
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
}

const StyledReactModal = styled(ReactModalAdapter).attrs((props) => ({
  overlayClassName: "ReactModal__Overlay",
  contentClassName: `ReactModal__Content ${props.contentClassName}`.trim(),
}))`
  & .ReactModal__Overlay {
    opacity: 0;
    transition: opacity 250ms linear;
  }
  & .ReactModal__Overlay--after-open {
    opacity: 1;
  }
`;
