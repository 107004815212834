import { ACCOUNT_PAYMENTS_ACCOUNT_LINK } from "account/routes/apiRoutes";
import xhr, { createApiErrorAlert } from "util/xhr";

const LOAD_ACCOUNT_LINK_REQUEST  = "account/tax_id/LOAD_ACCOUNT_LINK_REQUEST";
const LOAD_ACCOUNT_LINK_RESPONSE = "account/tax_id/LOAD_ACCOUNT_LINK_RESPONSE";
const LOAD_ACCOUNT_LINK_ERROR    = "account/tax_id/LOAD_ACCOUNT_LINK_ERROR";

export function responseLoadAccountLink(response) {
  return { type: LOAD_ACCOUNT_LINK_RESPONSE, response };
}

export function errorLoadAccountLink(response) {
  return { type: LOAD_ACCOUNT_LINK_ERROR, response };
}

export function loadAccountLink() {
  return (dispatch, getState) => {
    const apiPath = ACCOUNT_PAYMENTS_ACCOUNT_LINK.replace(
      ":id",
      getState().account.profile.data.payment_merchant_id,
    );

    return xhr
      .get(apiPath)
      .then(response => {
        window.open(response.data.url, '_self', 'noopener noreferrer');
      })
      .catch(e => {
        dispatch(createApiErrorAlert(e));
      })
  };
}

const initialState = {
  isLoading: false,
  error: undefined,
  data: undefined,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_ACCOUNT_LINK_REQUEST:
      return { ...state, isLoading: true };
    case LOAD_ACCOUNT_LINK_RESPONSE:
      return {

        ...state, isLoading: false, data: action.response
      };
    case LOAD_ACCOUNT_LINK_ERROR:
      return { ...state, isLoading: false };
    default:
      return state;
  }
}
