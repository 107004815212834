// Helpers
export const appsflyerUrls = {
  client: "https://client.onelink.me/70Ot",
  tasker: "https://taskr.onelink.me/v5RT",
};

/* 
  This is a simplified object of the mapper generated by Appsflyer smartlink,
  any changes done to the original mapper needs to be reflected in this object

  Any changes done to this object needs to also be reflected in
  v3/apps/web/app/views/web/layouts/footers/_base.haml
*/
export const afParameters = {
  ad: { keys: ["utm_content"] },
  adSet: { keys: ["utm_adgroup"] },
  afCustom: [
    { keys: ["utm_adgroupID"], paramKey: "af_adset_id" },
    { defaultValue: "true", paramKey: "af_ss_ui" },
  ],
  afSub1: { keys: ["utm_marketplace"] },
  afSub2: { keys: ["utm_country"] },
  afSub3: { keys: ["utm_category"] },
  afSub4: { keys: ["utm_metro"] },
  campaign: { keys: ["utm_campaign"] },
  channel: { keys: ["utm_medium"] },
  mediaSource: { defaultValue: "Direct", keys: ["utm_source"] },
};

export const getOneLink = (app: keyof typeof appsflyerUrls) => {
  const oneLinkURL = appsflyerUrls[app];

  if (typeof globalThis.AF_SMART_SCRIPT === "undefined") return oneLinkURL;

  const { clickURL } = globalThis.AF_SMART_SCRIPT.generateOneLinkURL({
    afParameters,
    oneLinkURL,
  });

  return clickURL;
};
