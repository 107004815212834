import {
  ACCOUNT_TRANSACTIONS,
  ACCOUNT_TRANSACTIONS_EXPORT,
  ACCOUNT_TRANSACTIONS_EXPORT_STATUS,
} from "account/routes/apiRoutes";

import xhr, { createApiErrorAlert } from "util/xhr";

const LOAD_TRANSACTIONS_REQUEST =
  "account/transactions/LOAD_TRANSACTIONS_REQUEST";
const LOAD_TRANSACTIONS_RESPONSE =
  "account/transactions/LOAD_TRANSACTIONS_RESPONSE";
const LOAD_TRANSACTIONS_ERROR = "account/transactions/LOAD_TRANSACTIONS_ERROR";
const EXPORT_TRANSACTIONS_SET_YEAR =
  "account/transactions/EXPORT_TRANSACTIONS_SET_YEAR";
const EXPORT_TRANSACTIONS_REQUEST =
  "account/transactions/EXPORT_TRANSACTIONS_REQUEST";
const EXPORT_TRANSACTIONS_RESPONSE =
  "account/transactions/EXPORT_TRANSACTIONS_RESPONSE";
const EXPORT_TRANSACTIONS_ERROR =
  "account/transactions/EXPORT_TRANSACTIONS_ERROR";
export const INVALIDATE_TRANSACTIONS =
  "account/transactions/INVALIDATE_TRANSACTIONS";

export function receiveTransactions(response) {
  return { type: LOAD_TRANSACTIONS_RESPONSE, response };
}

export function requestTransactions() {
  return { type: LOAD_TRANSACTIONS_REQUEST };
}

export function errorTransactions() {
  return { type: LOAD_TRANSACTIONS_ERROR };
}

export function responseExportTransactions(response) {
  return { type: EXPORT_TRANSACTIONS_RESPONSE, response };
}

export function requestExportTransactions() {
  return { type: EXPORT_TRANSACTIONS_REQUEST };
}

export function errorExportTransactions() {
  return { type: EXPORT_TRANSACTIONS_ERROR };
}

export function setYearExportTransactions(year) {
  return {
    type: EXPORT_TRANSACTIONS_SET_YEAR,
    year,
  };
}

function fetchSettings(page) {
  return (dispatch) => {
    dispatch(requestTransactions());
    const url =
      page > 1 ? `${ACCOUNT_TRANSACTIONS}?page=${page}` : ACCOUNT_TRANSACTIONS;
    return xhr
      .get(url)
      .then((response) => dispatch(receiveTransactions(response.data)))
      .catch((e) => {
        dispatch(createApiErrorAlert(e));
        dispatch(errorTransactions());
      });
  };
}

function shouldFetchSettings(state, page = 1) {
  if (state.account.transactions.isLoading) return false;
  if (!state.account.transactions.data) return true;
  if (state.account.transactions.invalidated) return true;
  const perPage = 20;
  const pageData = state.account.transactions.data.items.slice(
    (page - 1) * perPage,
    page * perPage
  );
  if (!pageData.length) return true;

  return true;
}

export function fetchSettingsIfNeeded(page) {
  return (dispatch, getState) => {
    if (shouldFetchSettings(getState(), page)) {
      return dispatch(fetchSettings(page));
    }

    return null;
  };
}

const delay = (m) => {
  return new Promise((resolve) => {
    setTimeout(resolve, m);
  });
};

export function checkTxDownloadProgress(id, dispatch) {
  return xhr
    .get(ACCOUNT_TRANSACTIONS_EXPORT_STATUS, { params: { id } })
    .then((response) => {
      if (
        response.data &&
        response.data.state !== "requested" &&
        response.data.url
      ) {
        return response.data;
      }
      return delay(3000).then(checkTxDownloadProgress.bind(null, id));
    })
    .catch((e) => {
      dispatch(createApiErrorAlert(e));
    });
}

export function startTransactionDownload(year, export_format) {
  return (dispatch) => {
    dispatch(requestExportTransactions());
    xhr
      .post(
        ACCOUNT_TRANSACTIONS_EXPORT,
        JSON.stringify({ year, export_format })
      )
      .then((response) => {
        return checkTxDownloadProgress(response.data.id, dispatch);
      })
      .then((response) => {
        dispatch(responseExportTransactions(response));
      })
      .catch((e) => {
        dispatch(createApiErrorAlert(e));
      });
  };
}

const initialState = {
  data: undefined,
  error: undefined,
  isLoading: false,
  isDownloading: false,
  invalidated: false,
  exportData: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_TRANSACTIONS_REQUEST:
      return { ...state, isLoading: true };
    case LOAD_TRANSACTIONS_RESPONSE:
      const existingItems = state.data ? state.data.items : [];
      const { items, ...rest } = action.response;
      const newItems = items.reduce((memo, item, index) => {
        const { page } = action.response;
        const perPage = 20;
        const itemIndex = (page - 1) * perPage + index;
        memo[itemIndex] = item;
        return memo;
      }, existingItems);
      return {
        ...state,
        isLoading: false,
        invalidated: false,
        data: { ...state.data, ...rest, items: newItems },
      };
    case LOAD_TRANSACTIONS_ERROR:
      return { ...state, isLoading: false, invalidated: false };
    case INVALIDATE_TRANSACTIONS:
      return { ...state, isLoading: false, invalidated: true };
    case EXPORT_TRANSACTIONS_SET_YEAR:
      return { ...state, year: action.year };
    case EXPORT_TRANSACTIONS_REQUEST:
      return { ...state, isDownloading: true };
    case EXPORT_TRANSACTIONS_RESPONSE:
      return { ...state, isDownloading: false, exportData: action.response };
    case EXPORT_TRANSACTIONS_ERROR:
      return { ...state, isDownloading: false, exportData: null };
    default:
      return state;
  }
}
