import qs from "qs";
import xhr, { createApiErrorAlert } from "util/xhr";
import {
  createActionTypesFor,
  deepMergeTaskerCategoryItems,
  deepUpdateTaskerCategoryItem,
} from "util/reducerHelpers";

/* schema
  taskers: [
    {
      taskerId: number,
      categories: [
        {
          categoryId: number,
          photos: [],
        },
      ],
    },
  ],
  loading: bool
} */

const initialState = {
  taskers: [],
  loading: false,
};

const FETCH_PHOTOS = createActionTypesFor(
  "v3/taskerProfile/taskerPhotos",
  "fetchPhotos"
);

const REPORT_PHOTO = createActionTypesFor(
  "v3/taskerProfie/taskerPhotos",
  "reportPhoto"
);

export default function taskerPhotos(state = initialState, action) {
  switch (action.type) {
    case FETCH_PHOTOS.REQUEST:
      return {
        ...state,
        loadingPhotos: true,
      };

    case FETCH_PHOTOS.SUCCESS: {
      return {
        loading: false,
        taskers: deepMergeTaskerCategoryItems({
          action,
          state,
          collectionPropertyName: "photos",
          itemIdKey: "photoId",
        }),
      };
    }
    case REPORT_PHOTO.FAILURE:
      return { ...state, loading: false };

    case REPORT_PHOTO.SUCCESS: {
      return {
        loading: false,
        taskers: deepUpdateTaskerCategoryItem({
          action,
          state,
          collectionPropertyName: "photos",
          itemIdKey: "photoId",
        }),
      };
    }

    default:
      return state;
  }
}

export const fetchPhotosByCategory = (categoryId, taskerId) => (dispatch) => {
  const params = {
    rabbit_id: taskerId,
    category_id: categoryId,
  };
  dispatch({ type: FETCH_PHOTOS.REQUEST });
  const url = `/api/v3/rabbit_category_photos?${qs.stringify(params)}`;

  return xhr
    .get(url)
    .then(({ camelCasedData: data }) => {
      const photos = (data?.rabbitCategoryPhotos?.items || []).map((x) => ({
        ...x,
        photoId: x.id,
      }));

      return dispatch({
        type: FETCH_PHOTOS.SUCCESS,
        taskerId,
        categoryId,
        photos,
      });
    })
    .catch((e) => {
      dispatch({ type: FETCH_PHOTOS.FAILURE, error: e });
      createApiErrorAlert(e);
    });
};

export const reportPhoto = (id, reason, explanation, taskerId, categoryId) => (
  dispatch
) => {
  const params = {
    id,
    reason_key: reason,
    explanation,
  };

  const url = `/api/v3/rabbit_category_photos/${id}/report?${qs.stringify(
    params
  )}`;

  dispatch({ type: REPORT_PHOTO.REQUEST });

  return xhr
    .post(url)
    .then(() => {
      return dispatch({
        type: REPORT_PHOTO.SUCCESS,
        taskerId,
        categoryId,
        photos: {
          photoId: id,
          reportedByUser: true,
        },
      });
    })
    .catch((e) => {
      dispatch({ type: REPORT_PHOTO.FAILURE });
      createApiErrorAlert(e);
    });
};
