import React from "react";
import styled from "styled-components";
import { TrMetricsService } from "../../../services/TrMetricsService";
import { StoreBadge } from "./NativeAppBadges/StoreBadge";
import { DOWNLOAD_THE_APP_EVENT_NAME } from "../../../config/metricsEventConfig";

export function NativeAppBadges({ locale }) {
  const onStoreBadgeClick = () => {
    TrMetricsService.fire(DOWNLOAD_THE_APP_EVENT_NAME, { source: "footer" });
  };

  return (
    <BadgeContainer>
      <div className = "store-badge">
        <StoreBadge
          locale={locale}
          onClick={onStoreBadgeClick}
          storeType="appStore"
          width={175}
        />
      </div>
      <div className = "store-badge">
        <StoreBadge
          locale={locale}
          onClick={onStoreBadgeClick}
          storeType="playStore"
          width={175}
        />
      </div>
    </BadgeContainer>
  );
}

const BadgeContainer = styled.div`
  text-align: center;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: ${(props) => props.theme.spacing.md};
`;
