import React from "react";
import { defineMessages } from "react-intl";
import classnames from "classnames";
import { FormattedMessage } from "components/FormattedMessage";

const messages = defineMessages({
  card: {
    id: "payments.payment_method_type.card",
    defaultMessage: "Card Payment",
  },
  sepa_debit: {
    id: "payments.payment_method_type.sepa_debit",
    defaultMessage: "SEPA Direct Debit",
  },
  apple_pay: {
    id: "payments.payment_method_type.apple_pay",
    defaultMessage: "Apple Pay",
  },
  google_pay: {
    id: "payments.payment_method_type.google_pay",
    defaultMessage: "Google Pay",
  },
});

const PaymentMethodTypeSelector = ({
  availablePaymentMethodTypes = [],
  selectedPaymentMethodType,
  handlePaymentMethodTypeChange,
  hasApplePay = false,
  paymentRequest,
  hasGooglePay = false,
}) => {
  const isNativePaySelected = selectedPaymentMethodType === "native_pay";
  return (
    (availablePaymentMethodTypes.length > 1 || hasApplePay || hasGooglePay) && (
      <div className="stripe__payment-methods-container" role="tablist">
        {availablePaymentMethodTypes.map((paymentMethodType) => {
          const isSelected = paymentMethodType === selectedPaymentMethodType;
          return (
            // [MEADOW_TODO] - Button
            <button
              type="button"
              data-testid={paymentMethodType}
              key={paymentMethodType}
              onClick={() => handlePaymentMethodTypeChange(paymentMethodType)}
              className={classnames("stripe__payment-method", {
                "stripe__payment-method--selected": isSelected,
                "is-disabled": isSelected,
              })}
              role="tab"
              aria-selected={isSelected}
              disabled={isSelected}
            >
              <FormattedMessage message={messages[paymentMethodType]} />
              <span className="stripe__payment-method--moving-tab">
                <FormattedMessage message={messages[paymentMethodType]} />
              </span>
            </button>
          );
        })}
        {(hasApplePay || hasGooglePay) && (
          <>
            <button
              type="button"
              data-testid="native_pay"
              key="native_pay"
              onClick={() => {
                handlePaymentMethodTypeChange("native_pay");
                paymentRequest.show();
              }}
              className={classnames("stripe__payment-method", {
                "stripe__payment-method--selected": isNativePaySelected,
                "is-disabled": isNativePaySelected,
              })}
              role="tab"
              aria-selected={isNativePaySelected}
              disabled={isNativePaySelected}
            >
              <FormattedMessage message={hasApplePay ? messages.apple_pay : messages.google_pay} />
              <span className="stripe__payment-method--moving-tab">
                <FormattedMessage message={hasApplePay ? messages.apple_pay : messages.google_pay} />
              </span>
            </button>
          </>
        )}
      </div>
    )
  );
};

export default PaymentMethodTypeSelector;
