import { ACCOUNT_AVATAR_UPLOAD } from "account/routes/apiRoutes";
import { createAlert } from "alerts/redux/modules/alerts";
import { LOAD_PROFILE_RESPONSE } from "account/redux/modules/profile";
import { defineMessages } from "react-intl";
import xhr, { createApiErrorAlert } from "util/xhr";

const messages = defineMessages({
  avatar_uploaded: {
    id: "alerts.avatar_uploaded",
    defaultMessage: "Your photo successfully uploaded.",
  },
});

const AVATAR_UPLOAD_REQUEST = "account/avatar/AVATAR_UPLOAD_REQUEST";
const AVATAR_UPLOAD_ERROR = "account/avatar/AVATAR_UPLOAD_ERROR";
const AVATAR_UPLOAD_RESPONSE = "account/avatar/AVATAR_UPLOAD_RESPONSE";

function requestUploadAvatar() {
  return { type: AVATAR_UPLOAD_REQUEST };
}

function responseUploadAvatar(response) {
  return { type: AVATAR_UPLOAD_RESPONSE, response };
}

function errorUploadAvatar(error) {
  return { type: AVATAR_UPLOAD_ERROR, error };
}

function notifyNewProfileSettings(profile) {
  return { type: LOAD_PROFILE_RESPONSE, profile };
}

export function uploadAvatarPic(options) {
  return (dispatch, getState) => {
    dispatch(requestUploadAvatar());
    return xhr
      .post(ACCOUNT_AVATAR_UPLOAD, JSON.stringify(options))
      .then(response => {
        dispatch(responseUploadAvatar());
        // server provides new profile JSON as the response
        dispatch(notifyNewProfileSettings(response.data));
        dispatch(
          createAlert({
            messages: messages.avatar_uploaded.id,
          })
        );
      })
      .catch(e => {
        dispatch(errorUploadAvatar());
        dispatch(createApiErrorAlert(e));
      });
  };
}

export default function avatar(state = { avatarLoading: false }, action) {
  switch (action.type) {
    case AVATAR_UPLOAD_REQUEST:
      return { avatarLoading: true };
    case AVATAR_UPLOAD_RESPONSE:
      return { avatarLoading: false };
    case AVATAR_UPLOAD_ERROR:
      return { avatarLoading: false };

    default:
      return state;
  }
}
