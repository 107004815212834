import React from "react";
import { defineMessages, injectIntl } from "react-intl";
import { FormattedMessage } from "components/FormattedMessage";
import get from "lodash/get";

import americanExpressSvg from "../images/american_express.svg";
import defaultCardSvg from "../images/default_card.svg";
import dinersClubSvg from "../images/diners_club.svg";
import discoverSvg from "../images/discover.svg";
import jcbSvg from "../images/jcb.svg";
import mastercardSvg from "../images/mastercard.svg";
import sepaSvg from "../images/sepa.svg";
import visaSvg from "../images/visa.svg";

const messages = defineMessages({
  sepa_debit_number: {
    id: "payments.displayInfo.last4.sepa_debit",
    defaultMessage: "Ending in {last4}",
  },
  card_number: {
    id: "payments.displayInfo.last4.card",
    defaultMessage: "**** **** **** {last4}",
  },
  sepa_debit_img_alt: {
    id: "payments.displayInfo.imgAlt.sepa_debit",
    defaultMessage: "SEPA Direct Debit",
  },
  card_img_alt: {
    id: "payments.displayInfo.imgAlt.card",
    defaultMessage: "Credit Card",
  },
});

const PaymentMethodInfo = ({
  paymentMethodType,
  cardType = null, // default to null in case it is undefined (e.g. SEPA Debit)
  last4,
  intl,
}) => {
  const imgSrcByPaymentMethodType = {
    sepa_debit: {
      null: sepaSvg,
    },
    card: {
      amex: americanExpressSvg,
      diners: dinersClubSvg,
      discover: discoverSvg,
      jcb: jcbSvg,
      mastercard: mastercardSvg,
      visa: visaSvg,
    },
  };

  const imgSrc = () => {
    return get(
      imgSrcByPaymentMethodType,
      [paymentMethodType, String(cardType).toLowerCase()],
      defaultCardSvg
    );
  };

  const imgAlt = () => {
    return (
      cardType || intl.formatMessage(messages[`${paymentMethodType}_img_alt`])
    );
  };

  const renderImg = () => (
    <img className="stripe__payment-type-img" src={imgSrc()} alt={imgAlt()} />
  );

  return (
    <span>
      {renderImg()}
      <FormattedMessage
        message={messages[`${paymentMethodType}_number`]}
        values={{ last4 }}
      />
    </span>
  );
};

export default injectIntl(PaymentMethodInfo);
