import React, { useEffect } from "react";
import { hot } from "react-hot-loader/root";
import { defineMessages } from "react-intl";
import { FormattedMessage } from "components/FormattedMessage";
import { Row, Col } from "components/Grid";
import { connect } from "react-redux";
import classnames from "classnames";
import { bindActionCreators } from "redux";
import ButtonPrimary from "@taskrabbit/meadow-web/lib/ButtonPrimary";

import { resetErrorMessage, localLogin } from "login/redux/modules/loginSignup";
import { reduxForm, Field } from "redux-form";
import { createValidator, email, password } from "util/validation";
import { internalPath } from "util/internalPath";
import { LoginTextField } from "login/components/LoginInputFields";
import { withGoogleReCaptcha } from "react-google-recaptcha-v3";
import Typography from "@taskrabbit/meadow-web/lib/Typography";

export const messages = defineMessages({
  email: {
    id: "loginSignup.labels.email",
    defaultMessage: "Email Address",
  },
  password: {
    id: "loginSignup.labels.password",
    defaultMessage: "Password",
  },
  login: {
    id: "loginSignup.labels.login",
    defaultMessage: "Log in",
  },
  forgotPassword: {
    id: "loginSignup.labels.forgotPassword",
    defaultMessage: "Forgot password?",
  },
  createPassword: {
    id: "loginSignup.labels.createNewPassword",
    defaultMessage: "Tap here to create a password",
  },
  dontHave: {
    id: "build.confirm.login.labels.dontHave",
    defaultMessage: "Don't have an account?",
  },
  needToCreateAccount: {
    id: "build.confirm.login.labels.needToCreateAccount",
    defaultMessage: "Need to create an account?",
  },
  goSignup: {
    id: "build.confirm.login.labels.goSignup",
    defaultMessage: "Sign up",
  },
  lookingForSocialLogin: {
    id: "build.confirm.login.labels.lookingForSocialLogin",
    defaultMessage: "Signed up with Facebook or Google?",
  },
  ikeaSetPasswordLogin: {
    id: "build.confirm.login.labels.ikeaSetPasswordLogin",
    defaultMessage: "Signed up with <nl>Facebook or Google?</nl> <cta>Create Password</cta>",
  },
  ikeaSetPasswordLoginPhase2: {
    id: "build.confirm.login.labels.ikeaSetPasswordLoginPhase2",
    defaultMessage: "Did you purchase an assembly from IKEA or sign up <nl>via Facebook or Google?</nl> <cta>Create Password</cta>",
  }
});

let LoginForm = ({
  handleSubmit,
  loading,
  errorMessage,
  localLoginAction,
  resetErrorMessageAction,
  googleReCaptchaProps,
}) => {
  useEffect(() => {
    resetErrorMessageAction();
  }, [resetErrorMessageAction]);

  /* TODO: Check with the design team about the loading animation
  currently meadow-web doesn't provide a loading animation and
  there's a discussion about which Button component will have a it.
  Leaving the old classes at the moment, this can be deleted if is not needed */
  const loginButtonClasses = classnames({
    "u-full-width": true,
    "is-loading-meadow": loading,
  });

  const isIkeaPhase2 = window.IKEA_PHASE_2;

  return (
    <form
      style={{ width: "100%" }}
      className="login-signup-v2"
      onSubmit={(e) => e.preventDefault()}
    >
      <div className="login-signup-v2-item">
        <div className="login-signup-v2-first-label">
          <Typography variant="label3">
            <FormattedMessage message={messages.email} />
          </Typography>
        </div>
        <fieldset>
          <Field
            component={LoginTextField}
            messageId={messages.email.id}
            type="email"
            name="email"
            autoComplete="username"
          />
        </fieldset>
      </div>

      <div className="login-signup-v2-item-label">
        <div className="login-signup-v2-label">
          <Typography variant="label3">
            <FormattedMessage message={messages.password} />
          </Typography>
        </div>
        <fieldset>
          <Field
            component={LoginTextField}
            messageId={messages.password.id}
            type="password"
            name="password"
            autoComplete="current-password"
          />
        </fieldset>

        {errorMessage && (
          <Row className="exterior__bottom--md">
            <Col size={12}>
              <div className="field-error-container-blue">
                {errorMessage.message}
              </div>
            </Col>
          </Row>
        )}
      </div>
      <div className="forgot-password">
        <a href={internalPath("/password/reset")} data-ref="forgot-password">
          <FormattedMessage message={messages.forgotPassword} />
        </a>
      </div>

      <fieldset className="fields-submit login-signup-v2-item">
        <ButtonPrimary
          className={loginButtonClasses}
          data-testid="submit-login"
          fullWidth={true}
          onClick={handleSubmit((data) =>
            localLoginAction(data, googleReCaptchaProps.executeRecaptcha)
          )}
          sx={{ margin: 0 }}
          type="submit"
        >
          <FormattedMessage message={messages.login} />
        </ButtonPrimary>
      </fieldset>

      <div className="looking-for_social-signup">
        <FormattedMessage
          message={isIkeaPhase2 ? messages.ikeaSetPasswordLoginPhase2 : messages.ikeaSetPasswordLogin}
          values={{
            nl: msg => (
              <span>
                <br /> {msg}
              </span>
            ),
            cta: msg => <a href={internalPath("/password/reset")}>{msg}</a>,
          }}
        />
      </div>
    </form >
  );
};

const mapStateToProps = (state) => {
  const { loading, errorPayload } = state.loginSignup.login;
  return {
    loading,
    errorMessage: errorPayload,
  };
};

const mapDispatchToProps = (dispatch) => ({
  localLoginAction: bindActionCreators(localLogin, dispatch),
  resetErrorMessageAction: bindActionCreators(resetErrorMessage, dispatch),
});

const loginValidator = createValidator({
  email: [email],
  password: [password],
});

LoginForm = reduxForm({
  form: "login",
  validate: loginValidator,
})(withGoogleReCaptcha(LoginForm));

export default hot(connect(mapStateToProps, mapDispatchToProps)(LoginForm));
