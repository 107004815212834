import React, { Component, createElement } from "react";
import PropTypes from "prop-types";
import { a11yizeClickEvent } from "util/a11yHelpers";

import DefaultModalBody from "components/Modal/DefaultModalBody";
import enhanceWithModal from "components/Modal/enhanceWithModal";

class CarouselModalContainer extends Component {
  render() {
    const wrappedElement = createElement(
      this.props.modalClass || DefaultModalBody,
      this.props
    );
    return (
      <div className="carousel--content-container">
        <div className="lightbox--content-wrapper carousel--content-wrapper">
          {!!this.props.title && (
            <div className="lightbox--title">{this.props.title}</div>
          )}
          <div className="lightbox--content">{wrappedElement}</div>
        </div>

        <div
          className="carousel-lightbox-dismiss"
          {...a11yizeClickEvent(this.props.declineFn)}
        >
          <i className="ss-lnr-cross2 carousel--dismiss" />
        </div>
      </div>
    );
  }
}

export default class ModalContainer extends Component {
  render() {
    return createElement(
      this.props.renderModal || enhanceWithModal(CarouselModalContainer),
      this.props
    );
  }
}

ModalContainer.propTypes = {
  // renderModal: new modal class that is already enhanced with enhanceWithModal
  renderModal: PropTypes.func,
  // modalClass: older API to provide optional inner component to DefaultModalContainer
  // if not provided the DefaultModalBody component would be used
  modalClass: PropTypes.func,
};
