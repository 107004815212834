import {
  REVIEW_TASK_CREATE,
  REVIEW_TASK_UPDATE,
  POST_JOB_FOLLOWUP,
} from "dashboard/routes/apiRoutes";
import xhr, { createApiErrorAlert } from "util/xhr";
import { createAlert } from "alerts/redux/modules/alerts";
import {
  removeTaskFromActive,
  fetchSingleTask,
  fetchJobDataResponse,
} from "dashboard/redux/modules/taskLists";
import { push } from "connected-react-router";
import { defineMessages } from "react-intl";
import qs from "qs";
import moment from "util/momentWithTZ";
import find from "lodash/find";
import { internalPath } from "util/internalPath";
import { POSITIVE_STAR_RATING } from "../../../util/constants";

const messages = defineMessages({
  success: {
    id: "dashboard.review.notifications.success",
    defaultMessage: "Rating has been submitted",
  },
});

const STORE_JOB_DATA = "STORE_JOB_DATA";
const POST_REVIEW_REQUEST = "POST_REVIEW_REQUEST";
const POST_REVIEW_RESPONSE = "POST_REVIEW_RESPONSE";
const POST_REVIEW_ERROR = "POST_REVIEW_ERROR";
const CHOOSE_FOLLOWUP_TIME_PERIOD = "CHOOSE_FOLLOWUP_TIME_PERIOD";
const TOGGLE_REVIEW_DIMENSION = "TOGGLE_REVIEW_DIMENSION";
const SET_REVIEW_POSIVITITY = "SET_REVIEW_POSIVITITY";
const SET_REVIEW_MESSAGE = "SET_REVIEW_MESSAGE";
const SET_DISPLAY_STATE = "SET_DISPLAY_STATE";
const SET_REVIEW_STAR_RATING = "SET_REVIEW_STAR_RATING";
const SET_START_FROM_FOLLOWUP = "SET_START_FROM_FOLLOWUP";

export const setStartFromFollowUp = () => ({ type: SET_START_FROM_FOLLOWUP });

// action creator to wipe the state and add new job data
function storeJobData(jobData) {
  return {
    type: STORE_JOB_DATA,
    jobData,
  };
}

function setDisplayState(displayState) {
  return { type: SET_DISPLAY_STATE, displayState };
}

export function loadJobDataForReview({ job_id, rabbit_id }) {
  return (dispatch, getState) => {
    const state = getState();
    let job;
    if (
      state.dashboard.taskLists &&
      state.dashboard.taskLists.active &&
      state.dashboard.taskLists.active.items
    ) {
      job = find(
        getState().dashboard.taskLists.active.items,
        item => item.job.id === job_id
      );
    }

    if (job) {
      dispatch(storeJobData(job));
      return Promise.resolve(job);
    }
      return fetchSingleTask(job_id, rabbit_id).then((jobData) => {
        dispatch(storeJobData(jobData));
      });

  };
}

// action creators for reviewing the job
function requestPostReview() {
  return { type: POST_REVIEW_REQUEST };
}

function responsePostReview(response) {
  return {
    type: POST_REVIEW_RESPONSE,
    response,
  };
}

function errorPostReview() {
  return { type: POST_REVIEW_ERROR };
}

// action creator for scheduling followup
export function chooseFollowupPeriod(seconds_between) {
  return {
    type: CHOOSE_FOLLOWUP_TIME_PERIOD,
    seconds_between,
  };
}

export function toggleDimension(key) {
  return {
    type: TOGGLE_REVIEW_DIMENSION,
    key,
  };
}

export function setReviewPositivity(isPositive) {
  return {
    type: SET_REVIEW_POSIVITITY,
    isPositive,
  };
}

function _setNumStars(numStars) {
  return {
    type: SET_REVIEW_STAR_RATING,
    numStars,
  };
}

export function setNumStars(numStars) {
  return (dispatch, getState) => {
    const isPositive = numStars >= POSITIVE_STAR_RATING;
    dispatch(setReviewPositivity(isPositive));
    dispatch(_setNumStars(numStars));
  };
}

export function setReviewMessage(message) {
  return {
    type: SET_REVIEW_MESSAGE,
    message,
  };
}

export function proceed({ affirmed } = {}) {
  return (dispatch, getState) => {
    const state = getState();
    const { displayState } = state.dashboard.reviewTasker;
    const nextDisplayState = calcDisplayState(state, affirmed);

    const submitReviewData = ["review", "comment"].includes(displayState);
    const fireAlert = ["schedule", "dismiss"].includes(nextDisplayState);
    const clearParams = nextDisplayState === "dismiss";

    if (clearParams) dispatch(push(internalPath("/"))); // TODO: just clear `focus` param.
    if (submitReviewData) dispatch(performTaskerReview({ fireAlert }));
    if (nextDisplayState === "schedule") dispatch(scheduleFollowup());

    // this fakes review success alert after followup msg.
    // TODO: rm once followup isn't 3rd page of modal anymore
    if (!submitReviewData && fireAlert)
      dispatch(createAlert(messages.success.id));

    return dispatch(setDisplayState(nextDisplayState));
  };
}

function calcDisplayState(state, affirmed) {
  const {
    displayState,
    reviewData: { isPositive },
    jobData: {
      job: { ongoing },
    },
  } = state.dashboard.reviewTasker;
  switch (displayState) {
    case "review":
      return "comment";
    case "comment":
      return isPositive && !ongoing ? "followup" : "dismiss";
    case "followup":
      return affirmed ? "schedule" : "dismiss";
    default:
      return state;
  }
}

// perform the API call to review the job/tasker
function performTaskerReview({ fireAlert }) {
  return (dispatch, getState) => {
    const state = getState();
    const {
      job: { id, rabbit_id, ongoing },
    } = state.dashboard.reviewTasker.jobData;
    const {
      dimensionValues,
      isPositive,
      message,
      numStars,
    } = state.dashboard.reviewTasker.reviewData;
    const rating_description = "star" + numStars;
    const review_id = state.dashboard.reviewTasker.followupData.id;
    const endpoint = review_id ? REVIEW_TASK_UPDATE : REVIEW_TASK_CREATE;
    const url = endpoint
      .replace(":job_id", id)
      .replace(":review_id", review_id);
    const data = {
      improvement_dimensions: dimensionValues,
      positive: isPositive,
      message,
      job_id: id,
      rabbit_id,
      id: review_id,
      rating_description,
    };

    dispatch(requestPostReview());
    return xhr.post(url, JSON.stringify(data)).then(
      response => {
        if (fireAlert) dispatch(createAlert(messages.success.id));
        dispatch(responsePostReview(response.data));

        const filteredList = getState().dashboard.taskLists.active.items.filter(
          item => item.job.id === id
        );

        if (filteredList.length && ongoing) {
          return fetchSingleTask(id, rabbit_id).then(item => {
            dispatch(fetchJobDataResponse(item));
          });
        }
          dispatch(removeTaskFromActive(id));

        return undefined;
      },
      e => {
        dispatch(createApiErrorAlert(e));
        dispatch(errorPostReview());
      }
    );
  };
}

function scheduleFollowup() {
  return (dispatch, getState) => {
    const state = getState();
    const {
      job: { id, rabbit_id },
    } = state.dashboard.reviewTasker.jobData;
    const { seconds_between } = state.dashboard.reviewTasker.followupData;
    const data = {
      job_id: id,
      rabbit_id,
      seconds_between,
    };
    return xhr.post(POST_JOB_FOLLOWUP, JSON.stringify(data)).then(response => {
      const { schedule_at } = response.data;
      const date = moment(schedule_at * 1000).format("YYYY-MM-DD");
      const query = qs.stringify({
        date,
        job_id: id,
        invitee_id: rabbit_id,
        form_referrer: "followup",
        target: "confirm",
        source: "dashboard",
      });
      window.location = internalPath(`/tasks/repost?${query}`);
    });
  };
}

const initialState = {
  displayState: "review",
  reviewData: {
    isPositive: undefined,
    message: undefined,
    dimensionValues: [],
  },
  jobData: undefined,
  followupData: {},
  loadingJobData: false,
};

export default function postReviewReducer(state = initialState, action) {
  switch (action.type) {
    case STORE_JOB_DATA: // reset initial state + action's job data
      return {
        ...initialState,
        jobData: action.jobData,
        displayState: state.displayState,
      };

    case SET_DISPLAY_STATE:
      return { ...state, displayState: action.displayState };

    case SET_START_FROM_FOLLOWUP:
      return { ...state, displayState: "followup" };

    case POST_REVIEW_RESPONSE:
      return {
        ...state,
        followupData: { ...state.followupData, ...action.response },
      };

    case CHOOSE_FOLLOWUP_TIME_PERIOD:
      return {
        ...state,
        followupData: {
          ...state.followupData,
          seconds_between: action.seconds_between,
        },
      };

    case TOGGLE_REVIEW_DIMENSION: {
      const dimensionEnabled = state.reviewData.dimensionValues.includes(
        action.key
      );
      const newDims = dimensionEnabled
        ? state.reviewData.dimensionValues.filter((dim) => dim !== action.key)
        : [...state.reviewData.dimensionValues, action.key];
      return {
        ...state,
        reviewData: {
          ...state.reviewData,
          dimensionValues: newDims,
        },
      };
    }

    case SET_REVIEW_POSIVITITY:
      return {
        ...state,
        reviewData: {
          ...state.reviewData,
          isPositive: action.isPositive,
        },
      };

    case SET_REVIEW_STAR_RATING:
      return {
        ...state,
        reviewData: {
          ...state.reviewData,
          numStars: action.numStars,
        },
      };

    case SET_REVIEW_MESSAGE:
      return {
        ...state,
        reviewData: { ...state.reviewData, message: action.message },
      };

    default:
      return state;
  }
}
