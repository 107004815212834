import React, { useEffect } from "react";
import { connect } from "react-redux";
import { loadTaskCounts as loadTaskCountsAction } from "../../dashboard/redux/modules/taskCounts";
import { useIsClient } from "../../shared/utils/useIsClient";

const defaultHeaderNavContext = {
  hasActiveTasks: false,
  hasCompletedTasks: false,
  isRabbit: false,
  loggedIn: false,
  referralData: {},
  routing: { location: {} },
};

export const HeaderNavContext = React.createContext(defaultHeaderNavContext);

export function HeaderNavProvider_({
  children,

  // from mapDispatchToProps
  loadTaskCounts,

  // from mapStateToProps
  hasActiveTasks,
  hasCompletedTasks,
  isRabbit,
  loggedIn,
  referralData,
  routing,
}) {
  const { isClient } = useIsClient();
  useEffect(() => {
    if (isClient && loggedIn) {
      loadTaskCounts();
    }
  }, [isClient, loadTaskCounts, loggedIn]);

  const value = {
    hasActiveTasks,
    hasCompletedTasks,
    isRabbit,
    loggedIn,
    referralData,
    routing,
  };

  return (
    <HeaderNavContext.Provider value={value}>
      {children}
    </HeaderNavContext.Provider>
  );
}

function mapStateToProps(state) {
  const hasCompletedTasks =
    (state.dashboard.taskCounts?.counts?.completed || 0) > 0;
  const hasActiveTasks = (state.dashboard.taskCounts?.counts?.active || 0) > 0;
  const profileData = state.account.profile.data;

  return {
    hasActiveTasks,
    hasCompletedTasks,
    isRabbit: profileData ? profileData.rabbit : false,
    loggedIn: state.user.loggedIn,
    referralData: state.dashboard.bootstrap.referral,
    routing: state.router,
  };
}
const mapDispatchToProps = {
  loadTaskCounts: loadTaskCountsAction,
};
export const HeaderNavProvider = connect(
  mapStateToProps,
  mapDispatchToProps
)(HeaderNavProvider_);
