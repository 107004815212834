import { LOCATION_CHANGE } from "connected-react-router";
import { combineEpics } from "redux-observable";
import { fireMetric } from "store/middleware/metricMiddleware";
import {
  isIkeaJobFlow,
  isDirectHireFlow,
  isAutoTaskerSelectionFlow,
} from "build/redux/modules/selector";
import { REFERRER_QUERY_PARAMS } from "util/constants";
import qs from "qs";

import { buildPages } from "enums/buildPages";

const initialState = {
  page: buildPages.form,
  pages: [buildPages.form, buildPages.recommendations, buildPages.confirm],
  /*
      connected-react-router fires LOCATION_CHANGE twice on pageload:
      https://github.com/reactjs/connected-react-router/issues/481
      so, start urlChanges counter at -1 as workaround
  */
  urlChanges: -1,
};

const actionMaker = (actionName) => `build/progress/${actionName}`;
const SET_PAGES = actionMaker("SET_PAGES");

export const fireSetPages = (data) => ({
  type: SET_PAGES,
  page: data.page,
  pages: data.pages,
});

export const changePage = (page) => (dispatch, getState) => {
  const { build } = getState();
  const { pages } = build.progress;
  return dispatch(fireSetPages({ page, pages }));
};

export default function progress(state = initialState, action) {
  switch (action.type) {
    case LOCATION_CHANGE:
      return {
        ...state,
        query: action.payload.location.query,
        urlChanges: state.urlChanges + 1,
        pathname: action.payload.location.pathname,
      };
    case SET_PAGES:
      return {
        ...state,
        page: action.page,
        pages: action.pages,
      };
    default:
      return state;
  }
}

const recalculatePages = ({
  nextPage,
  skipRecommendations,
  formReferrer,
  isIkeaJob,
  autoTaskerSelection,
  isDirectHireJob,
}) => {
  const quotePages = [buildPages.quote];
  const hirePages = [buildPages.hire, buildPages.confirm];
  const ikeaGMhirePages = [
    buildPages.hire,
    buildPages.chooseDateTime,
    buildPages.confirm,
  ];
  const ikeaGMAutoTaskerSelectionPages = [
    buildPages.form,
    buildPages.chooseDateTime,
    buildPages.confirm,
  ];
  const bookPages = [buildPages.book, buildPages.confirm];
  const skipRecoPages = [buildPages.form, buildPages.confirm];
  const formPages = [
    buildPages.form,
    buildPages.recommendations,
    buildPages.dateTime,
    buildPages.confirm,
  ];
  const affiliatePages = [
    buildPages.affiliate,
    buildPages.recommendations,
    buildPages.dateTime,
    buildPages.confirm,
  ];
  const determinePages = (page) => {
    if (skipRecommendations) return skipRecoPages;
    if (page === buildPages.hire || isDirectHireJob) {
      return isIkeaJob ? ikeaGMhirePages : hirePages;
    }
    if (autoTaskerSelection) return ikeaGMAutoTaskerSelectionPages;
    if (page === buildPages.quote) return quotePages;
    if (page === buildPages.book) return bookPages;

    if (formReferrer === "business_partnership") return affiliatePages;
    return formPages;
  };

  return { pages: determinePages(nextPage), page: nextPage };
};

export const setPagesAction = (state, nextPage, data={}) => {
  const {
    form_referrer: formReferrer,
    taskTemplate: { skip_recommendations: skipRecommendations } = {},
  } = state.build.manager.job;
  const isIkeaJob = isIkeaJobFlow(state);
  const isDirectHireJob = isDirectHireFlow(state);
  const autoTaskerSelection = isAutoTaskerSelectionFlow(state);
  return {
    type: SET_PAGES,
    ...recalculatePages({
      nextPage,
      skipRecommendations,
      formReferrer,
      isIkeaJob,
      autoTaskerSelection,
      isDirectHireJob,
      ...data,
    }),
  };
}

const pagesEpic = (action$, { getState }) =>
  action$.ofType(LOCATION_CHANGE).map((action) => {
    const state = getState();
    const nextPage = action.payload.location.pathname.substring(1);
    return setPagesAction(state, nextPage);
  });

const pageViewEpic = (action$, { getState }) =>
  action$.ofType(LOCATION_CHANGE).map(() => {
    const { urlChanges } = getState().build.progress;
    let action = { type: "IGNORED:no-op_action_fired_from_pageViewEpic" }; // epic has to return *some* action
    // on page load, fire page_referred if we have some referrer type querystring param
    // otherwise, fire page view whenever url location changes
    if (urlChanges > 1) {
      action = fireMetric("pageview", { page: window.location.pathname });
    } else if (urlChanges === 0) {
      const query = qs.parse(window.location.href.split("?")[1]);
      const hasUtmParam = Object.keys(query).some((key) =>
        REFERRER_QUERY_PARAMS.includes(key)
      );
      const hasReferrer = !!(
        window.location.pathname === "/dashboard" && query.referrer
      );
      const referrerHostname = hasReferrer
        ? query.referrer
        : document.referrer.split("?")[0];
      const isExternal =
        referrerHostname.length > 0 && !referrerHostname.match(/taskrabbit\./);
      const isTaskerDetail = window.location.pathname.match(/tasker-detail/);

      if ((hasUtmParam || isExternal) && !isTaskerDetail) {
        action = fireMetric("page_referred", query);
      }
    }

    return action;
  });

export const progressEpic = combineEpics(
  pageViewEpic,
  pagesEpic,
);
