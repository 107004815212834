import React from "react";
import { MobileServicesMenu } from "./ServicesMenu/MobileServicesMenu";
import { DesktopServicesMenu } from "./ServicesMenu/DesktopServicesMenu";

function isMenuRenderable({ links, serviceIndexPath }) {
  return links != null && serviceIndexPath != null;
}

const DEVICE_TYPES = ["desktop", "mobile"];

export function ServicesMenu({
  deviceType,
  links,
  mobileExpandInitialState,
  serviceIndexPath,
}) {
  if (!isMenuRenderable({ links, serviceIndexPath })) {
    return null;
  }

  if (deviceType === "desktop") {
    return (
      <DesktopServicesMenu
        links={links}
        serviceIndexPath={serviceIndexPath}
        mobileExpandInitialState={mobileExpandInitialState}
      />
    );
  }

  if (deviceType === "mobile") {
    return (
      <MobileServicesMenu
        links={links}
        mobileExpandInitialState={mobileExpandInitialState}
      />
    );
  }

  throw Error(
    `Unknown device type '${deviceType}'. Expected one of ${DEVICE_TYPES}.`
  );
}
