type Key = string | readonly string[];
type KeyStr<K extends Key> = K extends readonly string[] ? K[number] : K;

export const omitKeys = <T extends Record<string, unknown>, K extends Key>(
  obj: T,
  keys: K
): Omit<T, KeyStr<K>> => {
  const omittedKeys = new Set<string>(Array.isArray(keys) ? keys : [keys]);
  const newObj: Partial<T> = {};

  for (const key in obj) {
    if (key && !omittedKeys.has(key)) {
      newObj[key as keyof T] = obj[key];
    }
  }

  return newObj as Omit<T, KeyStr<K>>;
};
