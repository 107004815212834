import { IKEA_CATEGORIES, IKEA_LEGACY_CATEGORY } from "util/constants";

export const isIkeaCategory = (
  categorized: { category_id: number } | number
) => {
  const categoryId =
    typeof categorized === "number" ? categorized : categorized.category_id;
  return IKEA_CATEGORIES.includes(categoryId);
};

export const isIkeaLegacyCategory = (categoryId: number) => {
  return categoryId === IKEA_LEGACY_CATEGORY;
};
