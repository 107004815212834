
export const safeJSONParse = <T>(data: T | string): (T | Record<string | number, unknown> | unknown[]) => {
  try {
    if (data === undefined || data === null || typeof data !== 'string') {
      return {}
    }
    return JSON.parse(data)
  } catch(err) {
    return {};
  }
}