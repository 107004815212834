import { createReducer } from "@reduxjs/toolkit";
const IKEA_JOB_REPOST_REQUEST =
  "dashboard/repost_ikea_task/IKEA_JOB_REPOST_REQUEST";
const IKEA_JOB_REPOST_RESPONSE =
  "dashboard/repost_ikea_task/IKEA_JOB_REPOST_RESPONSE";

export function repostIkeaTaskRequest({ job_id }) {
  return {
    job_id,
    type: IKEA_JOB_REPOST_REQUEST,
  };
}

export function repostIkeaTaskResponse({ rescheduled_job_guid }) {
  return {
    type: IKEA_JOB_REPOST_RESPONSE,
    rescheduled_job_guid,
  };
}
const initialState = {
  jobLoadingId: undefined,
  rescheduledJobGuid: undefined,
};

export default createReducer(initialState, {
  [IKEA_JOB_REPOST_REQUEST]: (state, action) => {
    return { ...state, jobLoadingId: action.job_id };
  },
  [IKEA_JOB_REPOST_RESPONSE]: (state, action) => {
    return { ...state, jobLoadingId: undefined, rescheduledJobGuid: action.rescheduled_job_guid };
  },
});
