import {
  ACCOUNT_DEACTIVATION,
  ACCOUNT_DEACTIVATE,
} from "account/routes/apiRoutes";
import xhr from "util/xhr";
import { internalPath } from "util/internalPath";

const LOAD_DEACTIVATION_STATUS_REQUEST =
  "account/deactivation/LOAD_DEACTIVATION_STATUS_REQUEST";
const LOAD_DEACTIVATION_STATUS_RESPONSE =
  "account/deactivation/LOAD_DEACTIVATION_STATUS_RESPONSE";
const LOAD_DEACTIVATION_STATUS_ERROR =
  "account/deactivation/LOAD_DEACTIVATION_STATUS_ERROR";
const DEACTIVATE_REQUEST = "account/deactivation/DEACTIVATE_REQUEST";
const DEACTIVATE_RESPONSE = "account/deactivation/DEACTIVATE_RESPONSE";
const DEACTIVATE_ERROR = "account/deactivation/DEACTIVATE_ERROR";

export function receiveDeactivationStatus(response) {
  return { type: LOAD_DEACTIVATION_STATUS_RESPONSE, response };
}

export function requestDeactivationStatus() {
  return { type: LOAD_DEACTIVATION_STATUS_REQUEST };
}

export function requestSelfDeactivation() {
  return { type: DEACTIVATE_REQUEST };
}

export function receiveSelfDeactivation(response) {
  return { type: DEACTIVATE_RESPONSE, response };
}

export function receiveSelfDeactivationError(response) {
  return { type: DEACTIVATE_ERROR, response };
}

function fetchDeactivationStatus() {
  return dispatch => {
    dispatch(requestDeactivationStatus());
    return xhr
      .get(ACCOUNT_DEACTIVATION)
      .then(response => dispatch(receiveDeactivationStatus(response.data)));
  };
}

function shouldFetchDeactivationStatus(state) {
  if (!state.account.deactivation.data) return true;
  if (state.account.deactivation.isLoading) return false;
}

export function fetchDeactivationStatusIfNeeded() {
  return (dispatch, getState) => {
    if (shouldFetchDeactivationStatus(getState())) {
      return dispatch(fetchDeactivationStatus());
    }
  };
}

export function selfDeactivate() {
  return (dispatch, getState) => {
    dispatch(requestSelfDeactivation());
    return xhr
      .post(ACCOUNT_DEACTIVATE)
      .then(response => {
        dispatch(receiveSelfDeactivation(response.data));
        window.location = internalPath("/login");
      })
      .catch(e => console.error(e));
  };
}

const initialState = {
  data: undefined,
  error: undefined,
  isLoading: false,
};

export default function reducer(state = initialState, action = { type: null }) {
  if (!action) return state;
  switch (action.type) {
    case LOAD_DEACTIVATION_STATUS_REQUEST:
      return { ...state, isLoading: true };
    case LOAD_DEACTIVATION_STATUS_RESPONSE:
      return {

        ...state, isLoading: false, data: action.response
      };
    case LOAD_DEACTIVATION_STATUS_ERROR:
      return state;
    case DEACTIVATE_REQUEST:
      return { ...state, isLoading: true };
    case DEACTIVATE_RESPONSE:
      return {

        ...state, isLoading: false, data: action.response
      };
    case DEACTIVATE_ERROR:
      return state;
    default:
      return state;
  }
}
