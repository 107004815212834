import { findIndex } from "lodash";
import {
  fireRecommendationFilterMetric,
  SCHEDULE_FILTER_CHANGED_EVENT_NAME,
} from "build/redux/modules/metrics";

const initialState = {
  loadingData: false,
  recommendationDateRange: [],
  filterOptions: [],
  activeDateKey: "",
  previousActiveKey: "",
  calendarData: {
    title: "",
    dayNames: [],
    rows: [],
    dates: [],
    activeTimeRanges: {},
  },
  activeTimeRanges: {},
  offsetSeconds: 0,
  selectedScheduleDate: "",
  dateFilterOptions: [],
  todayEnabled: false,
  timeRangeOptions: [],
};

const actionMaker = (actionName) => `build/calendar/${actionName}`;
const FETCH_CALENDAR = actionMaker("FETCH_CALENDAR");
const FETCH_CALENDAR_RESPONSE = actionMaker("FETCH_CALENDAR_RESPONSE");
const FETCH_CALENDAR_ERROR = actionMaker("FETCH_CALENDAR_ERROR");
export const SET_RECO_DATE_TIME_RANGE = actionMaker("SET_RECO_DATE_TIME_RANGE");
const SELECT_PREVIOUS_DATE_OPTION = actionMaker("SELECT_PREVIOUS_DATE_OPTION");
const SELECT_SCHED_DATE = actionMaker("SELECT_SCHED_DATE");

export default function calendarReduce(state = initialState, action) {
  switch (action.type) {
    case FETCH_CALENDAR:
      return { ...state, loadingData: true };
    case FETCH_CALENDAR_RESPONSE: {
      const {
        calendarData,
        dateFilterOptions,
        timeRangeOptions,
        todayEnabled,
      } = action;
      return {
        ...state,
        calendarData,
        dateFilterOptions,
        timeRangeOptions,
        todayEnabled,
        loadingData: false,
      };
    }
    case FETCH_CALENDAR_ERROR:
      return { ...state, loadingData: false };
    case SET_RECO_DATE_TIME_RANGE: {
      const newState = {
        ...state,
        recommendationDateRange: action.dateRange,
        activeDateKey: action.key,
        offsetSeconds: action.offsetSeconds,
        activeTimeRanges: action.activeTimeRanges,
      };

      if (!action.isCalendar) {
        newState.previousActiveKey = action.key;
      }

      return newState;
    }
    case SELECT_PREVIOUS_DATE_OPTION:
      return {
        ...state,
        activeDateKey: state.previousActiveKey,
      };

    case SELECT_SCHED_DATE:
      return {
        ...state,
        selectedScheduleDate: action.date,
      };

    default:
      return state;
  }
}

export const selectScheduleDate = (date) => ({
  type: SELECT_SCHED_DATE,
  date,
});

export const updateRecoDateTimeRange = ({
  activeDateKey,
  dateRange,
  isCalendar = false,
  offsetSeconds = 0,
  activeTimeRanges = {},
}) => {
  return {
    type: SET_RECO_DATE_TIME_RANGE,
    key: activeDateKey,
    dateRange,
    isCalendar,
    offsetSeconds,
    activeTimeRanges,
  };
};

export const fetchCalendar = () => ({
  type: FETCH_CALENDAR,
});

export const fetchCalendarError = () => ({
  type: FETCH_CALENDAR_ERROR,
});

const fetchCalendarResponse = (
  calendarData,
  dateFilterOptions,
  timeRangeOptions,
  todayEnabled
) => ({
  type: FETCH_CALENDAR_RESPONSE,
  calendarData,
  dateFilterOptions,
  timeRangeOptions,
  todayEnabled,
});

/**
 * if one of the first 3 out of 4 keys is given, the preloaded payload is set
 * start & end will be omitted in this case
 * if key is calendar_data, dates array is sliced for range
 * @param {string} key to find preloaded dates
 * @param {number} start index for the dates array
 * @param {number} end index for the dates array
 */
export const setActiveDateKey = (
  key,
  start = 0,
  end = 0,
  initialLoad = false
) => (dispatch, getState) => {
  const { calendarData, dateFilterOptions } = getState().build.calendar;
  const activeOption = dateFilterOptions.find((option) => option.key === key);
  const isCalendar = activeOption.key === "choose_dates";
  const dateRange = isCalendar
    ? calendarData.dates.slice(start, end + 1)
    : activeOption.dates;

  const scheduleDates = dateRange.map(({ date }) => date);

  const metricsParams = {
    schedule_filter: key,
    schedule_dates: scheduleDates,
    badge_filters: ["none"],
    day_time_ranges: [],
    offsetSeconds: 0,
  };

  if (!initialLoad) {
    dispatch(
      fireRecommendationFilterMetric(
        SCHEDULE_FILTER_CHANGED_EVENT_NAME,
        metricsParams
      )
    );
  }

  dispatch(
    updateRecoDateTimeRange({
      activeDateKey: key,
      dateRange,
      isCalendar,
    })
  );
};

/**
 * Fetch calendar data and filter options
 * Set first option to default date range
 * @param {cb} dispatch
 * @returns {promise}
 */
export const handleCalendarResponse = (
  dispatch,
  todayEnabled,
  { calendarData, filterOptions, defaultFilterKey, timeRangeOptions },
  fromBoostrapCalander = false
) => {
  const defaultFilterOptionIndex = filterOptions.findIndex(
    (option) => option.key === defaultFilterKey
  );

  dispatch(
    fetchCalendarResponse(
      calendarData,
      filterOptions,
      timeRangeOptions,
      todayEnabled
    )
  );
  if (fromBoostrapCalander) {
    const todayIndex = findIndex(calendarData.dates, { sameday: true });
    dispatch(
      setActiveDateKey("choose_dates", todayIndex + 1, todayIndex + 1, true)
    );
  } else {
    dispatch(
      setActiveDateKey(
        filterOptions[defaultFilterOptionIndex].key,
        null,
        null,
        true
      )
    );
  }
};
