import React, { useEffect } from "react";
import { render } from "react-dom";
import { connect } from "react-redux";
import {
  injectIntl,
  FormattedHTMLMessage as RIFormattedHTMLMessage,
  IntlProvider,
} from "react-intl";
import { expireAlert, init } from "alerts/redux/modules/alerts";
import { notificationPollingInitialize } from "dashboard/redux/modules/eventPolling";

function renderAlert(alert) {
  return (
    <Alert
      key={alert.id}
      alertId={alert.id}
      type={alert.type}
      isFlash={alert.isFlash}
      skipI18n={alert.skipI18n}
      intl={alert.intl}
      messages={alert.messages}
      values={alert.values}
      close={alert.closeAlert}
    />
  );
}

function renderAlerts(alerts, closeAlert, intl) {
  const [node] = document.getElementsByClassName("react-alert--container");
  const alertElements = alerts.map((alert) =>
    renderAlert({ ...alert, closeAlert, intl })
  );
  render(<div>{alertElements}</div>, node);
}

const AlertContainer = (props) => {
  const { alerts, expireAlert: closeAlert, intl } = props;

  useEffect(() => {
    props.init();
    props.notificationPollingInitialize();
  }, []);

  useEffect(() => {
    renderAlerts(alerts, closeAlert, intl);
  }, [alerts]);

  return null;
};

function mapStateToProps(state) {
  return {
    alerts: state.alerts.alerts,
  };
}

export default connect(mapStateToProps, {
  expireAlert,
  init,
  notificationPollingInitialize,
})(injectIntl(AlertContainer));

const Alert = ({
  intl,
  alertId,
  skipI18n,
  isFlash,
  close,
  type,
  messages,
  values,
}) => {
  const closeAlert = () => {
    close(alertId);
  };

  return (
    <IntlProvider
      locale={intl.locale}
      messages={intl.messages}
      textComponent="span"
    >
      <div
        className={`alert alert-${type}`}
        tabIndex="0"
        role="button"
        onClick={closeAlert}
        onKeyDown={closeAlert}
      >
        <ul>
          {messages.map((msg) => {
            if (skipI18n === true) {
              return <li key={msg}>{msg}</li>;
            }
            if (isFlash === true) {
              // Translated server-side. Translations will be moved to react
              // in a future PR.
              return <li key={msg} dangerouslySetInnerHTML={{ __html: msg }} />;
            }
            return (
              <li key={msg}>
                <RIFormattedHTMLMessage id={msg} values={values} />
              </li>
            );
          })}
        </ul>
      </div>
    </IntlProvider>
  );
};
