import xhr from "util/xhr";
import qs from "qs";
import { Observable } from "rxjs";
import { FETCH_RABBIT_REVIEWS } from "build/routes/apiRoutes";
import { combineEpics } from "redux-observable";
import range from "lodash/range";

const initialState = {
  page: 1,
  perPage: 5,
  byTasker: {},
  filter: "all",
};

const INITIALIZE = "v3/build/reviews/INITIALIZE";
const CHANGE_REVIEW_PAGE = "v3/build/reviews/CHANGE_REVIEW_PAGE";
const FETCH_REVIEWS = "v3/build/reviews/FETCH_REVIEWS";
const FETCH_REVIEWS_RESPONSE = "v3/build/reviews/FETCH_REVIEWS_RESPONSE";
const FETCH_REVIEWS_ERROR = "v3/build/reviews/FETCH_REVIEWS_ERROR";
const CHANGE_PAGE_DATA_LOADED = "v3/build/reviews/CHANGE_PAGE_DATA_LOADED";
const SET_REVIEW_FILTER = "v3/build/reviews/SET_REVIEW_FILTER";

const FETCH_REVIEWS_ACTIONS = [
  INITIALIZE,
  SET_REVIEW_FILTER,
  CHANGE_REVIEW_PAGE,
];

export default function recommendationReviews(state = initialState, action) {
  switch (action.type) {
    case INITIALIZE:
      return {
        ...state,
        selectedTaskerId: action.selectedTaskerId,
        page: 1,
        perPage: action.perPage,
      };
    case FETCH_REVIEWS:
      return { ...state, loadingReviews: true };

    case FETCH_REVIEWS_RESPONSE:
      const { page, perPage, byTasker: { [action.id]: tasker = {} } } = state;
      const oldItems = tasker[action.filter] ? tasker[action.filter].items : [];
      const cacheInvalidatedByNewReviews =
        tasker[action.filter] &&
        tasker[action.filter].totalItems !== action.data.total_items;
      const startRange = (page - 1) * perPage;
      const endRange = page * perPage;
      const indicesToReplace = range(startRange, endRange);

      const newItems = Array.apply(null, Array(action.data.total_items)).map(
        (item, index) => {
          return indicesToReplace.includes(index)
            ? action.data.items.shift()
            : cacheInvalidatedByNewReviews ? undefined : oldItems[index];
        }
      );

      return {
        ...state,
        loadingReviews: false,
        byTasker: {
          [action.id]: {
            ...state.byTasker[action.id],
            [action.filter]: {
              totalItems: action.data.total_items,
              items: newItems,
            },
          },
        },
      };

    case SET_REVIEW_FILTER:
      return { ...state, page: 1, filter: action.filter };

    case CHANGE_REVIEW_PAGE:
      return { ...state, page: action.page };

    case CHANGE_PAGE_DATA_LOADED:
      return { ...state, loadingReviews: false };

    default:
      return state;
  }
}

export const initialize = ({ selectedTaskerId, perPage }) => ({
  type: INITIALIZE,
  selectedTaskerId,
  perPage,
});

export const changePage = (id, page = 1) => ({
  type: CHANGE_REVIEW_PAGE,
  id,
  page,
});

export const setReviewFilter = filter => ({
  type: SET_REVIEW_FILTER,
  filter,
});

const fetchReviews = (id, page, getState) => {
  const state = getState();
  const { category_id } = state.build.manager.job;
  const {
    byTasker: { [id]: tasker = {} },
    perPage: per_page,
    filter,
  } = state.build.reviews;

  const highlight_category_id = category_id;

  let fetchNeeded;

  if (tasker[filter] && tasker[filter].items) {
    const thisPageItems = tasker[filter].items.slice(
      (page - 1) * per_page,
      (page - 1) * per_page + per_page
    );
    fetchNeeded = thisPageItems.some(item => !item); // some items are undefined
  } else {
    fetchNeeded = true;
  }

  if (fetchNeeded) {
    const url = FETCH_RABBIT_REVIEWS.replace(":rabbit_id", id);
    const data = {
      highlight_category_id,
      with_message: true,
      default_all: true,
      page,
      per_page,
    };

    if (filter !== "all") data.category_id = filter;

    return xhr.get(`${url}?${qs.stringify(data)}`).then(({ data }) => ({
      type: FETCH_REVIEWS_RESPONSE,
      data,
      id,
      filter,
    }));
  } 
    return Promise.resolve({ type: CHANGE_PAGE_DATA_LOADED });
  
};

const fetchReviewPageEpic = (action$, { getState }) =>
  action$
    .filter(({ type }) => FETCH_REVIEWS_ACTIONS.includes(type))
    .switchMap(() => {
      const { selectedTaskerId, page } = getState().build.reviews;
      return Observable.concat(
        [{ type: FETCH_REVIEWS }],
        fetchReviews(selectedTaskerId, page, getState).catch(() => ({
          type: FETCH_REVIEWS_ERROR,
        }))
      );
    });

export const reviewsEpic = combineEpics(fetchReviewPageEpic);
