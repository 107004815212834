import { useState, useEffect } from "react";

/**
 * This hook is meant to address rehydration issues in Gatsby. The readings
 * below explain what the issue is, and how this hook can be used to fix it.
 *
 * https://blog.logrocket.com/fixing-gatsbys-rehydration-issue/
 * https://github.com/gatsbyjs/gatsby/issues/14601#issuecomment-499922794
 * https://joshwcomeau.com/react/the-perils-of-rehydration/
 * https://www.gatsbyjs.com/docs/debugging-html-builds/
 */
export function useIsClient() {
  const [isClient, setClient] = useState(false);
  const isClientKey = isClient ? "client" : "server";

  useEffect(() => {
    setClient(true);
  }, []);

  return { isClient, isClientKey };
}
