/* DEPRECATED - TODO: SWITCH ANYTHING USING THIS TO BOOTSTRAP */

import { CURRENT_PROMO_API } from "currentPromo/routes/apiRoutes";
import xhr, { createApiErrorAlert } from "util/xhr";
import isEmpty from "lodash/isEmpty";

const LOAD_CURRENT_PROMO_REQUEST = "currentPromo/LOAD_CURRENT_PROMO_REQUEST";
const LOAD_CURRENT_PROMO_RESPONSE = "currentPromo/LOAD_CURRENT_PROMO_RESPONSE";
const LOAD_CURRENT_PROMO_ERROR = "currentPromo/LOAD_CURRENT_PROMO_ERROR";

function requestPromo() {
  return { type: LOAD_CURRENT_PROMO_REQUEST };
}

function receivePromo(response) {
  return { type: LOAD_CURRENT_PROMO_RESPONSE, response };
}

function errorPromo(error) {
  return { type: LOAD_CURRENT_PROMO_ERROR, error };
}

function fetchPromo() {
  return dispatch => {
    dispatch(requestPromo());
    return xhr.get(CURRENT_PROMO_API).then(
      response => {
        dispatch(receivePromo(response.data));
      },
      e => {
        dispatch(createApiErrorAlert(e));
        dispatch(errorPromo());
      }
    );
  };
}

function shouldFetchPromo(state) {
  if (!state.currentPromo.data) return true;
  if (state.currentPromo.isLoading) return false;
}

export function fetchPromoIfNeeded() {
  return (dispatch, getState) => {
    if (shouldFetchPromo(getState())) {
      return dispatch(fetchPromo());
    }
  };
}

export default function reducer(state = {}, action) {
  switch (action.type) {
    case LOAD_CURRENT_PROMO_REQUEST:
      return { ...state, isLoading: true };

    case LOAD_CURRENT_PROMO_RESPONSE:
      const promo = isEmpty(action.response) ? null : action.response;
      return { ...state, isLoading: false, data: promo };

    default:
      return state;
  }
}
