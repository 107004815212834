export enum buildPages {
  form = "form",
  chooseDateTime = "choose-date-time",
  dateTime = "date-time",
  hire = "hire",
  book = "book",
  recommendations = "recommendations",
  confirm = "confirm",
  quote = "quote",
  repost = "repost",
  affiliate = "affiliate",
}
