import React from "react";
import styled from "styled-components";
import { headerNavContentHeight } from "./header-constants";
import TaskRabbitLogo from "./Header/TaskRabbitLogo";

export function Header({
  children,
  dontShowLinks,
  isInsideReactRouter = false,
  onHamburgerKeyDown,
  toggleMobile,
  routing,
}) {
  return (
    <NavHeader>
      <WideContainer>
        <ClearFix>
          {!dontShowLinks ? (
            <HamburgerMenu
              role="button"
              tabIndex="0"
              className="ss-lnr-menu"
              onClick={toggleMobile}
              onKeyDown={onHamburgerKeyDown}
            />
          ) : null}

          <TaskRabbitLogo
            routing={routing}
            isInsideReactRouter={isInsideReactRouter}
          />

          {!dontShowLinks ? (
            <DesktopLinksContainer>{children}</DesktopLinksContainer>
          ) : null}
        </ClearFix>
      </WideContainer>
    </NavHeader>
  );
}

const ClearFix = styled.div`
  &:after {
    content: "";
    clear: both;
    display: table;
  }
`;

const WideContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  padding-left: 4%;
  padding-right: 4%;

  ${(props) => props.theme.breakpoints.tabletUp} {
    padding-left: 2%;
    padding-right: 2%;
  }

  ${(props) => props.theme.breakpoints.xlDesktopUp} {
    padding-left: 0;
    padding-right: 0;
  }
`;

const NavHeader = styled.header`
  border-bottom: 1px solid ${(props) => props.theme.color.border};
  position: relative;
  padding-top: 12px;
  padding-bottom: 12px;
  background-color: white;
`;

const HamburgerMenu = styled.i`
  font-size: ${(props) => props.theme.typography.typeScale["500"]};
  color: ${(props) => props.theme.color.buttonPrimary};
  float: right;
  line-height: ${headerNavContentHeight};
  ${(props) => props.theme.breakpoints.desktopUp} {
    display: none;
  }
  cursor: pointer;
`;

const DesktopLinksContainer = styled.div`
  display: none;
  ${(props) => props.theme.breakpoints.desktopUp} {
    display: block;
    float: right;
  }
`;
