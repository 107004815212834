import { AnyAction, Dispatch } from "redux";
import { defineMessages } from "react-intl";
import { createAlert } from "alerts/redux/modules/alerts";
import { fetchSingleTask } from "dashboard/redux/modules/taskLists";
import {
  FEEDBACK_REQUEST_ENDPOINT,
  addTipFeedbackEndpoint,
} from "feedback/routes/apiRoutes";
import { ACCOUNT_PROFILE } from "account/routes/apiRoutes";
import { fireMetric } from "store/middleware/metricMiddleware";
import { internalPath } from "util/internalPath";
import { snake_caseKeys } from "util/casing";
import xhr, { createApiErrorAlert } from "util/xhr";

const messages = defineMessages({
  success: {
    id: "feedback_request.tips.tip_added_success",
    defaultMessage: "Thanks, your tip has been added",
  },
  genericError: {
    id: "feedback_request.tip.generic_error",
    defaultMessage: "Oops! We couldn’t find this task. Please try again later.",
  },
});

export const BOOTSTRAP_FEEDBACK_TIP_FORM =
  "v3/feedback/posterFeedbackTipTasker/BOOTSTRAP_TIP_FORM";
export const FETCH_FEEDBACK_TIP_DATA =
  "v3/feedback/posterFeedbackTipTasker/FETCH_TIP_DATA";
export const FETCH_FEEDBACK_TIP_DATA_RESPONSE =
  "v3/feedback/posterFeedbackTipTasker/FETCH_TIP_DATA_RESPONSE";
export const FETCH_FEEDBACK_TIP_DATA_ERROR =
  "v3/feedback/posterFeedbackTipTasker/FETCH_TIP_DATA_ERROR";
export const ADD_FEEDBACK_TIP = "v3/feedback/posterFeedbackTipTasker/ADD_TIP";
export const ADD_FEEDBACK_TIP_RESPONSE =
  "v3/feedback/posterFeedbackTipTasker/ADD_TIP_RESPONSE";
export const ADD_FEEDBACK_TIP_ERROR =
  "v3/feedback/posterFeedbackTipTasker/ADD_TIP_ERROR";
export const FETCH_FEEDBACK_JOB_DATA_RESPONSE =
  "v3/feedback/posterFeedbackTipTasker/FETCH_FEEDBACK_JOB_DATA_RESPONSE";
export const FETCH_PAYMENT_METHOD_RESPONSE =
  "v3/feedback/posterFeedbackTipTasker/FETCH_PAYMENT_METHOD_RESPONSE";

interface TipFormState {
  currency?: string;
  errorLoading: boolean;
  hasPaymentMethod?: boolean;
  loading: boolean;
  prepaid?: boolean;
  requestReceived: boolean;
  selectedAmountCents?: number;
  selectedStrategy?: string | null;
  submitting: boolean;
  taskId?: number;
  taskerId?: number;
  tipState?: string;
  tippableAmountCents?: number;
  taskDetails?: {
    categoryId: number;
    title: string;
    metroId: number;
    metro: string;
  }
}

const initialState: TipFormState = {
  requestReceived: false,
  errorLoading: false,
  submitting: false,
  loading: false,
};

const processResponse = (state: TipFormState, action: AnyAction) => {
  const { data } = action;
  return {
    ...state,
    currency: data.taskerTip.currency,
    errorLoading: false,
    expiresAt: data.taskerTip.expiresAt,
    loading: false,
    requestReceived: true,
    selectedAmountCents: data.taskerTip.selectedAmountCents,
    submitting: false,
    taskId: data.taskId,
    taskerId: data.taskerId,
    tipState: data.taskerTip.state,
    tippableAmountCents: data.taskerTip.tippableAmountCents,
  };
};

const processJobResponse = (state: TipFormState, action: AnyAction) => {
  const { details } = action.data.tasks[0]
  return {
    ...state,
    prepaid: details.prepaid,
    taskDetails: details,
  };
};

const fetchJobDataResponse = (data: Record<string, unknown>) => ({
  type: FETCH_FEEDBACK_JOB_DATA_RESPONSE,
  data,
});
const fetchPaymentMethodResponse = (data: boolean) => ({
  type: FETCH_PAYMENT_METHOD_RESPONSE,
  data,
});

const fetchTipDataResponse = (data: Record<string, unknown>) => ({
  type: FETCH_FEEDBACK_TIP_DATA_RESPONSE,
  data,
});

const fetchTipDataError = () => ({
  type: FETCH_FEEDBACK_TIP_DATA_ERROR,
});

const addTip = () => ({
  type: ADD_FEEDBACK_TIP,
});

const addTipResponse = (response: Record<string, unknown>) => ({
  type: ADD_FEEDBACK_TIP_RESPONSE,
  response,
});

const addTipError = () => ({
  type: ADD_FEEDBACK_TIP_ERROR,
});

// eslint-disable-next-line @typescript-eslint/default-param-last
export default function tip(state = initialState, action: AnyAction) {
  switch (action.type) {
    case FETCH_FEEDBACK_TIP_DATA:
      return { ...state, loading: true };
    case FETCH_FEEDBACK_TIP_DATA_RESPONSE:
      if (!action.data) return { ...state, errorLoading: true };
      return processResponse(state, action);
    case FETCH_FEEDBACK_TIP_DATA_ERROR:
      return { ...state, loading: false, errorLoading: true };
    case ADD_FEEDBACK_TIP:
      return { ...state, submitting: true };
    case ADD_FEEDBACK_TIP_ERROR:
      return { ...state, submitting: false };
    case ADD_FEEDBACK_TIP_RESPONSE:
      if (!action.data) return { ...state };
      return processResponse(state, action);
    case FETCH_FEEDBACK_JOB_DATA_RESPONSE:
      if (!action.data) return { ...state };
      return processJobResponse(state, action);
    case FETCH_PAYMENT_METHOD_RESPONSE:
      return { ...state, hasPaymentMethod: action.data };
    default:
      return state;
  }
}

export const preBootstrapError = () => (dispatch: Dispatch) => {
  dispatch(fetchTipDataError());
};

export const bootstrapTipForm =
  (feedbackRequestId: string) => async (dispatch: Dispatch) => {
    dispatch({
      type: FETCH_FEEDBACK_TIP_DATA,
      feedbackRequestId,
    });
    try {
      const { camelCasedData: data } = await xhr.get(
        FEEDBACK_REQUEST_ENDPOINT + "/" + feedbackRequestId
      );
      dispatch(fetchTipDataResponse(data));
    } catch {
      dispatch(fetchTipDataError());
    }
  };

interface submitTipProps {
  currency: string;
  selectedAmountCents: number;
  selectedStrategy: string | null;
  taskId: number;
  feedbackRequestId: string;
}

export const submitTip =
  ({
    currency,
    selectedAmountCents,
    selectedStrategy,
    taskId,
    feedbackRequestId,
  }: submitTipProps) =>
  async (dispatch: Dispatch) => {
    dispatch(addTip());
    const closedReason = "client_submitted";
    const url = addTipFeedbackEndpoint(feedbackRequestId);
    const submissionData = snake_caseKeys({
      selectedStrategy,
      selectedAmountCents,
      closedReason,
      currency,
    });
    const metricData = snake_caseKeys({
      taskId,
      selectedStrategy,
      selectedAmountCents,
      closedReason,
      currency,
    });
    dispatch(fireMetric("poster_left_tip_for_tasker", metricData));
    return xhr
      .patch(url, submissionData)
      .then(({ camelCasedData: data }) => {
        dispatch(addTipResponse(data));
        if (selectedAmountCents) {
          dispatch(createAlert(messages.success.id));
        }
        window.location.href = internalPath("/dashboard/active");
      })
      .catch((error) => {
        dispatch(createApiErrorAlert(error));
        dispatch(addTipError());
      });
  };
export const fetchTask =
  ({ taskId, taskerId }: { taskId: number; taskerId: number }) =>
  async (dispatch: Dispatch) => {
    return fetchSingleTask(taskId, taskerId).then((item) => {
      dispatch(fetchJobDataResponse(item));
    });
  };
export const fetchClientPaymentMethod = () => async (dispatch: Dispatch) => {
  const { camelCasedData: data } = await xhr.get(ACCOUNT_PROFILE);
  const { paymentMethods, paymentMethodId } = data;
  const hasPaymentMethodStored = paymentMethods.some(
    (method: { paymentMethodType: string }) =>
      ["card", "digital_wallet", "SEPA"].includes(method.paymentMethodType)
  );
  const hasPaymentMethod = hasPaymentMethodStored || !!paymentMethodId;
  dispatch(fetchPaymentMethodResponse(hasPaymentMethod));
};
