import React, { useCallback, useEffect, useState } from "react";
import { defineMessages, injectIntl } from "react-intl";
import { Row, Col } from "components/Grid";
import countryIsoCodeFromLocale from "util/countryIsoCodeFromLocale";
import { IbanElement } from "@stripe/react-stripe-js";
import { theme } from "components/theme";

const messages = defineMessages({
  accountNameHolderPlaceholder: {
    id: "payments.stripe_iban_form.account_name_holder_placeholder",
    defaultMessage: "Account holder name",
  },
  emailPlaceholder: {
    id: "payments.stripe_iban_form.email_placeholder",
    defaultMessage: "Email address",
  },
});

const StripeIbanForm = ({
  handlePaymentMethodChange,
  setBillingDetails,
  debitAuthorizationMandate,
  billingDetails: { name, email },
  intl: { locale, formatMessage },
}) => {
  const [ibanFieldValid, setIbanFieldValid] = useState(false);

  const onChange = useCallback(
    (ibanStatus) => setIbanFieldValid(ibanStatus.complete),
    [setIbanFieldValid]
  );

  useEffect(() => {
    const formIsValid = name && email && ibanFieldValid;
    handlePaymentMethodChange(formIsValid);
  }, [name, email, ibanFieldValid, handlePaymentMethodChange]);

  const onBillingDetailChange = useCallback(
    (event) => {
      const newBillingDetails = {
        name,
        email,
        [event.target.getAttribute("name")]: event.target.value,
      };
      setBillingDetails(newBillingDetails);
    },
    [setBillingDetails, email, name]
  );

  const ibanElementOptions = {
    supportedCountries: ["SEPA"],
    placeholderCountry: countryIsoCodeFromLocale(locale),
    style: {
      base: {
        "::placeholder": {
          color: theme.color.subduedText,
        },
      },
    },
  };

  return (
    <div data-testid="sepa_debit_form">
      <Row guttered={true}>
        <Col size={12} lg={6}>
          <input
            type="text"
            name="name"
            placeholder={formatMessage(messages.accountNameHolderPlaceholder)}
            aria-label={formatMessage(messages.accountNameHolderPlaceholder)}
            value={name}
            onChange={onBillingDetailChange}
            required={true}
            className="stripe__billing-info-input"
          />
        </Col>
        <Col size={12} lg={6}>
          <input
            type="email"
            name="email"
            placeholder={formatMessage(messages.emailPlaceholder)}
            aria-label={formatMessage(messages.emailPlaceholder)}
            value={email}
            onChange={onBillingDetailChange}
            required={true}
            className="stripe__billing-info-input"
          />
        </Col>
      </Row>
      <IbanElement
        className="stripe__iban-input"
        onChange={onChange}
        options={ibanElementOptions}
      />
      <div className="stripe__iban-mandate">{debitAuthorizationMandate}</div>
    </div>
  );
};
export default injectIntl(StripeIbanForm);
