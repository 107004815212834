import React from "react";
import { injectIntl } from "react-intl";
import { useBadgeImageData } from "./StoreBadge/useBadgeImageData";
import { calculateBadgeDimensions } from "./StoreBadge/calculateBadgeDimensions";
import { useBadgeLinkData } from "./StoreBadge/useBadgeLinkData";

const storeTypes = ["appStore", "playStore"];

function StoreBadgeBase({
  isAppCard,
  height,
  intl: { formatMessage },
  locale,
  onClick,
  storeType,
  width,
}) {
  if (!storeTypes.includes(storeType)) {
    throw Error(
      `Unknown store type: ${storeType}. Expected one of ${storeTypes}.`
    );
  }

  const { imgSrc, aspectRatio, testid } = useBadgeImageData({
    storeType,
    locale,
  });
  const { imgAlt, storeHref } = useBadgeLinkData({ formatMessage, storeType });

  let desktopStoreHref = storeHref;

  if (isAppCard) {
    if (storeType === "appStore")  {
      desktopStoreHref += '&af_r=https://apps.apple.com/us/app/taskrabbit-handyman-more/id374165361';
    } else if (storeType === "playStore") {
      desktopStoreHref += '&af_r=https://play.google.com/store/apps/details?id=com.taskrabbit.droid.consumer';
    }
  }

  const { badgeWidth, badgeHeight } = calculateBadgeDimensions({
    width,
    height,
    aspectRatio,
  });

  return (
    <a href={isAppCard ? desktopStoreHref : storeHref} onClick={onClick} target="_blank" rel="noopener">
      <img
        data-testid={testid}
        src={imgSrc}
        alt={imgAlt}
        width={badgeWidth}
        height={badgeHeight}
      />
    </a>
  );
}

export const StoreBadge = injectIntl(StoreBadgeBase);
