import PropTypes from "prop-types";
import React, { Component } from "react";
import classnames from "classnames";

class Row extends Component {
  render() {
    const {
      children,
      thin,
      guttered,
      className,
      ...remainingProps
    } = this.props;

    return (
      <div
        className={classnames({
          row: true,
          "row--thin": thin,
          "row--guttered": guttered,
          [className]: !!className,
        })}
        {...remainingProps}
      >
        {children}
      </div>
    );
  }
}

Row.propTypes = {
  thin: PropTypes.bool,
  guttered: PropTypes.bool,
  className: PropTypes.string,
};

export default Row;
