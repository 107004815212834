import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { defineMessages, useIntl } from "react-intl";
import { internalPath } from "../../../../util/internalPath";
import { headerNavContentHeight } from "../header-constants";
import TRLogo from "components/Logos/TRLogo";

const messages = defineMessages({
  taskRabbitDashboard: {
    id: "HeaderNav.Header.TaskRabbitLogo.task_rabbit_dashboard",
    defaultMessage: "TaskRabbit Dashboard",
  },
  taskRabbitHome: {
    id: "HeaderNav.Header.TaskRabbitLogo.task_rabbit_home",
    defaultMessage: "TaskRabbit Home",
  },
});

export default function TaskRabbitLogo({ isInsideReactRouter, routing }) {
  const { formatMessage } = useIntl();
  const baseUrlIsDashboard = routing.base_url === "/dashboard";

  if (isInsideReactRouter && baseUrlIsDashboard) {
    return (
      <Link
        to={internalPath("/explore")}
        aria-label={formatMessage(messages.taskRabbitDashboard)}
      >
        <Logo />
      </Link>
    );
  }

  return (
    <a
      href={internalPath("/")}
      aria-label={formatMessage(messages.taskRabbitHome)}
    >
      <Logo />
    </a>
  );
}

const Logo = styled(TRLogo)`
  vertical-align: bottom;
  height: calc(${headerNavContentHeight} * 0.9);

  ${(p) => p.theme.breakpoints.mobile} {
    height: calc(${headerNavContentHeight} * 0.7);
    margin: calc(${headerNavContentHeight} * 0.15) 0;
  }
`;
