import { snakeCase } from "snake-case";
import stringExpand from "./rubyStringExpand";

// given data (i.e. such as profile) and size, tells the
// correct profile pic that should be used.

function isRetina() {
  return window.devicePixelRatio > 1;
}

export function avatar(data, size) {
  if (!data) throw Error("trying to use Avatar without passing data.");
  let path;

  if (size) {
    if (isRetina()) path = path || data["avatar_url_" + size + "_2x"];
    path = path || data["avatar_url_" + size];
  }

  if (isRetina()) path = path || data.avatar_url_2x;

  path = path || data.avatar_url;
  return path;
}

export function avatarTemplate(data, pixels) {
  if (!data) throw Error("trying to use avatarTemplate without passing data.");
  let localPixels = pixels;
  if (isRetina()) localPixels *= 2;
  return stringExpand(
    data.avatar_url_template || data.profile_photo_url_template,
    { localPixels }
  );
}

export function avatarTemplateCamelCase(data, pixels) {
  if (!data) throw Error("trying to use avatarTemplate without passing data.");
  let localPixels = pixels;
  if (isRetina()) localPixels *= 2;
  const imageUrl = data.avatarUrlTemplate || data.profilePhotoUrlTemplate;
  return stringExpand(imageUrl, { pixels: localPixels });
}

export const avatarCamelCased = (data, size) => {
  const snakeData = Object.entries(data).reduce((casedObj, [k, v]) => {
    casedObj[snakeCase(k)] = v;
    return casedObj;
  }, {});
  return avatar(snakeData, size);
};
