import * as API from "account/routes/apiRoutes";
import { push } from "connected-react-router";
import xhr, { createApiErrorAlert } from "util/xhr";
import { defineMessages } from "react-intl";
import { createAlert } from "alerts/redux/modules/alerts";
import { TrMetricsService } from "services/TrMetricsService";
import { VERIFY_ACTIVE_METRO } from "build/routes/apiRoutes";
import { geocodeLocation } from "build/redux/modules/form/validation";

const messages = defineMessages({
  addressUpdated: {
    id: "alerts.address_changed",
    defaultMessage: "Default address successfully updated.",
  },
  addressCreated: {
    id: "alerts.address_created",
    defaultMessage: "Default address successfully added.",
  },
});

const SAVE_ADDRESS = "SAVE_ADDRESS";
const SAVE_ADDRESS_EXTRA = "SAVE_ADDRESS_EXTRA";
const UPDATE_ADDRESS_REQUEST = "UPDATE_ADDRESS_REQUEST";
const UPDATE_ADDRESS_RESPONSE = "UPDATE_ADDRESS_RESPONSE";
const UPDATE_ADDRESS_ERROR = "UPDATE_ADDRESS_ERROR";

const LOAD_ADDRESS_REQUEST = "LOAD_ADDRESS_REQUEST";
const LOAD_ADDRESS_ERROR = "LOAD_ADDRESS_ERROR";
const LOAD_ADDRESS_RESPONSE = "LOAD_ADDRESS_RESPONSE";

const REQUEST_ACTIVE_METRO_CHECK = "REQUEST_ACTIVE_METRO_CHECK";
const RESPONSE_ACTIVE_METRO_CHECK = "RESPONSE_CTIVE_METRO_CHECK";

function saveAddress(address) {
  return { type: SAVE_ADDRESS, address };
}
function saveAddressExtra(addressExtra) {
  return { type: SAVE_ADDRESS_EXTRA, addressExtra };
}

function requestSaveAddress() {
  return { type: UPDATE_ADDRESS_REQUEST };
}

function responseSaveAddress(address) {
  return { type: UPDATE_ADDRESS_RESPONSE, address };
}

function requestLoadAddress(setIsLoading, setIsLoadingView) {
  return { type: LOAD_ADDRESS_REQUEST, setIsLoading, setIsLoadingView };
}

function responseLoadAddress(address, setIsLoading, setIsLoadingView) {
  return { type: LOAD_ADDRESS_RESPONSE, address, setIsLoading, setIsLoadingView };
}

function requestActiveMetroCheck() {
  return { type: REQUEST_ACTIVE_METRO_CHECK };
}

function responseActiveMetroCheck(isActiveMetro, hasError) {
  return { type: RESPONSE_ACTIVE_METRO_CHECK, isActiveMetro, hasError };
}

export function saveAddressToStore(address) {
  return (dispatch) => {
    dispatch(saveAddress(address));
  };
}

export function saveAddressExtraToStore(addressExtra) {
  return (dispatch) => {
    dispatch(saveAddressExtra(addressExtra));
  };
}

export function verifyActiveMetro() {
  return async (dispatch, getState) => {
    dispatch(requestActiveMetroCheck());

    const state = getState();
    let params = {
      lat: state.account.addresses.addressGoogle.lat,
      lng: state.account.addresses.addressGoogle.lng,
      address: state.account.addresses.addressGoogle.freeform,
    };

    if (!params.lat && !params.lng) {
      try {
        const coordinates = await geocodeLocation(state.account.addresses.addressGoogle);
        dispatch(saveAddress(coordinates));
        params.lat = coordinates.lat;
        params.lng = coordinates.lng;
      } catch {
        dispatch(responseActiveMetroCheck(false, true));
        return;
      }
    }
    return xhr
    .post(VERIFY_ACTIVE_METRO, params)
      .then((response) => {
        dispatch(responseActiveMetroCheck(response.data.in_service_area, !response.data.in_service_area));
        return response;
      })
      .catch((e) => {
        dispatch(createApiErrorAlert(e));
      });
  };
}

export function saveAddressForm(isNewAddress) {
  return (dispatch, getState) => {
    const state = getState();
    if (!state.account.addresses.hasError) {
      dispatch(requestSaveAddress());
      const params = {
        location: {
          ...state.account.addresses.addressGoogle,
          address2: state.account.addresses.addressExtra
        },
        key: 'home'
      };
      return xhr
        .post(API.ACCOUNT_SET_ADDRESSES, params)
        .then((response) => {
          dispatch(responseSaveAddress(response.data));
          dispatch(push("/addresses"));
          if (isNewAddress) {
            TrMetricsService.fire("account_address_new");
          } else {
            TrMetricsService.fire("account_address_edit");
          }
          
          dispatch(createAlert(messages.addressUpdated.id));
        })
        .catch((e) => {
          dispatch(createApiErrorAlert(e));
        });
    }
    return state.hasError;
   
  };
}

export function loadAddress(userId, setIsLoading, setIsLoadingView) {
  return (dispatch) => {
    dispatch(requestLoadAddress(setIsLoading, setIsLoadingView));
    const params = {
      "key": "home",
      "id": userId
    };

    return xhr.get(API.ACCOUNT_GET_ADDRESSES, { params : params }).then(
      (response) => {
        const responseToDispatch = response.data.items.length === 0 ? {} : response.data.items[0];
        dispatch(responseLoadAddress(responseToDispatch, setIsLoading, setIsLoadingView));
        
        return responseToDispatch;
      },
      (e) => {
        dispatch(createApiErrorAlert(e));
      }
    );
  };
}

const initialState = {
  address: {},
  addressGoogle: {},
  addressExtra: "",
  isLoading: true,
  isLoadingView: true,
  invalidated: false,
  isActiveMetro: false,
  hasError: false,
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SAVE_ADDRESS:
      return {
        ...state,
        isLoading: false,
        addressGoogle: action.address,
        invalidated: false,
        hasError: false,
      };
    case SAVE_ADDRESS_EXTRA:
      return {
        ...state,
        isLoading: false,
        addressExtra: action.addressExtra,
        invalidated: false,
      };
    case LOAD_ADDRESS_REQUEST:
      if (action.setIsLoadingView) {
        return { ...state, isLoadingView: true };
      } else if (!action.setIsLoading) { 
        return { ...state };
      } 
      return { ...state, isLoading: true };
    case LOAD_ADDRESS_RESPONSE:
      if (action.setIsLoadingView) {
        return {
          ...state,
          address: action.address.address,
          invalidated: false,
          addressGoogle: {},
          isLoadingView: false,
          isActiveMetro: false,
          hasError: false,
        };
      } else if (!action.setIsLoading) {
        return {
          ...state,
          address: action.address.address,
          invalidated: false,
          addressGoogle: {},
          isActiveMetro: false,
          hasError: false,
        };
      }
      return {
        ...state,
        isLoading: false,
        address: action.address.address,
        invalidated: false,
        isActiveMetro: false,
        hasError: false,
      };
    case LOAD_ADDRESS_ERROR:
      return {
        ...state,
        isLoading: false,
        invalidated: false,
      };
    case UPDATE_ADDRESS_REQUEST:
      return { ...state };
    case UPDATE_ADDRESS_RESPONSE:
      return {
        ...state,
        address: action.address,
      };
    case UPDATE_ADDRESS_ERROR:
      return { ...state, isLoading: false };
    case REQUEST_ACTIVE_METRO_CHECK:
      return { ...state };
    case RESPONSE_ACTIVE_METRO_CHECK:
      return {
        ...state,
        isActiveMetro: action.isActiveMetro,
        hasError: action.hasError,
      };
    default:
      return state;
  }
}
