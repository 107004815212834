
const emails = {
  "topLevelDomains": {
    "AAA": true,
    "AARP": true,
    "ABARTH": true,
    "ABB": true,
    "ABBOTT": true,
    "ABBVIE": true,
    "ABC": true,
    "ABLE": true,
    "ABOGADO": true,
    "ABUDHABI": true,
    "AC": true,
    "ACADEMY": true,
    "ACCENTURE": true,
    "ACCOUNTANT": true,
    "ACCOUNTANTS": true,
    "ACO": true,
    "ACTIVE": true,
    "ACTOR": true,
    "AD": true,
    "ADAC": true,
    "ADS": true,
    "ADULT": true,
    "AE": true,
    "AEG": true,
    "AERO": true,
    "AETNA": true,
    "AF": true,
    "AFAMILYCOMPANY": true,
    "AFL": true,
    "AFRICA": true,
    "AG": true,
    "AGAKHAN": true,
    "AGENCY": true,
    "AI": true,
    "AIG": true,
    "AIGO": true,
    "AIRBUS": true,
    "AIRFORCE": true,
    "AIRTEL": true,
    "AKDN": true,
    "AL": true,
    "ALFAROMEO": true,
    "ALIBABA": true,
    "ALIPAY": true,
    "ALLFINANZ": true,
    "ALLSTATE": true,
    "ALLY": true,
    "ALSACE": true,
    "ALSTOM": true,
    "AM": true,
    "AMERICANEXPRESS": true,
    "AMERICANFAMILY": true,
    "AMEX": true,
    "AMFAM": true,
    "AMICA": true,
    "AMSTERDAM": true,
    "ANALYTICS": true,
    "ANDROID": true,
    "ANQUAN": true,
    "ANZ": true,
    "AO": true,
    "AOL": true,
    "APARTMENTS": true,
    "APP": true,
    "APPLE": true,
    "AQ": true,
    "AQUARELLE": true,
    "AR": true,
    "ARAB": true,
    "ARAMCO": true,
    "ARCHI": true,
    "ARMY": true,
    "ARPA": true,
    "ART": true,
    "ARTE": true,
    "AS": true,
    "ASDA": true,
    "ASIA": true,
    "ASSOCIATES": true,
    "AT": true,
    "ATHLETA": true,
    "ATTORNEY": true,
    "AU": true,
    "AUCTION": true,
    "AUDI": true,
    "AUDIBLE": true,
    "AUDIO": true,
    "AUSPOST": true,
    "AUTHOR": true,
    "AUTO": true,
    "AUTOS": true,
    "AVIANCA": true,
    "AW": true,
    "AWS": true,
    "AX": true,
    "AXA": true,
    "AZ": true,
    "AZURE": true,
    "BA": true,
    "BABY": true,
    "BAIDU": true,
    "BANAMEX": true,
    "BANANAREPUBLIC": true,
    "BAND": true,
    "BANK": true,
    "BAR": true,
    "BARCELONA": true,
    "BARCLAYCARD": true,
    "BARCLAYS": true,
    "BAREFOOT": true,
    "BARGAINS": true,
    "BASEBALL": true,
    "BASKETBALL": true,
    "BAUHAUS": true,
    "BAYERN": true,
    "BB": true,
    "BBC": true,
    "BBT": true,
    "BBVA": true,
    "BCG": true,
    "BCN": true,
    "BD": true,
    "BE": true,
    "BEATS": true,
    "BEAUTY": true,
    "BEER": true,
    "BENTLEY": true,
    "BERLIN": true,
    "BEST": true,
    "BESTBUY": true,
    "BET": true,
    "BF": true,
    "BG": true,
    "BH": true,
    "BHARTI": true,
    "BI": true,
    "BIBLE": true,
    "BID": true,
    "BIKE": true,
    "BING": true,
    "BINGO": true,
    "BIO": true,
    "BIZ": true,
    "BJ": true,
    "BLACK": true,
    "BLACKFRIDAY": true,
    "BLANCO": true,
    "BLOCKBUSTER": true,
    "BLOG": true,
    "BLOOMBERG": true,
    "BLUE": true,
    "BM": true,
    "BMS": true,
    "BMW": true,
    "BN": true,
    "BNL": true,
    "BNPPARIBAS": true,
    "BO": true,
    "BOATS": true,
    "BOEHRINGER": true,
    "BOFA": true,
    "BOM": true,
    "BOND": true,
    "BOO": true,
    "BOOK": true,
    "BOOKING": true,
    "BOSCH": true,
    "BOSTIK": true,
    "BOSTON": true,
    "BOT": true,
    "BOUTIQUE": true,
    "BOX": true,
    "BR": true,
    "BRADESCO": true,
    "BRIDGESTONE": true,
    "BROADWAY": true,
    "BROKER": true,
    "BROTHER": true,
    "BRUSSELS": true,
    "BS": true,
    "BT": true,
    "BUDAPEST": true,
    "BUGATTI": true,
    "BUILD": true,
    "BUILDERS": true,
    "BUSINESS": true,
    "BUY": true,
    "BUZZ": true,
    "BV": true,
    "BW": true,
    "BY": true,
    "BZ": true,
    "BZH": true,
    "CA": true,
    "CAB": true,
    "CAFE": true,
    "CAL": true,
    "CALL": true,
    "CALVINKLEIN": true,
    "CAM": true,
    "CAMERA": true,
    "CAMP": true,
    "CANCERRESEARCH": true,
    "CANON": true,
    "CAPETOWN": true,
    "CAPITAL": true,
    "CAPITALONE": true,
    "CAR": true,
    "CARAVAN": true,
    "CARDS": true,
    "CARE": true,
    "CAREER": true,
    "CAREERS": true,
    "CARS": true,
    "CARTIER": true,
    "CASA": true,
    "CASE": true,
    "CASEIH": true,
    "CASH": true,
    "CASINO": true,
    "CAT": true,
    "CATERING": true,
    "CATHOLIC": true,
    "CBA": true,
    "CBN": true,
    "CBRE": true,
    "CBS": true,
    "CC": true,
    "CD": true,
    "CEB": true,
    "CENTER": true,
    "CEO": true,
    "CERN": true,
    "CF": true,
    "CFA": true,
    "CFD": true,
    "CG": true,
    "CH": true,
    "CHANEL": true,
    "CHANNEL": true,
    "CHARITY": true,
    "CHASE": true,
    "CHAT": true,
    "CHEAP": true,
    "CHINTAI": true,
    "CHRISTMAS": true,
    "CHROME": true,
    "CHRYSLER": true,
    "CHURCH": true,
    "CI": true,
    "CIPRIANI": true,
    "CIRCLE": true,
    "CISCO": true,
    "CITADEL": true,
    "CITI": true,
    "CITIC": true,
    "CITY": true,
    "CITYEATS": true,
    "CK": true,
    "CL": true,
    "CLAIMS": true,
    "CLEANING": true,
    "CLICK": true,
    "CLINIC": true,
    "CLINIQUE": true,
    "CLOTHING": true,
    "CLOUD": true,
    "CLUB": true,
    "CLUBMED": true,
    "CM": true,
    "CN": true,
    "CO": true,
    "COACH": true,
    "CODES": true,
    "COFFEE": true,
    "COLLEGE": true,
    "COLOGNE": true,
    "COM": true,
    "COMCAST": true,
    "COMMBANK": true,
    "COMMUNITY": true,
    "COMPANY": true,
    "COMPARE": true,
    "COMPUTER": true,
    "COMSEC": true,
    "CONDOS": true,
    "CONSTRUCTION": true,
    "CONSULTING": true,
    "CONTACT": true,
    "CONTRACTORS": true,
    "COOKING": true,
    "COOKINGCHANNEL": true,
    "COOL": true,
    "COOP": true,
    "CORSICA": true,
    "COUNTRY": true,
    "COUPON": true,
    "COUPONS": true,
    "COURSES": true,
    "CR": true,
    "CREDIT": true,
    "CREDITCARD": true,
    "CREDITUNION": true,
    "CRICKET": true,
    "CROWN": true,
    "CRS": true,
    "CRUISE": true,
    "CRUISES": true,
    "CSC": true,
    "CU": true,
    "CUISINELLA": true,
    "CV": true,
    "CW": true,
    "CX": true,
    "CY": true,
    "CYMRU": true,
    "CYOU": true,
    "CZ": true,
    "DABUR": true,
    "DAD": true,
    "DANCE": true,
    "DATA": true,
    "DATE": true,
    "DATING": true,
    "DATSUN": true,
    "DAY": true,
    "DCLK": true,
    "DDS": true,
    "DE": true,
    "DEAL": true,
    "DEALER": true,
    "DEALS": true,
    "DEGREE": true,
    "DELIVERY": true,
    "DELL": true,
    "DELOITTE": true,
    "DELTA": true,
    "DEMOCRAT": true,
    "DENTAL": true,
    "DENTIST": true,
    "DESI": true,
    "DESIGN": true,
    "DEV": true,
    "DHL": true,
    "DIAMONDS": true,
    "DIET": true,
    "DIGITAL": true,
    "DIRECT": true,
    "DIRECTORY": true,
    "DISCOUNT": true,
    "DISCOVER": true,
    "DISH": true,
    "DIY": true,
    "DJ": true,
    "DK": true,
    "DM": true,
    "DNP": true,
    "DO": true,
    "DOCS": true,
    "DOCTOR": true,
    "DODGE": true,
    "DOG": true,
    "DOHA": true,
    "DOMAINS": true,
    "DOT": true,
    "DOWNLOAD": true,
    "DRIVE": true,
    "DTV": true,
    "DUBAI": true,
    "DUCK": true,
    "DUNLOP": true,
    "DUNS": true,
    "DUPONT": true,
    "DURBAN": true,
    "DVAG": true,
    "DVR": true,
    "DZ": true,
    "EARTH": true,
    "EAT": true,
    "EC": true,
    "ECO": true,
    "EDEKA": true,
    "EDU": true,
    "EDUCATION": true,
    "EE": true,
    "EG": true,
    "EMAIL": true,
    "EMERCK": true,
    "ENERGY": true,
    "ENGINEER": true,
    "ENGINEERING": true,
    "ENTERPRISES": true,
    "EPOST": true,
    "EPSON": true,
    "EQUIPMENT": true,
    "ER": true,
    "ERICSSON": true,
    "ERNI": true,
    "ES": true,
    "ESQ": true,
    "ESTATE": true,
    "ESURANCE": true,
    "ET": true,
    "ETISALAT": true,
    "EU": true,
    "EUROVISION": true,
    "EUS": true,
    "EVENTS": true,
    "EVERBANK": true,
    "EXCHANGE": true,
    "EXPERT": true,
    "EXPOSED": true,
    "EXPRESS": true,
    "EXTRASPACE": true,
    "FAGE": true,
    "FAIL": true,
    "FAIRWINDS": true,
    "FAITH": true,
    "FAMILY": true,
    "FAN": true,
    "FANS": true,
    "FARM": true,
    "FARMERS": true,
    "FASHION": true,
    "FAST": true,
    "FEDEX": true,
    "FEEDBACK": true,
    "FERRARI": true,
    "FERRERO": true,
    "FI": true,
    "FIAT": true,
    "FIDELITY": true,
    "FIDO": true,
    "FILM": true,
    "FINAL": true,
    "FINANCE": true,
    "FINANCIAL": true,
    "FIRE": true,
    "FIRESTONE": true,
    "FIRMDALE": true,
    "FISH": true,
    "FISHING": true,
    "FIT": true,
    "FITNESS": true,
    "FJ": true,
    "FK": true,
    "FLICKR": true,
    "FLIGHTS": true,
    "FLIR": true,
    "FLORIST": true,
    "FLOWERS": true,
    "FLY": true,
    "FM": true,
    "FO": true,
    "FOO": true,
    "FOOD": true,
    "FOODNETWORK": true,
    "FOOTBALL": true,
    "FORD": true,
    "FOREX": true,
    "FORSALE": true,
    "FORUM": true,
    "FOUNDATION": true,
    "FOX": true,
    "FR": true,
    "FREE": true,
    "FRESENIUS": true,
    "FRL": true,
    "FROGANS": true,
    "FRONTDOOR": true,
    "FRONTIER": true,
    "FTR": true,
    "FUJITSU": true,
    "FUJIXEROX": true,
    "FUN": true,
    "FUND": true,
    "FURNITURE": true,
    "FUTBOL": true,
    "FYI": true,
    "GA": true,
    "GAL": true,
    "GALLERY": true,
    "GALLO": true,
    "GALLUP": true,
    "GAME": true,
    "GAMES": true,
    "GAP": true,
    "GARDEN": true,
    "GB": true,
    "GBIZ": true,
    "GD": true,
    "GDN": true,
    "GE": true,
    "GEA": true,
    "GENT": true,
    "GENTING": true,
    "GEORGE": true,
    "GF": true,
    "GG": true,
    "GGEE": true,
    "GH": true,
    "GI": true,
    "GIFT": true,
    "GIFTS": true,
    "GIVES": true,
    "GIVING": true,
    "GL": true,
    "GLADE": true,
    "GLASS": true,
    "GLE": true,
    "GLOBAL": true,
    "GLOBO": true,
    "GM": true,
    "GMAIL": true,
    "GMBH": true,
    "GMO": true,
    "GMX": true,
    "GN": true,
    "GODADDY": true,
    "GOLD": true,
    "GOLDPOINT": true,
    "GOLF": true,
    "GOO": true,
    "GOODHANDS": true,
    "GOODYEAR": true,
    "GOOG": true,
    "GOOGLE": true,
    "GOP": true,
    "GOT": true,
    "GOV": true,
    "GP": true,
    "GQ": true,
    "GR": true,
    "GRAINGER": true,
    "GRAPHICS": true,
    "GRATIS": true,
    "GREEN": true,
    "GRIPE": true,
    "GROCERY": true,
    "GROUP": true,
    "GS": true,
    "GT": true,
    "GU": true,
    "GUARDIAN": true,
    "GUCCI": true,
    "GUGE": true,
    "GUIDE": true,
    "GUITARS": true,
    "GURU": true,
    "GW": true,
    "GY": true,
    "HAIR": true,
    "HAMBURG": true,
    "HANGOUT": true,
    "HAUS": true,
    "HBO": true,
    "HDFC": true,
    "HDFCBANK": true,
    "HEALTH": true,
    "HEALTHCARE": true,
    "HELP": true,
    "HELSINKI": true,
    "HERE": true,
    "HERMES": true,
    "HGTV": true,
    "HIPHOP": true,
    "HISAMITSU": true,
    "HITACHI": true,
    "HIV": true,
    "HK": true,
    "HKT": true,
    "HM": true,
    "HN": true,
    "HOCKEY": true,
    "HOLDINGS": true,
    "HOLIDAY": true,
    "HOMEDEPOT": true,
    "HOMEGOODS": true,
    "HOMES": true,
    "HOMESENSE": true,
    "HONDA": true,
    "HONEYWELL": true,
    "HORSE": true,
    "HOSPITAL": true,
    "HOST": true,
    "HOSTING": true,
    "HOT": true,
    "HOTELES": true,
    "HOTELS": true,
    "HOTMAIL": true,
    "HOUSE": true,
    "HOW": true,
    "HR": true,
    "HSBC": true,
    "HT": true,
    "HU": true,
    "HUGHES": true,
    "HYATT": true,
    "HYUNDAI": true,
    "IBM": true,
    "ICBC": true,
    "ICE": true,
    "ICU": true,
    "ID": true,
    "IE": true,
    "IEEE": true,
    "IFM": true,
    "IKANO": true,
    "IL": true,
    "IM": true,
    "IMAMAT": true,
    "IMDB": true,
    "IMMO": true,
    "IMMOBILIEN": true,
    "IN": true,
    "INC": true,
    "INDUSTRIES": true,
    "INFINITI": true,
    "INFO": true,
    "ING": true,
    "INK": true,
    "INSTITUTE": true,
    "INSURANCE": true,
    "INSURE": true,
    "INT": true,
    "INTEL": true,
    "INTERNATIONAL": true,
    "INTUIT": true,
    "INVESTMENTS": true,
    "IO": true,
    "IPIRANGA": true,
    "IQ": true,
    "IR": true,
    "IRISH": true,
    "IS": true,
    "ISELECT": true,
    "ISMAILI": true,
    "IST": true,
    "ISTANBUL": true,
    "IT": true,
    "ITAU": true,
    "ITV": true,
    "IVECO": true,
    "JAGUAR": true,
    "JAVA": true,
    "JCB": true,
    "JCP": true,
    "JE": true,
    "JEEP": true,
    "JETZT": true,
    "JEWELRY": true,
    "JIO": true,
    "JLC": true,
    "JLL": true,
    "JM": true,
    "JMP": true,
    "JNJ": true,
    "JO": true,
    "JOBS": true,
    "JOBURG": true,
    "JOT": true,
    "JOY": true,
    "JP": true,
    "JPMORGAN": true,
    "JPRS": true,
    "JUEGOS": true,
    "JUNIPER": true,
    "KAUFEN": true,
    "KDDI": true,
    "KE": true,
    "KERRYHOTELS": true,
    "KERRYLOGISTICS": true,
    "KERRYPROPERTIES": true,
    "KFH": true,
    "KG": true,
    "KH": true,
    "KI": true,
    "KIA": true,
    "KIM": true,
    "KINDER": true,
    "KINDLE": true,
    "KITCHEN": true,
    "KIWI": true,
    "KM": true,
    "KN": true,
    "KOELN": true,
    "KOMATSU": true,
    "KOSHER": true,
    "KP": true,
    "KPMG": true,
    "KPN": true,
    "KR": true,
    "KRD": true,
    "KRED": true,
    "KUOKGROUP": true,
    "KW": true,
    "KY": true,
    "KYOTO": true,
    "KZ": true,
    "LA": true,
    "LACAIXA": true,
    "LADBROKES": true,
    "LAMBORGHINI": true,
    "LAMER": true,
    "LANCASTER": true,
    "LANCIA": true,
    "LANCOME": true,
    "LAND": true,
    "LANDROVER": true,
    "LANXESS": true,
    "LASALLE": true,
    "LAT": true,
    "LATINO": true,
    "LATROBE": true,
    "LAW": true,
    "LAWYER": true,
    "LB": true,
    "LC": true,
    "LDS": true,
    "LEASE": true,
    "LECLERC": true,
    "LEFRAK": true,
    "LEGAL": true,
    "LEGO": true,
    "LEXUS": true,
    "LGBT": true,
    "LI": true,
    "LIAISON": true,
    "LIDL": true,
    "LIFE": true,
    "LIFEINSURANCE": true,
    "LIFESTYLE": true,
    "LIGHTING": true,
    "LIKE": true,
    "LILLY": true,
    "LIMITED": true,
    "LIMO": true,
    "LINCOLN": true,
    "LINDE": true,
    "LINK": true,
    "LIPSY": true,
    "LIVE": true,
    "LIVING": true,
    "LIXIL": true,
    "LK": true,
    "LLC": true,
    "LOAN": true,
    "LOANS": true,
    "LOCKER": true,
    "LOCUS": true,
    "LOFT": true,
    "LOL": true,
    "LONDON": true,
    "LOTTE": true,
    "LOTTO": true,
    "LOVE": true,
    "LPL": true,
    "LPLFINANCIAL": true,
    "LR": true,
    "LS": true,
    "LT": true,
    "LTD": true,
    "LTDA": true,
    "LU": true,
    "LUNDBECK": true,
    "LUPIN": true,
    "LUXE": true,
    "LUXURY": true,
    "LV": true,
    "LY": true,
    "MA": true,
    "MACYS": true,
    "MADRID": true,
    "MAIF": true,
    "MAISON": true,
    "MAKEUP": true,
    "MAN": true,
    "MANAGEMENT": true,
    "MANGO": true,
    "MAP": true,
    "MARKET": true,
    "MARKETING": true,
    "MARKETS": true,
    "MARRIOTT": true,
    "MARSHALLS": true,
    "MASERATI": true,
    "MATTEL": true,
    "MBA": true,
    "MC": true,
    "MCKINSEY": true,
    "MD": true,
    "ME": true,
    "MED": true,
    "MEDIA": true,
    "MEET": true,
    "MELBOURNE": true,
    "MEME": true,
    "MEMORIAL": true,
    "MEN": true,
    "MENU": true,
    "MERCKMSD": true,
    "METLIFE": true,
    "MG": true,
    "MH": true,
    "MIAMI": true,
    "MICROSOFT": true,
    "MIL": true,
    "MINI": true,
    "MINT": true,
    "MIT": true,
    "MITSUBISHI": true,
    "MK": true,
    "ML": true,
    "MLB": true,
    "MLS": true,
    "MM": true,
    "MMA": true,
    "MN": true,
    "MO": true,
    "MOBI": true,
    "MOBILE": true,
    "MOBILY": true,
    "MODA": true,
    "MOE": true,
    "MOI": true,
    "MOM": true,
    "MONASH": true,
    "MONEY": true,
    "MONSTER": true,
    "MOPAR": true,
    "MORMON": true,
    "MORTGAGE": true,
    "MOSCOW": true,
    "MOTO": true,
    "MOTORCYCLES": true,
    "MOV": true,
    "MOVIE": true,
    "MOVISTAR": true,
    "MP": true,
    "MQ": true,
    "MR": true,
    "MS": true,
    "MSD": true,
    "MT": true,
    "MTN": true,
    "MTR": true,
    "MU": true,
    "MUSEUM": true,
    "MUTUAL": true,
    "MV": true,
    "MW": true,
    "MX": true,
    "MY": true,
    "MZ": true,
    "NA": true,
    "NAB": true,
    "NADEX": true,
    "NAGOYA": true,
    "NAME": true,
    "NATIONWIDE": true,
    "NATURA": true,
    "NAVY": true,
    "NBA": true,
    "NC": true,
    "NE": true,
    "NEC": true,
    "NET": true,
    "NETBANK": true,
    "NETFLIX": true,
    "NETWORK": true,
    "NEUSTAR": true,
    "NEW": true,
    "NEWHOLLAND": true,
    "NEWS": true,
    "NEXT": true,
    "NEXTDIRECT": true,
    "NEXUS": true,
    "NF": true,
    "NFL": true,
    "NG": true,
    "NGO": true,
    "NHK": true,
    "NI": true,
    "NICO": true,
    "NIKE": true,
    "NIKON": true,
    "NINJA": true,
    "NISSAN": true,
    "NISSAY": true,
    "NL": true,
    "NO": true,
    "NOKIA": true,
    "NORTHWESTERNMUTUAL": true,
    "NORTON": true,
    "NOW": true,
    "NOWRUZ": true,
    "NOWTV": true,
    "NP": true,
    "NR": true,
    "NRA": true,
    "NRW": true,
    "NTT": true,
    "NU": true,
    "NYC": true,
    "NZ": true,
    "OBI": true,
    "OBSERVER": true,
    "OFF": true,
    "OFFICE": true,
    "OKINAWA": true,
    "OLAYAN": true,
    "OLAYANGROUP": true,
    "OLDNAVY": true,
    "OLLO": true,
    "OM": true,
    "OMEGA": true,
    "ONE": true,
    "ONG": true,
    "ONL": true,
    "ONLINE": true,
    "ONYOURSIDE": true,
    "OOO": true,
    "OPEN": true,
    "ORACLE": true,
    "ORANGE": true,
    "ORG": true,
    "ORGANIC": true,
    "ORIGINS": true,
    "OSAKA": true,
    "OTSUKA": true,
    "OTT": true,
    "OVH": true,
    "PA": true,
    "PAGE": true,
    "PANASONIC": true,
    "PANERAI": true,
    "PARIS": true,
    "PARS": true,
    "PARTNERS": true,
    "PARTS": true,
    "PARTY": true,
    "PASSAGENS": true,
    "PAY": true,
    "PCCW": true,
    "PE": true,
    "PET": true,
    "PF": true,
    "PFIZER": true,
    "PG": true,
    "PH": true,
    "PHARMACY": true,
    "PHD": true,
    "PHILIPS": true,
    "PHONE": true,
    "PHOTO": true,
    "PHOTOGRAPHY": true,
    "PHOTOS": true,
    "PHYSIO": true,
    "PIAGET": true,
    "PICS": true,
    "PICTET": true,
    "PICTURES": true,
    "PID": true,
    "PIN": true,
    "PING": true,
    "PINK": true,
    "PIONEER": true,
    "PIZZA": true,
    "PK": true,
    "PL": true,
    "PLACE": true,
    "PLAY": true,
    "PLAYSTATION": true,
    "PLUMBING": true,
    "PLUS": true,
    "PM": true,
    "PN": true,
    "PNC": true,
    "POHL": true,
    "POKER": true,
    "POLITIE": true,
    "PORN": true,
    "POST": true,
    "PR": true,
    "PRAMERICA": true,
    "PRAXI": true,
    "PRESS": true,
    "PRIME": true,
    "PRO": true,
    "PROD": true,
    "PRODUCTIONS": true,
    "PROF": true,
    "PROGRESSIVE": true,
    "PROMO": true,
    "PROPERTIES": true,
    "PROPERTY": true,
    "PROTECTION": true,
    "PRU": true,
    "PRUDENTIAL": true,
    "PS": true,
    "PT": true,
    "PUB": true,
    "PW": true,
    "PWC": true,
    "PY": true,
    "QA": true,
    "QPON": true,
    "QUEBEC": true,
    "QUEST": true,
    "QVC": true,
    "RACING": true,
    "RADIO": true,
    "RAID": true,
    "RE": true,
    "READ": true,
    "REALESTATE": true,
    "REALTOR": true,
    "REALTY": true,
    "RECIPES": true,
    "RED": true,
    "REDSTONE": true,
    "REDUMBRELLA": true,
    "REHAB": true,
    "REISE": true,
    "REISEN": true,
    "REIT": true,
    "RELIANCE": true,
    "REN": true,
    "RENT": true,
    "RENTALS": true,
    "REPAIR": true,
    "REPORT": true,
    "REPUBLICAN": true,
    "REST": true,
    "RESTAURANT": true,
    "REVIEW": true,
    "REVIEWS": true,
    "REXROTH": true,
    "RICH": true,
    "RICHARDLI": true,
    "RICOH": true,
    "RIGHTATHOME": true,
    "RIL": true,
    "RIO": true,
    "RIP": true,
    "RMIT": true,
    "RO": true,
    "ROCHER": true,
    "ROCKS": true,
    "RODEO": true,
    "ROGERS": true,
    "ROOM": true,
    "RS": true,
    "RSVP": true,
    "RU": true,
    "RUGBY": true,
    "RUHR": true,
    "RUN": true,
    "RW": true,
    "RWE": true,
    "RYUKYU": true,
    "SA": true,
    "SAARLAND": true,
    "SAFE": true,
    "SAFETY": true,
    "SAKURA": true,
    "SALE": true,
    "SALON": true,
    "SAMSCLUB": true,
    "SAMSUNG": true,
    "SANDVIK": true,
    "SANDVIKCOROMANT": true,
    "SANOFI": true,
    "SAP": true,
    "SARL": true,
    "SAS": true,
    "SAVE": true,
    "SAXO": true,
    "SB": true,
    "SBI": true,
    "SBS": true,
    "SC": true,
    "SCA": true,
    "SCB": true,
    "SCHAEFFLER": true,
    "SCHMIDT": true,
    "SCHOLARSHIPS": true,
    "SCHOOL": true,
    "SCHULE": true,
    "SCHWARZ": true,
    "SCIENCE": true,
    "SCJOHNSON": true,
    "SCOR": true,
    "SCOT": true,
    "SD": true,
    "SE": true,
    "SEARCH": true,
    "SEAT": true,
    "SECURE": true,
    "SECURITY": true,
    "SEEK": true,
    "SELECT": true,
    "SENER": true,
    "SERVICES": true,
    "SES": true,
    "SEVEN": true,
    "SEW": true,
    "SEX": true,
    "SEXY": true,
    "SFR": true,
    "SG": true,
    "SH": true,
    "SHANGRILA": true,
    "SHARP": true,
    "SHAW": true,
    "SHELL": true,
    "SHIA": true,
    "SHIKSHA": true,
    "SHOES": true,
    "SHOP": true,
    "SHOPPING": true,
    "SHOUJI": true,
    "SHOW": true,
    "SHOWTIME": true,
    "SHRIRAM": true,
    "SI": true,
    "SILK": true,
    "SINA": true,
    "SINGLES": true,
    "SITE": true,
    "SJ": true,
    "SK": true,
    "SKI": true,
    "SKIN": true,
    "SKY": true,
    "SKYPE": true,
    "SL": true,
    "SLING": true,
    "SM": true,
    "SMART": true,
    "SMILE": true,
    "SN": true,
    "SNCF": true,
    "SO": true,
    "SOCCER": true,
    "SOCIAL": true,
    "SOFTBANK": true,
    "SOFTWARE": true,
    "SOHU": true,
    "SOLAR": true,
    "SOLUTIONS": true,
    "SONG": true,
    "SONY": true,
    "SOY": true,
    "SPACE": true,
    "SPIEGEL": true,
    "SPORT": true,
    "SPOT": true,
    "SPREADBETTING": true,
    "SR": true,
    "SRL": true,
    "SRT": true,
    "ST": true,
    "STADA": true,
    "STAPLES": true,
    "STAR": true,
    "STARHUB": true,
    "STATEBANK": true,
    "STATEFARM": true,
    "STATOIL": true,
    "STC": true,
    "STCGROUP": true,
    "STOCKHOLM": true,
    "STORAGE": true,
    "STORE": true,
    "STREAM": true,
    "STUDIO": true,
    "STUDY": true,
    "STYLE": true,
    "SU": true,
    "SUCKS": true,
    "SUPPLIES": true,
    "SUPPLY": true,
    "SUPPORT": true,
    "SURF": true,
    "SURGERY": true,
    "SUZUKI": true,
    "SV": true,
    "SWATCH": true,
    "SWIFTCOVER": true,
    "SWISS": true,
    "SX": true,
    "SY": true,
    "SYDNEY": true,
    "SYMANTEC": true,
    "SYSTEMS": true,
    "SZ": true,
    "TAB": true,
    "TAIPEI": true,
    "TALK": true,
    "TAOBAO": true,
    "TARGET": true,
    "TATAMOTORS": true,
    "TATAR": true,
    "TATTOO": true,
    "TAX": true,
    "TAXI": true,
    "TC": true,
    "TCI": true,
    "TD": true,
    "TDK": true,
    "TEAM": true,
    "TECH": true,
    "TECHNOLOGY": true,
    "TEL": true,
    "TELECITY": true,
    "TELEFONICA": true,
    "TEMASEK": true,
    "TENNIS": true,
    "TEVA": true,
    "TF": true,
    "TG": true,
    "TH": true,
    "THD": true,
    "THEATER": true,
    "THEATRE": true,
    "TIAA": true,
    "TICKETS": true,
    "TIENDA": true,
    "TIFFANY": true,
    "TIPS": true,
    "TIRES": true,
    "TIROL": true,
    "TJ": true,
    "TJMAXX": true,
    "TJX": true,
    "TK": true,
    "TKMAXX": true,
    "TL": true,
    "TM": true,
    "TMALL": true,
    "TN": true,
    "TO": true,
    "TODAY": true,
    "TOKYO": true,
    "TOOLS": true,
    "TOP": true,
    "TORAY": true,
    "TOSHIBA": true,
    "TOTAL": true,
    "TOURS": true,
    "TOWN": true,
    "TOYOTA": true,
    "TOYS": true,
    "TR": true,
    "TRADE": true,
    "TRADING": true,
    "TRAINING": true,
    "TRAVEL": true,
    "TRAVELCHANNEL": true,
    "TRAVELERS": true,
    "TRAVELERSINSURANCE": true,
    "TRUST": true,
    "TRV": true,
    "TT": true,
    "TUBE": true,
    "TUI": true,
    "TUNES": true,
    "TUSHU": true,
    "TV": true,
    "TVS": true,
    "TW": true,
    "TZ": true,
    "UA": true,
    "UBANK": true,
    "UBS": true,
    "UCONNECT": true,
    "UG": true,
    "UK": true,
    "UNICOM": true,
    "UNIVERSITY": true,
    "UNO": true,
    "UOL": true,
    "UPS": true,
    "US": true,
    "UY": true,
    "UZ": true,
    "VA": true,
    "VACATIONS": true,
    "VANA": true,
    "VANGUARD": true,
    "VC": true,
    "VE": true,
    "VEGAS": true,
    "VENTURES": true,
    "VERISIGN": true,
    "VERSICHERUNG": true,
    "VET": true,
    "VG": true,
    "VI": true,
    "VIAJES": true,
    "VIDEO": true,
    "VIG": true,
    "VIKING": true,
    "VILLAS": true,
    "VIN": true,
    "VIP": true,
    "VIRGIN": true,
    "VISA": true,
    "VISION": true,
    "VISTA": true,
    "VISTAPRINT": true,
    "VIVA": true,
    "VIVO": true,
    "VLAANDEREN": true,
    "VN": true,
    "VODKA": true,
    "VOLKSWAGEN": true,
    "VOLVO": true,
    "VOTE": true,
    "VOTING": true,
    "VOTO": true,
    "VOYAGE": true,
    "VU": true,
    "VUELOS": true,
    "WALES": true,
    "WALMART": true,
    "WALTER": true,
    "WANG": true,
    "WANGGOU": true,
    "WARMAN": true,
    "WATCH": true,
    "WATCHES": true,
    "WEATHER": true,
    "WEATHERCHANNEL": true,
    "WEBCAM": true,
    "WEBER": true,
    "WEBSITE": true,
    "WED": true,
    "WEDDING": true,
    "WEIBO": true,
    "WEIR": true,
    "WF": true,
    "WHOSWHO": true,
    "WIEN": true,
    "WIKI": true,
    "WILLIAMHILL": true,
    "WIN": true,
    "WINDOWS": true,
    "WINE": true,
    "WINNERS": true,
    "WME": true,
    "WOLTERSKLUWER": true,
    "WOODSIDE": true,
    "WORK": true,
    "WORKS": true,
    "WORLD": true,
    "WOW": true,
    "WS": true,
    "WTC": true,
    "WTF": true,
    "XBOX": true,
    "XEROX": true,
    "XFINITY": true,
    "XIHUAN": true,
    "XIN": true,
    "XN--trueBtrueCtrueD": true,
    "XN--trueCKtrueEtrueB": true,
    "XN--trueQQWtrueA": true,
    "XN--trueSCRJtrueC": true,
    "XN--trueRRtrueY": true,
    "XN--trueBSTtrueM": true,
    "XN--trueDStrueG": true,
    "XN--trueEtrueBtrueE": true,
    "XN--trueHCRJtrueC": true,
    "XN--trueOQtrueVLtruePNtrueA": true,
    "XN--truePXUtrueK": true,
    "XN--trueCtrueDtrueA": true,
    "XN--trueBRtrueCYL": true,
    "XN--trueBRJtrueC": true,
    "XN--trueQtrueC": true,
    "XN--trueGBRIM": true,
    "XN--trueBtrueFTAtrueCC": true,
    "XN--trueQWtrueG": true,
    "XN--trueQXtrueD": true,
    "XN--trueSUtrueJtrueBGSG": true,
    "XN--trueTZMtrueG": true,
    "XN--trueFRZtrueG": true,
    "XN--trueQQtrueBtrueXL": true,
    "XN--trueADXHKS": true,
    "XN--trueAOtrueA": true,
    "XN--trueAQECDRtrueA": true,
    "XN--trueASEHDB": true,
    "XN--trueASWG": true,
    "XN--trueYtrueAtrueA": true,
    "XN--trueAtrueAC": true,
    "XN--trueAE": true,
    "XN--trueAIS": true,
    "XN--trueDBQtrueA": true,
    "XN--trueETtrueU": true,
    "XN--trueKRTtrueA": true,
    "XN--BtrueWtrueFERD": true,
    "XN--BCKtrueBtrueAtrueDREtrueC": true,
    "XN--CtrueAVG": true,
    "XN--CtrueBRtrueG": true,
    "XN--CCKtrueBtrueB": true,
    "XN--CGtrueBKI": true,
    "XN--CLCHCtrueEAtrueBtrueGtrueAtrueGCD": true,
    "XN--CZRtrueB": true,
    "XN--CZRStrueT": true,
    "XN--CZRUtrueD": true,
    "XN--DtrueACJtrueB": true,
    "XN--DtrueALF": true,
    "XN--EtrueAtrueC": true,
    "XN--ECKVDTCtrueD": true,
    "XN--EFVYtrueH": true,
    "XN--ESTVtrueG": true,
    "XN--FCTtrueK": true,
    "XN--FHBEI": true,
    "XN--FIQtrueCtrueHS": true,
    "XN--FIQtrueB": true,
    "XN--FIQStrueS": true,
    "XN--FIQZtrueS": true,
    "XN--FJQtrueA": true,
    "XN--FLWtrueE": true,
    "XN--FPCRJtrueCtrueD": true,
    "XN--FZCtrueCtrueEtrueC": true,
    "XN--FZYStrueDtrueUVGM": true,
    "XN--GtrueXXtrueC": true,
    "XN--GCKRtrueFtrueF": true,
    "XN--GECRJtrueC": true,
    "XN--GKtrueATtrueE": true,
    "XN--HtrueBREGtrueEVE": true,
    "XN--HtrueBRJtrueC": true,
    "XN--HtrueBRJtrueCtrueC": true,
    "XN--HXTtrueE": true,
    "XN--ItrueBtrueBtrueAtrueAtrueE": true,
    "XN--IMRtrueN": true,
    "XN--IOtrueAtrueI": true,
    "XN--JtrueAEF": true,
    "XN--JtrueAMH": true,
    "XN--JtrueWtrueG": true,
    "XN--JLQtrueUtrueWtrueB": true,
    "XN--JVRtrueM": true,
    "XN--KCRXtrueDtrueXtrueA": true,
    "XN--KPRWtrueD": true,
    "XN--KPRYtrueD": true,
    "XN--KPUtrueF": true,
    "XN--KPUTtrueI": true,
    "XN--LtrueACC": true,
    "XN--LGBBATtrueADtrueJ": true,
    "XN--MGBtrueAWBF": true,
    "XN--MGBAtrueAtrueEJT": true,
    "XN--MGBAtrueAtrueFtrueA": true,
    "XN--MGBAtrueCtrueBBNtrueA": true,
    "XN--MGBAAKCtrueDVF": true,
    "XN--MGBAAMtrueAtrueH": true,
    "XN--MGBABtrueBD": true,
    "XN--MGBAItrueAZGQPtrueJ": true,
    "XN--MGBAYHtrueGPA": true,
    "XN--MGBBtrueFBPOB": true,
    "XN--MGBBHtrueA": true,
    "XN--MGBBHtrueAtrueE": true,
    "XN--MGBCtrueAtrueAZCG": true,
    "XN--MGBCAtrueDZDO": true,
    "XN--MGBERPtrueAtrueDtrueAR": true,
    "XN--MGBGUtrueA": true,
    "XN--MGBItrueECEXP": true,
    "XN--MGBPLtrueFH": true,
    "XN--MGBTtrueDHD": true,
    "XN--MGBTXtrueB": true,
    "XN--MGBXtrueCDtrueAB": true,
    "XN--MIXtrueF": true,
    "XN--MKtrueBUtrueC": true,
    "XN--MXTQtrueM": true,
    "XN--NGBCtrueAZD": true,
    "XN--NGBEtrueEtrueA": true,
    "XN--NGBRX": true,
    "XN--NODE": true,
    "XN--NQVtrueF": true,
    "XN--NQVtrueFStrueEMA": true,
    "XN--NYQYtrueA": true,
    "XN--OtrueCWtrueH": true,
    "XN--OGBPFtrueFL": true,
    "XN--OTUtrueD": true,
    "XN--PtrueACF": true,
    "XN--PtrueAI": true,
    "XN--PBTtrueC": true,
    "XN--PGBStrueDH": true,
    "XN--PSSYtrueU": true,
    "XN--QtrueJYBtrueC": true,
    "XN--QCKAtruePMC": true,
    "XN--QXAM": true,
    "XN--RHQVtrueG": true,
    "XN--ROVUtrueB": true,
    "XN--RVCtrueEtrueAMtrueE": true,
    "XN--StrueBRJtrueC": true,
    "XN--SEStrueG": true,
    "XN--TtrueBtrueA": true,
    "XN--TCKWE": true,
    "XN--TIQtrueXQYJ": true,
    "XN--UNUPtrueY": true,
    "XN--VERMGENSBERATER-CTB": true,
    "XN--VERMGENSBERATUNG-PWB": true,
    "XN--VHQUV": true,
    "XN--VUQtrueB": true,
    "XN--WtrueRtrueELtrueFHUtrueDNRA": true,
    "XN--WtrueRStrueL": true,
    "XN--WGBHtrueC": true,
    "XN--WGBLtrueA": true,
    "XN--XHQtrueB": true,
    "XN--XKCtrueALtrueHYEtrueA": true,
    "XN--XKCtrueDLtrueAtrueEEtrueH": true,
    "XN--YtrueAtrueAQ": true,
    "XN--YFROtrueItrueO": true,
    "XN--YGBItrueAMMX": true,
    "XN--ZFRtrueB": true,
    "XXX": true,
    "XYZ": true,
    "YACHTS": true,
    "YAHOO": true,
    "YAMAXUN": true,
    "YANDEX": true,
    "YE": true,
    "YODOBASHI": true,
    "YOGA": true,
    "YOKOHAMA": true,
    "YOU": true,
    "YOUTUBE": true,
    "YT": true,
    "YUN": true,
    "ZA": true,
    "ZAPPOS": true,
    "ZARA": true,
    "ZERO": true,
    "ZIP": true,
    "ZIPPO": true,
    "ZM": true,
    "ZONE": true,
    "ZUERICH": true,
    "ZW": true
  }
}


export default emails; 